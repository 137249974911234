<template>
  <div>
    <template v-if="editionMode">
      <div class="grid grid-cols-3 gap-3">
        <div
          class="relative"
          :class="
            priceEncodingType === PriceEncodingTypes.HTVA
              ? 'order-1'
              : 'order-3'
          "
        >
          <span
            v-if="
              priceEncodingType === PriceEncodingTypes.HTVA && displayLockButton
            "
            class="absolute bottom-2 left-3 z-10 flex flex-row items-center"
          >
            <button
              @click="
                canLockUnlock
                  ? (localLocked = !locked)
                  : $h.toastInfo($t('toasts.price_locked'))
              "
            >
              <FontAwesomeIcon :icon="['fal', locked ? 'lock' : 'lock-open']" />
            </button>
          </span>
          <BaseShowEditPriceInput
            v-model="localHtvaValue"
            :label="
              priceEncodingType === PriceEncodingTypes.HTVA
                ? `${label} (${$t('global.excluding_vat')})`
                : $t('global.excluding_vat')
            "
            :edition-mode="editionMode"
            :required="
              required && priceEncodingType === PriceEncodingTypes.HTVA
            "
            price-type="htva"
            :disabled="priceEncodingType === PriceEncodingTypes.TVAC || locked"
            :errors="errorsPriceHtva"
          />
        </div>
        <div class="order-2">
          <BaseShowEditPriceInput
            v-model="localTvaValue"
            :label="`${$t('global.vat')} (${tvaRateAsLabel}%)`"
            :edition-mode="editionMode"
            :disabled="true"
            price-type="tva"
            :errors="errorsPriceTvaValue"
          />
        </div>
        <div
          class="relative"
          :class="
            priceEncodingType === PriceEncodingTypes.TVAC
              ? 'order-1'
              : 'order-3'
          "
        >
          <span
            v-if="
              priceEncodingType === PriceEncodingTypes.TVAC && displayLockButton
            "
            class="absolute bottom-2 left-3 z-10 flex flex-row items-center"
          >
            <button
              @click="
                canLockUnlock
                  ? (localLocked = !locked)
                  : $h.toastInfo($t('toasts.price_locked'))
              "
            >
              <FontAwesomeIcon :icon="['fas', locked ? 'lock' : 'lock-open']" />
            </button>
          </span>
          <BaseShowEditPriceInput
            v-model="localTvacValue"
            :label="
              priceEncodingType === PriceEncodingTypes.TVAC
                ? `${label} (${$t('global.tax_included')})`
                : $t('global.tax_included')
            "
            :required="
              required && priceEncodingType === PriceEncodingTypes.TVAC
            "
            :edition-mode="editionMode"
            price-type="tvac"
            :disabled="priceEncodingType === PriceEncodingTypes.HTVA || locked"
            :errors="errorsPriceTvac"
          />
        </div>
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>
    <template v-else>
      <div class="grid grid-cols-3 gap-3">
        <div
          :class="
            priceEncodingType === PriceEncodingTypes.HTVA
              ? 'order-1'
              : 'order-3'
          "
        >
          <BaseShowPriceLabel
            :label="
              priceEncodingType === PriceEncodingTypes.HTVA
                ? `${label} (${$t('global.excluding_vat')})`
                : $t('global.excluding_vat')
            "
            :value="localHtvaValue"
            price-type="excluding_vat"
          />
        </div>
        <div class="order-2">
          <BaseShowPriceLabel
            :value="localTvaValue"
            price-type="vat"
            :label="`${$t('global.vat')} (${
              parseFloat(vatRate).toFixed() ?? 0
            }%)`"
          />
        </div>
        <div
          :class="
            priceEncodingType === PriceEncodingTypes.TVAC
              ? 'order-1'
              : 'order-3'
          "
        >
          <BaseShowPriceLabel
            :label="
              priceEncodingType === PriceEncodingTypes.TVAC
                ? `${label} (${$t('global.tax_included')})`
                : $t('global.tax_included')
            "
            :value="localTvacValue"
            price-type="tax_included"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseShowEditPriceInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditPriceInput'
import BaseShowPriceLabel from '@c/addf-package/components/BaseLabel/BaseShowPriceLabel.vue'
import { PriceEncodingTypes } from '@/assets/enums'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseShowEditPricingInput',
  components: {
    FontAwesomeIcon,
    ErrorContainer,
    BaseShowPriceLabel,
    BaseShowEditPriceInput
  },
  props: {
    vatRate: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: [Object, Array],
      required: false,
      default: null
    },
    errorsPriceHtva: {
      type: Array,
      default() {
        return []
      }
    },
    errorsPriceTvac: {
      type: Array,
      default() {
        return []
      }
    },
    errorsPriceTvaValue: {
      type: Array,
      default() {
        return []
      }
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    htvaValue: {
      type: Number,
      required: false,
      default: null
    },
    tvacValue: {
      type: Number,
      required: false,
      default: null
    },
    tvaValue: {
      type: Number,
      required: false,
      default: null
    },
    priceEncodingType: {
      type: String,
      required: true
    },
    isVertical: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    displayLockButton: {
      type: Boolean,
      required: false,
      default: false
    },
    canLockUnlock: {
      type: Boolean,
      required: false,
      default: false
    },
    locked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'update:htvaValue',
    'update:tvacValue',
    'update:tvaValue',
    'update:locked'
  ],
  computed: {
    PriceEncodingTypes() {
      return PriceEncodingTypes
    },
    tvaRateAsLabel() {
      return parseFloat(this.vatRate).toString()
    },
    localHtvaValue: {
      get() {
        return this.htvaValue
      },
      set(value) {
        this.updateFromHTVA(value)
        this.$emit('update:htvaValue', value)
      }
    },
    localTvacValue: {
      get() {
        return this.tvacValue
      },
      set(value) {
        this.updateFromTVAC(value)
        this.$emit('update:tvacValue', value)
      }
    },
    localTvaValue: {
      get() {
        return this.tvaValue
      },
      set(value) {
        this.$emit('update:tvaValue', value)
      }
    },
    localLocked: {
      get() {
        return this.locked
      },
      set(value) {
        this.$emit('update:locked', value)
      }
    }
  },
  watch: {
    vatRate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateFromTauxTVA(newValue)
      }
    }
  },
  methods: {
    updateFromTVAC(value) {
      if (this.priceEncodingType !== 'TVAC') {
        return
      }
      let htvaValue = (value / (1 + this.vatRate / 100)).toFixed(2)
      this.localHtvaValue = htvaValue
      this.localTvaValue = (value - htvaValue).toFixed(2)
    },
    updateFromHTVA(value) {
      if (this.priceEncodingType !== 'HTVA') {
        return
      }
      let tvacValue = value * (1 + this.vatRate / 100).toFixed(2)
      this.localTvacValue = tvacValue
      this.localTvaValue = tvacValue - value
    },
    updateFromTauxTVA(value) {
      if (this.priceEncodingType === 'TVAC') {
        let htvaValue = (this.localTvacValue / (1 + value / 100)).toFixed(2)
        this.localHtvaValue = htvaValue
        this.localTvaValue = (this.localTvacValue - htvaValue).toFixed(2)
      } else {
        let tvacValue = this.localHtvaValue * (1 + value / 100)
        this.localTvacValue = tvacValue
        this.localTvaValue = (tvacValue - this.localHtvaValue).toFixed(2)
      }
    }
  }
}
</script>
