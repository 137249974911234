import ability from '@u/ability'

export default {
  setAuth(state, payload) {
    state.token = payload.token
    if (typeof payload.expiration !== 'undefined') {
      state.sessionExpiration = payload.expiration
    }
    if (payload.remember) {
      state.remember = payload.remember
    }
    state.errors = payload.errors
  },
  setToken(state, token) {
    state.token = token
  },
  setErrors(state, errors) {
    state.errors = errors
  },
  setAuthUser(state, user) {
    state.user = user
  },
  setPermissions(state, _payload) {
    const permissions = state.user.permissions
    const roles = state.user.roles

    const arrayAbilities = []
    permissions.map((perm) =>
      arrayAbilities.push({ action: perm, subject: 'all' })
    )

    roles.map((role) => arrayAbilities.push({ action: role, subject: 'all' }))

    ability.update([])
    ability.update(arrayAbilities)
  }
}
