import apiClient from '@u/apiClient'

export default {
  async searchForAPerson({ commit }, payload) {
    await apiClient
      .get(`search-person?term=${payload.filterText}`)
      .then(async (response) => {
        await commit('setPersonList', response.data.personList)
      })
      .catch(async (reason) => {
        await console.error('searchForAPerson', reason)
      })
  }
}
