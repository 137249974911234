<template>
  <button
    class="popover-trigger-button grid aspect-square w-5 place-content-center rounded-full bg-yellow-400"
    :id="`elem-${uniqKey}`"
    :popovertarget="`popover-${uniqKey}`"
    popovertargetaction
  >
    <FontAwesomeIcon
      :icon="['fal', 'exclamation-circle']"
      size="sm"
      fixed-width
    />
  </button>
  <span popover class="z-100 bg-white p-2"> TEST </span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SimpleTooltip',
  components: { FontAwesomeIcon },
  props: {
    uniqKey: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.popover-trigger-button {
  anchor-name: --i-btn;
}

[popover] {
  inset: unset;
  bottom: anchor(--i-btn top);
  left: anchor(--i-btn center);
  translate: -50%;
  margin: 0.25rem 0;
}
</style>
