<template>
  <div
    class="flex h-full w-full flex-col overflow-y-hidden rounded-lg bg-white bg-white shadow-lg"
  >
    <div class="flex items-center justify-between">
      <div class="mb-5 flex items-center gap-x-5 px-8">
        <FontAwesomeIcon
          v-if="icon"
          :icon="[icon === 'star' ? 'fas' : 'fal', icon]"
          size="2x"
          :class="[
            icon === 'star' ? 'text-yellow-300' : 'text-slate-300',
            'mt-5'
          ]"
        />
        <div class="rounded-lg bg-white px-4 pt-5 sm:px-6">
          <h3
            class="cursor-default select-none text-lg font-medium leading-6 text-gray-600 capitalize-first"
          >
            {{ title }}
          </h3>
        </div>
      </div>
      <!--      <div class="pr-5">-->
      <!--        <BaseSearchInput @type-input="setSearchValue" />-->
      <!--      </div>-->
    </div>
    <div class="scrollbar-custom h-full h-full bg-gray-50 pl-3 pr-6">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WikiCityListComponent',
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue'],
  methods: {
    setSearchValue(searchValue) {
      this.$emit('update:modelValue', searchValue)
    }
  }
}
</script>
