import { sortEvents } from './helpers/helpers'
import DossierContactMutations from './dossier_contacts/mutations'
import DossierEventsMutations from './dossier_events/mutations'
import DossierItemsMutations from './dossier_items/mutations'
import DossierPartnersMutations from './dossier_partners/mutations'
import DossierPersonMutations from './dossier_person/mutations'
import DossierPropertiesMutations from './dossier_properties/mutations'
import DossierTaskMutations from './dossier_task/mutations'

import * as _ from 'lodash-es'

export default {
  ...DossierContactMutations,
  ...DossierEventsMutations,
  ...DossierItemsMutations,
  ...DossierPartnersMutations,
  ...DossierPersonMutations,
  ...DossierPropertiesMutations,
  ...DossierTaskMutations,

  setDossierProperties(state) {
    state.dossier.properties = {}
  },
  // #region setters
  setDossierMetadata(state, metadata) {
    state.dossierMetadata = metadata
  },

  addColumnToDossierMetadata(state, metadata) {
    state.dossierMetadata = metadata
  },

  setDossierList(state, dossiersList) {
    state.dossiersList = dossiersList
  },
  setDossierInList(state, updatedDossier) {
    let newList = state.dossiersList
    const index = newList.indexOf(
      newList.find((dossier) => dossier.id === updatedDossier.id)
    )
    newList.splice(index, 1, updatedDossier)

    state.dossiersList = newList
  },
  setDossier(state, dossier) {
    state.dossier = dossier
  },
  setDossierActivities(state, activities) {
    state.dossierActivities = activities
  },
  setDossierMetadataActivities(state, metadata) {
    state.dossierMetadataActivities = metadata
  },
  setDossierFieldFromDotNotation(state, payload) {
    Object.entries(payload).forEach((item) => {
      _.set(state.dossier, item[0], item[1])
    })
  },
  setDossierUrn(state) {
    state.dossier.urn = {}
    state.dossier.urn.awaiting_at_crema = 1
  },
  // #endregion

  // #region modifiers

  alterDossier(state, payload) {
    console.log(payload)
    console.log(payload.attribute)
    console.log(payload.value)
    state.dossier[payload.attribute] = payload.value
    console.log('mutation', state.dossier)
  },

  alterDossierProperties(state, payload) {
    state.dossier.properties[payload.attribute] = payload.value
  },

  alterDossierDeceased(state, payload) {
    console.log(
      'state.dossier.deceased[payload.attribute]',
      state.dossier.deceased[payload.attribute]
    )
    state.dossier.deceased[payload.attribute] = payload.value
    // if(payload.attribute === 'CON'){
    //   console.log('state.dossier.urn.awaiting_at_crema' , state.dossier.urn.awaiting_at_crema)
    //   state.dossier.urn.awaiting_at_crema = 1
    //   console.log('state.dossier.urn.awaiting_at_crema' , state.dossier.urn.awaiting_at_crema)
    // }
  },

  alterDossierUrn(state, payload) {
    // console.log(payload)
    state.dossier.urn[payload.attribute] = payload.value
  },

  alterDossierItemsValuesByDossierItemType(state, payload) {
    const objIndex = state.dossier?.dossier_items?.findIndex(
      (item) => item.type === payload.item_type
    )
    state.dossier.dossier_items[objIndex][payload.attribute] = payload.value
    sortEvents(state)
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setLeaveRouteByButton(state, leaveRouteByButton) {
    state.leaveRouteByButton = leaveRouteByButton
  },
  setLeaveRouteByNotification(state, leaveRouteByNotification) {
    state.leaveRouteByNotification = leaveRouteByNotification
  },
  setInitialDossier(state, dossier) {
    state.initialDossier = dossier
  },
  setHasChanged(state, hasChanged) {
    let response = (state.hasChanged =
      JSON.stringify(state.initialDossier) !== hasChanged)
    // console.log('response', response)
  },
  setContactsDossier(state, contacts) {
    state.contactsDossier = contacts
  },
  setDocumentsDossier(state, documents) {
    state.documentsDossier = documents
  },
  setNavSubItems(state, navSubItems) {
    state.navSubItems = navSubItems
  },
  // #endregion

  addOnlineMembers(state, payload) {
    if (
      !state.online_members.some(
        (online_member) => online_member.id === payload.id
      )
    ) {
      state.online_members.push(payload)
    }
  },
  removeOnlineMembers(state, payload) {
    state.online_members = state.online_members.filter(
      (online_member) => online_member.id !== payload.id
    )
  },
  resetOnlineMembers(state) {
    state.online_members = []
  }
}
