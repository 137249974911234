<template>
  <div class="grid grid-cols-1 gap-x-6 gap-y-5 md:grid-cols-2">
    <div>
      <SelectComponent
        v-model="localCivility"
        :values="civilities"
        :label="$t('attributes.civility')"
        :required="required"
        name="civility"
        :errors="errorsCivility"
        :edition-mode="editionMode"
      />
    </div>
    <div v-show="displayGenderRadio">
      <BaseShowEditRadio
        v-model="localGender"
        :label="$t('attributes.gender')"
        :edition-mode="editionMode"
        name="gender"
        :options="genders"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectComponent from '@c/BaseFormComponent/SelectComponent'
import BaseShowEditRadio from '@c/addf-package/components/BaseShowEditRadioGroup/BaseShowEditRadio.vue'
import { getGender } from '@/assets/enums/person/Civility'

export default {
  name: 'SelectCivilityAndGender',
  components: { BaseShowEditRadio, SelectComponent },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    civility: {
      type: String,
      required: true
    },
    gender: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    errorsCivility: {
      type: Array,
      required: false,
      default: () => []
    },
    errorsGender: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['update:civility', 'update:gender'],
  data() {
    return {
      displayGenderRadio: null
    }
  },
  computed: {
    ...mapGetters('metadata', {
      civilities: 'getCivilityMetadata',
      genders: 'getGenderMetadata'
    }),
    localCivility: {
      get() {
        return this.civility
      },
      set(value) {
        this.$emit('update:civility', value)
      }
    },
    localGender: {
      get() {
        return this.gender
      },
      set(value) {
        this.$emit('update:gender', value)
      }
    }
  },
  watch: {
    civility(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.changeGender(newValue)
      }
    }
  },
  mounted() {
    this.changeGender(this.civility)
  },
  methods: {
    changeGender(civility) {
      if (!civility) {
        this.displayGenderRadio = false
        return
      }
      const gender = getGender(civility)
      if (!gender) {
        this.displayGenderRadio = true
      } else {
        this.displayGenderRadio = false
        this.localGender = gender
      }
    }
  }
}
</script>
