import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      LocationList: [],
      location: {},
      locationActivity: {},
      metadataActivities: [],
      locationMetadata: {},
      editionMode: false
    }
  },
  getters,
  actions,
  mutations
}
