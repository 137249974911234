import { Languages } from '@/assets/enums'

import flag_fr_BE from '../../images/flags/fr_BE.svg'
import flag_fr_FR from '../../images/flags/fr_FR.svg'
import flag_fr_CH from '../../images/flags/fr_CH.svg'
import flag_fr_LU from '../../images/flags/fr_LU.svg'
import flag_nl_BE from '../../images/flags/nl_BE.svg'
import flag_nl_NL from '../../images/flags/nl_NL.svg'
import flag_de_BE from '../../images/flags/de_BE.svg'
import flag_de_CH from '../../images/flags/de_CH.svg'
import flag_de_DE from '../../images/flags/de_DE.svg'
import flag_en_UK from '../../images/flags/en_UK.svg'
import flag_it_IT from '../../images/flags/it.svg'
import flag_it_CH from '../../images/flags/it_CH.svg'
import flag_pt_PT from '../../images/flags/pt_PT.svg'
import flag_sp_SP from '../../images/flags/sp_SP.svg'
import flag_ro_RO from '../../images/flags/ro_RO.svg'
import flag_lb from '../../images/flags/lu.svg'

const LanguagesFlags = Object.freeze({
  [Languages.FRENCH_BELGIUM]: flag_fr_BE,
  [Languages.FRENCH_FRANCE]: flag_fr_FR,
  [Languages.FRENCH_LUXEMBURG]: flag_fr_LU,
  [Languages.FRENCH_SWITZERLAND]: flag_fr_CH,

  [Languages.NEDERLANDS_BELGIUM]: flag_nl_BE,
  [Languages.NEDERLANDS_NEDERLAND]: flag_nl_NL,

  [Languages.ENGLISH_UK]: flag_en_UK,

  [Languages.GERMAN_BELGIUM]: flag_de_BE,
  [Languages.GERMAN_DEUTSCHLAND]: flag_de_DE,
  [Languages.GERMAN_SWITZERLAND]: flag_de_CH,

  [Languages.ITALIAN_ITALY]: flag_it_IT,
  [Languages.ITALIAN_SWITZERLAND]: flag_it_CH,

  [Languages.LUXEMBOURGISH]: flag_lb,

  [Languages.SPANISH_SPAIN]: flag_sp_SP,

  [Languages.PORTUGUESE_PORTUGAL]: flag_pt_PT,

  [Languages.ROMANIA]: flag_ro_RO
})

export default LanguagesFlags
