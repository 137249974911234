<template>
  <div :class="[cGroupClass]">
    <template v-if="editionMode">
      <div class="fm-form-input" :class="label ? 'mt-2' : ''">
        <label v-if="label"
          >{{ label }}
          <span v-if="label && required" class="text-red-600">*</span>
        </label>
        <div class="relative rounded-md">
          <div
            v-if="appendString"
            class="pointer-events-none absolute inset-y-0 right-3 flex items-center"
          >
            <span
              :class="[
                disabled ? 'text-gray-300' : 'text-gray-500',
                'sm:text-sm'
              ]"
            >
              {{ appendString }}
            </span>
          </div>
          <div
            v-if="prependString"
            class="pointer-events-none absolute inset-y-0 left-3 flex items-center"
          >
            <span
              :class="[
                disabled ? 'text-gray-300' : 'text-gray-500',
                'sm:text-sm'
              ]"
            >
              {{ prependString }}
            </span>
          </div>
          <input
            :id="id"
            :type="type"
            :min="min"
            :max="max"
            :value="modelValue"
            :disabled="disabled"
            :class="[
              disabled ? 'text-gray-500' : '',
              appendString ? '!pr-8' : '',
              prependString ? '!pl-8' : '',
              sizeClass,
              $attrs.class
            ]"
            class="rounded-md"
            @input="updateInput"
            @keydown="type === 'number' ? isNumber : {}"
            @keyup="handleKeyUp"
            @blur="$emit('blur')"
          />
        </div>
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>

    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="modelValue"
        :hide-model-value="hideModelValue"
        :disabled="disabled"
        :class="$attrs.class"
      />
    </template>
  </div>
</template>

<script>
import BaseShowLabel from '../BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditInput',
  components: { ErrorContainer, BaseShowLabel },
  props: {
    name: {
      type: String,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    hideModelValue: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    withPlaceHolder: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    parseType: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        const admittedTypes = ['text', 'int', 'float']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    appendString: {
      type: String,
      required: false,
      default: null
    },
    prependString: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      default: '',
      required: false
    }
  },
  emits: ['update:modelValue', 'change', 'input:keyup', 'blur'],
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === '' ? 'form-control' : this.inputClass
    },
    sizeClass() {
      switch (this.size) {
        case 'extra-small':
          return '!px-2 !py-1 text-xs'
        case 'small':
          return '!px-3 !py-1.5 text-sm'
        case 'large':
          return '!px-5 !py-3'
        case 'medium':
        default:
          return '!px-3 !py-2'
      }
    }
  },
  methods: {
    isNumber(event) {
      if (this.type === 'number') {
        if (this.parseType === 'float') {
          if (
            !/\d/.test(event.key) &&
            ![8, 9, 37, 38, 39, 40].includes(event.keyCode)
          ) {
            return event.preventDefault()
          }
        }
        if (this.parseType === 'int') {
          if (
            !/\d/.test(event.key) &&
            ![8, 9, 37, 38, 39, 40, 108, 190, 109].includes(event.keyCode)
          ) {
            return event.preventDefault()
          }
        }
      }
    },
    restrictedRangeNumberValue(payload) {
      if (this.min || this.max) {
        if (this.payload < this.min) {
          return this.min
        }
        if (this.payload > this.max) {
          return this.max
        }
        return payload
      }
      return payload
    },
    updateInput(event) {
      if (this.type === 'number') {
        switch (this.parseType) {
          case 'int':
            return this.$emit(
              'update:modelValue',
              event.target.value !== '' && !isNaN(event.target.value)
                ? this.restrictedRangeNumberValue(parseInt(event.target.value))
                : ''
            )
          case 'float':
            return this.$emit(
              'update:modelValue',
              event.target.value !== '' && !isNaN(event.target.value)
                ? this.restrictedRangeNumberValue(
                    parseFloat(event.target.value)
                  )
                : ''
            )
          default:
            return this.$emit(
              'update:modelValue',
              this.max && parseInt(this.max) < event.target.value
                ? parseInt(this.max)
                : event.target.value
            )
        }
      } else {
        this.$emit('update:modelValue', event.target.value)
        this.$emit('change', event.target.value)
      }
    },
    handleKeyUp(e) {
      this.$emit('input:keyup', e)
    }
  }
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
