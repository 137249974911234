<template>
  <div class="w-full">
    <div class="relative left-0 mb-2">
      <template v-if="optionIndex !== 0">
        <div
          class="align-center absolute flex content-center items-center align-middle"
          style="
            width: calc(100% - 2.5rem - 1rem);
            top: 50%;
            transform: translate(-50%, -50%);
          "
        >
          <div
            class="align-center w-full flex-1 items-center rounded align-middle"
          >
            <div
              :class="[
                isSelected ? 'bg-theme-primary' : 'bg-gray-200',
                'w-full rounded py-1'
              ]"
            />
          </div>
        </div>
      </template>

      <div
        :class="[
          isSelected ? 'bg-blue-300' : 'bg-slate-300',
          'mx-auto flex h-5 w-5 cursor-pointer items-center rounded-full text-lg text-white'
        ]"
        @click="updateModelValue"
      >
        <span
          class="flex w-full w-full items-center justify-center text-center text-white"
        >
          <font-awesome-icon
            v-if="option.icon"
            :icon="['fal', option.icon]"
            size="small"
            class="h-3 w-3"
          />
        </span>
      </div>
    </div>

    <div
      class="cursor-pointer select-none text-center text-xs text-gray-500"
      @click="updateModelValue"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseStepsInputOption',
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: null
    },
    option: {
      type: Object,
      required: true
    },
    optionIndex: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    isSelected() {
      if (!this.modelValue) return false
      if (!this.option.value) return false
      return this.option.value.every((value) => this.modelValue.includes(value))
    }
  },
  methods: {
    updateModelValue() {
      this.$emit('update:modelValue', this.option.value)
    }
  }
}
</script>
