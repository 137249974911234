import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      bankAccountList: [],
      bankAccount: {},
      bankAccountActivity: {},
      metadataActivities: [],
      bankAccountMetadata: {},
      editionMode: false
    }
  },
  getters,
  actions,
  mutations
}
