import getters from '@/store/modules/dashboard/getters'
import mutations from '@/store/modules/dashboard/mutations'
import actions from '@/store/modules/dashboard/actions'

export default {
    namespaced: true,
    state() {
        return {
            currentTemplateColumnList: [],
            fullColumnsList: [],
            column: [],
            templateList: [],
            template: [],
            filter: [],
            sort: [],
            edited: false
        }
    },
    getters,
    actions,
    mutations
}
