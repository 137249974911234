<template>
  <BaseSlideOverModal v-model:is-modal-open="localOpen" width="2xl">
    <template #cta>
      <div class="flex h-full gap-x-1">
        <template v-if="internalTab === 'list'">
          <BaseButton
            :title="
              $t('button.add_new_m', {
                var: roleId ? roleName : $t('global.contact')
              })
            "
            icon="plus"
            size="small"
            @click="handleCreateContactButton"
          />
        </template>
        <template v-else>
          <BaseButton
            v-if="!forceForm"
            :title="$t('button.back')"
            color="dark"
            size="small"
            icon="long-arrow-left"
            @click="resumeToList"
          />
          <BaseButton
            v-if="contact"
            :title="formButtonTitle"
            icon="paper-plane"
            size="small"
            @click="SubmitForm"
          />
        </template>
      </div>
    </template>
    <template #body>
      <div
        class="scrollbar-custom flex h-full w-full flex-col justify-between overflow-x-visible overflow-y-scroll px-1"
      >
        <SelectContactList
          v-if="internalTab === 'list'"
          v-model="internalValue"
          :role-id="roleId"
          :funeral-home-id="funeralHome"
          :role-name="roleName"
          :icon="icon"
          :is-wiki-contact="isWikiContact"
          @edit-contact="handleEditContact"
        />
        <SelectContactForm
          v-else
          :role-id="roleId"
          :contact-id="selectedContactId"
          :is-wiki-contact="isWikiContact"
        />
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectContactList from '@ui/modal/contact/components/SelectContactList'
import SelectContactForm from '@ui/modal/contact/components/SelectContactForm'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'

export default {
  name: 'SelectContactModal',
  components: { BaseSlideOverModal, SelectContactForm, SelectContactList },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    roleId: {
      type: [String, Number],
      required: false,
      default: null
    },
    funeralHome: {
      type: [String, Number],
      required: false,
      default: null
    },
    roleName: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    forceForm: {
      type: Boolean,
      required: false,
      default: false
    },
    isWikiContact: {
      type: Boolean,
      required: false,
      default: false
    },
    wikiCityId: {
      type: [String, Number],
      required: false,
      default: null
    },
    wikiPlaceId: {
      type: [String, Number],
      required: false,
      default: null
    },
    wikiContactId: {
      type: [String, Number],
      required: false,
      default: null
    },
    refreshFunction: {
      type: Function,
      required: false,
      default: null
    }
  },
  emits: ['close', 'update:modelValue', 'change', 'update:open'],
  data() {
    return {
      internalTab: 'list',
      selectedContactId: null
    }
  },
  computed: {
    ...mapGetters({
      contact: 'contact/getContact',
      wikiContact: 'wiki/getContact'
    }),
    formButtonTitle() {
      if (!this.contact) return null
      if (this.roleId) {
        return this.contact.id
          ? this.$t('button.update_m', { var: this.roleName })
          : this.$t('button.save_new_m', { var: this.roleName })
      } else {
        return this.contact.id
          ? this.$t('button.update_m', { var: this.$t('global.contact') })
          : this.$t('button.save_new_m', { var: this.$t('global.contact') })
      }
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
        this.localOpen = false
      }
    },
    localOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  },
  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          if (this.forceForm) {
            this.selectedContactId = this.wikiContactId
            this.internalTab = 'form'
          } else {
            this.internalTab = 'list'
            this.initialFetch()
          }
        }
      },
      immediate: true,
      deep: true
    },
    internalValue(newValue, oldValue) {
      this.localOpen = false
    }
  },
  methods: {
    ...mapActions({
      processFetchRoleContactListAction: 'contact/fetchRoleContactList',
      processFetchContactListAction: 'contact/fetchContactList',
      processStoreContactAction: 'contact/storeNewContact',
      processUpdateContactAction: 'contact/updateContact',
      processRefreshSpecificInitialMetadataModelsAction:
        'metadata/refreshSpecificInitialMetadataModels',
      processFetchWikiContactByCityAction: 'wiki/fetchWikiContactByCity',
      processStoreWikiContactAction: 'wiki/storeWikiContact',
      processUpdateWikiContactAction: 'wiki/updateWikiContact'
    }),
    initialFetch() {
      if (!this.forceForm) {
        if (this.isWikiContact) {
          this.processFetchWikiContactByCityAction({
            wiki_city_id: this.wikiCityId
          })
        } else {
          if (this.roleId) {
            this.processFetchRoleContactListAction({ id: this.roleId })
          } else {
            this.processFetchContactListAction()
          }
        }
      } else {
        this.processFetchContactListAction()
        this.$emit('close')
      }
      this.processRefreshSpecificInitialMetadataModelsAction({
        model: 'contacts'
      })
    },
    handleCreateContactButton() {
      this.internalTab = 'form'
    },
    SubmitForm() {
      if (this.isWikiContact) {
        if (this.wikiContact.id) {
          this.processUpdateWikiContactAction({
            wiki_contact_id: this.wikiContactId,
            wiki_contact: this.wikiContact,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.contact_created'), {
                className: 'toastify-content success'
              })
              this.selectedContactId = null
              if (this.forceForm) {
                if (this.refreshFunction) this.refreshFunction()
                this.close()
              } else {
                this.initialFetch()
                this.internalTab = 'list'
              }
            }
          })
        } else {
          if (this.wikiCityId) this.wikiContact.city_id = this.wikiCityId
          this.processStoreWikiContactAction({
            wiki_place_id: this.wikiPlaceId,
            wiki_contact: this.wikiContact,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.contact_created'), {
                className: 'toastify-content success'
              })
              this.selectedContactId = null
              if (this.forceForm) {
                if (this.refreshFunction) this.refreshFunction()
                this.close()
              } else {
                this.initialFetch()
                this.internalTab = 'list'
              }
            }
          })
        }
      } else {
        if (this.contact.id) {
          this.processUpdateContactAction({
            contact: this.contact,
            id: this.contact.id,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.contact_updated'), {
                className: 'toastify-content success'
              })
              this.initialFetch()
              this.selectedContactId = null
              this.internalTab = 'list'
            }
          })
        } else {
          this.processStoreContactAction({
            contact: this.contact,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.contact_created'), {
                className: 'toastify-content success'
              })
              this.initialFetch()
              this.selectedContactId = null
              this.internalTab = 'list'
            }
          })
        }
      }
    },
    handleEditContact(contact_id) {
      this.selectedContactId = contact_id
      this.internalTab = 'form'
    },
    resumeToList() {
      this.selectedContactId = null
      this.internalTab = 'list'
    }
  }
}
</script>
