<template>
  <template v-if="editionMode">
    <div
      class="base-checkbox rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 flex align-center"
      :class="{
        'bg-primary-500': modelValue && !disabled,
        'text-white': modelValue,
        'text-gray-900': !modelValue,
        'cursor-pointer': !disabled,
        'disabled bg-gray-100': disabled
      }"
    >
      <label
        v-if="label"
        class="w-full border-0 p-0 placeholder-gray-500 focus:ring-0 sm:text-sm flex items-center flex-nowrap gap-2"
        :class="{
          'cursor-pointer': !disabled,
          'cursor-context-menu': disabled,
        }"
      >
        <FontAwesomeIcon
          :icon="['far', modelValue ? 'circle-check' : 'circle']"
        ></FontAwesomeIcon>
        <span>
          {{ label }}&nbsp;
          <span v-if="label && required" class="text-red-600">*</span>
        </span>
        <input
          v-model="modelValue"
          class="hidden"
          type="checkbox"
          :name="name"
          :disabled="disabled"
        />
      </label>
    </div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, {attribute: $t('attributes.' + name)}) }}
    </div>
  </template>
  <template v-else>
    <div
      class="mt-2 rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 flex align-center"
      :class="modelValue ? 'bg-primary text-white' : 'bg-white text-gray-900'"
    >
      <div
        class="w-full border-0 p-0 placeholder-gray-500 focus:ring-0 sm:text-sm flex flex-nowrap items-center gap-2"
      >
        <FontAwesomeIcon
          :icon="['far', modelValue ? 'circle-check' : 'circle']"
        ></FontAwesomeIcon>
        <span>
          {{ label }}
        </span>
      </div>
    </div>
  </template>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseCheckBox',
  components: {FontAwesomeIcon},
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['change', 'update:modelValue'],
  watch: {
    modelValue(newValue) {
      this.$emit('update:modelValue', newValue)
      this.$emit('change', newValue)
    }
  }
}
</script>

<style>
.base-checkbox {
  user-select: none;
}

.base-checkbox.disabled {
  cursor: context-menu;
  color: #3d3d3d;
}
</style>
