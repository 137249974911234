<template>
  <div
    v-if="auth && auth?.roles"
    class="box flex h-full max-h-full flex-col rounded-lg"
  >
    <!--region header-->
    <div class="relative flex flex-shrink-0 items-center px-4 pb-2 pt-4">
      <h2 class="text-2xl font-bold capitalize-first">
        {{ $t('global.invoicing') }}
      </h2>
    </div>
    <!--endregion-->

    <div class="flex-1 overflow-scroll overflow-x-hidden">
      <div class="space-y-1 border-t border-gray-200 p-4 dark:border-slate-600">
        <template v-for="entry in mainMenu" :key="entry">
          <InvoicingNavLink
            v-if="entry.gate"
            :to="{ name: entry.routeName, params: { id: $route.params.id } }"
            :icon="entry.icon"
            :label="entry.label"
            :route-name="entry.routeName"
            :new-accounting-document-route-name="
              entry.newAccountingDocumentRouteName
            "
          />
        </template>
      </div>
      <div class="space-y-1 border-t border-gray-200 p-5 dark:border-slate-600">
        <InvoicingNavLink
          :to="{
            name: 'third_party_cost_index_route',
            params: { id: $route.params.id }
          }"
          :icon="['fal', 'file-invoice']"
          :label="$t('global.third_party_cost')"
          route-name="third_party_cost_index_route"
        />
      </div>
      <div
        v-if="defaultCountry === Country.FRANCE"
        class="space-y-1 border-t border-gray-200 p-5 dark:border-slate-600"
      >
        <InvoicingNavLink
          :to="{
            name: 'check_index_route'
          }"
          :icon="['fal', 'file-invoice']"
          :label="$t('global.check')"
          route-name="check_index_route"
        />
      </div>
      <div class="space-y-1 border-t border-gray-200 p-5 dark:border-slate-600">
        <InvoicingNavLink
          :to="{
            name: 'accounting_export_page_route'
          }"
          :icon="['fal', 'file-invoice']"
          :label="$t('global.export')"
        />
      </div>
      <hr v-if="allSubMenuItemsAreVisible" />

      <div v-if="!cIsCustomerUser" class="space-y-1 p-5 dark:border-slate-600">
        <template v-for="entry in subMenu" :key="entry">
          <InvoicingNavLink
            v-if="entry.gate"
            :to="{ name: entry.routeName, params: { id: $route.params.id } }"
            :icon="entry.icon"
            :label="entry.label"
            :route-name="entry.routeName"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { Country } from '@/assets/enums'
import InvoicingNavLink from '@/views/invoicing/nav/InvoicingNavLink.vue'

export default {
  name: 'TheNewInvoicingNav',
  components: { InvoicingNavLink },
  data() {
    return {
      Country: Country
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      metadata: 'metadata/getInitialMetadata',
      defaultCountry: 'metadata/getDefaultCountry'
    }),
    cIsCustomerUser() {
      return this.auth.roles[0] === 'customer-user'
    },
    allSubMenuItemsAreVisible() {
      return !!this.subMenu.every((currentValue) => {
        return !Object.values(currentValue).includes(false)
      })
    },
    mainMenu() {
      return [
        {
          label: this.$t('attributes.invoice'),
          routeName: 'invoice_index_route',
          newAccountingDocumentRouteName: 'invoice_wizard_route',
          icon: ['fal', 'file-invoice'],
          gate: true
        },
        {
          label: this.$t('global.purchase_order'),
          routeName: 'purchase_order_index_route',
          newAccountingDocumentRouteName: 'purchase_order_wizard_route',
          icon: ['fal', 'file-invoice'],
          gate: true
        },
        {
          label: this.$t('global.quote'),
          routeName: 'quote_index_route',
          newAccountingDocumentRouteName: 'quote_wizard_route',
          icon: ['fal', 'file-invoice'],
          gate: true
        },
        {
          label: this.$t('global.credit_note'),
          routeName: 'credit_note_index_route',
          newAccountingDocumentRouteName: 'credit_note_wizard_route',
          icon: ['fal', 'file-invoice'],
          gate: true
        }
      ]
    },
    subMenu() {
      return [
        // {
        //   label: this.$t('global.checks'),
        //   routeName: 'check_index_route',
        //   icon: ['fal', 'money-check-alt'],
        //   gate:
        //     this.metadata?.settings?.default_country === this.Country?.FRANCE
        // }
      ]
    }
  },
  methods: {
    handleAddInvoice() {
      this.$router.push({ name: 'invoice_wizard_route' })
    },
    handleAddPurchaseOrder() {
      this.$router.push({ name: 'invoicing_wizard_route' })
    },
    handleAddQuote() {
      this.$router.push({ name: 'invoicing_wizard_route' })
    },
    handleAddCreditNote() {
      this.$router.push({ name: 'invoicing_wizard_route' })
    }
    // handleAddCheckDeposit() {
    //   this.$router.push({
    //     name: 'check_deposit_edit_route',
    //     params: { id: 'new' }
    //   })
    // }
  }
}
</script>
