<template>
  <div
    v-if="defaultAjax"
    class="base-multiselect-simple relative mt-2 rounded-md border border-slate-300 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
  >
    <label
      :for="name"
      class="absolute -top-2 left-2 z-10 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
      >{{ label ? label : $t('attributes.country') }}&nbsp;
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <multiselect
      :id="name"
      :key="defaultValue"
      v-model="defaultValue"
      :label="attributeLabel"
      :track-by="trackBy"
      placeholder="Type to search"
      open-direction="bottom"
      :options="options"
      :value="defaultAjax"
      :multiple="false"
      :searchable="true"
      :loading="loading"
      :internal-search="true"
      :clear-on-select="true"
      :close-on-select="true"
      :options-limit="300"
      :limit="10"
      :limit-text="2"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      :select-label="$t('button.select')"
      :deselect-label="$t('button.unselect')"
      :selected-label="$t('button.selected')"
      :show-no-options="false"
      @search-change="fetchOption"
    >
      <template #tag="{ option, remove }">
        <span class="custom__tag">
          <span>{{ option.name }}</span>
          <span class="custom__remove" @click="remove(option)">❌</span>
        </span>
      </template>
    </multiselect>
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
</template>
<script>
import apiClient from '@u/apiClient'
import multiselect from 'vue-multiselect'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseSelectSimpleAjax',
  components: { ErrorContainer, multiselect },
  props: {
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    modelValue: { type: Object, required: false, default: null },
    defaultAjax: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    url: {},
    name: {},
    required: { type: Boolean, required: false, default: false },
    label: { type: String, required: false },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    fullModelResponse: { type: Boolean, required: false, default: false },
    defaultOptions: {
      type: Array,
      required: false,
      default() {
        return null
      }
    },
    errors: { type: Object, required: false }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [],
      loading: false,
      defaultValue: this.defaultAjax
    }
  },
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? '' : this.groupClass
    },
    cLabelClass() {
      return this.labelClass === '' ? 'form-label' : this.labelClass
    }
  },
  watch: {
    defaultValue(newValue) {
      if (newValue != null && newValue !== '') {
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
          this.$emit('workSelect', newValue)
        } else {
          this.$emit('update:modelValue', newValue[this.trackBy])
          this.$emit('selected', {
            attribute: this.name || 'selectAjax',
            value: newValue[this.trackBy]
          })
        }
      }
    }
  },
  mounted() {
    if (this.defaultOptions) {
      this.options = this.defaultOptions
    }
  },
  methods: {
    async fetchOption(keyword) {
      if (keyword.length > 2) {
        this.loading = true
        await apiClient
          .get(this.url + '?terms=' + keyword)
          .then(async (response) => {
            this.loading = false
            this.options = response.data
          })
      }
    }
  }
}
</script>
