import apiClient from '@u/apiClient'
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

const base_errors = {}

export default {
  async fetchAttachmentCategoryIndex({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`attachment-category`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async postAttachmentCategory({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post(`attachment-category`, payload.attachment_category)
      .then(async (response) => {
        if (response.status === 200) {
          commit(
            'setAttachmentCategoryIndex',
            response.data.attachment_categories
          )
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('postAttachmentCategory', reason)
      })
  },
  async updateAttachmentCategory({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(
        `attachment-category/${payload.attachment_category_id}`,
        payload.attachment_category
      )
      .then(async (response) => {
        if (response.status === 200) {
          commit(
            'setAttachmentCategoryIndex',
            response.data.attachment_categories
          )
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('updateAttachmentCategory', reason)
      })
  },
  async deleteAttachmentCategory({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .delete(`attachment-category/${payload.attachment_category_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit(
            'setAttachmentCategoryIndex',
            response.data.attachment_categories
          )
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('deleteAttachmentCategory', reason)
      })
  },
  async undoDeleteAttachmentCategory({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`attachment-category/undo-delete/${payload.attachment_category_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit(
            'setAttachmentCategoryIndex',
            response.data.attachment_categories
          )
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('undoDeleteAttachmentCategory', reason)
      })
  }
}
