import apiClient from '@u/apiClient'
import Promise from 'lodash-es/_Promise'
const base_errors = {}
export default {
  fetchAccountingDiaryList({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('accounting-diary')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // region Fetch
  async fetchAccountingDiaryListOld({ commit }) {
    await apiClient
      .get('accounting-diary')
      .then(async (response) => {
        commit('setAccountingDiaryList', response.data.accounting_diaries)
        commit('setAccountingDiaryMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchAccountingDiaryList', reason)
      })
  },
  async fetchAccountingDiaryByID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`accounting-diary/${payload.id}`)
      .then(async (response) => {
        await commit('setAccountingDiary', response.data.accounting_diary)
      })
      .catch(async (reason) => {
        console.error('fetchAccountingDiaryByID', reason)
      })
  },
  async fetchNewAccountingDiary({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=accountingDiary')
      .then((response) => {
        commit('setAccountingDiary', response.data.accountingDiary)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },

  // endregion

  // region store
  async storeAccountingDiary({ commit }, payload) {
    await apiClient
      .post('accounting-diary', payload.accounting_diary)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setAccountingDiaryList', response.data.accounting_diaries)
          if (payload.successCallback) {
            payload.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        console.error('storeAccountingDiary', reason)
      })
  },
  // endregion

  // region update
  async updateAccountingDiary({ commit }, payload) {
    await apiClient
      .put(`accounting-diary/${payload.id}`, payload.accounting_diary)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setAccountingDiaryList', response.data.accounting_diaries)
          if (payload.successCallback) {
            payload.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        console.error('updateAccountingDiary', reason)
      })
  },
  // endregion

  // region delete
  async deleteAccountingDiaryById({ commit }, payload) {
    await apiClient
      .delete(`accounting-diary/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setAccountingDiaryList', response.data.accounting_diaries)
          if (payload.successCallback) {
            payload.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        console.error('deleteAccountingDiaryById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteAccountingDiaryById({ commit }, payload) {
    await apiClient
      .get(`accounting-diary/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setAccountingDiaryList', response.data.accounting_diaries)
          if (payload.successCallback) {
            payload.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteAccountingDiaryById', reason)
      })
  }
  // endregion
}
