<template>
  <div class="fm-form-input" :class="[label ? 'mt-2' : '']">
    <label
      v-if="label"
      class="absolute -top-2 left-2 -mt-px inline-block px-1 text-xs font-medium text-gray-900 capitalize-first"
      >{{ label }}
    </label>
    <div class="flex flex-row overflow-hidden border-0 p-0 sm:text-sm">
      <span
        v-if="cGetCountryFlag"
        class="flex-shrink-0 items-center p-1.5 text-xs font-medium"
      >
        <img
          :src="CountryFlags[cGetCountryFlag.country]"
          :alt="cGetCountryFlag.country"
          class="h-4 w-6 rounded"
        />
      </span>
      <input type="text" readonly :value="cmodelValue" />
    </div>
  </div>
</template>
<script>
import CountryFlags from '@/assets/enums/config/CountryFlags'
import PhoneCountry from '@/assets/enums/config/PhoneCountry'
export default {
  name: 'BaseShowPhoneLabel',
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    isVertical: {
      type: Boolean,
      required: false,
      default: false
    },
    countryValue: {
      type: String,
      required: true
    },
    valueClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      PhoneCountry,
      CountryFlags
    }
  },
  computed: {
    cGetCountryFlag() {
      return PhoneCountry.find((item) => item.country === this.countryValue)
    },
    cmodelValue () {

      return this.modelValue ? this.modelValue[0].toUpperCase() + this.modelValue.slice(1) : this.modelValue;
    }
  }
}
</script>
