<template >
  <template v-if="editionMode">
    <div >

      <BaseEditLabel :label="label" :required="required" />
      <multiselect
        v-model="cDefaultValue"
        :options="orderItem"
        :placeholder="placeholder"
        :searchable="searchable"
        :label="attributeLabel"
        :track-by="trackBy"
        :multiple="false"
        :required="required"
        :taggable="true"
        :tag-placeholder="$t('button.add_tag_placeholder')"
        :select-label="$t('button.select')"
        :deselect-label="$t('button.unselect')"
        :selected-label="$t('button.selected')"
        @tag="addTag"
        @update:model-value="updateInput"
      >
        <template v-slot:noOptions><span>{{ $t('global.is_empty_list') }}</span></template>
      </multiselect>
    </div >
    <div v-for='(error,index) in errors' :key='index' class="form-help text-red-600">
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div >
  </template >
  <template v-else>
    <BaseShowLabel :label="label" :model-value="cDefaultValue ? cDefaultValue[attributeLabel]: null" />
  </template >
</template >

<script >
import multiselect from 'vue-multiselect'

export default {
  name: "BaseShowEditTaggableSelectSimple",
  components: { multiselect },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true
    },
    modelValueCanBeNull: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  emits: ['update:modelValue', 'update:options', 'change', 'newTagSelected'],
  computed: {
    cDefaultValue() {
      if (this.options && this.options.find(t => t.id === this.modelValue)) {
        return this.options.find(t => t.id === this.modelValue)
      }
      else {
        return null
      }
    },
    orderItem(){
      return this.options.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {}
      tag[this.trackBy] = 'new'
      tag[this.attributeLabel] = newTag

      this.$emit("update:options", [...this.options.filter(option => option.id !== 'new'), tag]);
      this.$emit("update:modelValue", tag[this.trackBy]);
      this.$emit("newTagSelected", tag)
      this.$emit("change", tag[this.trackBy]);
    },
    updateInput(newValue) {
      if (newValue) {
        this.$emit("update:modelValue", newValue[this.trackBy]);
        this.$emit("change", newValue[this.trackBy]);
      }
      else {
        if (this.modelValueCanBeNull) {
          this.$emit("update:modelValue", null);
          this.$emit("change", null);
        }
      }
    }
  },
}
</script >
