import apiClient from '@u/apiClient'

const baseErrors = {}

export default {
  deleteGravePlot({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`grave-plot/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  undoDeleteGravePlot: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`grave-plot/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // region fetch

  fetchGravePlotList({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`grave-plot`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchGravePlotById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`grave-plot/${payload.grave_plot_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewGravePlot({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model/new`, {
          params: {
            ...payload.params,
            model: 'GravePlot'
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storeGravePlot({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .post('grave-plot', payload.grave_plot)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateGravePlot({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`grave-plot/${payload.id}`, payload.grave_plot)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  updateMainImage({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`grave-plot/${payload.id}/set-main-image`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  setLocation({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`grave-plot/${payload.id}/set-location`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
