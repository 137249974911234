<template>
  <th
    v-if="!column.hidden"
    class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
    :class="index === 0 ? 'py-3.5 pl-4 pr-3 sm:pl-6' : 'px-3 py-3.5'"
  >
    <a href="#" class="group inline-flex" @click="sortColumn">
      <span class="capitalize-first">{{ c_title }}</span>
      <span
        v-if="column.sorter && sortMode"
        class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:visible group-hover:bg-gray-300 group-focus:visible"
      >
        <FontAwesomeIcon
          class="h-3 w-3"
          :icon="['fas', sortIcon]"
          fixed-width
        />
      </span>
    </a>
  </th>
</template>
<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseThComponent',
  components: { FontAwesomeIcon },
  props: {
    column: {
      type: Object,
      required: true
    },
    currentSort: {
      type: String,
      required: false,
      default: null
    },
    index: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['sort-column'],
  data() {
    return {
      sortMode: null
    }
  },
  computed: {
    ...mapGetters({ initialMetadata: 'metadata/getInitialMetadata' }),
    sortIcon() {
      return this.sortMode
        ? this.sortMode === 'down'
          ? 'chevron-down'
          : 'chevron-up'
        : null
    },
    c_title() {
      if (!this.column.title) {
        return ''
      }
      if (this.column.translatable) {
        return this.$t(`attributes.${this.column.title}`, {
          PRICE_ENCODING_TYPE:
            this.initialMetadata?.settings?.price_encoding_type
        })
      }
      return this.column.title
    }
  },
  watch: {
    currentSort(newValue) {
      if (newValue !== this.column.field) {
        this.sortMode = null
      }
    }
  },
  methods: {
    emitSortColumn() {
      this.$emit('sort-column', {
        column: this.column.field,
        direction: this.sortMode,
        type: this.column.type
      })
    },
    sortColumn() {
      if (this.sortMode === 'down') {
        this.sortMode = 'up'
        this.emitSortColumn()
      } else if (this.sortMode === 'up') {
        this.sortMode = 'down'
        this.emitSortColumn()
      } else {
        this.sortMode = 'up'
        this.emitSortColumn()
      }
    }
  }
}
</script>
