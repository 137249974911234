<template>
  <div class="pr-3 py-2">
    <SupplierForm
      :supplier="supplier"
      :errors="errors"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SupplierForm from "@ui/modal/supplier/components/SupplierForm";

export default {
    name: "SelectSupplierForm",
  components: { SupplierForm },
  props: {
      supplierId: {
        type: [String, Number],
        required: false,
        default: null
      }
    },
    computed: {
      ...mapGetters({
        supplier: 'supplier/getSupplier',
        errors: 'auth/getErrors',
        dossier: 'dossier/getDossier'
      })
    },
    beforeMount() {
      if(this.supplierId) {
        this.processFetchSupplierByIdAction({ supplier_id: this.supplierId })
      } else {
        this.processFetchNewSupplierAction()
      }
    },
    methods: {
      ...mapActions({
        processFetchSupplierByIdAction: 'supplier/fetchSupplierById',
        processFetchNewSupplierAction: 'supplier/fetchNewSupplier'
      }),
    },
  }
</script>
