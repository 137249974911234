<template>
  <div class="grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2">
    <div>
      <BaseShowEditDatePicker
        v-model:date="contact.cohabitant_date"
        :label="$t('attributes.date')"
        :edition-mode="editionMode"
        :today-default="false"
        name="cohabitant_date"
      />
    </div>
    <BaseShowEditInput
      v-model="contact.cohabitant_place"
      :label="$t('attributes.place')"
      name="place"
      :edition-mode="editionMode"
    />
  </div>
</template>

<script>
import BaseShowEditDatePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'

export default {
  name: 'ContactCardCohabitantLegalComponent',
  components: { BaseShowEditDatePicker },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    }
  }
}
</script>
