import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      cities: [],
      closestCities: [],
      favoriteCities: [],
      city: null,
      city_id: null,
      place: null,
      placeList: [],
      contactList: [],
      wikiContactList: [],
      contact: {}
    }
  },
  getters,
  actions,
  mutations
}
