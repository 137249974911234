const TheUserInformationPage = () =>
  import(
    '@/views/settings/user/form/tabs/userInformation/TheUserInformationPage'
  )
const TheUserActivityPage = () =>
  import('@/views/settings/user/form/tabs/userActivity/TheUserActivityPage')
const TheUserAgendaPage = () =>
  import('@/views/settings/user/form/tabs/userAgenda/TheUserAgendaPage')
const TheUserCompanyAndFuneralHomePage = () =>
  import(
    '@/views/settings/user/form/tabs/userCompanyAndFuneralHome/TheUserCompanyAndFuneralHomePage'
  )

const TheUserFormsTabsComponent = () =>
  import('@/views/settings/user/form/TheUserFormsTabsComponent')
const TheUserNotificationsPage = () =>
  import(
    '@/views/settings/user/form/tabs/userNotification/TheUserNotificationsPage'
  )
const TheUserRolesAndPermissionsPage = () =>
  import(
    '@/views/settings/user/form/tabs/userRoleAndPermission/TheUserRolesAndPermissionsPage'
  )
const TheUserRemunerationPage = () =>
  import(
    '@/views/settings/user/form/tabs/userRemuneration/TheUserRemunerationPage'
  )
const TheUserIndexPage = () =>
  import('@/views/settings/user/index/TheUserIndexPage')

export const settingsUsersRoutes = [
  {
    path: 'user',
    children: [
      {
        path: '',
        name: 'settings_list_user_route',
        component: TheUserIndexPage,
        meta: {
          type: 'index',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_user_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_user_profile_route',
        props: true,
        component: TheUserFormsTabsComponent,
        children: [
          {
            path: '',
            component: TheUserInformationPage,
            name: 'settings_edit_user_route'
          },
          {
            path: 'roles',
            component: TheUserRolesAndPermissionsPage,
            props: true,
            name: 'settings_edit_user_roles_route'
          },
          {
            path: 'companiesAndFuneralServices',
            component: TheUserCompanyAndFuneralHomePage,
            props: true,
            name: 'settings_edit_user_companies_and_funeral_home_route'
          },
          {
            path: 'agenda',
            component: TheUserAgendaPage,
            props: true,
            name: 'settings_edit_user_agenda_route'
          },
          {
            path: 'remuneration',
            component: TheUserRemunerationPage,
            props: true,
            name: 'settings_edit_user_remuneration_route'
          },
          {
            path: 'notifications',
            component: TheUserNotificationsPage,
            props: true,
            name: 'settings_edit_user_notifications_route'
          },
          {
            path: 'activities',
            component: TheUserActivityPage,
            props: true,
            name: 'settings_edit_user_activity_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            {
              title: 'content_company_title',
              active: false
            },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_user_profile_route',
        component: TheUserFormsTabsComponent,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            {
              title: 'content_company_title',
              active: false
            },
            { title: 'content_company_new_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheUserInformationPage,
            name: 'settings_create_user_route'
          },
          {
            path: 'roles',
            component: TheUserRolesAndPermissionsPage,
            props: true,
            name: 'settings_create_user_roles_route'
          },
          {
            path: 'companiesAndFuneralServices',
            component: TheUserCompanyAndFuneralHomePage,
            props: true,
            name: 'settings_create_user_companies_and_funeral_home_route'
          },
          {
            path: 'agenda',
            component: TheUserAgendaPage,
            props: true,
            name: 'settings_create_user_agenda_route'
          },
          {
            path: 'remuneration',
            component: TheUserRemunerationPage,
            props: true,
            name: 'settings_create_user_remuneration_route'
          },
          {
            path: 'notifications',
            component: TheUserNotificationsPage,
            props: true,
            name: 'settings_create_user_notifications_route'
          }
        ]
      }
    ]
  }
]
