export default {
  setUsersMetadata(state, users) {
    state.users = users
  },
  setSingleSetting(state, payload) {
    if (payload.funeralHomeId) {
      state.initialMetadata.fh_settings[payload.funeralHomeId][
        payload.setting
      ] = payload.values
    } else {
      state.initialMetadata.settings[payload.setting] = payload.values
    }
  },
  setAllMetadata(state, data) {
    state.users = [...data.users]
  },
  setInitialMetadata(state, initialMetadata) {
    state.initialMetadata = initialMetadata
    // console.log('%c✔ metadata loaded ', 'color: #648d3d')
    state.loaded = true
  },
  resetState(state, initialState) {
    state.loaded = false
    state.initialMetadata = { ...initialState.initialMetadata }
  },
  setMetadataModels(state, models) {
    state.initialMetadata.models = models
  },
  setSpecificMetadataModels(state, payload) {
    state.initialMetadata.models[payload.model] = payload.data
  },
  setMetadataSettings(state, payload) {
    state.initialMetadata.settings = payload.settings
    state.initialMetadata.fh_settings = payload.fh_settings
  },
  setMutlipleMetadataModels(state, payload) {
    let modelNames = Object.keys(payload)
    modelNames.forEach((modelName) => {
      state.initialMetadata.models[modelName] = payload[modelName]
    })
  },
  setMainKey(state, payload) {
    state.mainKey = payload
  },
  incrementMainKey(state) {
    // console.log('mainKey', state.mainKey)
    state.mainKey = state.mainKey + 1
  },
  toggleModalVersionNote(state, status) {
    state.openModalVersionNote = status
  },
  updateFormConfigSettings(state, newFormValue) {
    state.global_form.current_form_settings = newFormValue
  },
  updateFormConfigFuneralHomeId(state, id) {
    state.global_form.funeral_home_id = id
  },
  toggleGlobalFormEditionMode(state, status) {
    state.global_form.edition_mode =
      status !== undefined ? status : !state.global_form.edition_mode
  },

  //  USER STATUS
  changeUserOnlineStatus(state, payload) {
    const index = state.initialMetadata.models.all_users.findIndex(
      (user) => user.id === payload.user_id
    )
    if (index !== -1) {
      state.initialMetadata.models.all_users[index] = {
        ...state.initialMetadata.models.all_users[index],
        online: payload.online
      }
    }
  }
}
