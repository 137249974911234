export default {
  getSupplierList(state){
    return state.supplierList
  },
  getSupplier(state){
    return state.supplier
  },
  getMetadata(state) {
    return state.metadata
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getSupplierActivity(state) {
    return state.supplierActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
}
