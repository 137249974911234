<template>
  <div :class="cGroupClass">
    <template v-if="editionMode">
      <div
        class="relative rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
        :class="label ? 'mt-2' : ''"
      >
        <label
          v-if="label"
          :for="name"
          class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
          >{{ label }}&nbsp;
          <span v-if="label && required" class="text-red-600">*</span>
        </label>
        <textarea
          :id="id"
          :value="modelValue"
          :disabled="disabled"
          class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
          @input="updateInput"
        />
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>

    <template v-else>
      <BaseShowLabel :label="label" :model-value="modelValue" />
    </template>
  </div>
</template>

<script>
import BaseEditLabel from '../BaseLabel/BaseEditLabel.vue'
import BaseShowLabel from '../BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditTextArea',
  components: { ErrorContainer, BaseShowLabel, BaseEditLabel },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
