import apiClient from '@u/apiClient'
export default {
  fetchDossierNumbering({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier-numbering/${payload.dossier_numbering_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  storeDossierNumbering({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier-numbering`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateDossierNumbering({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `dossier-numbering/${payload.dossier_numbering_id}`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchDossierNumberingsList({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('dossier-numbering')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  deleteDossierNumbering({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`dossier-numbering/${payload.dossier_numbering_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  undoDeleteDossierNumbering({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier-numbering/${payload.dossier_numbering_id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
