import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      itemsList: [],
      categorisedItemList: [],
      packsList: [],
      item: null,
      itemActivity: {},
      metadataActivities: [],
      itemMetadata: {},
      editionMode: false,
      errorImport: []
    }
  },
  getters,
  actions,
  mutations
}
