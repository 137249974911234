<template>
  <WalkmanButton v-model="localModelValue" :options="options" :size="size" />
  <ErrorContainer :error-key="name" :errors="errors" />
</template>

<script>
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'BaseBooleanRadioGroup',
  components: { WalkmanButton, ErrorContainer },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      options: [
        {
          value: 1,
          label: true
        },
        {
          value: 0,
          label: false
        }
      ]
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
