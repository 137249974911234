<template>
  <h2 class="w-full md:w-auto text-lg font-medium mr-auto flex-1 text-center sm:text-left">
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'BasePageTitle'

}
</script>
