<template>
  <div class="py-2 pr-3">
    <ContactForm
      v-model:contact="selectedContact"
      :errors="errors"
      :is-wiki-contact="isWikiContact"
      :role-is-disabled="roleId"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ContactForm from '@ui/modal/contact/components/ContactForm'

export default {
  name: 'SelectContactForm',
  components: { ContactForm },
  props: {
    contactId: {
      type: [String, Number],
      required: false,
      default: null
    },
    roleId: {
      type: [String, Number],
      required: false,
      default: null
    },
    isWikiContact: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      contact: 'contact/getContact',
      wikiContact: 'wiki/getContact',
      errors: 'auth/getErrors',
      dossier: 'dossier/getDossier'
    }),
    selectedContact: {
      get() {
        if (this.isWikiContact) {
          return this.wikiContact
        }
        return this.contact
      },
      set(value) {
        console.log('mutation', value)
        if (this.isWikiContact) {
          return this.processSetWikiContactMutation(value)
        }
        return this.processSetContactMutation(value)
      }
    }
  },
  beforeMount() {
    if (this.contactId) {
      if (this.isWikiContact) {
        this.processFetchWikiContactByIdAction({
          wiki_contact_id: this.contactId
        })
      } else {
        this.processFetchContactByIdAction({ contact_id: this.contactId })
      }
    } else {
      if (this.roleId) {
        this.processFetchNewContactAction(this.roleId)
      } else {
        if (this.isWikiContact) {
          this.processFetchNewWikiContactAction()
        } else {
          this.processFetchNewContactAction()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchContactByIdAction: 'contact/fetchContactById',
      processFetchNewContactAction: 'contact/fetchNewContact',
      processFetchWikiContactByIdAction: 'wiki/fetchWikiContactById',
      processFetchNewWikiContactAction: 'wiki/fetchNewWikiContact'
    }),
    ...mapMutations({
      processSetContactMutation: 'contact/setContact',
      processSetWikiContactMutation: 'wiki/setContact'
    })
  }
}
</script>
