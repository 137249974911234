const WikiCityDocumentsPage = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/documents/WikiCityDocumentsPage.vue'
  )

export const wikiDocumentRoutes = [
  {
    path: 'documents',
    name: 'wiki_edit_city_documents_page_route',
    component: WikiCityDocumentsPage,
    meta: {
      pre_page: '',
      next_page: ''
    }
  }
]
