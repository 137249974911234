import apiClient from '@u/apiClient'

// import router from "@/router";

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch

  fetchAllThirdPartyCost({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`third_party_cost`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchInvoiceItemID({ commit }, payload) {
    await apiClient
      .get(`invoicing/invoice-item/${payload.id}`)
      .then(async (response) => {
        await commit('setInvoiceItem', response.data.invoice_item)
        commit('auth/setErrors', {}, { root: true })
      })
      .catch((reason) => {
        console.error('fetchInvoiceItemID reason', reason)
      })
  },
  // endregion

  // region store
  async storeInvoiceItem({ commit }, payload) {
    await apiClient
      .put(`invoicing/add-item-fast/${payload.invoiceId}`, {
        item_id: payload.item_id
      })
      .then(async (response) => {
        await commit('setInvoice', response.data.invoice)
      })
      .catch((reason) => {
        console.error('storeInvoiceItem reason', reason)
      })
  },
  // endregion

  // region update
  async addItemsToInvoice({ commit }, payload) {
    await apiClient
      .post(`invoicing/${payload.invoice_id}/add-items`, payload.data)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setInvoice', response.data.invoice)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },
  addItemDossiersToInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(
          `invoicing/${payload.invoice_id}/add-items-dossiers`,
          payload.data
        )
        .then(async (response) => {
          if (response.status === 200) {
            await commit('setInvoice', response.data.invoice)
          }
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async addPackToInvoice({ commit }, payload) {
    await apiClient
      .post(`invoicing/${payload.invoice_id}/add-packs`, payload.data)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setInvoice', response.data.invoice)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },
  // ci dessous devrait disparaitre
  async updateInvoiceItem({ commit }, payload) {
    await apiClient
      .put(`invoicing/add-item-fast/${payload.invoice_id}`, payload.content)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setInvoice', response.data.invoice)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },
  // ci dessous devrait disparaitre
  async updateInvoiceDossierItem({ commit }, payload) {
    await apiClient
      .put(`invoicing/add-item-fast/${payload.invoice_id}`, {
        dossier_id: payload.dossier_id,
        item_dossier_id: payload.dossier_item_id
      })
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setInvoice', response.data.invoice)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },
  // ci dessous devrait disparaitre
  async updateMultipleInvoiceItems({ commit }, payload) {
    await apiClient
      .put(`invoicing/add-multiple-items/${payload.invoice_id}`, payload.items)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setInvoice', response.data.invoice)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },

  updateSingleInvoiceItem: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `invoicing/${payload.invoice_id}/invoice-item/${payload.id}`,
          payload.invoice_item,
          {
            params: {
              for_invoice: payload.for_invoice
            }
          }
        )
        .then((response) => {
          if (payload.for_invoice) {
            commit('setInvoice', response.data.invoice)
          } else {
            commit('setInvoiceItemIndex', response.data.invoice_items)
            commit('setInvoiceItemsMetadata', response.data.metadata)
          }
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // async updateMultipleInvoiceItems({ commit }, payload) {
  //   await apiClient
  //     .put(
  //       `api/invoicing/add-multiple-items/${payload.invoiceId}`,
  //       payload.items
  //     )
  //     .then(async (response) => {
  //       await commit('setInvoice', response.data.invoice)
  //       if (payload.successCallback) {
  //         payload.successCallback()
  //       }
  //     })
  //     .catch(async (reason) => {
  //       console.error('putMultipleInvoiceItems reason', reason)
  //     })
  // },
  // endregion

  // region delete
  async deleteInvoiceItem({ commit }, payload) {
    await apiClient
      .delete(`invoicing/${payload.invoice_id}/invoice-item/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
        if (payload.supplier_order_index === null) {
          await commit('setInvoiceMetadata', response.data.metadata)
          await commit('setInvoice', response.data.invoice)
        } else {
          await commit('setInvoiceAtIndex', {
            index: payload.supplier_order_index,
            invoice: response.data.invoice
          })
        }
      })
      .catch(async (reason) => {
        console.error('deleteInvoiceById reason', reason)
      })
  },
  async undoDeleteInvoiceItem({ commit }, payload) {
    await apiClient
      .get(
        `invoicing/${payload.invoice_id}/invoice-item/${payload.id}/undo-delete`
      )
      .then(async (response) => {
        if (payload.successCallback) {
          payload.successCallback()
        }
        if (payload.supplier_order_index === null) {
          await commit('setInvoiceMetadata', response.data.metadata)
          await commit('setInvoice', response.data.invoice)
        } else {
          await commit('setInvoiceAtIndex', {
            index: payload.supplier_order_index,
            invoice: response.data.invoice
          })
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteMessageTextById', reason)
      })
  },
  // endregion
  // region order invoice items
  async reorderInvoiceItems({ commit }, payload) {
    // mutation dans le state local pour éviter l'effet de clipping dnas la facture
    console.log(payload);
    await commit('reorderInvoiceItems', payload)
    apiClient
      .put(
        `invoicing/${payload.invoice_id}/invoice-item/${payload.id}/reorder`,
        payload.data
      )
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response.data.invoice)
          commit('setInvoice', response.data.invoice)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  }
  // endregion
}
