<template>
  <div class="flex flex-row justify-between">
    <div class="flex flex-row gap-2">
      <button class="flex flex-row items-center gap-2 rounded border px-2 py-1" @click="doAnswer">
        <FontAwesomeIcon :icon="['fal', 'reply']" />
        <span>{{ $t('button.reply') }}</span>
      </button>
      <button class="flex flex-row items-center gap-2 rounded border px-2 py-1" @click="doShare">
        <FontAwesomeIcon :icon="['fal', 'share']" />
        <span>{{ $t('button.forward') }}</span>
      </button>
    </div>
    <div>test</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'ThreadTopBarComponent',
  components: { FontAwesomeIcon },
  emits: ['action:answer', 'action:share'],
  methods: {
    doAnswer() {
      this.$emit('action:answer', true)
    },
    doShare() {
      this.$emit('action:share', true)
    }
  }
})
</script>
