<template>
  <Popover v-slot="{ open, close }">
    <Float
      :offset="10"
      :auto-placement="{
        autoAlignment: true,
        allowedPlacements: ['bottom-end', 'top-end']
      }"
    >
      <PopoverButton
        :class="
          open
            ? 'border border-blue-600'
            : 'border border-slate-300 text-opacity-90 '
        "
        class="group inline-flex items-center justify-between gap-2 rounded-md px-3 py-2 font-medium text-gray-600 hover:text-opacity-100"
        @click="popoverOpened = !popoverOpened"
      >
        <FontAwesomeIcon
          v-if="isLoading"
          :icon="['fal', 'spinner-third']"
          class="animate-spin"
        />
        <FontAwesomeIcon v-else :icon="['fal', 'gears']" />
        <span class="text-xs font-medium capitalize-first">{{
          $t('global.view')
        }}</span>
        <FontAwesomeIcon
          class="h-4 transition duration-150 ease-in-out group-hover:text-opacity-80"
          :class="popoverOpened ? '' : 'text-opacity-70'"
          :icon="['fas', 'caret-down']"
        ></FontAwesomeIcon>
      </PopoverButton>
      <PopoverPanel
        class="z-90 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-4xl"
      >
        <div
          class="flex flex-col rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5"
        >
          <!--#region headLine -->
          <div class="relative flex flex-row items-center gap-2 px-3 py-2">
            <h1 class="flex-1 text-xl font-medium capitalize">
              {{ $t('global.view') }}
            </h1>
          </div>
          <!--#endregion -->
          <div class="p-3">
            <slot name="display-options-content" :close="close" />
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>
</template>
<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Float } from '@headlessui-float/vue'

export default {
  name: 'DisplayOptionsComponent',
  components: { Float, FontAwesomeIcon, PopoverPanel, Popover, PopoverButton },
  data() {
    return {
      popoverOpened: false,
      isLoading: false
    }
  },
  computed: {},
  methods: {}
}
</script>
