<template>
  <div class="flex ">
    <DropDownMenu as="div" class="relative inline-block text-left w-full bg-red">
      <div class="relative ">
        <MenuButton
            class="inline-flex w-full capitalize rounded-md border border-gray-300 bg-white px-4 py-5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
        >
          <label class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first">
            <span>{{ $t('attributes.funeral_homes') }}</span>
            <span v-if="contact?.funeral_homes_id && Object.keys(contact?.funeral_homes_id)?.length > 0">( {{ Object.keys(contact?.funeral_homes_id)?.length }} {{
                $t('global.selected_funeral_homes')
              }})</span></label>
        </MenuButton>
      </div>

      <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
            class="absolute w-96 right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white ">
          <div v-for="(funeral_home, index) in initialMetadata.models.funeralHomes" :key="index" class="py-1">
            <MenuItem v-slot="{ active }" @click="(e) => {e.preventDefault(); e.stopPropagation();}">
              <div class="flex justify-between hover:bg-gray-50">

                <div class="ml-6 flex mt-3">
                  <FontAwesomeIcon
                      :icon="['fal', Object.keys(contact.funeral_homes_id).includes(funeral_home.id) ? 'square-check' : 'square']"
                      size="lg"
                      class="mt-2 cursor-pointer"
                      :class="Object.keys(contact.funeral_homes_id).includes(funeral_home.id) ? 'text-primary-11' : ''"
                      @click.stop="$emit('selectFuneralHomeForContact', funeral_home)"
                  ></FontAwesomeIcon>
                  <div>
                    <a
                        href="#"
                        :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm font-medium']">
                      {{ funeral_home.name }}
                    </a>
                  </div>

                </div>
                <div class="mt-4">
                  <FontAwesomeIcon
                      v-if="Object.keys(contact.funeral_homes_id).includes(funeral_home.id)"
                      :icon="[contact.funeral_homes_id[funeral_home.id]['favorite'] ? 'fas' : 'fal', 'star']"
                      size="lg"
                      class="mt-1 mr-4 cursor-pointer"
                      :class="contact.funeral_homes_id[funeral_home.id]['favorite'] ? 'text-yellow-300' : ''"
                      @click.stop="$emit('addFavoriteOnFuneralHome', funeral_home)"
                  ></FontAwesomeIcon>
                </div>


              </div>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </DropDownMenu>
  </div>
  <div class="-mt-2 ml-4 text-gray-500 text-xs italic -mb-2">{{ $t('global.remark_affect_funeral_homes_all_m', { var: $t('global.contact')} ) }}</div>
</template>
<script>
import {mapGetters} from "vuex";
import {Menu as DropDownMenu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'

export default {
  name: 'SelectFuneralHomesForContactForm',
  components: {
    MenuItems,
    MenuButton,
    DropDownMenu,
    MenuItem
  },
  props: {
    contact: {
      type: Object,
      required: true
    },
  },
  emits:['addFavoriteOnFuneralHome', 'selectFuneralHomeForContact'],
  computed: {
    ...mapGetters({
      initialMetadata: "metadata/getInitialMetadata"
    })
  },
}
</script>
