export default {
  setAddressList(state, addressList) {
    state.addressList = addressList
  },
  setAddress(state, address) {
    state.address = address
  },
  setAddressMetadata(state, metadata) {
    state.metadata = metadata
  },
  setLongitudeLatitudeToAddress(state, { longitude, latitude }) {
    state.address.longitude = longitude
    state.address.latitude = latitude
  }
}
