<template>
  <div
    class="scrollbar-custom flex h-full flex-col overflow-auto rounded-2xl bg-white p-5"
  >
    <div class="flex flex-shrink-0 flex-row">
      <h2 class="text-2xl font-medium">Playground</h2>
    </div>
    <div class="grid min-h-0 grid-cols-4 gap-8">
      <div class="col-start-1 flex gap-3">
        <BaseAvatar initials="FD" size="extra-small" />
        <BaseAvatar initials="FD" size="small" />
        <BaseAvatar initials="FD" :ring-color="'ring-fuchsia-500'" />
        <BaseAvatar initials="FD" size="large" />
        <BaseAvatar initials="FD" size="extra-large" />
      </div>
      <div class="col-start-1 flex gap-3">
        <AvatarComponent user_id="01hp4p0h6d35xh5dtxt3emwm52" />
        <BaseAvatar initials="FD" />
        <BaseAvatar
          initials="FD"
          img-url="https://fm-v3-staging.s3.eu-west-3.amazonaws.com/v3-staging-fr/media-library/User/01GSVY1B7GNDJ4TJ1HBHYP1580/01ht2qr36prstb5sf14twyhg7y/IMG-20220903-WA0001.jpg"
        />
        <BaseAvatar initials="FD" is-square />
        <BaseAvatar
          initials="FD"
          is-square
          img-url="https://fm-v3-staging.s3.eu-west-3.amazonaws.com/v3-staging-fr/media-library/User/01GSVY1B7GNDJ4TJ1HBHYP1580/01ht2qr36prstb5sf14twyhg7y/IMG-20220903-WA0001.jpg"
        />
        <BaseAvatar
          initials="FD"
          blink-icon-enabled
          :blink-icon="['fas', 'times']"
        />
        <BaseAvatar
          initials="FD"
          is-square
          blink-icon-enabled
          :blink-icon="['fas', 'times']"
          img-url="https://fm-v3-staging.s3.eu-west-3.amazonaws.com/v3-staging-fr/media-library/User/01GSVY1B7GNDJ4TJ1HBHYP1580/01ht2qr36prstb5sf14twyhg7y/IMG-20220903-WA0001.jpg"
        />
      </div>
      <div class="col-start-1 flex gap-3">
        <UserAvatar :user-id="'01hp4p0h6d35xh5dtxt3emwm52'" />
        <UserAvatar :user-id="'01hp4p0h6d35xh5dtxt3emwm52'" role="driver" />
        <UserAvatarPopover
          :user-id="'01hp4p0h6d35xh5dtxt3emwm52'"
          role="driver"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseAvatar from '@c/Avatar/BaseAvatar.vue'
import AvatarComponent from '@c/AvatarComponent.vue'
import UserAvatar from '@c/Avatar/UserAvatar.vue'
import UserAvatarPopover from '@c/Avatar/Popover/UserAvatarPopover.vue'

export default {
  name: 'PlaygroundComponent',
  components: { UserAvatarPopover, UserAvatar, AvatarComponent, BaseAvatar },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      users: 'metadata/getUsersMetadata'
    })
  }
}
</script>
