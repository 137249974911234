import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state() {
    return {
      darkMode: localStorage.getItem('darkmode') === 'true'
    }
  },
  getters,
  actions,
  mutations
}
