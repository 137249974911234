<template>
  <div class="flex flex-1 flex-col">
    <MailBoxFormComponent
      v-if="modelNotification"
      :model-notification="modelNotification"
    />
    <div v-else class="flex flex-1 flex-row items-center justify-center">
      <LoadingComponent />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MailBoxFormComponent from '@/views/modelNotification/form/MailBoxFormComponent.vue'
import LoadingComponent from '@c/TheLoadingComponent.vue'

export default {
  name: 'TheMailBoxIndexPage',
  components: { LoadingComponent, MailBoxFormComponent },
  props: {
    message_id: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      modelNotification: null
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth'
    })
  },
  mounted() {
    if (!this.message_id) {
      this.processFetchNewModelNotificationAction({
        from: {
          id: this.auth.id,
          name: this.auth.full_name,
          type: 'App\\Models\\User',
          emailable: this.auth.email
        }
      }).then((response) => {
        if (response.modelNotification.from) {
          this.modelNotification = response.modelNotification
        } else {
          this.modelNotification = {
            ...response.modelNotification,
            from: {
              id: this.auth.id,
              name: this.auth.full_name,
              type: 'App\\Models\\User',
              emailable: this.auth.email
            }
          }
        }
        this.modelNotification = {
          ...this.modelNotification,
          new_attachments: [],
          document_dossier: []
        }
      })
    } else {
      this.processFetchModelNotificationByIdAction({
        id: this.message_id
      }).then((response) => {
        this.modelNotification = response.model_notification
        this.modelNotification = {
          ...this.modelNotification,
          new_attachments: [],
          document_dossier: []
        }
      })
    }
  },
  methods: {
    ...mapActions({
      processFetchNewModelNotificationAction:
        'modelNotification/fetchNewModelNotification',
      processFetchAllDocumentAttachedToDossierAction:
        'dossier/fetchAllDocumentAttachedToDossier',
      processFetchModelNotificationByIdAction:
        'modelNotification/fetchModelNotificationById'
    })
  }
}
</script>
