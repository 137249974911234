import axios from 'axios'

export const refreshPageMixin = {
  data() {
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      hashChanged: false,
      newHash: ''
    }
  },
  mounted() {
    if (!import.meta.env.DEV) {
      // this.initVersionCheck('/version.json')
    }
  },
  methods: {
    initVersionCheck(url, frequency = 1000 * 30 * 1) {
      setInterval(() => {
        this.checkVersion(url)
      }, frequency)
    },
    // async checkVersion(url) {
    //   axios.get(url + '?t=' + new Date().getTime()).then((response) => {
    //     console.log('response', response)
    //     console.log('new hash', this.newHash)
    //     this.newHash = response.data.hash
    //     console.log('new hash', this.newHash)
    //     this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash)
    //   })
    // },
    async checkVersion(url) {
      console.log('checkVersion', this.currentHash)
      try {
        const fileResponse = await axios.get(url + '?t=' + new Date().getTime())
        this.newHash = fileResponse.data.hash
        console.log('newHash', this.newHash)
        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash)
      } catch (error) {
        this.loading = false
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message
        }
      }
    },
    // hasHashChanged(currentHash, newHash) {
    //   return currentHash !== newHash
    // },
    hasHashChanged(currentHash, newHash) {
      console.log('currentHash bf', currentHash)
      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return true
      }
      console.log('currentHash after', currentHash)
      return currentHash !== newHash
    },
    reloadApp() {
      this.currentHash = this.newHash
      window.location.reload()
    }
  }
}
