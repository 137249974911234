<template>
  <dl class="space-y-3 sm:space-y-0 sm:divide-y sm:divide-gray-200">
    <div class="sm:flex sm:px-6 sm:py-5">
      <dt
        class="text-sm font-medium text-gray-500 capitalize-first sm:w-40 sm:flex-shrink-0 lg:w-48"
      >
        {{ $t('attributes.address') }}
      </dt>
      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        {{ person.street }}<br />{{ person.zip }} {{ person.city }}
      </dd>
    </div>
    <div
      v-if="!person.is_company && person.birth_date"
      class="sm:flex sm:px-6 sm:py-5"
    >
      <dt
        class="text-sm font-medium text-gray-500 capitalize-first sm:w-40 sm:flex-shrink-0 lg:w-48"
      >
        {{ $t('attributes.birth_date') }}
      </dt>
      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        {{ person.birth_date }}
      </dd>
    </div>
    <div
      v-if="!person.is_company && person.birth_place"
      class="sm:flex sm:px-6 sm:py-5"
    >
      <dt
        class="text-sm font-medium text-gray-500 capitalize-first sm:w-40 sm:flex-shrink-0 lg:w-48"
      >
        {{ $t('attributes.birth_place') }}
      </dt>
      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        {{ person.birth_place }}
      </dd>
    </div>
    <div class="sm:flex sm:px-6 sm:py-5">
      <dt
        class="text-sm font-medium text-gray-500 capitalize-first sm:w-40 sm:flex-shrink-0 lg:w-48"
      >
        {{ $t('attributes.phone') }}
      </dt>
      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        {{ person.phone_field }}
      </dd>
    </div>
    <div class="sm:flex sm:px-6 sm:py-5">
      <dt
        class="text-sm font-medium text-gray-500 capitalize-first sm:w-40 sm:flex-shrink-0 lg:w-48"
      >
        {{ $t('attributes.mobile') }}
      </dt>
      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        {{ person.mobile_field }}
      </dd>
    </div>
    <div class="sm:flex sm:px-6 sm:py-5">
      <dt
        class="text-sm font-medium text-gray-500 capitalize-first sm:w-40 sm:flex-shrink-0 lg:w-48"
      >
        {{ $t('attributes.email') }}
      </dt>
      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
        {{ person.email }}
      </dd>
    </div>
  </dl>
  <div v-if="person.dossiers" class="flex flex-col gap-2 px-0.5">
    <h4 class="text-xl font-bold text-gray-700 capitalize-first sm:text-xl">
      {{ $t('global.linked_dossiers') }}
    </h4>
    <ShowConcernedPersonModalTable v-if="person.dossiers" :person="person" />
  </div>
  <div v-if="person.invoices" class="flex flex-col gap-2 px-0.5">
    <h4 class="text-xl font-bold text-gray-700 capitalize-first sm:text-xl">
      {{ $t('global.invoicing') }}
    </h4>
    <ShowInvoicesModalTable :invoices="person.invoices" />
  </div>
</template>

<script>
import ShowConcernedPersonModalTable from '@c/Person/component/ShowConcernedPersonModalTable.vue'
import ShowInvoicesModalTable from '@c/Person/component/ShowInvoicesModalTable.vue'

export default {
  name: 'ShowPersonModalView',
  components: {
    ShowInvoicesModalTable,
    ShowConcernedPersonModalTable
  },
  props: {
    person: {
      type: Object,
      required: true
    }
  }
}
</script>
