<template>
  <div class="mt-3 grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2">
    <div>
      <BaseShowEditDatePickerv2
        v-model:date="contact.divorce_date"
        :label="$t('attributes.divorced_date')"
        :edition-mode="editionMode"
        :today-default="false"
        name="divorced_date"
      />
    </div>
  </div>
</template>

<script>
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'

export default {
  name: 'ContactCardDivorceComponent',
  components: { BaseShowEditDatePickerv2 },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    }
  }
}
</script>
