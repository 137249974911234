import attachmentCategoryMutations from './attachmentCategory/mutations'

export default {
  ...attachmentCategoryMutations,

  setListItemsIndex(state, listItemsIndex) {
    state.listItemsIndex = listItemsIndex
  },
  setlistItem(state, listItem) {
    state.listItem = listItem
  },
  setlistItemMetadata(state, metadata) {
    state.listItemMetadata = metadata
  },
  alterListItem(state, payload) {
    state.task[payload.attribute] = payload.value
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  }
}
