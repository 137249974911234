<template>
  <div class="flex h-full flex-col">
    <div
      class="-mx-1 flex min-h-0 flex-1 flex-col gap-4 overflow-y-scroll px-1"
    >
      <div class="flex flex-col gap-2">
        <h3 class="text-lg font-semibold capitalize-first">
          {{ $t('global.bank_accounts') }}
        </h3>
        <div class="grid grid-cols-3 gap-3 md:grid-cols-4">
          <BankAccountButton
            v-for="bank_account in bank_accounts"
            :key="bank_account.id"
            :bank-account="bank_account"
            @click="toggledBankAccount(bank_account)"
            :selected="form.bank_account_id.includes(bank_account.id)"
          />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <h3 class="text-lg font-semibold capitalize-first">
          {{ $t('attributes.periode') }}
        </h3>
        <div class="grid grid-cols-3 gap-3 md:grid-cols-4">
          <div>
            <BaseShowEditDatePickerv2
              :label="$t('attributes.date_from')"
              v-model:date="form.date_from"
              :edition-mode="selectedBankAccount !== null"
              :extra-dots="getDotsFromBankAccount"
              required
            />
          </div>
          <div>
            <BaseShowEditDatePickerv2
              :label="$t('attributes.date_to')"
              v-model:date="form.date_to"
              :edition-mode="selectedBankAccount !== null"
              :extra-dots="getDotsFromBankAccount"
              required
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex shrink-0 flex-col gap-2">
      <h3 class="text-lg font-semibold capitalize-first">
        {{ $t('options') }}
      </h3>
      <div class="grid grid-cols-2 gap-3 md:grid-cols-3">
        <div>
          <BaseCheckBox
            v-model="form.mark_as_exported"
            :label="'marquer comme exporté'"
            edition-mode
          />
        </div>
        <div>
          <BaseCheckBox
            v-model="form.include_exported"
            :label="'inclure les exportés'"
            edition-mode
          />
        </div>
      </div>
    </div>
    <div class="flex shrink-0 flex-row-reverse">
      <BaseButton
        icon="file-export"
        :title="$t('global.export')"
        @click="processExport"
        :disabled="!canExport"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'
import { mapActions, mapGetters } from 'vuex'
import AccountDiaryButton from '@/views/invoicing/index/accountingExport/component/AccountDiaryButton.vue'
import BankAccountButton from '@/views/invoicing/index/accountingExport/component/BankAccountButton.vue'

export default {
  name: 'ExportTreasury',
  components: {
    BankAccountButton,
    AccountDiaryButton,
    BaseCheckBox,
    BaseShowEditDatePickerv2,
    BaseButton
  },

  data() {
    return {
      bank_accounts: [],
      form: {
        bank_account_id: [],
        date_from: null,
        date_to: null,
        mark_as_exported: false,
        include_exported: false
      }
    }
  },
  computed: {
    ...mapGetters({}),
    selectedBankAccount() {
      if (!this.form.bank_account_id) {
        return null
      }
      return this.bank_accounts.find(
        (bank_account) => bank_account.id === this.form.bank_account_id
      )
    },
    canExport() {
      return (
        this.form.bank_account_id.length > 0 &&
        this.form.date_from !== null &&
        this.form.date_to !== null
      )
    },
    getDotsFromBankAccount() {
      if (!this.selectedBankAccount) {
        return []
      }
      let array = []
      this.selectedBankAccount.payments.map((payment) => {
        let color = payment.exported === 1 ? 'green' : 'orange'
        let date = payment.emission_date
        if (
          !!date &&
          !array.some((item) => item.color === color && item.date === date)
        ) {
          array.push({
            color,
            date
          })
        }
      })
      return array
    }
  },
  methods: {
    ...mapActions({
      processFetchBankAccountListForExportAction:
        'invoicing/fetchBankAccountListForExport',
      processExportTreasuryAction: 'invoicing/exportTreasury'
    }),
    fetchExportDataset() {
      this.processFetchBankAccountListForExportAction().then(
        (response) => (this.bank_accounts = response.bank_accounts)
      )
    },
    processExport() {
      this.processExportTreasuryAction({
        data: this.form
      }).then((response) => {
        if (response.message) {
          this.$h.toastInfo(response.message)
        }
        if (response.url) {
          this.$h.downloadURI(response.url)
        }
        this.fetchExportDataset()
      })
    },
    toggledBankAccount(bankAccountId) {
      if (this.form.bank_account_id.includes(bankAccountId.id)) {
        this.form.bank_account_id.splice(
          this.form.bank_account_id.indexOf(bankAccountId.id),
          1
        )
        return
      }
      this.form.bank_account_id.push(bankAccountId.id)
    }
  },
  mounted() {
    this.fetchExportDataset()
  }
}
</script>
