/* eslint-disable camelcase */
import apiClient from '@u/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchMessageTextList({ commit }) {
    await apiClient
      .get('message-text')
      .then(async (response) => {
        commit('setMessageTextList', response.data.message_texts)
        commit('setMessageTextMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchMessageTextList', reason)
      })
  },
  async fetchMessageTextById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`message-text/${payload.id}`)
      .then(async (response) => {
        commit('setMessageText', await response.data.message_text)
        commit('setMessageTextMetadata', await response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchMessageTextById', reason)
      })
  },
  async fetchNewMessageText({ commit }) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .get('model/new?model=messageText')
      .then(async (response) => {
        commit('setMessageText', response.data.messageText)
        commit('setMessageTextMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await commit('fetchNewMessageText', reason)
      })
  },
  
  // endregion

  // region store
  async storeMessageText({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .post('message-text', payload.message_text)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setMessageTextList', response.data.message_texts)
          commit('setMessageTextMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('storeMessageText', reason)
      })
  },
  // endregion

  // region update
  async updateMessageText({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .put(`message-text/${payload.message_text.id}`, payload.message_text)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setMessageText', await response.data.message_text)
          commit('setMessageTextMetadata', await response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('updateMessageText', reason)
      })
  },
  // endregion

  // region delete
  async deleteMessageText({ commit }, payload) {
    await apiClient
      .delete(`message-text/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setMessageTextList', response.data.message_texts)
          commit('setMessageTextMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteMessageTextById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteMessageText({ commit }, payload) {
    await apiClient
      .get(`message-text/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setMessageTextList', response.data.message_texts)
          commit('setMessageTextMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteMessageTextById', reason)
      })
  }
  // endregion
}
