export default {
  setInvoiceItemIndex(state, invoiceItemList) {
    state.invoiceItems = invoiceItemList
  },
  setInvoiceItemsMetadata(state, invoiceItemMetadata) {
    state.invoiceItemsMetadata = invoiceItemMetadata
  },

  reorderInvoiceItems(state, payload) {
    // console.log('reorderInvoiceItems')
    let item = state.invoice.invoice_items.find(
      (invoice_item) => invoice_item.id === payload.id
    )
    if (payload.data.type === 'detailed') {
      // console.log('detailed')
      if (item.invoicing_category_id === payload.data.category_id) {
        // on prend les invoice items de la categorie
        let invoice_items = state.invoice.invoice_items.filter(
          (invoice_item) =>
            invoice_item.invoicing_category_id === payload.data.category_id
        )
        // on prend les invoice items qui ne sont pas de la categorie
        // pour les merger a la fin avec la liste du dessous qui aura été modifiée
        let invoice_items_without = state.invoice.invoice_items.filter(
          (invoice_item) =>
            invoice_item.invoicing_category_id !== payload.data.category_id
        )
        // on retire celui qu'on a bougé
        invoice_items.splice(payload.data.oldIndex, 1)
        // on le place au bon endroit
        invoice_items.splice(payload.data.newIndex, 0, item)

        let items = invoice_items.map((invoice_item, index) => {
          return {
            ...invoice_item,
            position: index
          }
        })
        // console.log([...items])
        // on merge les deux tableaux
        state.invoice.invoice_items = [...invoice_items_without, ...items]
      } else {
        // on prend les invoice items de la categorie
        let invoice_items = state.invoice.invoice_items.filter(
          (invoice_item) =>
            invoice_item.invoicing_category_id === payload.data.category_id
        )
        // on prend les invoice items qui ne sont pas de la categorie et qui n'est pas celui qu'on bouge
        // pour les merger a la fin avec la liste du dessous qui aura été modifiée
        let invoice_items_without = state.invoice.invoice_items.filter(
          (invoice_item) =>
            invoice_item.invoicing_category_id !== payload.data.category_id &&
            invoice_item.id !== item.id
        )

        item.invoicing_category_id = payload.data.category_id

        // on le place au bon endroit
        invoice_items.splice(payload.data.newIndex, 0, item)

        let items = invoice_items.map((invoice_item, index) => {
          return {
            ...invoice_item,
            position: index
          }
        })

        state.invoice.invoice_items = [...invoice_items_without, ...items]
      }
    }
    else if (payload.data.type === 'normalized') {
      console.log('normalized');
      if (parseInt(item.category_devis_normalised) === payload.data.category_id) {
        // on prend les invoice items de la categorie
        let invoice_items = state.invoice.invoice_items.filter(
          (invoice_item) =>
            parseInt(invoice_item.category_devis_normalised) === payload.data.category_id
        )
        // on prend les invoice items qui ne sont pas de la categorie
        // pour les merger a la fin avec la liste du dessous qui aura été modifiée
        let invoice_items_without = state.invoice.invoice_items.filter(
          (invoice_item) =>
            parseInt(invoice_item.category_devis_normalised) !== payload.data.category_id
        )
        // on retire celui qu'on a bougé
        invoice_items.splice(payload.data.oldIndex, 1)
        // on le place au bon endroit
        invoice_items.splice(payload.data.newIndex, 0, item)
        let items = invoice_items.map((invoice_item, index) => {
          return {
            ...invoice_item,
            normalized_position: index
          }
        })
        // console.log([...items])
        // on merge les deux tableaux
        state.invoice.invoice_items = [...invoice_items_without, ...items]
        console.log(state.invoice.invoice_items);
      } else {
        // on prend les invoice items de la categorie
        let invoice_items = state.invoice.invoice_items.filter(
          (invoice_item) =>
            invoice_item.category_devis_normalised === payload.data.category_id
        )
        // on prend les invoice items qui ne sont pas de la categorie et qui n'est pas celui qu'on bouge
        // pour les merger a la fin avec la liste du dessous qui aura été modifiée
        let invoice_items_without = state.invoice.invoice_items.filter(
          (invoice_item) =>
            invoice_item.category_devis_normalised !== payload.data.category_id &&
            invoice_item.id !== item.id
        )

        item.category_devis_normalised = payload.data.category_id

        // on le place au bon endroit
        invoice_items.splice(payload.data.newIndex, 0, item)

        let items = invoice_items.map((invoice_item, index) => {
          return {
            ...invoice_item,
            normalized_position: index
          }
        })
        state.invoice.invoice_items = [...invoice_items_without, ...items]

      }
    }

  }
}
