export default {
  getBurEventInDossier(state) {
    return state.dossier.events?.find((el) => el.type === 'BUR')
  },
  getDisEventInDossier(state) {
    return state.dossier.events?.find((el) => el.type === 'DIS')
  },
  getAsrEventInDossier(state) {
    return state.dossier.events?.find((el) => el.type === 'ASR')
  },
  getOthEventInDossier(state) {
    return state.dossier.events?.find((el) => el.type === 'OTH')
  },
  getConEventInDossier(state) {
    return state.dossier.events?.find((el) => el.type === 'CON')
  }
}
