// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//region Solid icons
import {
  faCrosshairsSimple as faCrosshairsSimpleSolid,
  faAddressBook as faAddressBookSolid,
  faAddressCard as faAddressCardSolid,
  faArrowPointer as faArrowPointerSolid,
  faArrowRight as faArrowRightSolid,
  faArrowsRepeat as faArrowsRepeatSolid,
  faCaretDown as faCaretDownSolid,
  faCarGarage as faCarGarageSolid,
  faChartLineUp as faChartLineUpSolid,
  faCheck as faCheckSolid,
  faFileCheck as faFileCheckSolid,
  faCheckSquare as faCheckSquareSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
  faChurch as faChurchSolid,
  faCircle as faCircleSolid,
  faCircleCheck as faCircleCheckSolid,
  faCircleInfo as faCircleInfoSolid,
  faCircleQuestion as faCircleQuestionSolid,
  faCity as faCitySolid,
  faClock as faClockSolid,
  faCoffin as faCoffinSolid,
  faCommand as faCommandSolid,
  faCommentQuestion as faCommentQuestionSolid,
  faCopy as faCopySolid,
  faCross as faCrossSolid,
  faDrone as faDroneSolid,
  faEllipsis as faEllipsisSolid,
  faEnvelope as faEnvelopeSolid,
  faEuroSign as faEuroSignSolid,
  faSlashForward as faSlashForwardSolid,
  faCircleEuro as faCircleEuroSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faFaceSmile as faFaceSmileSolid,
  faFamily as faFamilySolid,
  faFeather as faFeatherSolid,
  faFileContract as faFileContractSolid,
  faFileLines as faFileLinesSolid,
  faFilePen as faFilePenSolid,
  faFireplace as faFireplaceSolid,
  faHotel as faHotelSolid,
  faFlower as faFlowerSolid,
  faHouseBlank as faHouseBlankSolid,
  faHouseNight as faHouseNightSolid,
  faListCheck as faListCheckSolid,
  faLock as faLockSolid,
  faLockOpen as faLockOpenSolid,
  faMicrophone as faMicrophoneSolid,
  faMug as faMugSolid,
  faNewspaper as faNewsPaperSolid,
  faPaperclip as faPaperClipSolid,
  faPen as faPenSolid,
  faPeopleCarryBox as faPeopleCarryBoxSolid,
  faPhone as faPhoneSolid,
  faPiggyBank as faPiggyBankSolid,
  faPlaneDeparture as faPlaneDepartureSolid,
  faPlus as faPlusSolid,
  faPrint as faPrintSolid,
  faReply as faReplySolid,
  faRingsWedding as faRingsWeddingSolid,
  faShovel as faShovelSolid,
  faSpinner as faSpinnerSolid,
  faStar as faStarSolid,
  faStarOfLife as faStarOfLifeSolid,
  faSteeringWheel as faSteeringWheelSolid,
  faTag as faTagSolid,
  faTimes as faTimesSolid,
  faTimesCircle as faTimesCircleSolid,
  faTombstone as faTombstoneSolid,
  faTrash as faTrashSolid,
  faTruck as faTruckSolid,
  faTruckClock as faTruckClockSolid,
  faTruckContainer as faTruckContainerSolid,
  faUser as faUserSolid,
  faUsers as faUsersSolid,
  faUserTag as faUserTagSolid,
  faUserTie as faUserTieSolid,
  faImagePortrait as faImagePortraitSolid,
  faBookmark as faBookmarkSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faCloud as faCloudSolid,
  faDatabase as faDatabaseSolid,
  faCamera as faCameraSolid,
  faBrainCircuit as faBrainCircuitSolid,
  faTruckMoving as faTruckMovingSolid,
  faGarageCar as faGarageCarSolid,
  faCircleXmark as faCircleXmarkSolid,
  faFileCode as faFileCodeSolid,
  faArrowRightArrowLeft as faArrowRightArrowLeftSolid,
  faShareNodes as faShareNodesSolid,
  faRight as faRightSolid,
  faLeft as faLeftSolid,
  faLink as faLinkSolid,
  faUnlink as faUnlinkSolid,
  faHighDefinition as faHighDefinitionSolid,
  faStandardDefinition as faStandardDefinitionSolid,
  faArrowLeft as faArrowLeftSolid,
  faRotateRight as faRotateRightSolid
} from '@fortawesome/pro-solid-svg-icons'
//endregion

//region Regular icons
import {
  faArrowLeftFromLine as faArrowLeftFromLineRegular,
  faBold as faBoldRegular,
  faBoxesStacked as faBoxesStackedRegular,
  faCircle as faCircleRegular,
  faCircleCheck as faCircleCheckRegular,
  faFeather as faFeatherRegular,
  faHotel as faHotelRegular,
  faItalic as faItalicRegular,
  faParagraph as faParagraphRegular,
  faTags as faTagsRegular,
  faTimes as faTimesRegular,
  faUnderline as faUnderlineRegular,
  faWarehouse as faWarehouseRegular,
  faFontCase as faFontCaseRegular,
  faArrowUpRightDots as faArrowUpRightDotsRegular
} from '@fortawesome/pro-regular-svg-icons'
//endregion

//region Brand icons
import { faSlack } from '@fortawesome/free-brands-svg-icons'
//endregion

//region Light icons
import {
  faAddressBook,
  faAddressCard,
  faAlarmClock,
  faAlarmExclamation,
  faAnalytics,
  faArchive,
  faArrowAltFromLeft,
  faArrowDownToSquare,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowsFromLine,
  faArrowsMaximize,
  faArrowsMinimize,
  faArrowSquareDown,
  faArrowUpRightFromSquare,
  faBadgeDollar,
  faBars,
  faBell,
  faBellOn,
  faBold,
  faBolt,
  faBook,
  faBookmark,
  faBookOpen,
  faBooks,
  faBoxesStacked,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarCircleUser,
  faCalendarClock,
  faCalendarDay,
  faCalendarDays,
  faCalendarMinus,
  faCalendarRange,
  faCalendarWeek,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChurch,
  faCircle,
  faCircleBolt,
  faCircleInfo,
  faCircleNotch,
  faCirclePlus,
  faCircleXmark,
  faCity,
  faClipboardListCheck,
  faClock,
  faClockRotateLeft,
  faClone,
  faClosedCaptioning,
  faCloudArrowDown,
  faCodeBranch,
  faCoffinCross,
  faCogs,
  faCommand,
  faComment,
  faCompressAlt,
  faCopy,
  faCreditCard,
  faCross,
  faDatabase,
  faDiamondTurnRight,
  faDigging,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEuroSign,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrows,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFamily,
  faFax,
  faFile,
  faFileAlt,
  faFileContract,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLines,
  faFilePdf,
  faFilePen,
  faFilePlus,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFileZipper,
  faFilter,
  faFireplace,
  faFolder,
  faFolderMagnifyingGlass,
  faFolderOpen,
  faFolderPlus,
  faFont,
  faGaugeCircleBolt,
  faGridHorizontal,
  faGaugeMax,
  faGlobe,
  faGraduationCap,
  faHandshake,
  faHospital,
  faHotel,
  faHouse,
  faHouseNight,
  faInbox,
  faIndent,
  faInfoSquare,
  faItalic,
  faLandmark,
  faLink,
  faList,
  faListOl,
  faListTimeline,
  faListUl,
  faLocation,
  faLocationArrow,
  faLocationSlash,
  faLock,
  faLockOpen,
  faLongArrowLeft,
  faMagnifyingGlassLocation,
  faMailbox,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapMarkerCheck,
  faMessages,
  faMinus,
  faMinusCircle,
  faMobile,
  faMoneyCheckAlt,
  faMoon,
  faPaperclip,
  faPaperPlane,
  faParagraph,
  faPen,
  faPenCircle,
  faPeopleRoof,
  faPercent,
  faPersonDolly,
  faPhone,
  faPhoneAlt,
  faPiggyBank,
  faPlaneDeparture,
  faPlus,
  faPollPeople,
  faPrint,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faQuoteLeft,
  faRedo,
  faReply,
  faRightLeft,
  faRingsWedding,
  faSearch,
  faShapes,
  faShare,
  faShovel,
  faSignOut,
  faSlidersH,
  faSort,
  faSortAlt,
  faSortDown,
  faSortUp,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faStar,
  faStarfighterAlt,
  faStrikethrough,
  faSun,
  faSwatchbook,
  faSync,
  faSyncAlt,
  faTable,
  faTag,
  faTasks,
  faTasksAlt,
  faText,
  faTextSize,
  faThumbsUp,
  faTicket,
  faTimes,
  faTombstone,
  faTrash,
  faTruck,
  faTruckClock,
  faTv,
  faUnderline,
  faUndo,
  faUndoAlt,
  faUnlock,
  faUser,
  faUserCircle,
  faUserClock,
  faUserCrown,
  faUserDoctor,
  faUserFriends,
  faUserHardHat,
  faUserHelmetSafety,
  faUserPlus,
  faUsers,
  faUserTie,
  faUserUnlock,
  faWandSparkles,
  faCloud,
  faHeadset,
  faSidebar,
  faSidebarFlip,
  faFileSignature,
  faInputText,
  faPenField,
  faMicrochipAi,
  faMessageSms,
  faMicrophoneStand,
  faCoffin,
  faPeopleCarryBox,
  faPersonCarryBox,
  faVanShuttle,
  faSteeringWheel
} from '@fortawesome/pro-light-svg-icons'
//endregion

//region Library
library.add(
  faCrosshairsSimpleSolid,
  faRotateRightSolid,
  faArrowLeftSolid,
  faRightSolid,
  faLeftSolid,
  faLinkSolid,
  faUnlinkSolid,
  faAddressBook,
  faAddressCard,
  faAlarmClock,
  faAlarmExclamation,
  faAnalytics,
  faArchive,
  faArrowAltFromLeft,
  faArrowDownToSquare,
  faArrowLeft,
  faArrowPointerSolid,
  faArrowRightSolid,
  faArrowRight,
  faArrowsAltH,
  faArrowsFromLine,
  faArrowSquareDown,
  faArrowsRepeatSolid,
  faArrowsRepeatSolid,
  faArrowUpRightFromSquare,
  faBadgeDollar,
  faBars,
  faBell,
  faBellOn,
  faSquare,
  faBold,
  faBold,
  faBolt,
  faBook,
  faBookmark,
  faBookOpen,
  faBooks,
  faBoxesStacked,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarDay,
  faCalendarDays,
  faCalendarMinus,
  faCalendarCircleUser,
  faCalendarClock,
  faCaretDownSolid,
  faCarGarageSolid,
  faChartBar,
  faChartLineUpSolid,
  faCheck,
  faCheckCircle,
  faCheckSolid,
  faFileCheckSolid,
  faEnvelopeSolid,
  faPhoneSolid,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronDownSolid,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronUpSolid,
  faChurch,
  faCircle,
  faCircleInfo,
  faFamily,
  faRingsWedding,
  faChurchSolid,
  faCircleCheckSolid,
  faCircleNotch,
  faCirclePlus,
  faCircleXmark,
  faCity,
  faCitySolid,
  faClipboardListCheck,
  faClock,
  faClockRotateLeft,
  faClockSolid,
  faClone,
  faClosedCaptioning,
  faCloudArrowDown,
  faCodeBranch,
  faCoffinCross,
  faCoffinSolid,
  faCogs,
  faCommand,
  faCommandSolid,
  faComment,
  faCopySolid,
  faCopy,
  faCompressAlt,
  faCopy,
  faCreditCard,
  faCross,
  faCrossSolid,
  faDatabase,
  faDiamondTurnRight,
  faDigging,
  faDollarSign,
  faDownload,
  faDroneSolid,
  faEllipsisSolid,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEuroSign,
  faEuroSignSolid,
  faSlashForwardSolid,
  faCircleEuroSolid,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExclamationTriangleSolid,
  faExpandArrows,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFaceSmileSolid,
  faFax,
  faFeatherSolid,
  faFile,
  faFileAlt,
  faFileContract,
  faFileContractSolid,
  faFileExcel,
  faFileExport,
  faHotelSolid,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLinesSolid,
  faFilePdf,
  faFilePen,
  faFilePenSolid,
  faFilePlus,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFileZipper,
  faFilter,
  faFireplace,
  faFireplaceSolid,
  faFlowerSolid,
  faFolder,
  faFolderMagnifyingGlass,
  faFolderOpen,
  faFolderPlus,
  faFont,
  faFont,
  faGaugeMax,
  faCircleBolt,
  faGlobe,
  faGraduationCap,
  faHandshake,
  faHospital,
  faHotel,
  faHotelRegular,
  faHouse,
  faHouseNight,
  faGaugeCircleBolt,
  faGridHorizontal,
  faHouseNightSolid,
  faInbox,
  faIndent,
  faInfoSquare,
  faItalic,
  faItalic,
  faLandmark,
  faFileLines,
  faLink,
  faList,
  faListCheckSolid,
  faListOl,
  faListOl,
  faListUl,
  faLocation,
  faLocationArrow,
  faLocationSlash,
  faLock,
  faLockOpen,
  faLongArrowLeft,
  faMailbox,
  faMagnifyingGlassLocation,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapMarkerCheck,
  faMessages,
  faMicrophoneSolid,
  faMinus,
  faMinusCircle,
  faMobile,
  faMoneyCheckAlt,
  faMoon,
  faMugSolid,
  faNewsPaperSolid,
  faPaperclip,
  faPaperClipSolid,
  faPaperPlane,
  faParagraph,
  faParagraph,
  faPen,
  faPenCircle,
  faPeopleCarryBoxSolid,
  faPeopleRoof,
  faPercent,
  faPersonDolly,
  faPhone,
  faPhoneAlt,
  faPiggyBank,
  faPiggyBankSolid,
  faPlaneDeparture,
  faPlaneDepartureSolid,
  faPlus,
  faPlusSolid,
  faPollPeople,
  faPrint,
  faPrintSolid,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faQuoteLeft,
  faRedo,
  faReplySolid,
  faLockOpenSolid,
  faLockSolid,
  faRightLeft,
  faSearch,
  faShapes,
  faShovel,
  faShovelSolid,
  faSignOut,
  faSlidersH,
  faSort,
  faSort,
  faSort,
  faSort,
  faSortAlt,
  faSortDown,
  faSortUp,
  faSpinnerSolid,
  faSpinnerThird,
  faStar,
  faStarfighterAlt,
  faStarOfLifeSolid,
  faStarSolid,
  faStarSolid,
  faSteeringWheelSolid,
  faStrikethrough,
  faUnderline,
  faSun,
  faSwatchbook,
  faSync,
  faSyncAlt,
  faTag,
  faTagSolid,
  faTasks,
  faSpinner,
  faTasksAlt,
  faText,
  faTextSize,
  faThumbsUp,
  faTicket,
  faTimes,
  faTimesSolid,
  faTimesCircleSolid,
  faTombstone,
  faTombstoneSolid,
  faTrash,
  faTrashSolid,
  faTruck,
  faTruckClock,
  faTruckClockSolid,
  faTruckContainerSolid,
  faTruckSolid,
  faUndo,
  faUndoAlt,
  faUnlock,
  faUser,
  faUserCircle,
  faUserClock,
  faUserCrown,
  faUserDoctor,
  faUserFriends,
  faUserHardHat,
  faUserHelmetSafety,
  faUserPlus,
  faUsers,
  faUsers,
  faUserSolid,
  faUsersSolid,
  faCircleInfoSolid,
  faCircleQuestionSolid,
  faFamilySolid,
  faRingsWeddingSolid,
  faAddressBookSolid,
  faAddressCardSolid,
  faUserTagSolid,
  faUserTie,
  faUserTieSolid,
  faUserUnlock,
  faWandSparkles,
  faPiggyBankSolid,
  faCommentQuestionSolid,
  faFileLinesSolid,
  faMugSolid,
  faFeatherSolid,
  faPrintSolid,
  faEuroSignSolid,
  faFlowerSolid,
  faListCheckSolid,
  faCheckSolid,
  faFileCheckSolid,
  faPhoneSolid,
  faEnvelopeSolid,
  faHouseBlankSolid,
  faPlusSolid,
  faCircleCheckSolid,
  faTimesSolid,
  faTimesCircleSolid,
  faStarOfLifeSolid,
  faReplySolid,
  faLockOpenSolid,
  faLockSolid,
  faTombstone,
  faChurch,
  faFireplace,
  faHospital,
  faCity,
  faHouseNight,
  faFilter,
  faSort,
  faStarSolid,
  faChartLineUpSolid,
  faCaretDownSolid,
  faSort,
  faAlarmClock,
  faTag,
  faArrowsFromLine,
  faFax,
  faCloudArrowDown,
  faClockRotateLeft,
  faArrowsFromLine,
  faUserHelmetSafety,
  faCirclePlus,
  faThumbsUp,
  faPhone,
  faMobile,
  faTruck,
  faArrowDownToSquare,
  faRightLeft,
  faTruckClock,
  faGlobe,
  faStar,
  faTv,
  faArrowsMaximize,
  faArrowsMinimize,
  faTable,
  faListTimeline,
  faReply,
  faShare,
  faCloud,
  faHeadset,
  faSidebar,
  faSidebarFlip,
  faFileSignature,
  faInputText,
  faPenField,
  faMessageSms,
  faMicrophoneStand,
  faCoffin,
  faPeopleCarryBox,
  faPersonCarryBox,
  faVanShuttle,
  faSteeringWheel,

  faCircleRegular,
  faCircleCheckRegular,
  faArrowLeftFromLineRegular,
  faFeatherRegular,
  faBoldRegular,
  faItalicRegular,
  faUnderlineRegular,
  faParagraphRegular,
  faFontCaseRegular,
  faArrowUpRightDotsRegular,

  faPenSolid,
  faExclamationTriangleSolid,
  faSpinnerSolid,
  faClockSolid,
  faCarGarageSolid,
  faTruckSolid,
  faTruckContainerSolid,
  faDroneSolid,
  faEllipsisSolid,
  faPeopleCarryBoxSolid,
  faMicrophoneSolid,
  faSteeringWheelSolid,
  faPaperClipSolid,
  faTagSolid,
  faNewsPaperSolid,
  faCommandSolid,
  faTrashSolid,
  faFaceSmileSolid,
  faFileContractSolid,
  faShovelSolid,
  faFilePenSolid,
  faPlaneDepartureSolid,
  faCrossSolid,
  faTruckClockSolid,
  faChurchSolid,
  faCitySolid,
  faFireplaceSolid,
  faTombstoneSolid,
  faHouseNightSolid,
  faArrowPointerSolid,
  faArrowsRepeatSolid,
  faArrowRightSolid,
  faCircleSolid,
  faCalendarRange,
  faCalendarWeek,
  faTimesRegular,
  faCheckSquareSolid,
  faWarehouseRegular,
  faTagsRegular,
  faBoxesStackedRegular,
  faImagePortraitSolid,
  faBookmarkSolid,
  faExclamationCircleSolid,
  faCloudSolid,
  faDatabaseSolid,
  faCameraSolid,
  faBrainCircuitSolid,
  faGarageCarSolid,
  faTruckMovingSolid,
  faCircleXmarkSolid,
  faFileCodeSolid,
  faArrowRightArrowLeftSolid,
  faShareNodesSolid,
  faHighDefinitionSolid,
  faStandardDefinitionSolid,
  faMicrochipAi,

  faSlack
)
//endregion

export default (app) => {
  app.component('FontAwesomeIcon', FontAwesomeIcon)
}
