import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      attachmentCategoryIndex: [],
      listItemsIndex: [],
      listItem: {},
      listItemMetadata: {},
      editionMode: false
    }
  },
  getters,
  actions,
  mutations
}
