export default {
  getContactList(state) {
    return state.contactList
  },
  getWikiContactList(state) {
    return state.wikiContactList
  },
  getContact(state) {
    return state.contact
  }
}
