<template>
  <div class="flex flex-row items-center gap-2 rounded border p-2">
    <span>
      <FontAwesomeIcon :icon="getIcon" />
    </span>
    <span class="truncate text-xs font-semibold">
      <a :href="attachment.original_url" target="_blank">
        {{ attachment.name }}
      </a>
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'AttachmentComponent',
  components: { FontAwesomeIcon },
  props: {
    attachment: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getIcon() {
      switch (this.attachment.extension) {
        case 'jpg':
        case 'png':
        case 'gif':
        case 'bmp':
          return ['fal', 'file-image']
        default:
          return ['fal', 'file']
      }
    }
  },
  mounted() {
  }
})
</script>
