export default {
  getFuneralHomesList(state) {
    return state.funeralHomesList
  },
  getFuneralHome(state) {
    return state.funeralHome
  },
  getFuneralHomeMetadata(state) {
    return state.metadata
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getFuneralHomeActivities(state) {
    return state.funeralHomeActivities
  }
}
