/* eslint-disable camelcase */
import apiClient from '@u/apiClient'
// import router from "../../../../router";

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchLocationIndex({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('location')
      .then(async (response) => {
        await commit('setLocationList', response.data.locations)
        await commit('setLocationMetadata', response.data.metadata)
      })
      .catch((reason) => {
        console.error('storeNewImplantation', reason)
      })
  },
  async fetchLocationByID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`location/${payload.id}`)
      .then(async (response) => {
        await commit('setLocation', response.data.location)
        await commit('setLocationMetadata', response.data.metadata)
      })
      .catch((reason) => {
        console.error('storeNewImplantation', reason)
      })
  },
  async fetchNewLocation({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=location')
      .then(async (response) => {
        await commit('setLocation', response.data.location)
        await commit('setLocationMetadata', response.data.metadata)
      })
      .catch((reason) => {
        console.error('storeNewImplantation', reason)
      })
  },
  async fetchLocationActivitiesByLocationID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`location/fetch-activities/${payload.location_id}`)
      .then(async (response) => {
        await commit('setLocationActivity', response.data.activities)
        await commit('setMetadataActivities', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },
  // endregion

  // region store
  async storeNewLocation({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post('location', payload.location)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setLocation', response.data.location)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('storeNewLocation', reason)
      })
  },
  async storeTimeSlot({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`location/add-timeslot/${payload.id}`, payload.timeSlot)
      .then(async (response) => {
        await commit('setLocation', response.data.data)
        processSuccessCallback(payload)
      })
      .catch(async (reason) => {
        console.error('storeTimeSlot', reason)
      })
  },

  // endregion

  // region update
  async updateLocation({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`location/${payload.id}`, payload.location)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setLocation', response.data.location)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('updateLocation', reason)
      })
  },
  // endregion

  // region delete
  async deleteLocationById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .delete(`location/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setLocationList', response.data.locations)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteLocationById', reason)
      })
  },
  async deleteTimeslotById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .delete(`timeslot/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setLocation', response.data.location)
          await commit('setLocationMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteTimeslotById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteLocationById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`location/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setLocationList', response.data.locations)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteLocationById', reason)
      })
  },
  async undoDeleteTimeslotById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`timeslot/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setLocation', response.data.location)
          await commit('setLocationMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteTimeslotById', reason)
      })
  }
  // endregion
}
