<template>
  <div>
    <div class="fm-form-input" :class="label ? 'mt-2' : ''">
      <label>
        {{ label }}&nbsp;
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <textarea
        v-model="localModelValue"
        :name="name"
        :disabled="disabled"
        :rows="rows"
      ></textarea>
    </div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSimpleTextArea',
  props: {
    name: {
      type: String,
      default: ''
    },
    hideModelValue: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    value: {
      type: [String, Number],
      default: '',
      require: true
    },
    modelValue: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    rows: {
      type: String,
      default: '3'
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
