import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      city: 'wiki/getCity',
      initialMetadata: 'metadata/getInitialMetadata',
      errors: 'auth/getErrors'
    })
  }
}
