import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    funeralHomeId() {
      if (this.$route.name === 'settings_edit_funeral_home_event_items_route') {
        return this.$route.params.id
      }
      return null
    }
  },
  methods: {
    ...mapMutations({
      processSetSingleSettingMutation: 'metadata/setSingleSetting'
    }),
    ...mapActions({
      processChangeSettingsKeyAction: 'settings/changeSettingsKey'
    }),
    changeSettingsKeys(payload) {
      this.processChangeSettingsKeyAction({
        key: payload.name,
        newVal: payload.value,
        funeralHomeId: this.funeralHomeId
      }).then((response) => {
        if (response.success) {
          this.$h.toastSuccess(
            this.$t('toasts.updated') +
              ' : ' +
              this.$t('enums.settings.settingsKeys.' + payload.name)
          )
          if (payload.successCallback) {
            payload.successCallback()
          }
        }
      })
    }
  }
}
