<template>
  <section
    class="flex flex-col gap-2 @5xl/dossier-main:flex-row"
    :class="[flex1 ? 'flex-1' : '']"
  >
    <div class="@5xl/dossier-main:w-1/5 @6xl/dossier-main:w-1/5">
      <div
        class="@5xl/dossier-main:sticky"
        :class="[
          disableStickyMargin
            ? '@5xl/dossier-main:top-0'
            : '@5xl/dossier-main:top-16'
        ]"
      >
        <h2
          v-if="!$slots.header && title && title.length"
          class="text-xl font-medium text-gray-900 capitalize-first xl:sticky"
          :class="[disableStickyMargin ? 'xl:top-0' : 'xl:top-16']"
        >
          {{ title }}
        </h2>
        <slot v-if="$slots.header" name="header"></slot>
        <slot v-if="$slots['sub-header']" name="sub-header"></slot>
      </div>
    </div>
    <div
      class="flex flex-col gap-6 rounded bg-white p-6 pt-5 shadow @5xl/dossier-main:w-4/5 @6xl/dossier-main:w-3/5"
    >
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageSection',
  props: {
    title: {
      type: String,
      required: true
    },
    large: {
      type: Boolean,
      default: true
    },
    flex1: {
      type: Boolean,
      required: false,
      default: false
    },
    disableStickyMargin: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
