const TheAllTasksIndexPage = () =>
  import(
    /* webpackChunkName: "group-tasks" */ '@/views/taskDossier/index/TheAllTaskDossierIndexPage'
  )

export const tasksSubPages = [
  {
    path: '/task-dossier',
    name: 'tasks_index_route',
    component: TheAllTasksIndexPage
  }
]
