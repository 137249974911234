const WorkInProgressComponent = () =>
  import(
    /* webpackChunkName: "group-condolance" */ '@/views/wip/WorkInProgressComponent'
  )

export const condolencesRootPages = {
  path: 'condolences',
  name: 'condolences_page_route',
  component: WorkInProgressComponent
}
