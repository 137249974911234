<template>
  <div class="flex h-screen flex-col">
    <div
      v-if="hashChanged"
      class="refresh-container flex shrink-0 flex-row justify-center gap-2 bg-yellow-400 p-2 shadow-inner"
    >
      <div class="font-bold">{{ $t('toasts.new_update_1') }}</div>
      <button class="rounded bg-white px-2" @click="reloadApp">
        {{ $t('toasts.reload') }}
      </button>
    </div>
    <div class="min-h-0 flex-1">
      <RouterView />
    </div>
  </div>
</template>
<script>
// mixins
import { refreshPageMixin } from '@u/mixins/refresh-page.mixin'

export default {
  mixins: [refreshPageMixin],
  data() {
    return {
      prod: import.meta.env.PROD,
      dev: import.meta.env.DEV
    }
  },
  async beforeMount() {},
  mounted() {
    console.log('%c✘ Env! ' + import.meta.env.MODE, 'color: #E2AF00')
  }
}
</script>
