<template>
  <div v-if="loading || !person" class="flex-1">
    <LoadingComponent />
  </div>
  <div v-else class="flex flex-col gap-y-3">
    <TheContactCard
      :index-contact="0"
      :edition-mode="true"
      :contact-index="0"
      :errors="errors"
      :contact="person"
      :dossier="dossier"
      :with-role-section="false"
      :with-death-section="false"
      :deletable="false"
      :is-card="false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TheContactCard from '@src/views/dossier/form/subpages/contact/components/TheContactCard.vue'
import LoadingComponent from '@c/TheLoadingComponent.vue'

export default {
  name: 'SelectPersonModalForm',
  components: { LoadingComponent, TheContactCard },
  props: {
    person: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors',
      dossier: 'dossier/getDossier'
    }),
    localPerson: {
      get(){
        return this.person
      },
      set(value){
        this.$emit('update:person', value)
      }
    }
  },
  mounted() {
    this.loading = true
    if (this.personId) {
      this.processFetchPersonByIdAction({ person_id: this.personId })
        .then((response) => (this.localPerson = response.person))
        .finally((_) => (this.loading = false))
    } else {
      this.processFetchNewPersonAction()
        .then((response) => (this.localPerson = response.person))
        .finally((_) => (this.loading = false))
    }
  },
  methods: {
    ...mapActions({
      processFetchPersonByIdAction: 'dossier/FetchPersonById',
      processFetchNewPersonAction: 'dossier/fetchNewPerson'
    })
  }
}
</script>
