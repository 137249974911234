<template>
  <template v-if="default_country === 'BE'">
    <button
      type="button"
      @click="clickEid"
      class="bg-id-card flex flex-row items-center justify-center gap-2 rounded-md border p-1 px-2"
    >
      <FontAwesomeIcon :icon="['fal', 'address-card']" size="lg" />
      <span v-if="!hideTitle">{{ $t('button.read_eid') }}</span>
    </button>
    <Teleport to="body">
      <transition
        enter-active-class="transition ease-out duration-300 transform "
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <div
          v-if="eidData"
          class="bg-id-card fixed left-1/2 top-1/2 z-100 flex -translate-x-1/2 -translate-y-1/2 flex-col gap-2 rounded-md border p-4 shadow-2xl"
        >
          <div class="flex w-[32rem] flex-row gap-2">
            <div class="flex-1">
              <div class="uppercase leading-none">Belgique</div>
              <div
                class="whitespace-nowrap text-[10px] font-black uppercase leading-none"
              >
                Carte d'identité
              </div>
            </div>
            <div class="flex-1">
              <div class="uppercase leading-none">BELGIË</div>
              <div
                class="whitespace-nowrap text-[10px] font-black uppercase leading-none"
              >
                IdentiteitKaart
              </div>
            </div>
            <div class="flex-1">
              <div class="uppercase leading-none">Belgien</div>
              <div
                class="whitespace-nowrap text-[10px] font-black uppercase leading-none"
              >
                PersonalAusweis
              </div>
            </div>
            <div class="flex-1">
              <div class="uppercase leading-none">BELGIUM</div>
              <div
                class="whitespace-nowrap text-[10px] font-black uppercase leading-none"
              >
                Identity Card
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="flex flex-row gap-1 text-[10px] leading-none">
                <div class="capitalize-first">
                  {{ $t('attributes.last_name') }}
                </div>
                <div>/</div>
                <div class="italic">Name</div>
              </div>
              <div class="text-base font-bold leading-none">
                {{ eidData.last_name }}
              </div>
            </div>
            <div>
              <div class="flex flex-row gap-1 text-[10px] leading-none">
                <div class="capitalize-first">
                  {{ $t('attributes.first_name') }}
                </div>
                <div>/</div>
                <div class="italic">Given names</div>
              </div>
              <div class="text-base font-bold leading-none">
                {{ eidData.first_name }}
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-2">
            <div class="w-1/3">
              <img
                :src="src_img"
                class="inset-0 aspect-photo w-full rounded bg-white object-contain"
              />
            </div>
            <div class="flex w-2/3 flex-col gap-2">
              <div class="flex flex-row">
                <div class="w-1/5">
                  <div class="flex flex-col text-[10px] leading-none">
                    <div class="flex flex-row gap-2">
                      <div class="capitalize-first">
                        {{ $t('attributes.sex') }}
                      </div>
                      <div>/</div>
                    </div>
                    <div class="italic">Sex</div>
                  </div>
                  <div class="font-bold leading-none">
                    {{ gender_formated }}
                  </div>
                </div>
                <div class="w-2/5">
                  <div class="flex flex-col text-[10px] leading-none">
                    <div class="flex flex-row gap-2">
                      <span class="capitalize-first">
                        {{ $t('attributes.nationality') }}
                      </span>
                      <div>/</div>
                    </div>
                    <div class="italic">Nationality</div>
                  </div>
                  <div class="font-bold leading-none">
                    {{ eidData.nationality }}
                  </div>
                </div>
                <div class="w-2/5">
                  <div class="flex flex-col text-[10px] leading-none">
                    <div class="flex flex-row gap-2">
                      <div class="capitalize-first">
                        {{ $t('attributes.birthdate') }}
                      </div>
                      <div>/</div>
                    </div>
                    <div class="italic">Date of birth</div>
                  </div>
                  <div class="font-bold leading-none">
                    {{ birth_date_formated }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <div>
                  <div class="flex flex-row gap-1 text-[10px] leading-none">
                    <div class="capitalize-first">
                      {{ $t('attributes.last_name') }}
                    </div>
                    <div>/</div>
                    <div class="italic">National Register N°</div>
                  </div>
                  <div class="text-base font-bold leading-none">
                    {{ eidData.national_number }}
                  </div>
                </div>
                <div>
                  <div class="flex flex-row gap-1 text-[10px] leading-none">
                    <div class="capitalize-first">
                      {{ $t('attributes.last_name') }}
                    </div>
                    <div>/</div>
                    <div class="italic">Card N°</div>
                  </div>
                  <div class="text-base font-bold leading-none">
                    {{ eidData.metadata?.cardnumber }}
                  </div>
                </div>
                <div>
                  <div class="flex flex-row gap-1 text-[10px] leading-none">
                    <div class="capitalize-first">
                      {{ $t('attributes.last_name') }}
                    </div>
                    <div>/</div>
                    <div class="italic">Expires on</div>
                  </div>
                  <div class="text-base font-bold leading-none">
                    {{ eidData.metadata?.validityenddate }}
                  </div>
                </div>
              </div>
              <div class="flex flex-1 flex-row items-end justify-end">
                <div class="flex flex-row gap-2">
                  <BaseButton
                    theme-color="bg-secondary text-secondary-contrast"
                    size="extra-small"
                    :title="$t('button.close')"
                    @click="eidData = null"
                  />
                  <BaseButton
                    size="extra-small"
                    :title="$t('button.add')"
                    @click="clickOk"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </Teleport>
  </template>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'EIDReader',
  components: { BaseButton, FontAwesomeIcon },
  emits: ['data-selected'],
  data() {
    return {
      eidData: null
    }
  },
  props: {
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      default_country: 'metadata/getDefaultCountry'
    }),
    src_img() {
      return `data:image/png;base64,${this.eidData?.metadata?.picture ?? ''}`
    },
    gender_formated() {
      return this.eidData.gender === 'male' ? 'M' : 'F'
    },
    birth_date_formated() {
      return this.$h.formatDate(this.eidData.birth_date, 'DD MM YYYY')
    },
    eid_url() {
      return import.meta.env.DEV
        ? `http://${window.location.hostname}:${this.$h.getDevPort()}/eid`
        : `/eid`
    }
  },
  methods: {
    clickEid() {
      window.addEventListener('message', this.popupEvent, false)
      window.open(
        this.eid_url,
        'IdentityProviderWindow',
        'width=400,height=300'
      )
    },
    popupEvent(event) {
      window.removeEventListener('message', this.popupEvent, false)
      this.eidData = event.data.eid
    },
    clickOk() {
      window.removeEventListener('message', this.popupEvent, false)
      let tmp_data = this.eidData
      delete tmp_data.metadata
      this.$emit('data-selected', tmp_data)
      this.eidData = null
    }
  }
}
</script>

<style scoped>
.bg-id-card {
  background: rgb(203, 222, 205);
  background: radial-gradient(
    circle,
    rgba(203, 222, 205, 1) 0%,
    rgba(193, 225, 213, 1) 82%
  );
}
</style>
