<template>
  <div
      class="bg-white  my-3 box shadow-md py-5 px-5 w-full border border-gray-100">
    <div class="w-full flex flex-col gap-y-2 px-3">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col">
          <h3 class="text-gray-600 font-semibold text-lg capitalize-first  select-none">
            {{ supplierOrder.funeral_home.name }}
          </h3>
          <p class="text-gray-500 text-sm select-none">
            {{ `${$t('attributes.reference')}: ${supplierOrder.reference}` }}
          </p>
          <p
              v-if="dossiersReferences"
              class="text-gray-500 text-sm select-none"
          >
            {{ `${$t('global.dossiers')}: ${dossiersReferences}` }}
          </p>

          <BaseShowEditInvoiceStatusInput
              v-model:modelValue="supplierOrder.status"
              :invoice-doc-type="supplierOrder.doc_type"
              :edition-mode="true"
              @change="changeStatus"
          />
        </div>
        <div class="flex flex-row gap-x-3">
          <div @click.stop="">
            <TippyTooltip
                tag="div"
                href="javascript:;"
                :content="$t('button.add_item_to_supplier_order')"
            >
              <FontAwesomeIcon
                  :icon="['fal', 'circle-plus']"
                  class="text-theme-primary-500 cursor-pointer"
                  size="xl"
                  @click="handleAddItemToSupplierOrder"
              />
            </TippyTooltip>
          </div>
          <div>
            <FontAwesomeIcon
                :icon="['fal', 'pen-circle']"
                class="text-yellow-500 cursor-pointer"
                size="xl"
                @click.stop="handleEditSupplierOrder(supplierOrder)"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-between gap-3">
        <div class=" flex flex-col">
          <p v-if="!supplierOrder.status === Status.DRAFT" class="text-gray-500 text-xs text-gray-500 capitalize-first">{{ $t('attributes.emission_date') }}:
            {{ formattedEmissionDate }}</p>
          <p v-if="!supplierOrder.status === Status.DRAFT" class="text-gray-500 text-xs text-gray-500 capitalize-first">{{ $t('attributes.due_date') }}:
            {{ formattedDueDate }}</p>
          <p class="text-gray-500 text-xs text-gray-500 capitalize-first">{{ $t('attributes.delivery_date') }}:
            {{ formattedDeliveryDate }}</p>
        </div>
      </div>
      <div>
        <div v-if="supplierOrder.invoice_items?.length && supplierOrder.invoice_items?.length > 0" @click.stop="">
          <BaseDisclosure
              :title="$t('attributes.items')"
          >
            <div class="divide-y divide-gray-300">
              <SupplierOrderItemCard
                  v-for="(supplierOrderItem, index) in supplierOrder.invoice_items"
                  :key="index"
                  :item="supplierOrderItem"
              />
            </div>
          </BaseDisclosure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { Status } from "@/assets/enums";
import moment from "moment";
import SupplierOrderItemCard from "@ui/modal/supplierOrder/components/SupplierOrderItemCard";

export default {
  name: "SupplierOrderCard",
  components: { SupplierOrderItemCard},
  props: {
    supplierOrder: {
      type: Object,
      required: true
    },
    supplierId: {
      type: [String, Number],
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    dossierId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  emits: ['editSupplierOrder'],
  data() {
    return {
      Status: Status,
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      dossier: 'dossier/getDossier',
    }),
    dossiersReferences() {
      if (!this.supplierOrder?.dossiers) return null
      return [...new Set(this.supplierOrder?.dossiers?.reduce((old, curr) => {
        old.push(curr?.reference)
        return old
      }, []))].join(', ')
    },
    formattedDeliveryDate() {
      return moment(this.supplierOrder.supplier_order_property.delivery_date).format('DD/MM/YYYY')
    },
    formattedEmissionDate() {
      return moment(this.supplierOrder.emission_date).format('DD/MM/YYYY')
    },
    formattedDueDate() {
      return moment(this.supplierOrder.due_date).format('DD/MM/YYYY')
    },
    selected() {
      return this.modelValue === this.supplierOrder.id
    },
  },
  methods: {
    ...mapActions({
      processUpdateInvoiceItemAction: 'invoicing/updateInvoiceItem',
      processFetchDraftSupplierOrderBySupplierIdAction: 'invoicing/fetchDraftSupplierOrderBySupplierId',
      processChangeInvoiceStatusAction: 'invoicing/changeInvoiceStatus',
      processFetchItemsDossier: 'dossier/fetchItemsDossier'
    }),
    changeStatus(newStatus) {
      this.processChangeInvoiceStatusAction({
        invoice_id: this.supplierOrder.id,
        supplier_id: this.supplierId,
        form_data: {
          status: newStatus
        },
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.status_updated'), {className: 'toastify-content success'})
          if(this.dossierId){
            this.processFetchItemsDossier({dossier_id: this.dossierId})
          }
        }
      })
    },
    handleAddItemToSupplierOrder() {
      this.processUpdateInvoiceItemAction({
        invoice_id: this.supplierOrder.id,
        content: {item_dossier_id: this.item.id},
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.item_added_to_supplier_order'), {className: 'toastify-content success'})
          this.processFetchDraftSupplierOrderBySupplierIdAction({supplier_id: this.supplierId})
          this.processFetchItemsDossier({dossier_id: this.dossier.id})
        }
      })

    },
    handleEditSupplierOrder(supplier_order) {
      this.$emit('editSupplierOrder', supplier_order?.id)
    },
  }
}
</script>
