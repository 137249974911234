<template>
  <template v-if="editionMode">
    <div class="fm-form-input" :class="internalLabel ? 'mt-2' : ''">
      <label :for="name"
        >{{ internalLabel }}&nbsp;
        <span v-if="required" class="text-red-600">*</span>
      </label>
      <div class="flex flex-1 flex-row items-center rounded-md bg-gray-200">
        <div
          class="flex flex-shrink-0 items-center justify-center text-base"
          :style="{
            aspectRatio: '1/1'
          }"
        >
          <ContactPopoverComponent v-if="getContact" :contact="getContact" />
        </div>
        <input
          :id="name"
          v-model="cModelValue"
          :name="name"
          :placeholder="internalPlaceholder"
          :disabled="editionMode"
          :class="getContact ? '!px-0' : '!px-0 ml-2'"
        />
        <button
          type="button"
          class="flex h-8 w-10 flex-shrink-0 items-center justify-center"
          @click="isModalOpen = true"
        >
          <FontAwesomeIcon
            :icon="['fas', modelValue ? 'arrows-repeat' : 'arrow-pointer']"
          ></FontAwesomeIcon>
        </button>
      </div>
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </template>
  <template v-else>
    <BaseShowLabel :label="internalLabel" :model-value="cModelValue">
      <template v-if="getContact" #append>
        <ContactPopoverComponent :contact="getContact" />
      </template>
    </BaseShowLabel>
  </template>
  <SelectContactModal
    v-model="internalValue"
    v-model:open="isModalOpen"
    :role-id="roleId"
    :funeral-home="funeralHome"
    :role-name="roleName"
    :icon="icon"
    @close="handleCloseModal"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectContactModal from '@ui/modal/contact/SelectContactModal'
import ContactPopoverComponent from '@c/addf-package/components/contact/component/ContactPopoverComponent.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'ContactInput',
  components: {
    ErrorContainer,
    ContactPopoverComponent,
    SelectContactModal
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user-tie'
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultContactIdForPlaceHolder: {
      type: String,
      required: false,
      default: null
    },
    roleId: {
      type: [String, Number],
      required: false,
      default: null
    },
    funeralHome: {
      type: [String, Number],
      required: false,
      default: null
    },
    forceLabel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      contactList: 'contact/getContactList',
      roleContactList: 'contact/getRoleContactList'
    }),
    roleName() {
      return this.initialMetadata?.models?.list_items?.contact_roles?.find(
        (role) => role.id === this.roleId
      )?.name
    },
    internalLabel() {
      if (this.forceLabel) return this.label
      return this.roleId ? this.roleName : this.label
    },
    internalPlaceholder() {
      if (!this.defaultContactIdForPlaceHolder) return this.internalLabel
      if (this.modelValue) return null
      return this.contactList.find(
        (contact) => contact.id === this.defaultContactIdForPlaceHolder
      )?.full_name
    },
    cModelValue() {
      if (!this.contactList) return null
      if (!this.modelValue) return null
      let contact = this.getContact
      if (contact) {
        return contact?.full_name !== ' ' ? contact?.full_name : contact?.company_name
      }
      return '-'
    },
    getContact() {
      return (
        this.initialMetadata?.models?.contacts?.find(
          (contact) => contact.id === this.modelValue
        ) ?? null
      )
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchContactListAction: 'contact/fetchContactList'
    }),
    handleCloseModal() {
      this.isModalOpen = false
    }
  }
}
</script>
