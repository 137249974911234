import axios from 'axios'
import store from '../store'
import Bugsnag from '@bugsnag/js'
import {helper} from "@u/helper";

const ApiPort = helper.getDevPort()
const ApiUri = '/api/'

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:${ApiPort}${ApiUri}`
    : ApiUri

const apiClient = axios.create({
  baseURL
})

apiClient.interceptors.request.use(
  (config) => {
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('expiration') &&
      new Date() > new Date(localStorage.getItem('expiration'))
    ) {
      localStorage.removeItem('token')
      localStorage.removeItem('expiration')
      window.location.href = '/connect'
    }
    if (localStorage.getItem('token')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // helper.toastWarning(
          //   "vous n'avez pas les permissions requises, veuillez contacter votre manager."
          // )
          store.commit('auth/setErrors', error.response.data.errors)
          // return Promise.reject(error)
          window.location.href = '/connect'
        case 422:
          if (error.response.data.message) {
            helper.toastWarning(error.response.data.message)
          } else {
            helper.toastWarning('des erreurs sont présentes dans le formulaire')
          }
          store.commit('auth/setErrors', error.response.data.errors)
          return Promise.reject(error)
        case 404:
          helper.toastDanger('resource not found')
          Bugsnag.notify(error)
          break
        case 500:
          helper.toastDanger('Erreur serveur')
          store.commit('auth/setErrors', {500: []})
          return Promise.reject(error)
        default:
          // alert(error.response.messageerror)

          Bugsnag.notify(error)
          return Promise.reject(error)
      }
    } else {
      helper.toastDanger('erreur serveur')
      return Promise.reject(error)
    }
  }
)

export default apiClient
