<template>
  <BaseSlideOverModal
    v-model:is-modal-open="open"
    width="2xl"
    @close="close"
  >
    <template #cta>
      <div class="h-full flex gap-x-1">
        <template v-if="internalTab === 'list'">
          <BaseButton
            :title="$t('button.add_new_m', {var: $t('attributes.supplier')})"
            icon="plus"
            size="small"
            @click="handleCreateSupplierButton"
          />
        </template>
        <template v-else >
          <BaseButton
            :title="$t('button.back')"
            color="dark"
            icon="long-arrow-left"
            size="small"
            @click="resumeToList"
          />
          <BaseButton
            v-if="supplier"
            :title="formButtonTitle"
            icon="paper-plane"
            size="small"
            @click="SubmitForm"
          />
        </template>
      </div>
    </template>
    <template #body>
      <div class="scrollbar-custom flex h-full w-full flex-col justify-between overflow-scroll">
        <SelectSupplierList
          v-if="internalTab === 'list'"
          v-model="internalValue"
          @edit-supplier="handleEditSupplier"
        />
        <SelectSupplierForm
          v-else
          :supplier-id="selectedSupplierId"
        />
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectSupplierList from "@ui/modal/supplier/components/SelectSupplierList";
import SelectSupplierForm from "@ui/modal/supplier/components/SelectSupplierForm";
import BaseSlideOverModal from "@c/addf-package/components/modal/BaseSlideOverModal";

export default {
  name: "SelectSupplierModal",
  components: { BaseSlideOverModal, SelectSupplierForm, SelectSupplierList },
  props:{
    open: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: [Object, String, Number],
      required: false,
      default: null
    }
  },
  emits: ['close', 'update:modelValue', 'change'],
  data() {
    return {
      internalTab: 'list',
      selectedSupplierId: null,
    }
  },
  computed: {
    ...mapGetters({
      supplier: 'supplier/getSupplier'
    }),
    formButtonTitle(){
      if(!this.supplier) return this.$t('button.save')
      return this.supplier.id ? this.$t('button.update_m', {var: this.$t('attributes.supplier')}) : this.$t('button.save_new_m', {var: this.$t('attributes.supplier')})
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
        this.close()
      }
    }
  },
  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          this.processFetchSupplierListAction()
          this.internalTab = 'list'
          this.selectedSupplierId = null
        }
      }
    },
    immediate: true,
    deep: true
  },
  methods: {
    ...mapActions({
      processFetchSupplierListAction: 'supplier/fetchSupplierList',
      processStoreSupplierAction: 'supplier/storeSupplier',
      processUpdateSupplierAction: 'supplier/updateSupplier',
    }),
    handleCreateSupplierButton(){
      this.internalTab = 'form'
    },
    SubmitForm(){
      if(this.supplier.id){
        this.processUpdateSupplierAction({
          supplier: this.supplier,
          supplier_id: this.supplier.id,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.supplier_updated'), { className: 'toastify-content success' })
            this.selectedSupplierId = null
            this.processFetchSupplierListAction()
            this.internalTab = 'list'
          }
        })
      } else {
        this.processStoreSupplierAction({
          supplier: this.supplier,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.supplier_created'), { className: 'toastify-content success' })
            this.selectedSupplierId = null
            this.processFetchSupplierListAction()
            this.internalTab = 'list'
          }
        })
      }
    },
    close() {
      this.$emit('close')
    },
    handleEditSupplier(supplier_id){
      this.selectedSupplierId = supplier_id
      this.internalTab = 'form'
    },
    resumeToList(){
      this.selectedSupplierId = null
      this.internalTab = 'list'
    }
  },
}
</script>
