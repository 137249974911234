import apiClient from '@u/apiClient'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

import attachmentCategoryAction from './attachmentCategory/actions'

export default {
  ...attachmentCategoryAction,

  // region fetch
  fetchListItems({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('list-item')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storeListItem({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post('list-item', payload.list_item)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateListItem({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`list-item/${payload.id}`, payload.list_item)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  deleteListItemById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`list-item/${payload.list_item}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undodelete
  undoDeleteListItemById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`list-item/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
