<template>
  <div
    v-if="currentValue || editionMode"
    class="flex flex-row gap-2"
    :class="[className, $attrs.class]"
  >
    <div class="flex-1">
      <slot name="default" />
    </div>
    <div v-if="editionMode" class="flex shrink-0 flex-row items-center">
      <button
        :data-tint="currentValue ? 'green' : 'red'"
        class="mt-2 h-8 w-8 rounded border border-slate-400 bg-primary text-contrast-primary"
        @click="() => enable(!currentValue)"
      >
        <FontAwesomeIcon :icon="['fal', currentValue ? 'eye' : 'eye-slash']" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { set, get } from 'lodash-es'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'InputViewerSelector',
  components: { FontAwesomeIcon },
  props: {
    path: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      editionMode: 'metadata/getGlobalFormEditionMode',
      currentFormSettings: 'metadata/getGlobalFormCurrentFormSettings'
    }),
    currentValue() {
      return get(this.currentFormSettings, this.path) ?? true
    }
  },
  methods: {
    ...mapMutations({
      mutateFormConfig: 'metadata/updateFormConfigSettings'
    }),
    enable(value) {
      let newForm = set(this.currentFormSettings, this.path, value)
      this.mutateFormConfig(newForm)
    }
  }
}
</script>

<style scoped></style>
