<template>
  <div class="flex w-full flex-col">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div
          class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
              >
                {{ $t('attributes.reference') }}
              </th>
<!--              <th-->
<!--                scope="col"-->
<!--                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"-->
<!--              >-->
<!--                {{ $t('global.dossier_type') }}-->
<!--              </th>-->
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
              >
                {{ $t('attributes.deceased') }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
              >
                {{ $t('attributes.relationship') }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
              >
                {{ $t('attributes.role') }}
              </th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
            <tr
              v-for="dossier in dossiers"
              :key="dossier.id"
            >
              <td
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
              >
                {{ dossier.reference }}
              </td>
<!--              <td-->
<!--                scope="col"-->
<!--                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"-->
<!--              >-->
<!--                {{ $t('global.dossier_type') }}-->
<!--              </td>-->
              <td
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
              >
                {{ dossier?.concernedPerson?.full_name }}
              </td>
              <td
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
              >
<!--                {{ $t('attributes.relationship') }}-->
              </td>
              <td
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
              >
<!--                {{ $t('attributes.role') }}-->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowInvoiceableModalTableRow from './ShowInvoiceableModalTableRow.vue'

export default {
  name: 'ShowInvoiceableModalTable',
  components: {ShowInvoiceableModalTableRow},
  props: {
    invoiceable: {
      type: Object,
      required: true
    },
    dossiers: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>
