import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import original_state from './original_state'

export default {
  namespaced: true,
  state() {
    return {
      ...original_state,
      openModalVersionNote: false,
      global_form: {
        edition_mode: false,
        current_form_settings: null,
        funeral_home_id: null
      }
    }
  },
  mutations,
  actions,
  getters
}
