import getters from '@/store/modules/taskDossier/getters'
import mutations from '@/store/modules/taskDossier/mutations'
import actions from '@/store/modules/taskDossier/actions'

export default {
  namespaced: true,
  state() {
    return {
      taskDossierList: [],
      taskDossier: null
    }
  },
  getters,
  actions,
  mutations
}
