<template>
  <div class="">
    <Popover class="relative z-100">
      <PopoverButton as="div" class="cursor-pointer">
        <span class="select-none">{{ reaction }}</span>
      </PopoverButton>
      <PopoverPanel :class="[
        'w-48 absolute z-100 box shadow-lg p-3  z-100 right-1/3',
        // isOtherPersonComment ? 'left-32' : 'right-1/3',
        ]">
        <div class="flex flex-col divide-y">
          <div
            v-for="(commentator, index) in commentators"
            :key="index"
            :class="[
              'flex gap-x-3 align-baseline text-gray-600 cursor-default select-none items-center',
              index !== 0 ? 'pt-1' : ''
              ]"
          >
            <div class=" flex-shrink-0 mr-2">
              <AvatarComponent :user_id="commentator" size="6" />
            </div >
            <div >
              <div class="option__title font-semibold">
                {{ initialMetadata?.models?.users?.find(user => user.id === commentator)?.full_name }}
              </div >
            </div >
            <div v-if="commentators.length > 1">{{ reaction }}</div>
          </div >
        </div>
      </PopoverPanel>
    </Popover>
  </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { mapGetters } from "vuex";
import AvatarComponent from "@c/AvatarComponent";

export default {
  name: "ChatReactionList",
  components: { AvatarComponent, Popover, PopoverButton, PopoverPanel },
  props: {
    reaction: {
      type: String,
      required: true
    },
    commentators: {
      type: Array,
      required: true
    },
    isOtherPersonComment: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({ initialMetadata: 'metadata/getInitialMetadata' }),
  },
}
</script>
