export default {
  setCheckDataStatus(state, checkDataStatus) {
    state.checkDataStatus = checkDataStatus
  },
  setCheckDepositList(state, checkDepositList) {
    state.checkDepositList = checkDepositList
  },
  setCheckListByType(state, checkListByType) {
    state.checkListByType = checkListByType
  },
  setCheckDeposit(state, check) {
    state.checkDeposit = check
  },
  setCheckMetadata(state, checkMetadata) {
    state.checkMetadata = checkMetadata
  },
  alterCheckDeposit(state, payload) {
    state.checkDeposit[payload.attribute] = payload.value
  },
  setEditedCheckList(state, payload) {
    state.editedCheckList = payload
  }
}
