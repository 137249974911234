export default {
  getNotificationSettingsList(state) {
    return state.notificationList
  },
  getNotificationSetting(state) {
    return state.notification
  },
  getNotificationMetadata(state) {
    return state.metadata
  }
}
