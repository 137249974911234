const TheTaskIndexPage = () =>
  import('@/views/settings/management/tasks/index/TheTaskIndexPage')
const TheTaskFormPage = () =>
  import(
    '@/views/settings/management/tasks/form/tabs/taskInformation/TheTaskFormPage'
  )
const TheTaskFormsTabComponent = () =>
  import('@/views/settings/management/tasks/form/TheTaskFormsTabComponent')
const TheTaskActivityPage = () =>
  import(
    '@/views/settings/management/tasks/form/tabs/taskActivivities/TheTaskActivityPage'
  )
const TheTaskTranslationPage = () =>
  import(
    '@/views/settings/management/tasks/form/tabs/taskTranslation/TheTaskTranslationPage'
  )
const TheTaskAutomationPage = () =>
  import(
    '@/views/settings/management/tasks/form/tabs/taskAutomation/TheTaskAutomationPage'
    )
const TheTaskPersonalPage = () =>
  import(
    '@/views/settings/management/tasks/form/tabs/taskPersonal/TheTaskPersonalPage'
  )

export const settingsTaskRoutes = [
  {
    path: 'tasks',
    children: [
      {
        path: '',
        name: 'settings_list_tasks_route',
        component: TheTaskIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_tasks_info_route',
        props: true,
        component: TheTaskFormsTabComponent,
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheTaskFormPage,
            name: 'settings_edit_tasks_route'
          },
          {
            path: 'task-translation',
            component: TheTaskTranslationPage,
            props: true,
            name: 'settings_edit_tasks_translation_route'
          },
          {
            path: 'task-personal',
            component: TheTaskPersonalPage,
            props: true,
            name: 'settings_edit_tasks_personal_route'
          },
          {
            path: 'automation',
            component: TheTaskAutomationPage,
            props: true,
            name: 'settings_edit_tasks_automation_route'
          },
          {
            path: 'activities',
            component: TheTaskActivityPage,
            props: true,
            name: 'settings_edit_tasks_activity_route'
          }
        ]
      },
      {
        path: 'new',
        name: 'settings_create_tasks_info_route',
        component: TheTaskFormsTabComponent,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheTaskFormPage,
            name: 'settings_create_tasks_route'
          },
          {
            path: 'task-translation',
            component: TheTaskTranslationPage,
            name: 'settings_create_tasks_translation_route'
          },
          {
            path: 'task-personal',
            component: TheTaskPersonalPage,
            name: 'settings_create_tasks_personal_route'
          },
          {
            path: 'activities',
            component: TheTaskActivityPage,
            name: 'settings_create_tasks_activity_route'
          }
        ]
      }
    ]
  }
]
