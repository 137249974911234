export default {
  setCompanyList(state, companyList) {
    state.companiesList = companyList
  },
  setCompany(state, company) {
    state.company = company
  },
  setMetadata(state, metadata) {
    state.metadata = metadata
  },
  setCompanyActivity(state, activity) {
    state.companyActivity = activity
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setCompanyAddress(state, address) {
    state.company.address = address
    state.company.address_id = address.id
  },
  alterCompanyForAddingBankAccount(state, bank_account) {
    state.company.bank_account_id = bank_account.id
  },
  setCompanyLocations(state, companyLocations) {
    state.companyLocations = companyLocations
  }
}
