<template class="overflow-y-scroll">
  <div
    v-if="contact && initialMetadata"
    :class="[
      wide ? 'grid grid-cols-1 gap-x-3 md:grid-cols-2' : 'grid grid-cols-1'
    ]"
  >
    <div class="grid grid-cols-1 gap-2">
      <!--region company-->
      <div>
        <div>
          <label class="mr-2 inline-block text-gray-500">
            <span class="mt-1 inline-block capitalize-first">{{
              $t('attributes.company')
            }}</span>
          </label>
          <input
            v-model="contact.is_company"
            name="is_company"
            type="checkbox"
            class="form-check-input"
          />
        </div>
      </div>
      <div>
        <BaseShowEditInput
          v-if="contact.is_company"
          v-model="contact.company_name"
          :label="$t('attributes.denomination')"
          :placeholder="$t('attributes.denomination')"
          name="company_name"
          :edition-mode="true"
        />
      </div>
      <div>
        <BaseShowEditInput
          v-if="contact.is_company"
          v-model="contact.tva"
          :label="$t('attributes.TVA')"
          :placeholder="$t('attributes.TVA')"
          name="tva"
          :edition-mode="true"
        />
      </div>
      <!--endregion-->

      <!--region agences -->
      <div class="mt-2">
        <SelectFuneralHomesForContactForm
          :contact="localContact"
          @add-favorite-on-funeral-home="handleAddFavoriteOnFuneralHome"
          @select-funeral-home-for-contact="handleSelectFuneralHomeForContact"
        />
      </div>
      <!--endregion-->

      <!--region informations-->
      <div>
        <SelectCivility
          v-if="!contact.is_company"
          v-model="localContact.civility"
          :edition-mode="true"
          :errors="errors?.civility"
        />
      </div>
      <div>
        <template v-if="!isWikiContact">
          <BaseShowEditTaggableSelectSimple
            v-if="initialMetadata.models.list_items.contact_roles"
            v-model="localContact.role"
            v-model:options="initialMetadata.models.list_items.contact_roles"
            :label="$t('attributes.role')"
            name="role"
            :edition-mode="contactRoleEditionModeCondition"
            :errors="errors.role"
            @new-tag-selected="handleContactRoleTag"
          />
        </template>
        <template v-else>
          <BaseShowEditInput
            v-model="localContact.fonction"
            :label="$t('attributes.fonction')"
            :placeholder="$t('attributes.fonction')"
            :edition-mode="false"
            name="fonction"
            :errors="errors.fonction"
          />
        </template>
      </div>
      <div>
        <BaseShowEditInput
          v-if="!contact.is_company"
          v-model="localContact.first_name"
          :label="$t('attributes.first_name')"
          :placeholder="$t('attributes.first_name')"
          :edition-mode="true"
          name="first_name"
          :errors="errors.first_name"
          :required="true"
        />
      </div>
      <div>
        <BaseShowEditInput
          v-if="!contact.is_company"
          v-model="localContact.last_name"
          :label="$t('attributes.last_name')"
          :placeholder="$t('attributes.last_name')"
          :edition-mode="true"
          name="last_name"
          :errors="errors.last_name"
          :required="true"
        />
      </div>
      <!--endregion-->

      <!--region adresse-->
      <BaseShowEditAddress
        v-model:country="localContact.country"
        v-model:city-id="localContact.city_id"
        v-model:current-city-object="localContact.city_object"
        v-model:zip="localContact.zip"
        v-model:city="localContact.city"
        v-model:extra-street="localContact.extra_street"
        v-model:street="localContact.street"
        :edition-mode="true"
        :errors="errors"
      />
      <!--endregion-->

      <!--region contact-->
      <div>
        <SelectLanguages
          v-model="localContact.language"
          :edition-mode="true"
          :errors="errors?.language"
        />
      </div>
      <div>
        <BaseShowEditInput
          v-model="localContact.email"
          :label="$t('attributes.email')"
          :placeholder="$t('attributes.email')"
          :edition-mode="true"
          :errors="errors.email"
          name="email"
        />
      </div>
      <div>
        <BaseShowEditPhoneInput
          v-model:countryValue="localContact.phone_country"
          v-model:numberValue="localContact.phone_field"
          :label="$t('attributes.phone')"
          :edition-mode="true"
          name="phone"
          :metadata="initialMetadata.enums"
          :errors="errors"
        />
      </div>
      <div>
        <BaseShowEditPhoneInput
          v-model:countryValue="localContact.mobile_country"
          v-model:numberValue="localContact.mobile_field"
          :label="$t('attributes.mobile')"
          :edition-mode="true"
          name="mobile"
          :metadata="initialMetadata.enums"
          :errors="errors"
        />
      </div>
      <div>
        <BaseShowEditPhoneInput
          v-if="isWikiContact"
          v-model:countryValue="localContact.fax_country"
          v-model:numberValue="localContact.fax_field"
          :label="$t('attributes.fax')"
          :edition-mode="true"
          name="fax"
          :metadata="initialMetadata.enums"
          :errors="errors"
        />
      </div>
      <!--endregion-->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Country } from '@/assets/enums'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'
import SelectCivility from '@c/InitialStateFormComponent/Enums/SelectCivility.vue'
import SelectFuneralHomesForContactForm from '@ui/modal/contact/components/SelectFuneralHomesForContactForm.vue'
import BaseShowEditAddress from '@c/BaseShowEditAddress.vue'

export default {
  name: 'ContactForm',
  components: {
    BaseShowEditAddress,
    SelectFuneralHomesForContactForm,
    SelectCivility,
    SelectLanguages
  },
  props: {
    contact: {
      type: Object,
      required: true
    },
    showContact: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    wide: {
      type: Boolean,
      required: false,
      default: false
    },
    roleIsDisabled: {
      type: Boolean,
      required: false,
      default: null
    },
    isWikiContact: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:contact'],
  data() {
    return {
      wiki_city: null,
      Country: Country
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    cCity() {
      return {
        id: this.contact?.city_id,
        full_name: this.contact?.city
      }
    },
    cityErrors() {
      let tmp = []
      if (this.errors?.city) tmp.push(...this.errors.city)
      if (this.errors?.zip) tmp.push(...this.errors.zip)
      return tmp
    },
    contactRoleEditionModeCondition() {
      return !!!this.roleIsDisabled
      // return ['dossier_form_edit_route', 'invoice_wizard_route'].includes(this.$route.name)
    },
    localContact: {
      get() {
        return this.contact
      },
      set(value) {
        this.$emit('update:contact', value)
      }
    }
  },
  watch: {
    localContact: {
      handler(newValue, oldValue) {
        this.$emit('update:contact', newValue)
      },
      deep: true
    }
  },
  methods: {
    getInputValue() {
      this.contact.city = this.wiki_city.full_name
      this.contact.zip = this.wiki_city.zip
      this.contact.city_id = this.wiki_city.id
    },
    handleContactRoleTag(tag) {
      this.contact.role_name = tag.name
    },
    handleAddFavoriteOnFuneralHome(value) {
      if (Object.keys(this.contact.funeral_homes_id).includes(value.id)) {
        this.contact.funeral_homes_id[value.id].favorite =
          !this.contact.funeral_homes_id[value.id]?.favorite
      }
    },
    handleSelectFuneralHomeForContact(value) {
      if (this.contact.funeral_homes_id[value.id]) {
        delete this.contact.funeral_homes_id[value.id]
      } else {
        this.contact.funeral_homes_id[value.id] = {
          favorite: false
        }
      }
    }
  }
}
</script>
