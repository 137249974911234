const TheItemCategorySettingPage = () =>
  import(
    '@/views/settings/invoicing/itemCategory/index/TheItemCategoryIndexPage.vue'
  )
const TheItemCategoryFormTabComponent = () =>
  import(
    '@/views/settings/invoicing/itemCategory/form/TheItemCategoryFormTabComponent.vue'
  )
const TheItemCategoryInformationPage = () =>
  import(
    '@/views/settings/invoicing/itemCategory/form/tabs/itemCategoryInformation/TheItemCategoryInformationPage.vue'
  )
const TheItemCategoryTranslationPage = () =>
  import(
    '@/views/settings/invoicing/itemCategory/form/tabs/itemCategoryTranslation/TheItemCategoryTranslationPage.vue'
  )

export const settingsItemCategoryRoutes = [
  {
    path: 'item-category',
    children: [
      {
        path: '',
        name: 'settings_item_categories_route',
        component: TheItemCategorySettingPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_item_category_profile_route',
        props: true,
        component: TheItemCategoryFormTabComponent,
        children: [
          {
            path: '',
            component: TheItemCategoryInformationPage,
            name: 'settings_edit_item_category_route'
          },
          {
            path: 'translation',
            component: TheItemCategoryTranslationPage,
            props: true,
            name: 'settings_edit_item_category_translation_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: false },
            { title: 'content_item_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_item_categories_route',
        component: TheItemCategoryFormTabComponent,
        children: [
          {
            path: '',
            component: TheItemCategoryInformationPage,
            name: 'settings_create_item_category_route'
          },
          {
            path: 'translation',
            component: TheItemCategoryTranslationPage,
            props: true,
            name: 'settings_create_item_category_translation_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_item_new_title', active: true }
          ]
        }
      }
    ]
  }
]
