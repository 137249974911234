import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      city: 'wiki/getCity',
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    isInCreateMode() {
      return this.$route.meta?.type === 'create'
    },
    isInEditMode() {
      return this.$route.meta?.type === 'edit'
    },
    routeType() {
      return this.$route.meta?.type
    },
    mainButtonTitle() {
      if (this.$route.meta?.type === 'edit') {
        return this.$t('button.update_wiki_city')
      }
      if (this.$route.meta.type === 'create') {
        return this.$t('button.create_wiki_city')
      }
      return ''
    },
    mainButtonIcon() {
      return 'paper-plane'
    }
  },
  methods: {
    ...mapActions({
      processFetchCityByIdAction: 'wiki/fetchCityById',
      processFetchNewCityAction: 'wiki/fetchNewCity',
      processUpdateCityAction: 'wiki/updateCity',
      processStoreCityAction: 'wiki/storeCity'
    })
  }
}
