export default {
  getContactList(state) {
    return state.contactList
  },
  getRoleContactList(state) {
    return state.roleContactList
  },
  getContact(state) {
    return state.contact
  },
  getContactMetadata(state) {
    return state.metadata
  }
}
