<template>
  <template v-if="editionMode">
    <div
      class="relative rounded-md border border-slate-300 shadow-sm"
      :class="label ? 'mt-2' : ''"
    >
      <label
        :for="name"
        class="absolute -top-2 left-2 z-10 -mt-px inline-block rounded-md bg-white/75 px-1 text-xs font-medium text-gray-900 capitalize-first"
        >{{ label }}&nbsp;
        <span v-if="required" class="text-red-600">*</span>
      </label>
      <div class="flex flex-row items-center rounded-md bg-gray-200">
        <div
          class="flex flex-shrink-0 items-center justify-center px-3 text-base"
        >
          <FontAwesomeIcon
            :icon="['fal', icon]"
            class="text-gray-600"
            fixed-width
          />
        </div>
        <input
          :id="name"
          v-model="cModelValue"
          :name="name"
          :placeholder="internalPlaceholder"
          class="block flex-1 border-0 bg-gray-100 p-0 px-3 py-2 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
          :disabled="editionMode"
        />
        <button
          type="button"
          class="flex h-8 w-8 flex-shrink-0 items-center justify-center"
          @click="isModalOpen = true"
        >
          <FontAwesomeIcon
            :icon="['fas', personId ? 'arrows-repeat' : 'arrow-pointer']"
          />
        </button>
        <button
          v-if="!required"
          type="button"
          class="flex h-8 w-8 flex-shrink-0 items-center justify-center border-l hover:text-red-500"
          @click="reset"
        >
          <FontAwesomeIcon :icon="['fas', 'times']" />
        </button>
      </div>
    </div>
    <div v-if="errors" class="form-help text-red-600">
      <div v-for="(error, index) in errors" :key="index">
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </div>
  </template>
  <template v-else>
    <BaseShowLabel :label="label" :model-value="cModelValue" />
  </template>
  <SelectPersonModal
    v-model:person="localPerson"
    v-model:person-id="localPersonId"
    v-model:open="isModalOpen"
    :suggestedPeople="suggestedPeople"
  />
</template>

<script>
import SelectPersonModal from '@ui/modal/Person/SelectPersonModal'
import { mapActions, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'

export default {
  name: 'PersonInput',
  components: { BaseShowLabel, FontAwesomeIcon, SelectPersonModal },
  props: {
    personId: {
      type: String,
      required: false,
      default: null
    },
    person: {
      type: Object,
      required: false,
      default: null
    },
    suggestedPeople: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user'
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:person-id', 'update:person'],
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      personList: 'dossier/getPersonList'
    }),
    internalPlaceholder() {
      return this.localPerson?.full_name ?? null
    },
    cModelValue() {
      return this.localPerson?.full_name ?? null
    },
    localPerson: {
      get() {
        return this.person
      },
      set(value) {
        this.$emit('update:person', value)
      }
    },
    localPersonId: {
      get() {
        return this.personId
      },
      set(value) {
        this.$emit('update:person-id', value)
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchPersonListAction: 'dossier/fetchPersonList'
    }),
    handleCloseModal() {
      this.isModalOpen = false
    },
    reset() {
      this.localPersonId = null
      this.localPerson = null
    }
  }
}
</script>
