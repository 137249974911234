import getters from '@/store/modules/address/getters'
import mutations from '@/store/modules/address/mutations'
import actions from '@/store/modules/address/actions'

export default {
  namespaced: true,
  state() {
    return {
      addressList: [],
      address: {},
      funeralHomeAddress: {},
      metadata: []
    }
  },
  getters,
  actions,
  mutations
}
