<template>
  <BaseCenteredModal
    v-model:is-modal-open="open"
    :translucent="true"
    @close="$emit('close')"
  >
    <template #body>
      <img :alt="alt" :src="src" />
    </template>
  </BaseCenteredModal>
</template>

<script>
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal'
export default {
  name: 'ImageModal',
  components: { BaseCenteredModal },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  },
  emits: ['close']
}
</script>
