<template>
  <div :key="properties">
    <div>
      <BaseEditLabel v-if="label" :label="label" :required="required" />
      <div
        class="flex rounded-md shadow-sm"
        @click="$emit('change')"
      >
        <div class="relative border border-gray-200 rounded-l-md flex items-stretch flex-grow focus-within:z-10">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none gap-y-3">
            <font-awesome-icon
              :icon="['fal', icon]"
              size="lg"
              class="mr-2 text-gray-500"
            />
          </div >
          <input
            :id="name"
            v-model="displayedValue"
            :name="name"
            :placeholder="label"
            class="h-11 focus:ring-indigo-500 focus:border-blue-300 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300 "
            :disabled="editionMode"
          />
        </div >
        <button
          type="button"
          class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50
            hover:bg-gray-100
            focus:outline-none focus:border-blue-300"
        >
          <span >{{ $t('global.select') }}</span >
        </button >
      </div >
    </div >
    <div v-if="errors" class="form-help text-red-600">
      <div v-for='(error,index) in errors' :key='index'>
        {{$t(error, { 'attribute': $t('attributes.' + name) }) }}
      </div >
    </div >
  </div>
</template>

<script>
export default {
  name: "SupplierOrderDeliveryLocationInput",
  props: {
    properties: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: 'location-dot'
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change'],
  computed: {
    displayedValue(){
      if (this.properties.delivery_place_type !== 'OTHER') return this.properties?.delivery_model?.name
      return this.properties.delivery_place_other
    }
  }
}
</script>
