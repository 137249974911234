import apiClient from '@u/apiClient'

// const base_errors = { deceased: {}, events: [] }
const base_errors = {}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

import taskDossierCommentsAction from './taskDossier/actions'

export default {
    ...taskDossierCommentsAction,
    async addReactionToTaskDossier({commit}, payload) {
        await apiClient
            .put(`comment/react/${payload.comment_id}`, {reaction: payload.reaction})
            .then(async response => {
                // console.log('addReactionToTaskDossier', response)
                if (response.status === 200) {
                    processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                await console.error('addReactionToTaskDossier', reason)
            })
    },
    async deleteComment({commit}, payload) {
        await apiClient
            .delete(`comment/${payload.comment_id}`)
            .then(async response => {
                // console.log('deleteComment', response)
                if (response.status === 200) {
                    processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                await console.error('deleteComment', reason)
            })
    },
    async replyToAComment({commit}, payload) {
        await apiClient
            .put(`comment/comment/${payload.comment_id}`, {text: payload.text})
            .then(async response => {
                if (response.status === 200) {
                    processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                await console.error('', reason)
            })
    }
}
