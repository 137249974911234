import Country from './Country'

const PhoneCountry = Object.freeze([
  {
    country: Country.BELGIUM,
    flag: '../../images/flags/be.svg',
    prefix: '+32'
  },
  {
    country: Country.FRANCE,
    flag: '../../images/flags/fr.svg',
    prefix: '+33'
  },
  {
    country: Country.GERMANY,
    flag: '../../images/flags/de.svg',
    prefix: '+49'
  },
  {
    country: Country.ITALY,
    flag: '../../images/flags/it.svg',
    prefix: '+'
  },
  {
    country: Country.LUXEMBOURG,
    flag: '../../images/flags/lu.svg',
    prefix: '+'
  },
  {
    country: Country.NETHERLANDS,
    flag: '../../images/flags/nl.svg',
    prefix: '+'
  },
  {
    country: Country.PORTUGAL,
    flag: '../../images/flags/pt.svg',
    prefix: '+'
  },
  {
    country: Country.SPAIN,
    flag: '../../images/flags/es.svg',
    prefix: '+'
  },
  {
    country: Country.SWITZERLAND,
    flag: '../../images/flags/ch.svg',
    prefix: '+'
  },
  {
    country: Country.UNITED_KINGDOM,
    flag: '../../images/flags/uk.svg',
    prefix: '+'
  },
  {
    country: Country.AFGHANISTAN,
    flag: '../../images/flags/af.svg',
    prefix: '+93'
  },
  {
    country: Country.ALBANIA,
    flag: '../../images/flags/al.svg',
    prefix: '+355'
  },
  {
    country: Country.ALGERIA,
    flag: '../../images/flags/dz.svg',
    prefix: '+213'
  },
  {
    country: Country.AMERICAN_SAMOA,
    flag: '../../images/flags/as.svg',
    prefix: '+1'
  },
  {
    country: Country.ANDORRA,
    flag: '../../images/flags/ad.svg',
    prefix: '+376'
  },
  {
    country: Country.ANGOLA,
    flag: '../../images/flags/ao.svg',
    prefix: '+244'
  },
  {
    country: Country.ANGUILLA,
    flag: '../../images/flags/ai.svg',
    prefix: '+1'
  },
  {
    country: Country.ANTARCTICA,
    flag: '../../images/flags/aq.svg',
    prefix: '+'
  },
  {
    country: Country.ANTIGUA_AND_BARBUDA,
    flag: '../../images/flags/ag.svg',
    prefix: '+1'
  },
  {
    country: Country.ARGENTINA,
    flag: '../../images/flags/ar.svg',
    prefix: '+54'
  },
  {
    country: Country.ARMENIA,
    flag: '../../images/flags/am.svg',
    prefix: '+374'
  },
  {
    country: Country.ARUBA,
    flag: '../../images/flags/aw.svg',
    prefix: '+297'
  },
  {
    country: Country.AUSTRALIA,
    flag: '../../images/flags/au.svg',
    prefix: '+61'
  },
  {
    country: Country.AUSTRIA,
    flag: '../../images/flags/at.svg',
    prefix: '+43'
  },
  {
    country: Country.AZERBAIJAN,
    flag: '../../images/flags/az.svg',
    prefix: '+994'
  },
  {
    country: Country.BAHAMAS,
    flag: '../../images/flags/bs.svg',
    prefix: '+1'
  },
  {
    country: Country.BAHRAIN,
    flag: '../../images/flags/bh.svg',
    prefix: '+973'
  },
  {
    country: Country.BANGLADESH,
    flag: '../../images/flags/bd.svg',
    prefix: '+880'
  },
  {
    country: Country.BARBADOS,
    flag: '../../images/flags/bb.svg',
    prefix: '+1'
  },
  {
    country: Country.BELARUS,
    flag: '../../images/flags/by.svg',
    prefix: '+375'
  },
  {
    country: Country.BELIZE,
    flag: '../../images/flags/bz.svg',
    prefix: '+501'
  },
  {
    country: Country.BENIN,
    flag: '../../images/flags/bj.svg',
    prefix: '+229'
  },
  {
    country: Country.BERMUDA,
    flag: '../../images/flags/bm.svg',
    prefix: '+1'
  },
  {
    country: Country.BHUTAN,
    flag: '../../images/flags/bt.svg',
    prefix: '+975'
  },
  {
    country: Country.BOLIVIA,
    flag: '../../images/flags/bo.svg',
    prefix: '+591'
  },
  {
    country: Country.BOSNIA_AND_HERZEGOVINA,
    flag: '../../images/flags/ba.svg',
    prefix: '+'
  },
  {
    country: Country.BOTSWANA,
    flag: '../../images/flags/bw.svg',
    prefix: '+387'
  },
  {
    country: Country.BOUVET_ISLAND,
    flag: '../../images/flags/bv.svg',
    prefix: '+'
  },
  {
    country: Country.BRAZIL,
    flag: '../../images/flags/br.svg',
    prefix: '+55'
  },
  {
    country: Country.BRITISH_INDIAN_OCEAN_TERRITORY,
    flag: '../../images/flags/io.svg',
    prefix: '+246'
  },
  {
    country: Country.BRUNEI_DARUSSALAM,
    flag: '../../images/flags/bn.svg',
    prefix: '+673'
  },
  {
    country: Country.BULGARIA,
    flag: '../../images/flags/bg.svg',
    prefix: '+359'
  },
  {
    country: Country.BURKINA_FASO,
    flag: '../../images/flags/bf.svg',
    prefix: '+226'
  },
  {
    country: Country.BURUNDI,
    flag: '../../images/flags/bi.svg',
    prefix: '+257'
  },
  {
    country: Country.CAMBODIA,
    flag: '../../images/flags/kh.svg',
    prefix: '+855'
  },
  {
    country: Country.CAMEROON,
    flag: '../../images/flags/cm.svg',
    prefix: '+237'
  },
  {
    country: Country.CANADA,
    flag: '../../images/flags/ca.svg',
    prefix: '+1'
  },
  {
    country: Country.CAPE_VERDE,
    flag: '../../images/flags/cv.svg',
    prefix: '+238'
  },
  {
    country: Country.CAYMAN_ISLANDS,
    flag: '../../images/flags/ky.svg',
    prefix: '+1'
  },
  {
    country: Country.CENTRAL_AFRICAN_REPUBLIC,
    flag: '../../images/flags/cf.svg',
    prefix: '+'
  },
  {
    country: Country.CHAD,
    flag: '../../images/flags/td.svg',
    prefix: '+236'
  },
  {
    country: Country.CHILE,
    flag: '../../images/flags/cl.svg',
    prefix: '+56'
  },
  {
    country: Country.CHINA,
    flag: '../../images/flags/cn.svg',
    prefix: '+86'
  },
  {
    country: Country.CHRISTMAS_ISLAND,
    flag: '../../images/flags/cx.svg',
    prefix: '+61'
  },
  {
    country: Country.COCOS_KEELING_ISLANDS,
    flag: '../../images/flags/cc.svg',
    prefix: '+61'
  },
  {
    country: Country.COLOMBIA,
    flag: '../../images/flags/co.svg',
    prefix: '+57'
  },
  {
    country: Country.COMOROS,
    flag: '../../images/flags/km.svg',
    prefix: '+269'
  },
  {
    country: Country.CONGO,
    flag: '../../images/flags/cg.svg',
    prefix: '+242'
  },
  {
    country: Country.CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE,
    flag: '../../images/flags/cd.svg',
    prefix: '+'
  },
  {
    country: Country.COOK_ISLANDS,
    flag: '../../images/flags/ck.svg',
    prefix: '+'
  },
  {
    country: Country.COSTA_RICA,
    flag: '../../images/flags/cr.svg',
    prefix: '+'
  },
  {
    country: Country.COTE_D_IVOIRE,
    flag: '../../images/flags/ci.svg',
    prefix: '+'
  },
  {
    country: Country.CROATIA,
    flag: '../../images/flags/hr.svg',
    prefix: '+'
  },
  {
    country: Country.CUBA,
    flag: '../../images/flags/cu.svg',
    prefix: '+'
  },
  {
    country: Country.CYPRUS,
    flag: '../../images/flags/cy.svg',
    prefix: '+'
  },
  {
    country: Country.CZECH_REPUBLIC,
    flag: '../../images/flags/cz.svg',
    prefix: '+'
  },
  {
    country: Country.DENMARK,
    flag: '../../images/flags/dk.svg',
    prefix: '+'
  },
  {
    country: Country.DJIBOUTI,
    flag: '../../images/flags/dj.svg',
    prefix: '+'
  },
  {
    country: Country.DOMINICA,
    flag: '../../images/flags/dm.svg',
    prefix: '+'
  },
  {
    country: Country.DOMINICAN_REPUBLIC,
    flag: '../../images/flags/do.svg',
    prefix: '+'
  },
  {
    country: Country.ECUADOR,
    flag: '../../images/flags/ec.svg',
    prefix: '+'
  },
  {
    country: Country.EGYPT,
    flag: '../../images/flags/eg.svg',
    prefix: '+'
  },
  {
    country: Country.EL_SALVADOR,
    flag: '../../images/flags/sv.svg',
    prefix: '+'
  },
  {
    country: Country.EQUATORIAL_GUINEA,
    flag: '../../images/flags/gq.svg',
    prefix: '+'
  },
  {
    country: Country.ERITREA,
    flag: '../../images/flags/er.svg',
    prefix: '+'
  },
  {
    country: Country.ESTONIA,
    flag: '../../images/flags/ee.svg',
    prefix: '+'
  },
  {
    country: Country.ETHIOPIA,
    flag: '../../images/flags/et.svg',
    prefix: '+'
  },
  {
    country: Country.FALKLAND_ISLANDS_MALVINAS,
    flag: '../../images/flags/fk.svg',
    prefix: '+'
  },
  {
    country: Country.FAROE_ISLANDS,
    flag: '../../images/flags/fo.svg',
    prefix: '+'
  },
  {
    country: Country.FIJI,
    flag: '../../images/flags/fj.svg',
    prefix: '+'
  },
  {
    country: Country.FINLAND,
    flag: '../../images/flags/fi.svg',
    prefix: '+'
  },
  {
    country: Country.FRENCH_GUIANA,
    flag: '../../images/flags/gf.svg',
    prefix: '+'
  },
  {
    country: Country.FRENCH_POLYNESIA,
    flag: '../../images/flags/pf.svg',
    prefix: '+'
  },
  {
    country: Country.FRENCH_SOUTHERN_TERRITORIES,
    flag: '../../images/flags/tf.svg',
    prefix: '+'
  },
  {
    country: Country.GABON,
    flag: '../../images/flags/ga.svg',
    prefix: '+'
  },
  {
    country: Country.GAMBIA,
    flag: '../../images/flags/gm.svg',
    prefix: '+'
  },
  {
    country: Country.GEORGIA,
    flag: '../../images/flags/ge.svg',
    prefix: '+'
  },
  {
    country: Country.GHANA,
    flag: '../../images/flags/gh.svg',
    prefix: '+'
  },
  {
    country: Country.GIBRALTAR,
    flag: '../../images/flags/gi.svg',
    prefix: '+'
  },
  {
    country: Country.GREECE,
    flag: '../../images/flags/gr.svg',
    prefix: '+'
  },
  {
    country: Country.GREENLAND,
    flag: '../../images/flags/gl.svg',
    prefix: '+'
  },
  {
    country: Country.GRENADA,
    flag: '../../images/flags/gd.svg',
    prefix: '+'
  },
  {
    country: Country.GUADELOUPE,
    flag: '../../images/flags/gp.svg',
    prefix: '+'
  },
  {
    country: Country.GUAM,
    flag: '../../images/flags/gu.svg',
    prefix: '+'
  },
  {
    country: Country.GUATEMALA,
    flag: '../../images/flags/gt.svg',
    prefix: '+'
  },
  {
    country: Country.GUINEA,
    flag: '../../images/flags/gn.svg',
    prefix: '+'
  },
  {
    country: Country.GUINEA_BISSAU,
    flag: '../../images/flags/gw.svg',
    prefix: '+'
  },
  {
    country: Country.GUYANA,
    flag: '../../images/flags/gy.svg',
    prefix: '+'
  },
  {
    country: Country.HAITI,
    flag: '../../images/flags/ht.svg',
    prefix: '+'
  },
  {
    country: Country.HEARD_ISLAND_AND_MCDONALD_ISLANDS,
    flag: '../../images/flags/hm.svg',
    prefix: '+'
  },
  {
    country: Country.VATICAN_CITY_STATE,
    flag: '../../images/flags/va.svg',
    prefix: '+'
  },
  {
    country: Country.HONDURAS,
    flag: '../../images/flags/hn.svg',
    prefix: '+'
  },
  {
    country: Country.HONG_KONG,
    flag: '../../images/flags/hk.svg',
    prefix: '+'
  },
  {
    country: Country.HUNGARY,
    flag: '../../images/flags/hu.svg',
    prefix: '+'
  },
  {
    country: Country.ICELAND,
    flag: '../../images/flags/is.svg',
    prefix: '+'
  },
  {
    country: Country.INDIA,
    flag: '../../images/flags/in.svg',
    prefix: '+'
  },
  {
    country: Country.INDONESIA,
    flag: '../../images/flags/id.svg',
    prefix: '+'
  },
  {
    country: Country.IRAN_ISLAMIC_REPUBLIC_OF,
    flag: '../../images/flags/ir.svg',
    prefix: '+'
  },
  {
    country: Country.IRAQ,
    flag: '../../images/flags/iq.svg',
    prefix: '+'
  },
  {
    country: Country.IRELAND,
    flag: '../../images/flags/ie.svg',
    prefix: '+'
  },
  {
    country: Country.ISRAEL,
    flag: '../../images/flags/il.svg',
    prefix: '+'
  },
  {
    country: Country.JAMAICA,
    flag: '../../images/flags/jm.svg',
    prefix: '+'
  },
  {
    country: Country.JAPAN,
    flag: '../../images/flags/jp.svg',
    prefix: '+'
  },
  {
    country: Country.JORDAN,
    flag: '../../images/flags/jo.svg',
    prefix: '+'
  },
  {
    country: Country.KAZAKHSTAN,
    flag: '../../images/flags/kz.svg',
    prefix: '+'
  },
  {
    country: Country.KENYA,
    flag: '../../images/flags/ke.svg',
    prefix: '+'
  },
  {
    country: Country.KIRIBATI,
    flag: '../../images/flags/ki.svg',
    prefix: '+'
  },
  {
    country: Country.KOREA_DEMOCRATIC_PEOPLE_S_REPUBLIC_OF,
    flag: '../../images/flags/kp.svg',
    prefix: '+'
  },
  {
    country: Country.KOREA_REPUBLIC_OF,
    flag: '../../images/flags/kr.svg',
    prefix: '+'
  },
  {
    country: Country.KUWAIT,
    flag: '../../images/flags/kw.svg',
    prefix: '+'
  },
  {
    country: Country.KYRGYZSTAN,
    flag: '../../images/flags/kg.svg',
    prefix: '+'
  },
  {
    country: Country.LAO_PEOPLE_DEMOCRATIC_REPUBLIC,
    flag: '../../images/flags/la.svg',
    prefix: '+'
  },
  {
    country: Country.LATVIA,
    flag: '../../images/flags/lv.svg',
    prefix: '+'
  },
  {
    country: Country.LEBANON,
    flag: '../../images/flags/lb.svg',
    prefix: '+'
  },
  {
    country: Country.LESOTHO,
    flag: '../../images/flags/ls.svg',
    prefix: '+'
  },
  {
    country: Country.LIBERIA,
    flag: '../../images/flags/lr.svg',
    prefix: '+'
  },
  {
    country: Country.LIBYAN_ARAB_JAMAHIRIYA,
    flag: '../../images/flags/ly.svg',
    prefix: '+'
  },
  {
    country: Country.LIECHTENSTEIN,
    flag: '../../images/flags/li.svg',
    prefix: '+'
  },
  {
    country: Country.LITHUANIA,
    flag: '../../images/flags/lt.svg',
    prefix: '+'
  },
  {
    country: Country.MACAO,
    flag: '../../images/flags/mo.svg',
    prefix: '+'
  },
  {
    country: Country.MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF,
    flag: '../../images/flags/mk.svg',
    prefix: '+'
  },
  {
    country: Country.MADAGASCAR,
    flag: '../../images/flags/mg.svg',
    prefix: '+'
  },
  {
    country: Country.MALAWI,
    flag: '../../images/flags/mw.svg',
    prefix: '+'
  },
  {
    country: Country.MALAYSIA,
    flag: '../../images/flags/my.svg',
    prefix: '+'
  },
  {
    country: Country.MALDIVES,
    flag: '../../images/flags/mv.svg',
    prefix: '+'
  },
  {
    country: Country.MALI,
    flag: '../../images/flags/ml.svg',
    prefix: '+'
  },
  {
    country: Country.MALTA,
    flag: '../../images/flags/mt.svg',
    prefix: '+'
  },
  {
    country: Country.MARSHALL_ISLANDS,
    flag: '../../images/flags/mh.svg',
    prefix: '+'
  },
  {
    country: Country.MARTINIQUE,
    flag: '../../images/flags/mq.svg',
    prefix: '+'
  },
  {
    country: Country.MAURITANIA,
    flag: '../../images/flags/mr.svg',
    prefix: '+'
  },
  {
    country: Country.MAURITIUS,
    flag: '../../images/flags/mu.svg',
    prefix: '+'
  },
  {
    country: Country.MAYOTTE,
    flag: '../../images/flags/yt.svg',
    prefix: '+'
  },
  {
    country: Country.MEXICO,
    flag: '../../images/flags/mx.svg',
    prefix: '+'
  },
  {
    country: Country.MICRONESIA_FEDERATED_STATES_OF,
    flag: '../../images/flags/fm.svg',
    prefix: '+'
  },
  {
    country: Country.MOLDOVA_REPUBLIC_OF,
    flag: '../../images/flags/md.svg',
    prefix: '+'
  },
  {
    country: Country.MONACO,
    flag: '../../images/flags/mc.svg',
    prefix: '+'
  },
  {
    country: Country.MONGOLIA,
    flag: '../../images/flags/mn.svg',
    prefix: '+'
  },
  {
    country: Country.MONTSERRAT,
    flag: '../../images/flags/ms.svg',
    prefix: '+'
  },
  {
    country: Country.MOROCCO,
    flag: '../../images/flags/ma.svg',
    prefix: '+'
  },
  {
    country: Country.MOZAMBIQUE,
    flag: '../../images/flags/mz.svg',
    prefix: '+'
  },
  {
    country: Country.MYANMAR,
    flag: '../../images/flags/mm.svg',
    prefix: '+'
  },
  {
    country: Country.NAMIBIA,
    flag: '../../images/flags/na.svg',
    prefix: '+'
  },
  {
    country: Country.NAURU,
    flag: '../../images/flags/nr.svg',
    prefix: '+'
  },
  {
    country: Country.NEPAL,
    flag: '../../images/flags/np.svg',
    prefix: '+'
  },
  // {
  //   country: Country.NETHERLANDS_ANTILLES,
  //   flag: '../../images/flags/an.svg',
  //   prefix: '+'
  // },
  {
    country: Country.NEW_CALEDONIA,
    flag: '../../images/flags/nc.svg',
    prefix: '+'
  },
  {
    country: Country.NEW_ZEALAND,
    flag: '../../images/flags/nz.svg',
    prefix: '+'
  },
  {
    country: Country.NICARAGUA,
    flag: '../../images/flags/ni.svg',
    prefix: '+'
  },
  {
    country: Country.NIGER,
    flag: '../../images/flags/ne.svg',
    prefix: '+'
  },
  {
    country: Country.NIGERIA,
    flag: '../../images/flags/ng.svg',
    prefix: '+'
  },
  {
    country: Country.NIUE,
    flag: '../../images/flags/nu.svg',
    prefix: '+'
  },
  {
    country: Country.NORFOLK_ISLAND,
    flag: '../../images/flags/nf.svg',
    prefix: '+'
  },
  {
    country: Country.NORTHERN_MARIANA_ISLANDS,
    flag: '../../images/flags/mp.svg',
    prefix: '+'
  },
  {
    country: Country.NORWAY,
    flag: '../../images/flags/no.svg',
    prefix: '+'
  },
  {
    country: Country.OMAN,
    flag: '../../images/flags/om.svg',
    prefix: '+'
  },
  {
    country: Country.PAKISTAN,
    flag: '../../images/flags/pk.svg',
    prefix: '+'
  },
  {
    country: Country.PALAU,
    flag: '../../images/flags/pw.svg',
    prefix: '+'
  },
  {
    country: Country.PALESTINIAN_TERRITORY_OCCUPIED,
    flag: '../../images/flags/ps.svg',
    prefix: '+'
  },
  {
    country: Country.PANAMA,
    flag: '../../images/flags/pa.svg',
    prefix: '+'
  },
  {
    country: Country.PAPUA_NEW_GUINEA,
    flag: '../../images/flags/pg.svg',
    prefix: '+'
  },
  {
    country: Country.PARAGUAY,
    flag: '../../images/flags/py.svg',
    prefix: '+'
  },
  {
    country: Country.PERU,
    flag: '../../images/flags/pe.svg',
    prefix: '+'
  },
  {
    country: Country.PHILIPPINES,
    flag: '../../images/flags/ph.svg',
    prefix: '+'
  },
  {
    country: Country.PITCAIRN,
    flag: '../../images/flags/pn.svg',
    prefix: '+'
  },
  {
    country: Country.POLAND,
    flag: '../../images/flags/pl.svg',
    prefix: '+'
  },
  {
    country: Country.PUERTO_RICO,
    flag: '../../images/flags/pr.svg',
    prefix: '+'
  },
  {
    country: Country.QATAR,
    flag: '../../images/flags/qa.svg',
    prefix: '+'
  },
  {
    country: Country.REUNION,
    flag: '../../images/flags/re.svg',
    prefix: '+'
  },
  {
    country: Country.ROMANIA,
    flag: '../../images/flags/ro.svg',
    prefix: '+'
  },
  {
    country: Country.RUSSIAN_FEDERATION,
    flag: '../../images/flags/ru.svg',
    prefix: '+'
  },
  {
    country: Country.RWANDA,
    flag: '../../images/flags/rw.svg',
    prefix: '+'
  },
  {
    country: Country.SAINT_HELENA,
    flag: '../../images/flags/sh.svg',
    prefix: '+'
  },
  {
    country: Country.SAINT_KITTS_AND_NEVIS,
    flag: '../../images/flags/kn.svg',
    prefix: '+'
  },
  {
    country: Country.SAINT_LUCIA,
    flag: '../../images/flags/lc.svg',
    prefix: '+'
  },
  {
    country: Country.SAINT_PIERRE_AND_MIQUELON,
    flag: '../../images/flags/pm.svg',
    prefix: '+'
  },
  {
    country: Country.SAINT_VINCENT_AND_THE_GRENADINES,
    flag: '../../images/flags/vc.svg',
    prefix: '+'
  },
  {
    country: Country.SAMOA,
    flag: '../../images/flags/ws.svg',
    prefix: '+'
  },
  {
    country: Country.SAN_MARINO,
    flag: '../../images/flags/sm.svg',
    prefix: '+'
  },
  {
    country: Country.SAO_TOME_AND_PRINCIPE,
    flag: '../../images/flags/st.svg',
    prefix: '+'
  },
  {
    country: Country.SAUDI_ARABIA,
    flag: '../../images/flags/sa.svg',
    prefix: '+'
  },
  {
    country: Country.SENEGAL,
    flag: '../../images/flags/sn.svg',
    prefix: '+'
  },
  {
    country: Country.MONTENEGRO,
    flag: '../../images/flags/me.svg',
    prefix: '+'
  },
  {
    country: Country.SERBIA,
    flag: '../../images/flags/rs.svg',
    prefix: '+'
  },
  {
    country: Country.SEYCHELLES,
    flag: '../../images/flags/sc.svg',
    prefix: '+'
  },
  {
    country: Country.SIERRA_LEONE,
    flag: '../../images/flags/sl.svg',
    prefix: '+'
  },
  {
    country: Country.SINGAPORE,
    flag: '../../images/flags/sg.svg',
    prefix: '+'
  },
  {
    country: Country.SLOVAKIA,
    flag: '../../images/flags/sk.svg',
    prefix: '+'
  },
  {
    country: Country.SLOVENIA,
    flag: '../../images/flags/si.svg',
    prefix: '+'
  },
  {
    country: Country.SOLOMON_ISLANDS,
    flag: '../../images/flags/sb.svg',
    prefix: '+'
  },
  {
    country: Country.SOMALIA,
    flag: '../../images/flags/so.svg',
    prefix: '+'
  },
  {
    country: Country.SOUTH_AFRICA,
    flag: '../../images/flags/za.svg',
    prefix: '+'
  },
  {
    country: Country.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS,
    flag: '../../images/flags/gs.svg',
    prefix: '+'
  },
  {
    country: Country.SRI_LANKA,
    flag: '../../images/flags/lk.svg',
    prefix: '+'
  },
  {
    country: Country.SUDAN,
    flag: '../../images/flags/sd.svg',
    prefix: '+'
  },
  {
    country: Country.SURINAME,
    flag: '../../images/flags/sr.svg',
    prefix: '+'
  },
  {
    country: Country.SVALBARD_AND_JAN_MAYEN,
    flag: '../../images/flags/sj.svg',
    prefix: '+'
  },
  {
    country: Country.SWAZILAND,
    flag: '../../images/flags/sz.svg',
    prefix: '+'
  },
  {
    country: Country.SWEDEN,
    flag: '../../images/flags/se.svg',
    prefix: '+'
  },
  {
    country: Country.SYRIAN_ARAB_REPUBLIC,
    flag: '../../images/flags/sy.svg',
    prefix: '+'
  },
  {
    country: Country.TAIWAN_PROVINCE_OF_CHINA,
    flag: '../../images/flags/tw.svg',
    prefix: '+'
  },
  {
    country: Country.TAJIKISTAN,
    flag: '../../images/flags/tj.svg',
    prefix: '+'
  },
  {
    country: Country.TANZANIA_UNITED_REPUBLIC_OF,
    flag: '../../images/flags/tz.svg',
    prefix: '+'
  },
  {
    country: Country.THAILAND,
    flag: '../../images/flags/th.svg',
    prefix: '+'
  },
  {
    country: Country.TIMOR_LESTE,
    flag: '../../images/flags/tl.svg',
    prefix: '+'
  },
  {
    country: Country.TOGO,
    flag: '../../images/flags/tg.svg',
    prefix: '+'
  },
  {
    country: Country.TOKELAU,
    flag: '../../images/flags/tk.svg',
    prefix: '+'
  },
  {
    country: Country.TONGA,
    flag: '../../images/flags/to.svg',
    prefix: '+'
  },
  {
    country: Country.TRINIDAD_AND_TOBAGO,
    flag: '../../images/flags/tt.svg',
    prefix: '+'
  },
  {
    country: Country.TUNISIA,
    flag: '../../images/flags/tn.svg',
    prefix: '+'
  },
  {
    country: Country.TURKEY,
    flag: '../../images/flags/tr.svg',
    prefix: '+'
  },
  {
    country: Country.TURKMENISTAN,
    flag: '../../images/flags/tm.svg',
    prefix: '+'
  },
  {
    country: Country.TURKS_AND_CAICOS_ISLANDS,
    flag: '../../images/flags/tc.svg',
    prefix: '+'
  },
  {
    country: Country.TUVALU,
    flag: '../../images/flags/tv.svg',
    prefix: '+'
  },
  {
    country: Country.UGANDA,
    flag: '../../images/flags/ug.svg',
    prefix: '+'
  },
  {
    country: Country.UKRAINE,
    flag: '../../images/flags/ua.svg',
    prefix: '+'
  },
  {
    country: Country.UNITED_ARAB_EMIRATES,
    flag: '../../images/flags/ae.svg',
    prefix: '+'
  },
  {
    country: Country.U_K,
    flag: '../../images/flags/gb.svg',
    prefix: '+'
  },
  {
    country: Country.UNITED_STATES,
    flag: '../../images/flags/us.svg',
    prefix: '+'
  },
  {
    country: Country.UNITED_STATES_MINOR_OUTLYING_ISLANDS,
    flag: '../../images/flags/um.svg',
    prefix: '+'
  },
  {
    country: Country.URUGUAY,
    flag: '../../images/flags/uy.svg',
    prefix: '+'
  },
  {
    country: Country.UZBEKISTAN,
    flag: '../../images/flags/uz.svg',
    prefix: '+'
  },
  {
    country: Country.VANUATU,
    flag: '../../images/flags/vu.svg',
    prefix: '+'
  },
  {
    country: Country.VENEZUELA,
    flag: '../../images/flags/ve.svg',
    prefix: '+'
  },
  {
    country: Country.VIET_NAM,
    flag: '../../images/flags/vn.svg',
    prefix: '+'
  },
  {
    country: Country.VIRGIN_ISLANDS_BRITISH,
    flag: '../../images/flags/vg.svg',
    prefix: '+'
  },
  {
    country: Country.VIRGIN_ISLANDS_U_S,
    flag: '../../images/flags/vi.svg',
    prefix: '+'
  },
  {
    country: Country.WALLIS_AND_FUTUNA,
    flag: '../../images/flags/wf.svg',
    prefix: '+'
  },
  {
    country: Country.WESTERN_SAHARA,
    flag: '../../images/flags/eh.svg',
    prefix: '+'
  },
  {
    country: Country.YEMEN,
    flag: '../../images/flags/ye.svg',
    prefix: '+'
  },
  {
    country: Country.ZAMBIA,
    flag: '../../images/flags/zm.svg',
    prefix: '+'
  },
  {
    country: Country.ZIMBABWE,
    flag: '../../images/flags/zw.svg',
    prefix: '+'
  }
])

export default PhoneCountry
