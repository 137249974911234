export default {
  getSiteIndex(state) {
    return state.siteList
  },
  getSite(state) {
    return state.site
  },
  getSiteMetadata(state) {
    return state.siteMetadata
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getSiteActivities(state) {
    return state.siteActivities
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
}
