import DossierContactGetters from './dossier_contacts/getters'
import DossierEventGetters from './dossier_events/getters'
import DossierPartnersGetters from './dossier_partners/getters'
import DossierPersonGetters from './dossier_person/getters'
import DossierTaskGetters from './dossier_task/getters'

export default {
  ...DossierContactGetters,
  ...DossierEventGetters,
  ...DossierPartnersGetters,
  ...DossierPersonGetters,
  ...DossierTaskGetters,

  getDossierIndex(state) {
    return state.dossiersList
  },

  getDossier(state) {
    return state.dossier
  },

  getDossierActivities(state) {
    return state.dossierActivities
  },
  getDossierMetadataActivities(state) {
    return state.dossierMetadataActivities
  },

  getDossierMetadata(state) {
    return state.dossierMetadata
  },

  getDossierEvents(state) {
    return state.dossier.events
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getLeaveRouteByButton(state) {
    return state.leaveRouteByButton
  },
  getLeaveRouteByNotification(state) {
    return state.leaveRouteByNotification
  },
  getInitialDossier(state) {
    return state.initialDossier
  },
  getHasChanged(state) {
    return state.hasChanged
  },
  getContactsDossier(state) {
    return state.contactsDossier
  },
  getDocumentsDossier(state) {
    return state.documentsDossier
  },
  getNavSubItems(state) {
    return state.navSubItems
  },
  getOnlineMembers(state) {
    return state.online_members
  }
}
