import getters from '@/store/modules/gravePlot/getters'
import mutations from '@/store/modules/gravePlot/mutations'
import actions from '@/store/modules/gravePlot/actions'

export default {
  namespaced: true,
  state() {
    return {}
  },
  getters,
  actions,
  mutations
}
