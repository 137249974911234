const TheDocumentFormsTabComponent = () =>
  import(
    '@/views/settings/management/documents/form/TheDocumentFormsTabComponent'
  )
const TheDocumentIndexPage = () =>
  import('@/views/settings/management/documents/index/TheDocumentIndexPage')
const TheWikiSharedDocumentIndexPage = () =>
  import(
    '@/views/settings/management/documents/index/TheWikiSharedDocumentIndexPage'
  )
const TheDocumentActivityPage = () =>
  import(
    '@/views/settings/management/documents/form/tabs/documentActivivities/TheDocumentActivityPage'
  )
const TheDocumentFormPage = () =>
  import(
    '@/views/settings/management/documents/form/tabs/documentInformation/TheDocumentFormPage'
  )
const TheDocumentTranslationPage = () =>
  import(
    '@/views/settings/management/documents/form/tabs/documentTranslation/TheDocumentTranslationPage'
  )

export const settingsDocumentsRoutes = [
  {
    path: 'documents',
    children: [
      {
        path: '',
        name: 'settings_list_documents_route',
        component: TheDocumentIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: 'shared',
        name: 'settings_list_shared_documents_route',
        component: TheWikiSharedDocumentIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_documents_info_route',
        props: true,
        component: TheDocumentFormsTabComponent,
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheDocumentFormPage,
            name: 'settings_edit_documents_route'
          },
          {
            path: 'activities',
            component: TheDocumentActivityPage,
            props: true,
            name: 'settings_edit_documents_activity_route'
          }
        ]
      },
      {
        path: 'new',
        name: 'settings_create_documents_info_route',
        component: TheDocumentFormsTabComponent,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheDocumentFormPage,
            name: 'settings_create_documents_route'
          },
          {
            path: 'activities',
            component: TheDocumentTranslationPage,
            name: 'settings_create_documents_translation_route'
          }
        ]
      }
    ]
  }
]
