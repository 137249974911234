<template>
  <div>
    <div class="fm-form-input" :class="label ? 'mt-2' : ''">
      <label>
        {{ label }}&nbsp;
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <input
        v-model.lazy="modelValue"
        :type="type"
        :max="max"
        :min="min"
        :name="name"
        @blur="$emit('update:modelValue', $event.target.value)"
      />
    </div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSimpleInput',
  props: {
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    value: {
      type: [String, Number],
      default: '',
      require: true
    },
    modelValue: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: Number,
      default: null,
      required: false
    },
    max: {
      type: Number,
      default: null,
      required: false
    },
    parseType: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        const admittedTypes = ['text', 'int', 'float']
        return admittedTypes.indexOf(value) !== -1
      }
    }
  },
  emits: ['update:modelValue']
}
</script>
