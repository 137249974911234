import TheSaManagementPage from '../../views/sa_management/TheSaManagementPage'
import { saManagementGeneralRoutes } from '@/router/sa_management/general'
import PlaygroundComponent from '@/views/wip/PlaygroundComponent.vue'

export const saManagementSubPages = [
  {
    path: '/sa-management',
    name: 'management_index_route',
    component: TheSaManagementPage,
    children: [...saManagementGeneralRoutes]
  },
  {
    path: '/playground',
    name: 'playground',
    component: PlaygroundComponent
  }
]
