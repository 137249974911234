/* eslint-disable */
import apiClient from '@u/apiClient'

let base_error = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region fetch
  async fetchEvents({ commit, getters }, payload) {
    // console.log('action', new URLSearchParams(payload).toString())
    await apiClient
      .post(`/agenda/fetch-events`, payload)
      .then(async (response) => {
        // console.log('res', response)
        if (response.data) {
          await commit('setEvents', response.data.events)
          await commit('setEventsUnplanned', response.data.unPlanned)
        }
      })
      .catch(async (reason) => {
        console.error('fetchEvents', reason)
      })
  },
  async fetchUnplannedEvents({ commit, getters }) {
    await apiClient
      .post(`/agenda/fetch-unplanned-events`)
      .then(async (response) => {
        // console.log('res', response)
        if (response.data) {
          // await commit('setEvents', response.data.events)
          await commit('setEventsUnplanned', response.data.unPlanned)
        }
      })
      .catch(async (reason) => {
        console.error('fetchEvents', reason)
      })
  },

  //endregion

  async updateEventByDrag({ commit }, obj) {
    await apiClient
      .put(`/agenda/update-event-time/${obj.id}`, obj)
      .then(async (response) => {
        commit('updateEventInList', response.data)
        processSuccessCallback(obj)
      })
      .catch(async (reason) => {
        console.error('updateEventByDrag', reason)
      })
  },

  async fetchEventById({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .get(`/agenda/event/${payload.event_id}`)
      .then(async (response) => {
        await commit('setEvent', response.data.event)
        await commit('setEventMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchEventById', reason)
      })
  },

  async fetchNewEvent({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })

    await apiClient
      .get(`model/new?model=event`)
      .then(async (response) => {
        await commit('setEvent', response.data.event)
        await commit('setEventMetadata', response.data.metadata)
        if (payload.from) commit('setEventDateFrom', payload.from)
        if (payload.to) commit('setEventDateTo', payload.to)
        if (payload.full_day) commit('setEventFullDay', payload.full_day)
        if (payload.type)
          commit('alterEvent', { attribute: 'type', value: payload.type })
        if (payload.dossier) commit('setEventDossier', payload.dossier)
      })
      .catch(async (reason) => {
        console.error('fetchNewEvent', reason)
      })
  },

  async postEvent({ commit }, obj) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .post(`agenda/store-event/`, obj.event)
      .then(async (response) => {
        // console.log("response", response)
        if (response.status === 200) {
          if (obj.successCallback) {
            if (!obj.event.type === 'REC') {
              await commit(
                'invoicing/setInvoiceIndex',
                response.data.invoices,
                {
                  root: true
                }
              )
            }
            await obj.successCallback(response.data.event)
          }
        }
      })
      .catch(async (reason) => {
        console.error('postEvent', reason)
      })
  },

  async updateEvent({ commit }, obj) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .put(`agenda/update-event/${obj.event.id}`, obj.event)
      .then(async (response) => {
        // console.log('response', response)
        if (response.status === 200) {
          commit('setEvent', response.data.event)
          if (obj.successCallback) {
            await obj.successCallback(response.data.event)
          }
        }
      })
      .catch(async (reason) => {
        console.error('updateEvent', reason)
      })
  },
  async deleteEventById({ commit }, payload) {
    await apiClient
      .delete(`event/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteEventById', reason)
      })
  },
  async undoDeleteEventById({ commit }, payload) {
    await apiClient
      .get(`event/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteEventById', reason)
      })
  }
  // async fetchEventDisponbilitiesByOption({ commit }, payload) {
  //   console.log('payload', payload)
  //   await apiClient
  //     .get(`agenda/fetch-timeslots`, { params: payload.params })
  //     .then(async (response) => {
  //       // await commit('setEventDisponibilities', response.data.timeslots
  //       if ('index' in payload) {
  //         //  commit('work/setEventTimeSlotInWorkEvent', {index: payload.index, timeslots: response.data.timeslots})
  //       }
  //       else {
  //         commit('setEventTimeSlotInEvent', response.data.timeslots)
  //       }
  //     })
  //     .catch(async (reason) => {
  //       await console.error('fetchEventDisponbilitiesByOption', reason)
  //     })
  // }
}
