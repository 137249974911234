const WikiCityFunerariumInformation = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/funerarium/form/tabs/information/WikiCityFunerariumInformation.vue'
  )
const WikiCityFunerariumFormsTabsComponent = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/funerarium/form/WikiCityFunerariumFormsTabsComponent.vue'
  )
const WikiCityFunerariumIndexPage = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/funerarium/index/WikiCityFunerariumIndexPage.vue'
  )
const WikiPlaceItemsTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceItemsTab.vue'
  )
const WikiPlaceTranslationTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceTranslationTab.vue'
  )
const WikiPlaceContactTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceContactTab.vue'
  )

export const wikiCityFunerariumRoutes = [
  {
    path: 'funerarium',
    children: [
      {
        path: '',
        name: 'wiki_edit_city_funerarium_page_route',
        component: WikiCityFunerariumIndexPage,
        props: true,
        meta: {
          pre_page: '',
          next_page: ''
        }
      },
      {
        path: ':funerarium_id',
        name: 'wiki_city_edit_funerarium_page_route',
        component: WikiCityFunerariumFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_funerarium_information_page_route',
            component: WikiCityFunerariumInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_edit_funerarium_translation_page_route',
            component: WikiPlaceTranslationTab
          },
          {
            path: 'contact',
            name: 'wiki_city_edit_funerarium_contact_page_route',
            component: WikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_edit_funerarium_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_funerarium_page_route',
        component: WikiCityFunerariumFormsTabsComponent,
        children: [
          {
            path: '',
            name: 'wiki_city_create_funerarium_information_page_route',
            component: WikiCityFunerariumInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_create_funerarium_translation_page_route',
            component: WikiPlaceTranslationTab
          },
          {
            path: 'contact',
            name: 'wiki_city_create_funerarium_contact_page_route',
            component: WikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_create_funerarium_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
