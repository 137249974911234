export default {
  setSupplierList(state, supplierList){
    state.supplierList = supplierList
  },
  setSupplier(state, supplier){
    if (supplier && !('contacts' in supplier)) {
      supplier['contacts'] = []
    }
    state.supplier = supplier
  },
  setMetadata(state, metadata){
    state.metadata = metadata
  },
  setEditionMode(state, editionMode){
    state.editionMode = editionMode
  },
  setSupplierActivity(state, activity) {
    state.supplierActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  addContactToSupplier(state, payload) {
    state.supplier.contacts.push(payload.contact)
  },
  deleteContactFromContactSupplier(state, payload) {
    state.supplier.contacts = state.supplier.contacts.filter(contact => contact.id !== payload.contact_id)
  },
  refreshContactsSupplier(state, payload) {
    state.supplier.contacts = payload.contacts
  },
}
