import apiClient from '@u/apiClient'
import Promise from 'lodash-es/_Promise'

let base_errors = { deceased: {}, events: [] }

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async fetchAllCities({ commit }) {
    await apiClient
      .get(`city/`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setCities', response.data.cities)
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },

  fetchClosestCities({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`city/closest-cities`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchFavoriteCities({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`city/favorite-cities`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setFavoriteCities', response.data.cities)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchFavoriteCities', reason)
      })
  },

  async fetchCityById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`city/${payload.city_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setCity', response.data.wiki_city)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchCityById', reason)
      })
  },

  async fetchNewCity({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`model/new?model=wikiCity`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setCity', response.data.wikiCity)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchNewCity', reason)
      })
  },

  async storeCity({ commit }, payload) {
    await apiClient
      .post(`city`, payload.city)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          commit('setCities', response.data.cities)
        }
      })
      .catch(async (reason) => {
        await console.error('storeCity', reason)
      })
  },

  async updateCity({ commit }, payload) {
    await apiClient
      .put(`city/${payload.city_id}`, payload.city)
      .then(async (response) => {
        await console.log('updateCity', response)
        if (response.status === 200) {
          commit('setCity', response.data.wiki_city)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('updateCity', reason)
      })
  },

  changeFavoriteCity: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`city/change-favorite-city/${payload.city_id}`, {
          is_favorite: payload.is_favorite
        })
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
