<template>
  <template v-if="editionMode && initialMetadata">
    <div class="fm-form-input" :class="label ? 'mt-2' : ''">
      <label v-if="label" :for="name"
        >{{ label }}
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <div
        class="flex flex-row space-x-1 p-1.5"
        :class="localReferenceArray?.length ? '' : 'h-8'"
        @drop="onDrop($event)"
        @dragover.prevent
        @dragenter.prevent
      >
        <span
          v-for="(element, idx) in localReferenceArray"
          :key="idx"
          class="flex h-6 flex-row items-center gap-1 rounded bg-primary-300 px-2 py-1.5 text-xs text-white"
          :data-index="idx"
        >
          <span v-if="element.type === 'company'">
            {{ $t('attributes.company') }} ({{ element.value }})
          </span>
          <span v-else-if="element.type === 'funeral_home'">
            {{ $t('attributes.funeral_home') }} ({{ element.value }})
          </span>
          <span v-else-if="element.type === 'char'">
            {{ element.value }}
          </span>
          <span v-else-if="element.type === 'custom'">
            <input
              class="w-12 px-0.5 py-0 text-xs text-slate-700"
              v-model="localReferenceArray[idx].value"
            />
          </span>
          <span v-else>{{ element.type }} ({{ element.value }})</span>
          <button
            v-if="element.type === 'custom'"
            class="flex aspect-square h-4 items-center justify-center rounded bg-white text-primary"
          >
            <FontAwesomeIcon :icon="['fal', 'edit']" />
          </button>
          <button
            class="flex aspect-square h-4 items-center justify-center rounded bg-white text-primary"
            @click="deleteTags(idx)"
          >
            <FontAwesomeIcon :icon="['fal', 'times']" />
          </button>
        </span>
      </div>
    </div>
    <div class="mt-2 flex flex-row flex-wrap gap-3">
      <div
        v-for="(element, idx) in initialMetadata.enums.pattern_elements"
        :key="idx"
      >
        <span
          class="cursor-move rounded border border-slate-300 px-2 py-1 text-xs"
          draggable="true"
          @dragstart="startDrag($event, element)"
        >
          {{ element.label }}
        </span>
      </div>
    </div>
  </template>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'

export default {
  name: 'ReferenceConfigurator',
  components: { FontAwesomeIcon },
  props: {
    referenceArray: {
      type: Array,
      required: true,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: 'pattern'
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:referenceArray'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters({ initialMetadata: 'metadata/getInitialMetadata' }),
    localReferenceArray: {
      get() {
        return this.referenceArray
      },
      set(value) {
        this.$emit('update:referenceArray', value)
      }
    }
  },
  methods: {
    startDrag(evt, element) {
      console.log('startDrag')
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('data', JSON.stringify(element.data))
    },
    onDrop(evt) {
      const itemData = evt.dataTransfer.getData('data')
      let tmp = [...this.localReferenceArray]
      console.log(evt.toElement.closest('[data-index]'))
      if (evt.toElement.closest('[data-index]')) {
        let index = evt.toElement.closest('[data-index]').dataset.index
        tmp = [
          ...tmp.slice(0, index),
          JSON.parse(itemData),
          ...tmp.slice(index)
        ]
      } else {
        tmp.push(JSON.parse(itemData))
      }
      this.localReferenceArray = tmp
    },
    deleteTags(index) {
      this.localReferenceArray.splice(index, 1)
    }
  }
}
</script>

<script setup></script>
<style scoped></style>
