<template>
  <BaseShowEditUndefinedRadioGroup
    v-model="contact.is_deceased"
    :label="$t('attributes.dead')"
    :edition-mode="editionMode"
    :default-value="cIsDeceased"
  />
  <div
    v-if="contact.is_deceased"
    class="mt-3 grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2"
  >
    <div>
      <BaseShowEditDateTimePicker
        v-model:date="contact.death_date_time"
        v-model:time-undefined="contact.death_date_time_undefined_time"
        :label-date="$t('attributes.death_date')"
        :label-time="$t('attributes.hour')"
        :edition-mode="editionMode"
        name="death_date"
      />
    </div>
    <BaseShowEditInput
      v-model="contact.death_place"
      :label="$t('attributes.death_place')"
      placeholder="lieu"
      name="death_place"
      :edition-mode="editionMode"
    />
  </div>
</template>

<script>
import BaseShowEditDateTimePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDateTimePickerv2.vue'

export default {
  name: 'ContactCardDeathComponent',
  components: { BaseShowEditDateTimePicker },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    contactType: {
      type: String,
      required: true
    }
  },
  computed: {
    cIsDeceased() {
      return this.contact?.is_deceased ? this.contact?.is_deceased : false
    }
  }
}
</script>
