/* eslint-disable camelcase */
import apiClient from '@u/apiClient'
// import router from '@/router'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchTasksList({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('task')
      .then(async (response) => {
        commit('setTasksList', response.data.tasks)
        commit('setTaskMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchTasksList', reason)
      })
  },
  async fetchTaskById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`task/${payload}`)
      .then(async (response) => {
        commit('setTask', response.data.task)
        commit('setTaskMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchTaskById', reason)
      })
  },
  async fetchNewTask({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=Task')
      .then(async (response) => {
        await commit('setTask', response.data.Task)
        await commit('setTaskMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewTask', reason)
      })
  },
  async fetchUsersByFuneralHomeID({ commit }, payload) {
    await apiClient
      .get(`task/fetch-users/${payload.funeral_home_id}?terms=${payload.terms}`)
      .then(async (response) => {
        if (payload.type === 'executors') {
          await commit('setTempExecutorUsers', {
            fh: payload.funeral_home_id,
            users: response.data.users_list
          })
        } else {
          await commit('setTempUsers', {
            fh: payload.funeral_home_id,
            users: response.data.users_list
          })
        }
      })
      .catch(async (reason) => {
        await console.error('fetchUsersByFuneralHomeID', reason)
      })
  },
  async fetchAutomationMetadata({ commit }, payload) {
    await apiClient
      .get(`task/fetch-automations/${payload}`)
      .then(async (response) => {
        await commit('setTaskMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error('fetchAutomationMetadata', reason)
      })
  },
  async fetchUsersForSelect({ commit }, payload) {
    await apiClient
      .get("/user-for-select")
      .then(async (response) => {
        console.log(response.data)
        // if (payload.type === 'executors') {
        //   await commit('setTempExecutorUsers', {
        //     fh: payload.funeral_home_id,
        //     users: response.data.users_list
        //   })
        // } else {
        //   await commit('setTempUsers', {
        //     fh: payload.funeral_home_id,
        //     users: response.data.users_list
        //   })
        // }
      })
      .catch(async (reason) => {
        await console.error('fetchUsersByFuneralHomeID', reason)
      })
  },
  async fetchTaskActivitiesByTaskID({ commit }, payload) {
    await apiClient
      .get(`task/fetch-activities/${payload.task_id}`)
      .then(async (response) => {
        await commit('setTaskActivity', response.data.activities)
        await commit('setMetadataActivities', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error('fetchTaskActivitiesByTaskID', reason)
      })
  },
  // endregion

  // region store
  async storeTask({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post('task', payload.task)
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('storeTask', reason)
      })
  },
  // endregion

  // region update
  async updateTask({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`task/${payload.id}`, payload.task)
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error(reason)
      })
  },
  // endregion

  // region delete
  async deleteTaskById({ commit }, payload) {
    await apiClient
      .delete(`task/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setTasksList', response.data.tasks)
          commit('setTaskMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteTaskById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteTaskById({ commit }, payload) {
    await apiClient
      .get(`task/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setTasksList', response.data.tasks)
          commit('setTaskMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteTaskById', reason)
      })
  }
  // endregion
}
