<template>
  <div class="flex-1 rounded-xl bg-white">
    <SettingsPageFull>
      <template #title>
        <div class="flex space-x-3">
          <span class="text-3xl font-bold capitalize-first">{{
            $t('navigation.version_notes_title')
          }}</span>
        </div>
      </template>

      <template #cta>
        <BaseButton
          title="redirection vers l'acceuil"
          @click="$router.push({ name: 'dossier_death_route' })"
        />
      </template>

      <template #body>
        <div v-if="versionNoteList" class="mx-auto mb-12 w-10/12 py-6">
          <div
            v-for="(version, index) in versionNoteList"
            :key="index"
            class="mx-auto mb-4 block w-10/12 rounded border border-slate-300 p-4"
          >
            <VersionCardComponent
              :version="version"
              @confirm-reading-version-note="confirmReadingVersionNote"
            />
          </div>
        </div>
      </template>
    </SettingsPageFull>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SettingsPageFull from '@c/SettingsPageFull'
import VersionCardComponent from '@ui/modal/versionNote/VersionCardComponent.vue'

export default {
  name: 'TheVersionNoteIndexPage',
  components: {
    VersionCardComponent,
    SettingsPageFull
  },
  computed: {
    ...mapGetters({
      versionNoteList: 'versionNote/getVersionNoteList'
    })
  },
  methods: {
    ...mapActions({
      processFetchAllVersionNotesAction: 'versionNote/fetchAllVersionNotes',
      processAddReadVersionNoteAction: 'metadata/addReadVersionNote',
      processRefreshSpecificInitialMetadataModelsAction:
        'metadata/refreshSpecificInitialMetadataModels'
    }),
    confirmReadingVersionNote(version) {
      this.processAddReadVersionNoteAction({
        versions: [version],
        successCallback: () => {
          this.$h.toastify(
            this.$t('global.version_marked_as_read', { var: version.version }),
            { className: 'toastify-content success' }
          )
          this.processRefreshSpecificInitialMetadataModelsAction({
            model: 'unreaded_version_notes'
          })
        }
      })
    }
  },
  async mounted() {
    await this.processFetchAllVersionNotesAction()
  }
}
</script>
