export default {
  getCompaniesList(state) {
    return state.companiesList
  },
  getCompanyMetadata(state) {
    return state.metadata
  },
  getCompany(state) {
    return state.company
  },
  getCompanyActivity(state) {
    return state.companyActivity
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getCompanyLocations(state) {
    return state.companyLocations
  }
}
