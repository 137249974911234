/* eslint-disable camelcase */
import apiClient from '@u/apiClient'
// import router from '@/router'

const base_errors = {}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {
    // region fetch
    async fetchDuplicateModelsIndex({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get(`/duplicate-models/?model=${payload.model}`)
            .then(async (response) => {
                await commit('setDuplicateList', response.data.duplicate_groups)
            })
            .catch(async (reason) => {
                console.error('fetchDuplicateModelsIndex', reason)
            })
    },
    // endregion

    async mergeSelectedModels({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .post(`/duplicate-update-models`, payload)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setDuplicateList', response.data.duplicate_groups)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                console.error('mergeSelectedModels', reason)
            })
    },

}
