export default {
  setItemList(state, payload) {
    if (payload.parent_is_a_place) {
      if (state.place !== null) {
        state.place.items = payload.item_list
      }
    } else {
      if (state.city !== null) {
        state.city.items = payload.item_list
      }
    }
  },
  addWikiItemToWikiPlace(state) {
    if (!state.place.items) {
      state.place.items = []
    }
    state.place.items.push({ name: {}, parent_id: state.place.id })
  },

  deleteWikiItemFromWikiPlace(state, wiki_item_index) {
    state.place.items.splice(wiki_item_index, 1)
  },

  addWikiItemToWikiCity(state) {
    if (!state.city.items) {
      state.city.items = []
    }
    state.city.items.push({ id: null, name: {}, parent_id: state.city.id })
  },
  deleteWikiItemFromWikiCity(state, wiki_item_index) {
    state.city.items.splice(wiki_item_index, 1)
  }
}
