const Status = Object.freeze({
    DRAFT: 'DRA',
    ESTABLISHED: 'EST',
    PARTIALLY_PAID: 'PP',
    PAYMENT_BY_INSTALLMENTS: 'PBI',
    PAID: 'PAI',
    NOTARY: 'NOT',
    LITIGATION: 'LIT',
    CANCELLED: 'CAN',
    RECEIVED: 'REC',
    PARTIALLY_RECEIVED: 'PAR_REC',
    SENDED: 'SEN',
})

export default Status
