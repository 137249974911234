export default {
    toggleCollapsed(state) {
        state.collapsed = !state.collapsed
        // localStorage.setItem('collapsed', state.collapsed)
    },
    collapse(state) {
        state.collapsed = true
        // localStorage.setItem('collapsed', true)
    },
    uncollapse(state) {
        state.collapsed = false
        // localStorage.setItem('collapsed', false)
    }
}
