import apiClient from '@u/apiClient'
import router from '@/router'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch

  async fetchFuneralHomesList({ commit }) {
    await apiClient
      .get('funeral-home')
      .then(async (response) => {
        commit('setFuneralHomesList', response.data.funeral_homes)
        commit('setFuneralHomeMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchFuneralHomesList', reason)
      })
  },

  async fetchFuneralHomesWithPartnersList({ commit }) {
    await apiClient
      .get('funeral-home/funeral-home-with-partners')
      .then(async (response) => {
        commit('setFuneralHomesList', response.data.funeral_homes)
        commit('setFuneralHomeMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchFuneralHomesList', reason)
      })
  },

  async fetchFuneralHomeByID({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get(`funeral_home/${payload.id}`)
      .then(async (response) => {
        await commit('setFuneralHome', response.data.funeral_home)
        await commit('setFuneralHomeMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchFuneralHomeByID', reason)
      })
  },

  async fetchNewFuneralHome({ commit }) {
    commit('setFuneralHome', {})
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get('model/new?model=FuneralHome')
      .then(async (response) => {
        await commit('setFuneralHome', response.data.FuneralHome)
        await commit('setFuneralHomeMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewFuneralHome', reason)
      })
  },
  async fetchFuneralHomeActivitiesByFuneralHomeID({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get(`funeral_home/fetch-activities/${payload.funeralHome_id}`)
      .then(async (response) => {
        await commit('setFuneralHomeActivities', response.data.activities)
        await commit('setMetadataActivities', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },
  // endregion

  // region store
  storeNewFuneralHome({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })

    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.funeral_home))
    if (payload.funeral_home.new_logo_file) {
      formData.append('new_logo_file', payload.funeral_home.new_logo_file)
    }

    return new Promise((resolve, reject) => {
      apiClient
        .post('funeral-home', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          commit('setFuneralHomesList', response.data.funeral_homes)
          commit('setFuneralHomeMetadata', response.data.metadata)
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateFuneralHome({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })

    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.funeral_home))

    if (payload.funeral_home.new_logo_file) {
      formData.append('new_logo_file', payload.funeral_home.new_logo_file)
    }

    formData.append('_method', 'PUT')

    return new Promise((resolve, reject) => {
      apiClient
        .post(`funeral-home/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          commit('setFuneralHomesList', response.data.funeral_homes)
          commit('setFuneralHomeMetadata', response.data.metadata)
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async updateFuneralHomePartner({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    // console.log(payload)

    await apiClient
      .put(`funeral_home_partner/${payload.id}`, payload.funeral_home_partner)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setFuneralHomesList', response.data.funeral_homes)
          commit('setFuneralHomeMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('error updateFuneralHome', reason)
      })
  },
  // endregion

  // region delete
  async deleteFuneralHomeById({ commit }, payload) {
    // console.log(payload.id, 'action deleteFuneralHomeById')
    await apiClient
      .delete(`funeral_home-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setFuneralHomesList', response.data.funeralHomes)
          commit('setFuneralHomeMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteFuneralHomeById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteFuneralHomeById({ commit }, payload) {
    await apiClient
      .get(`funeral_home/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setFuneralHomesList', response.data.funeralHomes)
          commit('setFuneralHomeMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteItemById', reason)
      })
  }
  // endregion
}
