export default {
  setSiteList(state, implantationList) {
    state.siteList = implantationList
  },
  setSite(state, implantation) {
    state.site = implantation
  },
  setSiteMetaData(state, metadata) {
    state.siteMetadata = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setSiteAddress(state, address) {
    state.site.address = address
    state.site.address_id = address.id
  },
  setSiteActivities(state, siteActivities) {
    state.siteActivities = siteActivities
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
}
