<template>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="py-2.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
        >
          {{ $t('attributes.reference') }}
        </th>
        <th
          scope="col"
          class="px-3 py-2.5 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
        >
          {{ $t('attributes.type') }}
        </th>
        <th
          scope="col"
          class="py-2.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
        >
          {{ $t('attributes.total_tvac') }}
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <template v-for="invoice in invoices" :key="invoice.id">
        <tr>
          <td
            class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
          >
            {{ invoice.reference }}
          </td>
          <td
            class="whitespace-nowrap px-3 py-2 text-left text-sm text-gray-500"
          >
            {{ invoice.doc_type }}
          </td>
          <td
            class="whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm text-gray-500"
          >
            {{ $h.formatCurrency(invoice.total_tvac) }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ShowInvoicesModalTable',
  props: {
    invoices: {
      type: Array,
      required: true
    }
  }
}
</script>
