<template >
  <div class="box drop-shadow-lg px-7 py-6 mb-2 ">
    <div class="text-lg text-gray-600 font-medium">{{ $t('global.other_place') }}</div >
    <BaseShowEditSelectWikiAjax
      :key="keyw"
      v-model="fakeComponentDataToDelete"
      :edition-mode="true"
      url="search-global-wiki"
      attribute-label="title"
      name="city"
      :default-ajax="otherPlaceBaseValue"
      :global-search="true"
      :displayed-option-icon="true"
      :full-model-response="true"
      @change="handlePickGetAddress"
    />
  </div >
</template >
<script >
import { mapGetters } from "vuex";

export default {
  name: 'OtherPlaceModalComponent',
  emits: ['getAddress'],
  data() {
    return {
      filterText: '',
      fakeComponentDataToDelete: null,
      keyw: 0
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'dossier/getDossier'
    }),
    otherPlaceBaseValue() {
      return { id: "", title: "" }
    }
  },
  methods: {
    handlePickGetAddress(address) {
      this.keyw++
      let address_formatted = {}
      address_formatted['type'] = address.type
      address_formatted['id'] = address.searchable.id
      address_formatted['label'] = address.searchable.name
      this.$emit('getAddress', address_formatted)
    }
  },
}
</script >
