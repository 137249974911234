const TheVariableIndexPage = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/management/variable/TheVariableIndexPage.vue'
  )

export const settingsVariableRoutes = [
  {
    path: 'variable',
    name: 'settings_variable_route',
    component: TheVariableIndexPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_item_title', active: true }
      ]
    }
  }
]
