<template>
  <span class="relative inline-block mr-5 ">
    <BaseButton
      :color="color"
      :icon="iconTest"
      :tooltip="tooltip"
      size="small"
      class="cursor-default"
      cursor="default"
      text-color="white"
    />
    <span
      v-if="count && icon"
      :class="`absolute top-0 right-0 left-1.5 inline-flex items-center justify-center px-2.5 py-1 text-xs font-bold leading-none text-white
                transform translate-x-1/2 bg-red-500 -translate-y-1/2  rounded-full select-none`"
    >
      {{ count }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'NotificationIconComponent',
  props: {
    count: {},
    color: {},
    tooltip: {},
    icon: {}
  },
  computed: {
    colorClass() {
      return this.color ? 'bg-' + this.color : 'bg-red-600'
    },
    iconTest() {
      return this.icon.substring(3)
    }
  }
}
</script>
