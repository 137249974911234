<template>
  <PageSection>
    <p>WikiCityCities</p>
  </PageSection>
</template>

<script>
import PageSection from "@c/PageSection.vue";

export default {
  name: "WikiCityCities",
  components: {PageSection}
}
</script>
