/* eslint-disable camelcase,no-unused-vars */

import apiClient from '@u/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  getGlobalSearchResult({ commit }, payload) {
    //commit('setSearchResult', payload)
    return new Promise((resolve, reject) => {
      apiClient
        .get(`search-global`, {
          params: {
            terms: payload.terms,
            longitude: payload.longitude,
            latitude: payload.latitude
          }
        })
        .then(async (response) => {
          commit('setSearchResult', response.data)
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  }
}
