export default {
    setInvoicingCategoryList(state, invoicingCategoryList) {
        state.invoicingCategoryList = invoicingCategoryList
    },
    setInvoicingCategory(state, invoicingCategory) {
        state.invoicingCategory = invoicingCategory
    },
    setMetadata(state, metadata) {
        state.metadata = metadata
    },
    setEditionMode(state, editionMode) {
        state.editionMode = editionMode
    },
}
