import mutationsVariante from './variante/mutations'

export default {
  ...mutationsVariante,

  setItemList(state, itemsList) {
    state.itemsList = itemsList
  },
  setItem(state, item) {
    state.item = item
  },
  setItemMetadata(state, metadata) {
    state.itemMetadata = metadata
  },
  setItemActivity(state, activity) {
    state.itemActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setSpecificInItem(state, payload) {
    state.item.specifics.push(  payload.values )
  },
  removeSpecificInItem(state, payload) {
    let temp = state.item.specifics;
    temp = temp.filter(o => o.funeralHomeId !== payload.funeralHome_id)
    state.item.specifics = temp
  },
  setCategorisedItemList(state, categorisedItemList) {
    state.categorisedItemList = categorisedItemList
  },
  setPacksList(state, packsList) {
    state.packsList = packsList
  },
  setErrorImport(state, errors) {
    state.errorImport = errors
  },
  setItemDefaultAccountingCodeName(state, default_accounting_code_name) {
    state.item.default_accounting_code_name = default_accounting_code_name
  },
  setItemNewMasterImageFile(state, new_master_image_file) {
    state.item.new_master_image_file = new_master_image_file
  },
  setItemsFuneralHomesSpecificAccountingCodeName(state, payload) {
    state.item.specifics[payload.id].specific_accounting_code_name =
      specific_accounting_code_name
  },

  //#region specific
  setItemSpecificAttribute(state, payload) {
    let temp = { visible: 1 }
    temp[payload.field] = payload.value

    if (payload.fields === 'specific_accounting_code') {
      temp['specific_accounting_code_name'] = payload.name
    }

    payload.funeral_homes.forEach((funeral_home) => {
      if(!state.item.specifics[funeral_home]) state.item.specifics[funeral_home] = {}
      return state.item.specifics[funeral_home] = Object.assign(
        state.item.specifics[funeral_home],
        temp
      )
    })
  },
  setItemSpecificTraduction(state, payload) {
    let temp = { visible: 1 }
    temp[payload.field] = payload.value[payload.field]

    payload.funeral_homes.forEach((funeral_home) => {
      return (state.item.specifics[funeral_home] = Object.assign(
        state.item.specifics[funeral_home],
        temp
      ))
    })
  },
  setItemSpecificPricing(state, payload) {
    let temp = { visible: 1 }
    if (payload.field === 'purchase_price') {
      temp['specific_purchase_price_htva'] = payload.htva
      temp['specific_purchase_price_tvac'] = payload.tvac
      temp['specific_purchase_price_tva'] = payload.tva
    } else {
      temp['specific_fixed_price_htva'] = payload.htva
      temp['specific_fixed_price_tvac'] = payload.tvac
      temp['specific_fixed_price_tva'] = payload.tva
    }

    payload.funeral_homes.forEach((funeral_home) => {
      if(!state.item.specifics[funeral_home]) state.item.specifics[funeral_home] = {}
      return state.item.specifics[funeral_home] = Object.assign(
        state.item.specifics[funeral_home],
        temp
      )
    })
  }
  //#endregion
}
