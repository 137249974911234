import getters from '@/store/modules/planning/getters'
import mutations from '@/store/modules/planning/mutations'
import actions from '@/store/modules/planning/actions'

export default {
  namespaced: true,
  state() {
    return {
      eventsList: [],
      event: {},
      eventActivity: {},
      focusEvent: null,
      eventMetadata: {},
      planningMetadata: [],
      eventDisponibilities: [],
      calendarState: null,
      date: null
    }
  },
  getters,
  actions,
  mutations
}
