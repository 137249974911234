<template>
  <div
    v-if="getGlobalFormEditionMode"
    class="fixed bottom-16 left-1/2 z-90 m-auto flex -translate-x-1/2 flex-row items-center gap-4 rounded-lg border border-slate-200 bg-white p-2 shadow-2xl"
  >
    <ConfirmModal ref="formInputConfigurator" />
    <div class="w-96 flex-1">
      <SelectFuneralHome
        v-model="localFuneralHome"
        edition-mode
        :label="''"
        error-key="no-error"
        required
        size="small"
      />
    </div>
    <div class="flex flex-shrink-0 flex-row gap-2">
      <BaseButton
        theme-color="bg-primary text-contrast-primary"
        icon="paper-plane"
        :title="$t('button.save')"
        @click="saveFormSettings"
        size="small"
      />
      <BaseButton
        theme-color="bg-primary text-contrast-primary"
        icon="paper-plane"
        :title="$t('button.save_for_all')"
        @click="saveFormSettingsForAll"
        size="small"
      />
      <BaseButton
        theme-color="bg-secondary text-contrast-secondary"
        icon="times"
        :title="$t('button.cancel')"
        @click="cancelFormSetting"
        size="small"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SelectFuneralHome from '@c/InitialStateFormComponent/SelectFuneralHome.vue'
import ConfirmModal from '@c/addf-package/components/modal/ConfirmModal.vue'

export default {
  name: 'FormInputConfigurator',
  components: { ConfirmModal, SelectFuneralHome, BaseButton },
  data() {
    return {
      originalFuneralHomeId: null,
      localFuneralHome: null
    }
  },
  computed: {
    ...mapGetters({
      funeralHomes: 'metadata/getFuneralHomesMetadata',
      getGlobalFormCurrentFormSettings:
        'metadata/getGlobalFormCurrentFormSettings',
      getGlobalFormFuneralHomeId: 'metadata/getGlobalFormFuneralHomeId',
      getGlobalFormEditionMode: 'metadata/getGlobalFormEditionMode'
    })
  },
  watch: {
    localFuneralHome(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.toggleGlobalFormEditionModeForFuneralHome({
          funeral_home_id: newValue
        })
      }
    },
    getGlobalFormEditionMode(newValue, oldValue) {
      if (newValue) {
        this.originalFuneralHomeId = this.getGlobalFormFuneralHomeId
        this.localFuneralHome = this.getGlobalFormFuneralHomeId
      } else {
        if (this.originalFuneralHomeId) {
          this.restoreGlobalForm({
            funeral_home_id: this.originalFuneralHomeId
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      toggleGlobalFormEditionMode: 'metadata/toggleGlobalFormEditionMode',
      updateFormConfigFuneralHomeId: 'metadata/updateFormConfigFuneralHomeId'
    }),
    ...mapActions({
      toggleGlobalFormEditionModeForFuneralHome:
        'metadata/toggleGlobalFormEditionModeForFuneralHome',
      processChangeSettingsKeyAction: 'settings/changeSettingsKey',
      restoreGlobalForm: 'metadata/restoreGlobalForm',
      commitFormSettingToInitialMetadata:
        'metadata/commitFormSettingToInitialMetadata'
    }),
    saveFormSettings() {
      this.processChangeSettingsKeyAction({
        key: 'form_settings',
        newVal: this.getGlobalFormCurrentFormSettings,
        funeralHomeId: this.getGlobalFormFuneralHomeId
      }).then((response) => {
        if (response.success) {
          this.$h.toastSuccess('ok')
          this.commitFormSettingToInitialMetadata({
            form_settings: this.getGlobalFormCurrentFormSettings,
            funeral_home_id: this.getGlobalFormFuneralHomeId
          })
        }
      })
    },
    async saveFormSettingsForAll() {
      let confirm = await this.$refs.formInputConfigurator.show({
        title: this.$t('TEST'),
        okButton: this.$t('global.yes')
      })
      if (confirm) {
        this.funeralHomes.forEach((funeralHome) => {
          this.processChangeSettingsKeyAction({
            key: 'form_settings',
            newVal: this.getGlobalFormCurrentFormSettings,
            funeralHomeId: funeralHome.id
          }).then((response) => {
            if (response.success) {
              this.$h.toastSuccess('enregistré pour ' + funeralHome.name)
              this.commitFormSettingToInitialMetadata({
                form_settings: this.getGlobalFormCurrentFormSettings,
                funeral_home_id: funeralHome.id
              })
            }
          })
        })
      }
    },
    cancelFormSetting() {
      this.toggleGlobalFormEditionMode(false)
    }
  }
}
</script>
