<template>
  <div class="flex flex-col gap-4 p-4">
    <!--region facturation-->
    <PageSection :title="$t('attributes.format')">
      <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <SelectComponent
            :label="$t('attributes.last_name')"
            v-model="funeralHome.settings.lastname_format"
            name="lastname_format"
            :values="lastnameFormatValues"
            :edition-mode="editionMode"
            :capitalize="false"
            required
          />
        </div>
        <div>
          <SelectComponent
            :label="$t('attributes.first_name')"
            v-model="funeralHome.settings.firstname_format"
            name="firstname_format"
            :values="firstnameFormatValues"
            :edition-mode="editionMode"
            :capitalize="false"
            required
          />
        </div>
        <div>
          <SelectComponent
            :label="$t('attributes.format')"
            v-model="funeralHome.settings.fullname_order"
            name="fullname_order"
            :values="fullnameOrderValues"
            :edition-mode="editionMode"
            :capitalize="false"
            required
          />
        </div>
      </div>
    </PageSection>
    <!--endregion-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageSection from '@c/PageSection.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'

export default {
  name: 'TheFuneralHomeSettingsPage',
  components: { SelectComponent, PageSection },
  data() {
    return {
      format: 'ucwords'
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      funeralHome: 'funeralHome/getFuneralHome',
      editionMode: 'funeralHome/getEditionMode'
    }),
    lastnameFormatValues() {
      return [
        {
          name: this.$h.convertToTitleCase(this.auth.last_name),
          id: 'ucwords'
        },
        {
          name: this.auth.last_name.toUpperCase(),
          id: 'uppercase'
        },
        {
          name: this.auth.last_name.toLowerCase(),
          id: 'lowercase'
        }
      ]
    },
    firstnameFormatValues() {
      return [
        {
          name: this.$h.convertToTitleCase(this.auth.first_name),
          id: 'ucwords'
        },
        {
          name: this.auth.first_name.toUpperCase(),
          id: 'uppercase'
        },
        {
          name: this.auth.first_name.toLowerCase(),
          id: 'lowercase'
        }
      ]
    },
    fullnameOrderValues() {
      return [
        {
          name: `${this.$h.convertToTitleCase(
            this.auth.first_name
          )} ${this.$h.convertToTitleCase(this.auth.last_name)}`,
          id: 'firstname_lastname'
        },
        {
          name: `${this.$h.convertToTitleCase(
            this.auth.last_name
          )} ${this.$h.convertToTitleCase(this.auth.first_name)}`,
          id: 'lastname_firstname'
        }
      ]
    }
  },
  methods: {}
}
</script>
