import attachmentCategoryGetters from './attachmentCategory/getters'

export default {
  ...attachmentCategoryGetters,

  getListItemsIndex(state) {
    return state.listItemsIndex
  },
  getListItem(state) {
    return state.listItem
  },
  getListItemMetadata(state) {
    return state.listItemMetadata
  },
  getEditionMode(state) {
    return state.editionMode
  }
}
