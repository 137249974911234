export default {
  setProperties(state, properties) {
    state.dossier.properties = properties
  },
  alterPropertiesToAddProfessionalContact(state, payload) {
    state.dossier.properties[payload.type] = payload.contact
  },
  alterDossierToAddPropertyField(state, payload) {
    state.dossier.properties[payload.attribute] = payload.value
  },
  removeContact(state, index) {
    state.dossier.contacts.splice(index, 1)
  }
}
