import TheDossierNumberingIndexPage from '@/views/settings/general/dossierNumbering/TheDossierNumberingIndexPage.vue'

const TheGeneralSettingsTabsComponent = () =>
  import('@/views/settings/general/TheGeneralSettingsTabsComponent')
const TheGeneralSettingsPage = () =>
  import('@/views/settings/general/tabs/general/TheGeneralSettingsPage.vue')
const TheEventSettingsPage = () =>
  import('@/views/settings/general/tabs/event/TheEventSettingsPage.vue')
const TheAttachmentSettingsPage = () =>
  import(
    '@/views/settings/general/tabs/attachment/TheAttachmentSettingsPage.vue'
  )

const DocumentEditor = () => import('@c/Document/editor/DocumentEditorPage.vue')

export const settingsGeneralRoutes = [
  {
    path: 'settings',
    name: 'settings_general_route',
    component: TheGeneralSettingsTabsComponent,
    redirect: { name: 'settings_general_items_route' },
    children: [
      {
        path: 'general',
        component: TheGeneralSettingsPage,
        name: 'settings_general_items_route'
      },
      {
        path: 'event',
        component: TheEventSettingsPage,
        name: 'settings_event_items_route'
      },
      {
        path: 'attachment',
        component: TheAttachmentSettingsPage,
        name: 'settings_attachment_items_route'
      }
    ],
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        {
          title: 'content_company_title',
          active: false
        },
        { title: 'content_company_new_title', active: true }
      ]
    }
  },
  {
    path: 'document-editor',
    children: [
      {
        path: ':document_type',
        children: [
          {
            path: '',
            props: true,
            name: 'settings_document_editor_new',
            component: DocumentEditor
          },
          {
            path: ':document_id',
            props: true,
            name: 'settings_document_editor_edit',
            component: DocumentEditor
          }
        ]
      }
    ]
  },
  {
    path: 'dossier-numbering',
    children: [
      {
        path: '',
        props: true,
        name: 'settings_dossier_numbering_list',
        component: TheDossierNumberingIndexPage
      }
    ]
  }
]
