<template>
  <TransitionRoot appear :show="localIsOpen" as="template">
    <Dialog
      as="div"
      class="relative"
      style="z-index: 9999"
      @close="localIsOpen = false"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="flex w-full max-w-xl transform flex-col gap-3 overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl shadow-primary-900 transition-all"
            >
              <DialogTitle
                as="h3"
                class="flex items-center gap-2 text-lg font-medium leading-6 text-gray-900 capitalize-first"
              >
                <FontAwesomeIcon :icon="icon" size="lg" />
                {{ $t('global.notification') }}
              </DialogTitle>
              <DialogDescription>
                <BaseSimpleTextArea
                  v-model="text"
                  :label="$t('attributes.compose')"
                  rows="5"
                />
                <div v-if="type === 'sms'" class="mt-2 flex">
                  <span
                    class="rounded border border-yellow-500 bg-yellow-200 px-1 py-0.5 text-xs"
                    >Message{{ countSms > 1 ? 's' : '' }} : {{ countSms }}</span
                  >
                </div>
              </DialogDescription>
              <div class="flex flex-row justify-end gap-2">
                <BaseButton
                  :title="$t('button.cancel')"
                  icon="times"
                  theme-color="bg-secondary text-contrast-secondary"
                  @click="localIsOpen = false"
                />
                <BaseButton
                  :title="$t('button.send')"
                  icon="paper-plane"
                  :disabled="text.length === 0 || sending"
                  :display-loader="sending"
                  @click="sendNotificationToUsers"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogDescription,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { mapActions, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseSimpleTextArea from '@c/BaseDynamicFormInput/BaseSimpleTextarea.vue'
import SmsCounter from 'sms-counter'

export default {
  name: 'NotificationComposeDialog',
  components: {
    BaseSimpleTextArea,
    FontAwesomeIcon,
    TransitionRoot,
    TransitionChild,
    BaseButton,
    Dialog,
    DialogTitle,
    DialogPanel,
    DialogDescription
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  emits: ['update:isOpen'],
  data() {
    return {
      text: '',
      title: '',
      sending: false
    }
  },
  computed: {
    localIsOpen: {
      get() {
        return this.isOpen
      },
      set(value) {
        this.$emit('update:isOpen', value)
      }
    },
    countSms() {
      return SmsCounter.count(this.text)?.messages ?? 0
    },
    icon() {
      switch (this.type) {
        case 'sms':
          return ['fal', 'message-sms']
        case 'slack':
          return ['fab', 'slack']
        case 'email':
          return ['fal', 'envelope']
        case 'toast':
          return ['fal', 'bell']
        default:
          return ['fal', 'envelope']
      }
    }
  },
  methods: {
    ...mapActions({
      processSendNotificationToUsersAction:
        'notification/sendNotificationToUsers'
    }),
    sendNotificationToUsers() {
      this.sending = true
      this.processSendNotificationToUsersAction({
        via: [this.type],
        user_ids: [this.userId],
        title: this.title,
        text: this.text
      })
        .then((response) => {
          if (response.success) {
            this.title = ''
            this.text = ''
            this.localIsOpen = false
          }
        })
        .finally((_) => (this.sending = false))
    }
  }
}
</script>
