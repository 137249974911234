<template>
  <template v-if="editionMode">
    <div class="">
      <label class="flex items-center">
        <BaseEditLabel :label="label" :required="required" />
        <input
          v-model="internalValue"
          name="is_company"
          type="checkbox"
          class="ml-2 h-4 w-4 rounded border-gray-500 text-blue-300"
          :class="disabled ? 'bg-gray-200' : ''"
          :disabled="!editionMode || disabled"
        />
      </label>
    </div>
    <template v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </template>
  <template v-else>
    <BaseShowLabel
      :label="label"
      :model-value="displayedValueWhenNotInEditMode"
    />
  </template>
</template>

<script>
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel'
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel'
export default {
  name: 'BaseShowEditCheckBoxSimple',
  components: { BaseEditLabel, BaseShowLabel },
  props: {
    modelValue: {
      type: [Boolean, Number, String],
      required: false,
      default: null
    },
    name: { type: String, required: true },
    label: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Object, required: false, default: () => ({}) },
    isVertical: { type: Boolean, required: false, default: false },
    editionMode: {
      type: Boolean,
      required: true
    }
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      internalValue: false,
      options: { 1: true, 0: false }
    }
  },
  computed: {
    cModelValue() {
      return Boolean(this.modelValue)
    },
    cPlacement() {
      return this.isVertical ? '' : 'flex flex-col sm:flex-row mt-2'
    },
    displayedValueWhenNotInEditMode() {
      return this.$t(`enums.yesNo.${this.cModelValue}`)
    }
  },
  watch: {
    cModelValue: {
      handler(newValue) {
        this.internalValue = Boolean(newValue)
      },
      immediate: true,
      deep: true
    },
    internalValue(newValue, oldValue) {
      // console.log('change', newValue)
      if (newValue !== oldValue) {
        this.$emit('update:modelValue', Boolean(newValue))
        this.$emit('change', Boolean(newValue))
      }
    }
  }
}
</script>
