import apiClient from '@u/apiClient'
import router from '@/router'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    if ('new_id' in payload) {
      payload.successCallback(payload.new_id)
    } else {
      payload.successCallback()
    }
  }
}

export default {
  // region fetch

  fetchRoleList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('role')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewRole({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=role')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchRoleById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`role/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storeNewRole: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`/role`, payload.role)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateRole: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .put(`/role/${payload.id}`, payload.role)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  deleteRoleById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`/role/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  undoDeleteRoleById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`/role/undo-delete/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion
}
