import TheManagementHomeTabsComponent from '@/views/sa_management/general/TheManagementHomeTabsComponent.vue'
import TheSAMCommandsPage from "@/views/sa_management/commands/TheSAMCommandsPage.vue";
import TheSAMDatabasePage from "@/views/sa_management/database/TheSAMDatabasePage.vue";
import TheGeneralSaManagementsPage from "@/views/sa_management/general/tabs/general/TheGeneralSaManagementsPage.vue";
import TheEventSettingsPage from "@/views/sa_management/general/tabs/event/TheEventSettingsPage.vue";

export const saManagementGeneralRoutes = [
    {
        path: '/sa-management',
        name: 'management_general_route',
        component: TheManagementHomeTabsComponent,
        redirect: {name: 'sa_management_home_route'},
        children: [
            {
                path: '',
                component: TheGeneralSaManagementsPage,
                name: 'sa_management_home_route'
            },
            // {
            //     path: 'event',
            //     components: TheEventSettingsPage,
            //     name: 'settings_event_items_route'
            // },
        ],
        meta: {
            breadcrumb: [
                {title: 'content_title', active: false},
                {
                    title: 'content_company_title',
                    active: false
                },
                {title: 'content_company_new_title', active: true}
            ]
        }
    },
    {
        path: '/sa-management/commands',
        name: 'management_commands_route',
        component: TheSAMCommandsPage,
        children: []
    }, {
        path: '/sa-management/database',
        name: 'management_db_route',
        component: TheSAMDatabasePage,
        children: []
    }
]
