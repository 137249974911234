<template>
  <div :class="[cGroupClass]">
    <template v-if="editionMode">
      <div class="fm-form-input" :class="label ? 'mt-2' : ''">
        <label v-if="label">
          {{ label }}
          <span v-if="label && required" class="text-red-600">*</span>
        </label>
        <div class="relative rounded-md">
          <input
            v-model="localModelValue"
            :disabled="disabled"
            :class="[disabled ? 'text-gray-500' : '', sizeClass]"
            class="rounded-md"
          />
          <Listbox v-model="localBrut">
            <Float placement="bottom-end" :offset="5">
              <ListboxButton
                class="absolute inset-y-0 right-0 text-gray-500"
                tabindex="-1"
              >
                <FontAwesomeLayers class="mr-4">
                  <FontAwesomeIcon :icon="['far', 'font-case']" />
                  <FontAwesomeIcon
                    v-if="brut"
                    data-tint="crimson"
                    :icon="['fas', 'circle-xmark']"
                    transform="shrink-3 down-8 right-12"
                    class="text-primary"
                  />
                </FontAwesomeLayers>
              </ListboxButton>
              <ListboxOptions
                class="flex flex-col gap-1 rounded border bg-white p-1 shadow-xl"
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  :value="false"
                  as="template"
                >
                  <li
                    class="flex cursor-pointer flex-row items-center gap-1 rounded bg-primary-200 px-2 py-1"
                    :class="selected ? 'bg-primary-200' : 'bg-white'"
                  >
                    <FontAwesomeLayers class="mr-3">
                      <FontAwesomeIcon :icon="['far', 'font-case']" />
                    </FontAwesomeLayers>
                    <div class="capitalize-first">
                      {{ $t('button.format_automatically') }}
                    </div>
                  </li>
                </ListboxOption>
                <ListboxOption
                  v-slot="{ active, selected }"
                  :value="true"
                  as="template"
                >
                  <li
                    class="flex cursor-pointer flex-row items-center gap-1 rounded bg-primary-200 px-2 py-1"
                    :class="selected ? 'bg-primary-200' : 'bg-white'"
                  >
                    <FontAwesomeLayers class="mr-3">
                      <FontAwesomeIcon :icon="['far', 'font-case']" />
                      <FontAwesomeIcon
                        data-tint="crimson"
                        :icon="['fas', 'circle-xmark']"
                        transform="shrink-3 down-8 right-12"
                        class="text-primary"
                      />
                    </FontAwesomeLayers>
                    <div class="capitalize-first">
                      {{ $t('button.keep_as_is') }}
                    </div>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </Float>
          </Listbox>
        </div>
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>

    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="modelValue"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

<script>
import BaseShowLabel from '../BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'

export default {
  name: 'BaseShowEditInputNameFormat',
  components: {
    Float,
    ListboxOption,
    ListboxOptions,
    ListboxButton,
    Listbox,
    FontAwesomeLayers,
    FontAwesomeIcon,
    ErrorContainer,
    BaseShowLabel
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    modelValue: {
      type: String,
      default: '',
      require: true
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      default: '',
      required: false
    },
    brut: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'update:brut'],
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    localBrut: {
      get() {
        return this.brut
      },
      set(value) {
        this.$emit('update:brut', value)
      }
    },
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === '' ? 'form-control' : this.inputClass
    },
    sizeClass() {
      switch (this.size) {
        case 'extra-small':
          return '!px-2 !py-1 text-xs'
        case 'small':
          return '!px-3 !py-1.5 text-sm'
        case 'large':
          return '!px-5 !py-3'
        case 'medium':
        default:
          return '!px-4 !py-2'
      }
    }
  }
}
</script>
