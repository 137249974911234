<template>
  <div class="flex min-h-0 flex-1 flex-col gap-2">
    <div class="shrink-0 px-3">
      <ThreadTopBarComponent
        @action:answer="makeAnswer"
        @action:share="makeShare"
      />
    </div>
    <div class="flex min-h-0 flex-1 flex-col gap-3 overflow-y-auto px-3 pb-3">
      <MessageComponent
        v-for="message in threadMessages"
        :key="message.id"
        :model-notification="message"
        @message-updated="refetchMessage"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import MessageComponent from '@/views/modelNotification/index/components/MessageComponent.vue'
import ThreadTopBarComponent from '@/views/modelNotification/index/components/ThreadTopBarComponent.vue'

export default defineComponent({
  name: 'ThreadComponent',
  components: { ThreadTopBarComponent, MessageComponent },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      threadMessages: []
    }
  },
  watch: {
    groupId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchMessages(newValue)
      }
    },
    '$route'(params) {
      const newMessage = this.threadMessages?.filter((msg) => !msg.id)

      if (newMessage?.length) {
        this.fetchMessages(this.groupId)
      }
    }
  },
  mounted() {
    this.fetchMessages(this.groupId)
  },
  methods: {
    ...mapActions({
      processFetchThreadAction: 'modelNotification/fetchThreadByGroupId',
      processFetchThreadByGroupIdForAnswer: 'modelNotification/fetchThreadByGroupIdForAnswer'
    }),
    fetchMessages(groupId) {
      this.loading = true
      this.processFetchThreadAction({ groupId }).then((response) => {
        this.threadMessages = response.data.model_notifications
        this.loading = false
      })
    },
    refetchMessage() {
      this.fetchMessages(this.groupId)
    },
    makeAnswer(response) {
      this.loading = true
      this.processFetchThreadByGroupIdForAnswer({
        groupId: this.groupId,
        type: 'answer'
      })
        .then((response) => {
          this.threadMessages = response.model_notifications
          this.loading = false
        })
    },
    makeShare(response) {
      this.loading = true
      this.processFetchThreadByGroupIdForAnswer({
        groupId: this.groupId,
        type: 'share'
      })
        .then((response) => {
          this.threadMessages = response.model_notifications
          this.loading = false
        })
    }
  }
})
</script>
