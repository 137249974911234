<template>
  <div class="flex-1">
    <TabContentFormComponent
        :page-header="false"
        :main-button-display-condition="mainButtonDisplayCondition"
        :main-button-function="SubmitForm"
        main-button-icon="paper-plane"
        :main-button-title="
        $t('button.save_plural', { var: $t('global.general_settings') })
      "
        :tabs="tabs"
        :with-footer="false"
    />
  </div>
</template>

<script>
import TabContentFormComponent from '@c/TabContentFormComponent'

export default {
  name: 'TheGeneralSettingsTabsComponent',
  components: {TabContentFormComponent},
  computed: {
    tabs() {
      return [
        // new
        {
          label: this.$t('global.general'),
          routeName: `sa_management_home_route`,
          gate: true
        },
        // {
        //   label: this.$t('global.events') + 'rrrr',
        //   routeName: `settings_event_items_route`,
        //   gate: true
        // }
      ]
    },
    mainButtonDisplayCondition() {
      return true
    },

  },

  methods: {
    // ...mapActions({
    //   processUpdateUserAction: 'user/updateUser',
    //   processStoreNewUserAction: 'user/storeNewUser',
    //   processFetchUserByIDAction: 'user/fetchUserByID',
    //   processFetchNewUserAction: 'user/fetchNewUser'
    // }),
    // ...mapMutations({
    //   processSetEditionModeMutation: 'user/setEditionMode'
    // }),
    switchEditMode() {
      if (this.editionMode === true) {
        // fetch les settings general
      }
      this.processSetEditionModeMutation(!this.editionMode)
    },
    SubmitForm() {
      console.log('submitForm')
    }
  }
}
</script>
