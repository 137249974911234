import apiClient from '@u/apiClient'

// const base_errors = { deceased: {}, events: [] }
const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  addCommentToTaskDossier: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`task-dossier/comment/${payload.task_dossier_id}`, {
          text: payload.text
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  }
}
