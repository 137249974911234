const DocTypes = Object.freeze({
  QUOTE: 'QUO',
  PURCHASE_ORDER: 'PURO',
  SUPPLIER_ORDER: 'SUPORD',
  INVOICE: 'INV',
  INVOICE_PROFORMA: 'INV_PRO',
  CREDIT_NOTE: 'CN',
  TRANSFERT_STOCK: 'TRS',
})

export default DocTypes
