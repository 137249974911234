<template>
  <div
    id="generic_toast"
    class="pointer-events-auto hidden w-72 max-w-sm overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <FontAwesomeIcon
            :icon="['far', 'circle-check']"
            class="toast-icon-success hidden h-5 w-5 text-green-400"
          />
          <FontAwesomeIcon
            :icon="['fal', 'triangle-exclamation']"
            class="toast-icon-warning hidden h-5 w-5 text-yellow-500"
          />
          <FontAwesomeIcon
            :icon="['fal', 'circle-exclamation']"
            class="toast-icon-danger hidden h-5 w-5 text-red-500"
          />
          <FontAwesomeIcon
            :icon="['fal', 'circle-info']"
            class="toast-icon-info hidden h-5 w-5 text-green-500"
          />
        </div>
        <div class="ml-3 w-0 flex-1">
          <p class="toast-title text-sm font-medium text-gray-900"></p>
          <p class="toast-description mt-1 hidden text-sm text-gray-500"></p>
        </div>
      </div>
    </div>
  </div>
  <div
    id="unsaved_change_notification"
    class="flex hidden gap-3 rounded-xl border border-gray-300 bg-white p-5 shadow-lg"
  >
    <FontAwesomeIcon
      :icon="['fal', 'circle-exclamation']"
      size="2x"
      class="text-red-500"
    />
    <div class="">
      <div class="font-bold capitalize-first">
        {{ $t('global.there_is_unsaved_changes') }}
      </div>
      <div class="mt-2.5 flex gap-x-2">
        <BaseButton
          class="btn_stay_page"
          :title="$t('button.stay_page')"
          color="info"
          size="small"
        />
        <BaseButton
          class="btn_next_page"
          :title="$t('button.next_page')"
          color="danger"
          size="small"
        />
      </div>
    </div>
  </div>
  <div
    id="toast_action_content"
    class="pointer-events-auto hidden w-72 overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="icon-container flex-shrink-0">
          <FontAwesomeIcon
            class="toast-icon-info hidden h-5 w-5 text-gray-400"
            :icon="['fal', 'circle-info']"
          />
          <FontAwesomeIcon
            class="toast-icon-delete hidden h-5 w-5 text-gray-400"
            :icon="['fal', 'trash']"
          />
        </div>
        <div class="-mt-1 ml-3 w-0 flex-1">
          <p class="toast-title text-sm font-medium text-gray-900"></p>
          <p class="toast-description mt-1 text-sm text-gray-500"></p>
          <div class="toast-cta-container mt-3 flex space-x-7">
            <button
              type="button"
              class="template-button hidden rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
            >
              ##TITLE##
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'NotificationsContainer',
  components: { FontAwesomeIcon, BaseButton }
}
</script>
