export default {
  setCities(state, cities) {
    state.cities = cities
  },
  setClosestCities(state, cities) {
    state.closestCities = cities
  },
  setFavoriteCities(state, cities) {
    state.favoriteCities = cities
  },
  setCity(state, city) {
    state.city = city
  },

  setCityId(state, city_id) {
    state.city_id = city_id
  }
}
