<template>
  <SelectComponent
    v-model="localModelValue"
    searchable
    :values="countries"
    :label="$t('attributes.country')"
    :required="required"
    name="country"
    :errors="errors"
    :favorite="favoriteCountries"
    :edition-mode="editionMode"
  >
    <template #option="{ option, selected, active }">
      <span
        class="flex flex-row items-center gap-2 truncate"
        :class="{ 'font-medium': selected, 'font-normal': !selected }"
      >
        <span class="shrink-0">
          <img
            class="h-4 w-6 rounded"
            :src="CountryFlags[option.id.toUpperCase()]"
            :alt="option.id"
          />
        </span>
        <span class="flex-1">{{ option.name }}</span>
      </span>
    </template>
  </SelectComponent>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectComponent from '@c/BaseFormComponent/SelectComponent'
import Country from '@/assets/enums/config/Country'
import CountryFlags from '@/assets/enums/config/CountryFlags'

export default {
  name: 'SelectCountries',
  components: { SelectComponent },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    modelValue: {
      type: [Array, Number],
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    CountryFlags() {
      return CountryFlags
    },
    ...mapGetters({
      countries: 'metadata/getCountriesMetadata',
      default_country: 'metadata/getDefaultCountry',
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    favoriteCountries() {
      if(this.initialMetadata?.settings?.favorites_countries?.length)  {
        return this.initialMetadata?.settings?.favorites_countries;
      }else{
        switch (this.default_country) {
            case Country.BELGIUM:
              return [
                Country.BELGIUM,
                Country.FRANCE,
                Country.LUXEMBOURG,
                Country.GERMANY,
                Country.NETHERLANDS
              ]
            case Country.FRANCE:
              return [Country.FRANCE, Country.BELGIUM, Country.SPAIN, Country.ITALY]
            case Country.SWITZERLAND:
              return [
                Country.SWITZERLAND,
                Country.FRANCE,
                Country.LUXEMBOURG,
                Country.GERMANY,
                Country.ITALY
              ]
            default:
              return undefined
          }
      }
    }
  }
}
</script>
