<template>
  <div v-if="city_id" class="flex h-full flex-row">
    <div class="w-72">
      <TheWikiCityFormNav :city-id="city_id" />
    </div>
    <div class="flex-1">
      <div class="-mr-5 h-full max-h-full">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script>
import TheWikiCityFormNav from '@/views/wiki/communes/form/components/TheWikiCityFormNav.vue'
import { mapActions } from 'vuex'

export default {
  name: 'TheWikiCityFormLayout',
  components: { TheWikiCityFormNav },
  props: {
    city_id: {
      type: String,
      required: true
    }
  },
  watch: {
    city_id(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchCity()
      }
    }
  },
  beforeMount() {
    this.fetchCity()
  },
  methods: {
    ...mapActions({
      processFetchCityByIdAction: 'wiki/fetchCityById'
    }),
    fetchCity() {
      this.processFetchCityByIdAction({ city_id: this.city_id })
    }
  }
}
</script>
