import permissionsMutations from './modules/permissions/mutations'

export default {
  ...permissionsMutations,

  setUserList(state, usersList) {
    state.usersList = usersList
  },
  setUser(state, user) {
    state.user = user
  },
  setUserPermissions(state, permissions) {
    state.user.permissions = permissions
  },
  setUserMetadata(state, metadata) {
    state.metadata = metadata
  },
  setUserMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setUserActivities(state, activities) {
    state.userActivities = activities
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  fillCompaniesId(state, payload) {
    payload.map((company) => {
      if (!state.user.companies_id.includes(company.id)) {
        state.user.companies_id.push(company.id)
      }
    })
  },
  cleanCompaniesIdAndFuneralHomesId(state) {
    state.user.companies_id = []
    state.user.funeral_homes_id = {}
  },
  setErrorImport(state, errors) {
    state.errorImport = errors
  },
  alterCompaniesId(state, payload) {
    if (state.user.companies_id.indexOf(payload.company.id) === -1) {
      state.user.companies_id.push(payload.company.id)
    } else {
      state.user.companies_id.splice(
        state.user.companies_id.indexOf(payload.company.id),
        1
      )

      const asArray = Object.entries(state.user.funeral_homes_id)
      const filtered = asArray.filter(
        ([fh_id, fh]) =>
          payload.funeral_homes.find(
            (funeral_home) => funeral_home.id === fh_id
          ).company_id !== payload.company.id
      )

      state.user.funeral_homes_id = Object.fromEntries(filtered)
    }
  },
  alterFuneralHomesId(state, payload) {
    if (!state.user.funeral_homes_id[payload.id]) {
      state.user.funeral_homes_id[payload.id] = {
        favorite: false,
        edition: false
      }
    } else {
      delete state.user.funeral_homes_id[payload.id]
    }
  },
  fillFuneralHomesId(state, payload) {
    payload.map((fh) => {
      let allUserFuneralHomesId = Object.keys(state.user.funeral_homes_id).map(
        (id) => parseInt(id)
      )
      if (!allUserFuneralHomesId.includes(fh.id)) {
        state.user.funeral_homes_id[fh.id] = { favorite: false, edition: false }
      }
    })
  },
  cleanFuneralHomesId(state) {
    state.user.funeral_homes_id = {}
  }
}
