<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row items-center gap-2">
      <WalkmanButton
        v-if="Array.isArray(suggestedPeople) && suggestedPeople.length"
        v-model="current_list"
        :options="[
          { value: 'suggested', label: $t('global.dossier') },
          { value: 'all', label: $t('global.all') }
        ]"
      />
      <BaseSearchInput
        v-if="current_list === 'suggested'"
        v-model="filter_text_local"
      />
      <BaseSearchInput
        v-else-if="current_list === 'all'"
        v-model="search_text"
      />
    </div>
    <template v-if="filteredPeople.length < 1">
      <div class="mx-auto mt-12 text-center text-slate-300">
        {{ $t('global.there_is_no_yet', { settings: $t('global.contact') }) }}
      </div>
    </template>
    <template v-else>
      <div class="grid grid-cols-2 gap-4">
        <PersonCard
          v-for="filteredPerson in filteredPeople"
          :key="filteredPerson.id"
          :person="filteredPerson"
          :selected="personId === filteredPerson.id"
        >
          <template #cta>
            <div class="flex flex-row gap-1">
              <FontAwesomeIcon
                :icon="['fal', 'circle-check']"
                size="xl"
                class="cursor-pointer text-green-500"
                @click.stop="handleSelectPerson(filteredPerson)"
              />
              <FontAwesomeIcon
                :icon="['fal', 'pen-circle']"
                size="xl"
                class="cursor-pointer text-yellow-500"
                @click.stop="handleEditPerson(filteredPerson)"
              />
            </div>
          </template>
        </PersonCard>
      </div>
    </template>
  </div>
</template>

<script>
import WalkmanButton from '@c/WalkmanButton'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PersonCard from '@ui/modal/Person/components/selectPersonModal/component/PersonCard.vue'
import BaseSearchInput from '@c/addf-package/components/BaseInput/BaseSearchInput.vue'
import { debounce } from '@u/debounce'
import { mapActions } from 'vuex'

export default {
  name: 'SelectPersonModalList',
  components: {
    BaseSearchInput,
    PersonCard,
    FontAwesomeIcon,
    BaseButton,
    WalkmanButton
  },
  props: {
    personId: {
      type: String,
      required: false,
      default: null
    },
    person: {
      type: Object,
      required: false,
      default: null
    },
    suggestedPeople: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: [
    'update:person',
    'update:person-id',
    'selectPerson',
    'editPerson',
    'selected'
  ],
  data() {
    return {
      contacts: [],
      filter_text_local: '',
      search_text: '',
      current_list: 'dossier'
    }
  },
  computed: {
    localPerson: {
      get() {
        return this.person
      },
      set(value) {
        this.$emit('update:person', value)
      }
    },
    localPersonId: {
      get() {
        return this.personId
      },
      set(value) {
        this.$emit('update:person-id', value)
      }
    },
    filteredPeople() {
      if (
        this.current_list === 'suggested' &&
        Array.isArray(this.suggestedPeople)
      ) {
        return this.suggestedPeople.filter((person) =>
          person.full_name.includes(this.filter_text_local)
        )
      }
      return this.contacts
    }
  },
  created() {
    this.debouncedSearch = debounce((term) => {
      if (term && term.length >= 3) {
        this.loading = true
        this.processFetchPeopleAction({
          params: {
            term: term
          }
        })
          .then((response) => (this.contacts = response.people))
          .finally(() => (this.loading = false))
      } else if (!term || term.length === 0) {
        this.items = null
      }
    }, 1000)
  },
  watch: {
    search_text() {
      this.debouncedSearch(this.search_text)
    }
  },
  mounted() {
    if (this.suggestedPeople?.length) {
      this.current_list = 'suggested'
    } else {
      this.current_list = 'all'
    }
  },
  methods: {
    ...mapActions({
      processFetchPeopleAction: 'person/fetchPersonList'
    }),
    handleSelectPerson(person) {
      this.localPerson = person
      this.localPersonId = person?.id
      this.$emit('selected')
    },
    handleEditPerson(person) {
      this.$emit('editPerson', person)
    }
  }
}
</script>
