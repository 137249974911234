<template>
  <tr :class="[(selected ? 'bg-gray-50 text-blue-300' : ''), (invoiceable.is_deceased ? 'text-gray-500' : 'text-gray-900 ')]">
    <td
      class="flex cursor-pointer flex-row items-center gap-1 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6"
      @click="modalOpened = true"
    >
      <div class="rounded bg-gray-200 px-1 py-0.5">
        <FontAwesomeIcon :icon="['fal', 'circle-info']" fixed-width />
      </div>
      <div>{{ invoiceable.full_name }}</div>
      <ShowInvoiceableModal
        v-model:open="modalOpened"
        :invoiceable-id="invoiceable.id"
      />
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm"
      :class="invoiceable.is_deceased ? 'text-gray-500' : 'text-gray-900 '"
    >
      {{ address }}
    </td>
    <td
      class="flex flex-row justify-end gap-2 whitespace-nowrap py-4 text-center text-sm font-medium sm:pr-6"
    >
      <slot name="person-cta">
        <BaseButton
          :title="'select'"
          size="extra-small"
          :disabled="invoiceable.is_deceased"
          :color="invoiceable.is_deceased ? 'secondary' : 'primary'"
          @click="$emit('selection')"
        />
      </slot>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import ShowInvoiceableModal from './ShowInvoiceableModal.vue'

export default defineComponent({
  name: 'InvoiceableRow',
  components: { ShowInvoiceableModal, BaseButton, FontAwesomeIcon },
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    invoiceable: {
      type: Object,
      required: true
    }
  },
  emits: ['selection'],
  data() {
    return {
      modalOpened: false
    }
  },
  computed: {
    address() {
      const street = this.invoiceable.street ? this.invoiceable.street : '';
      const zip = this.invoiceable.zip ? this.invoiceable.zip : '';
      const city = this.invoiceable.city ? this.invoiceable.city : '';

      return `${street}${street && zip ? ' ' : ''}${zip}${(street || zip) && city ? ' ' : ''}${city}`
    }
  },
})
</script>
