<template>
  <div :class="cGroupClass">
    <label
      :class="cLabelClass"
      class="relative left-2 top-4 z-10 inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
      >{{ label }}&nbsp;<span v-if="isRequired" class="text-red-600"
        >*</span
      ></label
    >
    <multiselect
      v-model="selectedValue"
      :options="options"
      :placeholder="placeholder"
      :searchable="searchable"
      :label="attributeLabel"
      :track-by="trackBy"
      :disabled="disabled"
      :required="required"
      :internal-search="internalSearch"
      @update:model-value="updateValueAction"
    />
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import { computed } from 'vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseSelectSimple',
  components: {
    ErrorContainer,
    multiselect
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    selectClass: { type: String, required: false, default: '' },
    errors: {
      type: Object,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    internalSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    fullModelResponse: { type: Boolean, required: false, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return { selectedValue }
  },
  computed: {
    isDisabled() {
      return this.disabled
    },
    isRequired() {
      return this.required
    },
    cGroupClass() {
      return this.groupClass === '' ? 'mt-3' : this.groupClass
    },
    cLabelClass() {
      return this.labelClass === '' ? 'form-label' : this.labelClass
    }
  },
  methods: {
    updateValueAction(newValue) {
      console.log('newValue', newValue)
      if (newValue) {
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
        } else {
          this.$emit('update:modelValue', newValue[this.trackBy])
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('update:modelValue', null)
        this.$emit('change', null)
      }
    }
  }
}
</script>
