<template>
  <div v-if="editionMode" :class="cGroupClass">
    <div
      class="relative rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
      :class="label ? 'mt-2' : ''"
    >
      <label
        v-if="label"
        :for="name"
        class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
        >{{ label }}&nbsp;
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <input
        :id="name"
        v-mask="mask"
        :name="name"
        type="text"
        :value="modelValue"
        :class="cInputClass"
        :placeholder="placeholder"
        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        @input="updateInput"
      />
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
  <div v-else>
    <BaseShowLabel :label="label" :model-value="modelValue" />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import BaseEditLabel from '../BaseLabel/BaseEditLabel.vue'
import BaseShowLabel from '../BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
export default {
  name: 'BaseShowEditTimeInput',
  directives: { mask },
  components: { ErrorContainer, BaseEditLabel, BaseShowLabel },
  props: {
    editionMode: { type: Boolean, required: true },
    name: { type: String, required: true },
    required: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    groupClass: { type: String, required: false, default: '' },
    mask: { type: String, required: false, default: '##:##' },
    inputClass: { type: String, required: false, default: '' },
    errors: { type: Object, required: false },
    modelValue: { type: [String, Number], default: '', require: true }
  },
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === '' ? '' : this.inputClass
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
