<template>
  <GoogleMap
    :api-key="googleMapApiKey"
    style="width: 100%; height: 275px"
    :center="center"
    :disable-default-ui="true"
    :keyboard-shortcuts="false"
    gesture-handling="none"
    :zoom="15"
  >
    <Marker v-if="markerAtCenter" :options="{ position: center }" />
    <template v-if="markers">
      <template v-for="(marker, index) in markers" :key="index">
        <Marker
          v-if="markerAtCenter"
          :options="{
            position: { lat: marker.latitude, lng: marker.longitude }
          }"
        />
      </template>
    </template>
  </GoogleMap>
</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map'

export default {
  name: 'BaseMapComponent',
  components: { GoogleMap, Marker },
  props: {
    width: {
      type: [String, Number],
      required: false,
      default: 200
    },
    height: {
      type: [String, Number],
      required: false,
      default: 200
    },
    latitude: {
      type: [Number, String],
      required: true
    },
    longitude: {
      type: [Number, String],
      required: true
    },
    markerAtCenter: {
      type: Boolean,
      required: false,
      default: true
    },
    markers: {
      type: Array,
      required: false,
      default: null
    }
  },
  computed: {
    center() {
      return { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) }
    },
    googleMapApiKey() {
      return import.meta.env.VITE_GOOGLE_MAP_API_KEY
    }
  }
}
</script>
