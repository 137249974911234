<template>
  <div>
    <RadioGroup v-model="modelValue" class="mt-2">
      <div class="grid grid-cols-1 gap-3 sm:grid-cols-3 mb-8">
        <template
          v-for="(option, index) in options"
          :key="index"
        >
          <RadioGroupOption
            v-if="option.gate"
            as="template"
            :value="option[trackBy]"
            :disabled="!option[disableAttribute]"
            v-slot="{ active, checked }"
          >
            <div :class="[
              option[disableAttribute] ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
              checked ?
              'bg-blue-100 border-transparent text-gray-600 hover:bg-blue-200' : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50',
              'border rounded-md py-2 px-2 flex items-center justify-center text-sm font-medium sm:flex-1 font-bold'
              ]">
              <RadioGroupLabel as="div">
                <span class="capitalize-first">{{ option[attributeLabel] }}</span>
              </RadioGroupLabel>
            </div>
          </RadioGroupOption>
        </template>
      </div>
    </RadioGroup>
  </div>
</template>

<script >
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  name: "BaseModalTabs",
  components: { RadioGroup, RadioGroupLabel, RadioGroupOption },
  props: {
    modelValue: {
      type: [Number, String],
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    disableAttribute: {
      type: String,
      required: false,
      default: 'active'
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      internalValue: null
    }
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue){
        if(newValue !== oldValue){
          this.internalValue = newValue
        }
      },
      immediate: true,
      deep:true,
    },
    internalValue(newValue, oldValue){
      if(newValue !== oldValue){
        this.$emit('update:modelValue', this.internalValue)
      }
    }
  },
}
</script >

