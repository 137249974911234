export default {
  getRoleList(state) {
    return state.roleList
  },
  getRoleMetadata(state) {
    return state.roleMetadata
  },
  getRole(state) {
    return state.role
  }
}
