<template>
  <BaseDossierPageLayout
    :title="$t('global.site')"
    :submit-method="submitForm"
    :with-back-button="true"
    :with-edition-mode-button="false"
    :display-submit-button-without-edition-mode="true"
    back-route="dossier_form_edit_route"
  >
    <template v-if="dossier" #default>
        <div class="flex flex-col gap-4 p-4 mt-10">
          <PageSection >

            <template #default>
              <div class="flex justify-start">
                <div class="mr-3 text-lg font-medium">{{ $t('attributes.publish_on_website') }}</div>
                <div>
                  <BaseSwitch
                    v-if="dossier.properties"
                    v-model="dossier.properties.publish_on_website"
                    class="cursor-pointer"
                    name="publish_on_website"
                  />
                </div>
              </div>
              <div  class="grid grid-cols-1 px-2">
                <ParticularityWysiwygComponent
                  v-if="dossier.properties.publish_on_website"
                  :edition-mode="true"
                  :model-value="dossier.properties.publish_on_website_remarks"
                  :label="$t('attributes.remarks')"
                  name="publish_on_website_remarks"
                  @get-input-value="handleGetInputValue"
                />
              </div>
            </template>
          </PageSection>
          <PageSection v-if="dossier.properties.publish_on_website" >
            <template  #default>
              <div class="flex justify-start">
                <div class="mr-3 text-lg font-medium">{{ $t('attributes.allow_condolences') }}</div>
                <div>
                  <BaseSwitch
                    v-if="dossier.properties"
                    v-model="dossier.properties.allow_condolences"
                    class="cursor-pointer"
                    name="allow_condolences"
                    :errors="errors?.allow_condolences"
                  />
                </div>
              </div>
              <div v-if="dossier.properties.allow_condolences" class="grid grid-cols-2 px-2 gap-x-5 my-5">
                <BaseShowEditInput
                  v-model="dossier.properties.email_condolences"
                  :label="$t('attributes.email_condolences')"
                  :edition-mode="true"
                  name="email_condolences"
                  :errors="errors?.email_condolences"
                />
                <div>
                  <BaseShowEditDatePicker
                    v-model:date="dossier.properties.condolences_start_date"
                    :label="$t('attributes.condolences_start_date')"
                    :edition-mode="true"
                    :today-default="true"
                    name="condolences_start_date"
                    :errors="errors?.condolences_start_date"
                  />
                </div>
              </div>
            </template>
          </PageSection>
          <PageSection v-if="dossier.properties.publish_on_website">
            <template #default>
              <div  class="grid grid-cols-2 px-2 gap-x-5 my-5">
                <div>
                  <BaseShowEditDatePicker
                    v-model:date="dossier.properties.flowers_order_limit"
                    :label="$t('attributes.flowers_order_limit')"
                    :edition-mode="true"
                    :today-default="true"
                    name="flowers_order_limit"
                    :errors="errors?.flowers_order_limit"
                  />
                </div>
                <BaseShowEditInput
                  v-model="dossier.properties.donations_kitty_url"
                  :label="$t('attributes.donations_kitty_url')"
                  :edition-mode="true"
                  name="donations_kitty_url"
                  :errors="errors?.donations_kitty_url"
                />
<!--                  :errors="errors.short_name"-->

<!--                  :errors="errors.birth_date"-->
              </div>
            </template>
          </PageSection>
        </div>
    </template>
  </BaseDossierPageLayout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PageSection from "@c/PageSection.vue";
import BaseSwitch from "@c/addf-package/components/BaseSwitch/BaseSwitch.vue";
import ParticularityWysiwygComponent
  from "@/views/dossier/form/subpages/particularities/components/ParticularityWysiwygComponent.vue";
import BaseShowEditInput from "@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue";
import BaseShowEditDatePicker from "@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue";

export default {
  name: "TheDossierSitePage",
  components: {
    BaseShowEditDatePicker,
    BaseShowEditInput,
    ParticularityWysiwygComponent,
    BaseSwitch,
    PageSection
  },
  computed: {
    ...mapGetters({
      dossier: 'dossier/getDossier',
      errors: 'auth/getErrors'
    }),
    name() {
      return this.data
    }
  },
  methods: {
    ...mapActions({
      processUpdateDossierSiteInfosAction: 'dossier/updateDossierSiteInfos'
    }),
    handleGetInputValue({ attribute, value }) {
      this.dossier.properties[attribute] = value
    },
    submitForm() {

      this.processUpdateDossierSiteInfosAction({
        dossier_id: this.dossier.id,
        site_infos: this.dossier.properties,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.dossier_site_infos'), {
            className: 'toastify-content success'
          })
        }
      })
    },

  },
}
</script>
