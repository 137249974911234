<template>
  <TransitionRoot as="template" :show="isModalOpen" class="z-100">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-blue-100 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div
                  class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
                >
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle
                        v-if="title"
                        class="text-lg font-medium text-gray-900"
                        >{{ title }}</DialogTitle
                      >
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="bg-white text-slate-300 hover:text-gray-500"
                          @click="close"
                        >
                          <span class="sr-only">Close panel</span>
                          <FontAwesomeIcon :icon="['fal', 'times']" size="xl" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <!-- Replace with your content -->
                    <div class="absolute inset-0 px-4 sm:px-6">
                      <slot name="body" />
                    </div>
                    <!-- /End replace -->
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end gap-x-3 px-4 py-4">
                  <slot name="modalFooter" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

export default {
  name: 'BaseWideSlideOverModal',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    footer: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:isModalOpen', 'close'],
  methods: {
    close() {
      this.$emit('update:isModalOpen', false)
      this.$emit('close')
    }
  }
}
</script>
