const PlaceType = Object.freeze({
  HOPITAL: 'HOP',
  MAISON_DE_REPOS: 'MDR',
  CIMETIERE: 'CIM',
  CREMATORIUM: 'CRE',
  SALLE_CREMATORIUM: 'SCR',
  ADMINISTRATION_COMMUNALE: 'ADM',
  LIEU_CEREMONIE: 'LCER',
  LIEU_DECES: 'LDEC',
  EGLISE: 'EGL',
  DOMICILE: 'DOM',
  FUNERARIUM: 'FUN',
  SALLE_FUNERARIUM: 'SFU',
  WIKI_FUNERARIUM: 'WFUN',
  RUE: 'RUE',
  AUTRES: 'OTHER'
})

export default PlaceType
