import TheVersionNoteIndexPage from "@/views/settings/versionNote/TheVersionNoteIndexPage.vue";

export const versionNoteRootPages = [
  {
    path: 'version-note',
    name: 'settings_version_note_index_route',
    component: TheVersionNoteIndexPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_company_title', active: false },
        { title: 'content_company_new_title', active: true }
      ]
    }
  }
]
