import TheFuneralHomeSettingsPage from '@/views/settings/corporation/funeralHome/form/tabs/funeralHomeSettings/TheFuneralHomeSettingsPage.vue'

const TheFuneralHomeIndexPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/index/TheFuneralHomeIndexPage'
  )
const TheFuneralHomeFormsTabComponent = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/TheFuneralHomeFormsTabComponent'
  )
const TheFuneralHomeInvoicingPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/tabs/funeralHomeInvoicing/TheFuneralHomeInvoicingPage'
  )
const TheFuneralHomePartnersPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/tabs/funeralHomePartners/TheFuneralHomePartnersPage'
  )
const TheFuneralHomeInformationPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/tabs/funeralHomeInformation/TheFuneralHomeInformationPage'
  )
const TheFuneralHomeStatisticsPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/tabs/funeralHomeStatistic/TheFuneralHomeStatisticsPage'
  )
const TheFuneralHomeDossierPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/tabs/funeralHomeDossier/TheFuneralHomeDossierPage'
  )
const TheFuneralHomeActivityPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/tabs/funeralHomeActivities/TheFuneralHomeActivityPage'
  )
const TheFuneralHomeGalleryPage = () =>
  import(
    '@/views/settings/corporation/funeralHome/form/tabs/funeralHomeHeader/TheFuneralHomeHeaderPage.vue'
  )
const TheEventSettingsPage = () =>
  import('@/views/settings/general/tabs/event/TheEventSettingsPage.vue')

export const settingsFuneralHomeRoutes = [
  {
    path: 'funeralHome',
    children: [
      {
        path: '',
        name: 'settings_list_funeral_home_route',
        component: TheFuneralHomeIndexPage,
        meta: {
          type: 'index',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_funeral_home_info_route',
        props: true,
        component: TheFuneralHomeFormsTabComponent,
        children: [
          {
            path: '',
            component: TheFuneralHomeInformationPage,
            name: 'settings_edit_funeral_home_route'
          },
          {
            path: 'invoicing',
            component: TheFuneralHomeInvoicingPage,
            props: true,
            name: 'settings_edit_funeral_home_invoicing_route'
          },
          {
            path: 'statistics',
            component: TheFuneralHomeStatisticsPage,
            props: true,
            name: 'settings_edit_funeral_home_statistics_route'
          },
          {
            path: 'partners',
            component: TheFuneralHomePartnersPage,
            props: true,
            name: 'settings_edit_funeral_home_partners_route'
          },
          {
            path: 'gallery',
            component: TheFuneralHomeGalleryPage,
            props: true,
            name: 'settings_edit_funeral_home_gallery_route'
          },
          {
            path: 'settings',
            component: TheFuneralHomeSettingsPage,
            props: true,
            name: 'settings_edit_funeral_home_settings_route'
          },
          {
            path: 'dossiers',
            component: TheFuneralHomeDossierPage,
            props: true,
            name: 'settings_edit_funeral_home_dossier_route'
          },
          {
            path: 'activities',
            component: TheFuneralHomeActivityPage,
            props: true,
            name: 'settings_edit_funeral_home_activity_route'
          },
          {
            path: 'event',
            component: TheEventSettingsPage,
            name: 'settings_edit_funeral_home_event_items_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_funeral_home_info_route',
        component: TheFuneralHomeFormsTabComponent,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheFuneralHomeInformationPage,
            name: 'settings_create_funeral_home_route'
          },
          {
            path: 'invoicing',
            component: TheFuneralHomeInvoicingPage,
            props: true,
            name: 'settings_create_funeral_home_invoicing_route'
          },
          {
            path: 'statistics',
            component: TheFuneralHomeStatisticsPage,
            props: true,
            name: 'settings_create_funeral_home_statistics_route'
          },
          {
            path: 'partners',
            component: TheFuneralHomePartnersPage,
            props: true,
            name: 'settings_create_funeral_home_partners_route'
          },
          {
            path: 'dossiers',
            component: TheFuneralHomeDossierPage,
            props: true,
            name: 'settings_create_funeral_home_dossier_route'
          },
          {
            path: 'settings',
            component: TheFuneralHomeSettingsPage,
            props: true,
            name: 'settings_create_funeral_home_settings_route'
          },
          {
            path: 'gallery',
            component: TheFuneralHomeGalleryPage,
            props: true,
            name: 'settings_create_funeral_home_gallery_route'
          }
        ]
      }
    ]
  }
]
