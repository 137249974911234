<template>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
        >
          {{ $t('attributes.reference') }}
        </th>
        <th
          scope="col"
          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
        >
          {{ $t('global.dossier_type') }}
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
        >
          {{ $t('attributes.deceased') }}
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
        >
          {{ $t('attributes.relationship') }}
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
        >
          {{ $t('attributes.role') }}
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <template v-for="dossier in person.dossiers" :key="dossier.id">
        <ShowConcernedPersonModalTableRow :dossier="dossier" :person="person" />
      </template>
    </tbody>
  </table>
</template>

<script>
import ShowConcernedPersonModalTableRow from '@c/Person/component/ShowConcernedPersonModalTableRow.vue'

export default {
  name: 'ShowConcernedPersonModalTable',
  components: { ShowConcernedPersonModalTableRow },
  props: {
    person: {
      type: Object,
      required: true
    }
  }
}
</script>
