<template>
  <BasePopupModal
    v-model:is-modal-open="localOpened"
    size="medium"
    :title="$t('global.dossier')"
  >
    <template #footer>
      <div class="mt-1 flex h-full gap-x-1">
        <BaseButton
          :title="$t('button.choose')"
          icon="paper-plane"
          size="small"
          @click="$emit('selected-dossier', choosenDossier)"
        />
      </div>
    </template>
    <template #body>
      <div class="flex h-[60vh] w-full flex-col">
        <div
          v-if="loading"
          class="flex shrink-0 flex-row items-center justify-center"
        >
          <LoadingComponent />
        </div>
        <div v-else class="flex min-h-0 flex-1 flex-col gap-2">
          <div class="flex shrink-0 flex-row">
            <div class="flex-1">&nbsp;</div>
            <div class="shrink-0">
              <BaseSearchInput v-model="searchText" />
            </div>
          </div>
          <div class="min-h-0 flex-1 overflow-y-scroll">
            <div class="grid grid-cols-2 gap-3">
              <button
                v-for="dossier in dossiers"
                :key="dossier.id"
                class="rounded border p-2 text-left"
                :class="[
                  choosenDossier === dossier.id
                    ? 'border-primary shadow-md'
                    : ''
                ]"
                @click="() => (choosenDossier = dossier.id)"
              >
                <span class="flex flex-col">
                  <span class="flex shrink-0 flex-row items-center">
                    <span class="line-clamp-1 flex-1 font-semibold">
                      {{ dossier.concernedPerson?.full_name }}
                    </span>
                    <span class="shrink-0">
                      <span
                        class="rounded bg-primary px-1 py-0.5 text-xs text-contrast-primary"
                        :data-tint="dossier.funeral_home.color"
                      >
                        {{ dossier.funeral_home?.initials }}
                      </span>
                    </span>
                  </span>
                  <span class="text-xs font-medium"
                    ># {{ dossier.reference }}</span
                  >
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BasePopupModal>
</template>

<script>
import LoadingComponent from '@c/TheLoadingComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BasePopupModal from '@c/addf-package/components/modal/BasePopupModal.vue'
import { mapActions } from 'vuex'
import BaseSearchInput from '@c/addf-package/components/BaseInput/BaseSearchInput.vue'
import { debounce } from '@u/debounce'

export default {
  name: 'SearchDossierModal',
  components: {
    BaseSearchInput,
    BasePopupModal,
    BaseButton,
    LoadingComponent
  },
  props: {
    opened: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:opened', 'selected-dossier'],
  data() {
    return {
      dossiers: [],
      loading: false,
      choosenDossier: null,
      searchText: ''
    }
  },
  computed: {
    localOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      }
    }
  },
  watch: {
    opened(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.loadDossier()
        }
      }
    },
    searchText() {
      this.debouncedSearch(this.searchText)
    }
  },
  created() {
    this.debouncedSearch = debounce((term) => {
      if (term && term.length >= 3) {
        this.loadDossier()
      } else if (!term || term.length === 0) {
        this.loadDossier()
      }
    }, 1000)
  },
  methods: {
    ...mapActions({
      processFetchDossierListAction: 'dossier/fetchDossierList'
    }),
    loadDossier() {
      this.loading = true
      this.processFetchDossierListAction({
        term: this.searchText
      })
        .then((response) => (this.dossiers = response.dossiers))
        .finally((_) => {
          this.loading = false
        })
    }
  }
}
</script>
