<template >
  <Disclosure v-slot="{open}">
    <DisclosureButton class="rounded-t-md bg-white border border-gray-300 text-md w-full hover:bg-gray-50 cursor-pointer py-2">
      <div class="w-full flex items-center">
        <div class="flex justify-center mx-auto">
          <p class="capitalize-first select-none cursor-pointer">{{ title }}</p>
        </div>
        <div class="flex justify-end mr-5">
          <FontAwesomeIcon :icon="['fal', open ? 'chevron-up' : 'chevron-down']" />
        </div>
      </div>
    </DisclosureButton>
    <DisclosurePanel class="text-gray-500">
      <div class="bg-white rounded-b-md border-l border-r border-b border-gray-300 p-3">
        <slot/>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template >

<script >
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

export default {
  name: "BaseDisclosure",
  components: { Disclosure, DisclosureButton, DisclosurePanel },
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script >
