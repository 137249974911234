<template>
  <tr>
    <td
      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
    >
      SLIP
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShowInvoiceableModalTableRow',
  props: {
    invoiceable: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      dossierTypes: 'metadata/getMetadataDossierTypes'
    }),
    relation() {
      if (!this.dossier.concernedPerson) {
        return '--'
      }
      if (this.dossier.concernedPerson.full_name === this.invoiceable.name) {
        return '-'
      } else {
        if (!this.dossier.contacts || this.dossier.contacts.length < 1) {
          return '-'
        } else {
          let relationship = this.dossier.contacts.find(
            (contact) => contact.id === this.invoiceable.id
          ).relationship
          if (!relationship) return '-'
          return this.initialMetadata?.enums?.relationship?.find(
            (relation) => relation.id === relationship
          )?.name
        }
      }
    },
    role() {
      return '-'
    }
  }
}
</script>
