import WorkInProgressComponent from '@/views/wip/WorkInProgressComponent'

import { wikiCityRoutes } from './city/index'
import { wikiLocationsRoute } from './locations/index'
import TheWikiCityIndexPage from '@/views/wiki/communes/index/TheWikiCityIndexPage.vue'

export const wikiRootPages = {
  path: 'wiki',
  name: 'wiki_route',
  component: WorkInProgressComponent
}

export const wikiSubPages = [
  {
    path: 'wiki/city',
    children: [
      {
        path: '',
        name: 'wiki_city_page_route',
        component: TheWikiCityIndexPage
      },
      ...wikiCityRoutes
    ]
  },
  ...wikiLocationsRoute
]
