<template>
  <BaseSlideOverModal
    v-model:is-modal-open="open"
    width="2xl"
    :title="$t('global.email')"
    @close="close"
  >
    <template #cta>
      <div class="mt-1 flex h-full gap-x-1">
        <BaseButton
          :title="$t('button.cancel')"
          icon="times"
          color="danger"
          size="small"
          @click="resetEmail"
        />
        <BaseButton
          :title="$t('button.send')"
          icon="paper-plane"
          size="small"
          @click="submitForm"
        />
      </div>
    </template>
    <template #body>
      <div
        class="scrollbar-custom flex h-full w-full flex-col justify-between overflow-scroll"
      >
        <div class="flex w-full flex-col gap-y-2 px-1">
          <div class="flex cursor-pointer flex-row items-end gap-2">
            <div class="flex-1">
              <BaseShowEditSelectMultipleTaggableAjax
                v-model="email.to"
                v-model:additional-tags="email.to_additional"
                :label="$t('global.to')"
                :missing-info-label="$t('global.missing_email')"
                class="w-full"
                url="notification/notifiable-global-search"
                option-sub-title-label="emailable"
                :full-model-response="true"
                :displayed-option-icon="true"
                :taggable="true"
                name="email_to"
                :edition-mode="true"
                :errors="errors.to"
                @tag="(newModelValue) => (email.to = newModelValue)"
              />
            </div>
            <div
              v-if="!hasCC || !hasBCC"
              class="flex flex-shrink-0 flex-row gap-3"
            >
              <!--#region cc buttons -->
              <div class="inline-flex rounded-md shadow-sm" role="group">
                <button
                  v-if="!hasCC"
                  type="button"
                  :class="[
                    hasCC ? 'bg-gray-200' : '',
                    ccButtonClass,
                    'rounded-l-lg'
                  ]"
                  @click="setHasCC"
                >
                  {{ $t('global.email_cc') }}
                </button>
                <button
                  v-if="!hasBCC"
                  type="button"
                  :class="[
                    hasBCC ? 'bg-gray-200' : '',
                    ccButtonClass,
                    'rounded-r-lg'
                  ]"
                  @click="setHasBCC"
                >
                  {{ $t('global.email_bcc') }}
                </button>
              </div>
              <!--#endregion -->
            </div>
          </div>

          <template v-if="hasCC">
            <BaseShowEditSelectMultipleTaggableAjax
              v-model="email.cc"
              v-model:additional-tags="email.cc_additional"
              :label="$t('global.email_cc')"
              :missing-info-label="$t('global.missing_email')"
              class="w-full"
              url="notification/notifiable-global-search"
              option-sub-title-label="emailable"
              :full-model-response="true"
              :displayed-option-icon="true"
              :taggable="true"
              name="cc"
              :edition-mode="true"
              :errors="errors.cc"
              @tag="(newModelValue) => (email.cc = newModelValue)"
            />
          </template>

          <template v-if="hasBCC">
            <BaseShowEditSelectMultipleTaggableAjax
              v-model="email.bcc"
              v-model:additional-tags="email.bcc_additional"
              :label="$t('global.email_bcc')"
              :missing-info-label="$t('global.missing_email')"
              class="w-full"
              url="notification/notifiable-global-search"
              option-sub-title-label="emailable"
              :full-model-response="true"
              :displayed-option-icon="true"
              :taggable="true"
              name="bcc"
              :edition-mode="true"
              :errors="errors.bcc"
              @tag="(newModelValue) => (email.bcc = newModelValue)"
            />
          </template>
          <BaseShowEditInput
            v-model="email.subject"
            :label="$t('attributes.email_subject')"
            name="subject"
            :errors="errors.subject"
            :edition-mode="true"
            :required="true"
          />
          <BaseShowEditSelectAjax
            v-model="email.model"
            class="w-full"
            :edition-mode="true"
            url="dossier-for-select"
            :label="$t('global.dossier')"
            name="dossier_id"
            track-by="id"
            attribute-label="title"
            :full-model-response="true"
            :default-ajax="dossierDefaultValue"
            @change="afterDossierSelected"
          />
          <div
            v-if="email.model || modelMounted"
            class="mx-auto flex flex w-full items-center justify-between gap-x-5 rounded-md bg-blue-300 px-5 py-2 font-bold text-white"
          >
            <div class="flex">
              <FontAwesomeIcon class="mt-1" :icon="['fal', 'tag']" />
              <p class="ml-3 capitalize-first">{{ email.model.title }}</p>
            </div>
            <div>
              <FontAwesomeIcon
                :icon="['fal', 'times']"
                class="cursor-pointer text-lg"
                @click="cancelModel"
              />
            </div>
          </div>
          <BaseShowEditSelectSimple
            v-if="initialMetadata?.models?.message_texts"
            :key="initialMetadata?.models?.message_texts"
            v-model="email.message_text"
            :label="$t('global.message_text')"
            :placeholder="$t('global.message_text')"
            name="messageConverted"
            :edition-mode="true"
            :options="initialMetadata?.models?.messageTexts"
            @change="getSelectedOptions"
          />
          <BaseShowEditSelectSimple
            :key="documentsDossier"
            :label="$t('global.invoicing_and_attachments')"
            name="document"
            :edition-mode="true"
            :options="documentsDossier"
            @change="getSelectedAttachment"
          />
          <EmailAttachmentList
            :key="email.attachments"
            :document-dossier="attachment_docs"
            :errors="errors.attachments"
            type="email"
            :email="email"
            @delete-document-selected="getSelectedAttachment"
          />

          <div class="mt-3">
            <BaseWysiwyg
              v-model="email.message"
              :value="email.message"
              name="message"
              :errors="errors.email_message"
            />
            <template v-if="errors && errors.message">
              <div
                v-for="(error, index) in errors.message"
                :key="index"
                class="form-help text-red-600"
              >
                {{ $t('global.required_message') }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BaseWysiwyg from '@c/addf-package/components/Wysiwyg/BaseWysiwyg'
import EmailAttachmentList from '@ui/modal/email/components/EmailAttachmentList'
import InitialEmailValue from '@/store/modules/email/initialEmailValue'

export default {
  name: 'SendEMailModal',
  components: { EmailAttachmentList, BaseWysiwyg },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    invoice: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['close'],
  data() {
    return {
      showModel: true,
      hasCC: false,
      hasBCC: false,
      modelParam: null,
      attachment_docs: [],
      selectedModel: null
    }
  },
  computed: {
    ...mapGetters({
      email: 'email/getEmail',
      dossier: 'dossier/getDossier',
      invoiceModel: 'invoicing/getInvoice',
      messageText: 'messageText/getMessageText',
      documentsDossier: 'dossier/getDocumentsDossier',
      initialMetadata: 'metadata/getInitialMetadata',
      auth: 'auth/getAuth',
      errors: 'auth/getErrors'
    }),
    ccButtonClass() {
      return 'border border-gray-200 bg-white py-2 px-4 text-sm font-medium text-gray-900 capitalize-first'
    },
    dossierDefaultValue() {
      return this.email?.model
        ? {
            id: this.email.model_id,
            title: `${this.email?.model?.title} ${
              this.email?.model?.reference
                ? `(${this.email?.model?.reference})`
                : ''
            }`
          }
        : {}
    },
    modelMounted() {
      if (this.$route.meta.parent === 'edit_dossier') {
        this.email.model = this.dossier
        this.email.model_type = 'App\\Models\\Dossier'
        this.modelParam = 'dossier'
        this.email.model_id = this.dossier.id
      } else if (this.$route.name === 'invoice_info_route') {
        this.email.model = this.invoice
        this.email.model_type = 'App\\Models\\Invoice'
        this.modelParam = 'invoice'
        this.email.model_id = this.invoice.id
      }
    }
  },
  mounted() {
    this.$store.watch(
      () => this.$store.getters['email/getEmail'],
      (newValue) => {
        this.processSetHasChangedMutation(
          JSON.stringify(InitialEmailValue()) !== JSON.stringify(newValue)
        )
      },
      { deep: true }
    )
  },
  methods: {
    ...mapActions({
      processSendEmailAction: 'email/sendEmail',
      processFetchNewModelNotificationAction:
        'modelNotification/fetchNewModelNotification',
      processFetchMessageTextConvertedAction: 'email/fetchMessageTextConverted',
      processFetchAllDocumentAttachedToDossierAction:
        'dossier/fetchAllDocumentAttachedToDossier'
    }),
    ...mapMutations({
      processResetEmailMutation: 'email/resetEmail',
      processSetHasChangedMutation: 'email/setHasChanged',
      processSetHasChangeMutation: 'email/setHasChanged',
      processSetDocumentsDossierMutation: 'dossier/setDocumentsDossier'
    }),
    getSelectedAttachment(value) {
      let fullValue = this.documentsDossier.find((doc) => doc.id === value)

      if (this.attachment_docs.some((doc) => doc['id'] === value)) {
        this.attachment_docs = this.attachment_docs.filter(
          (doc) => doc.id !== value
        )
      } else {
        this.attachment_docs.push(fullValue)
      }

      this.email['attachable'] = this.attachment_docs
    },
    submitForm() {
      this.email.sender = this.auth.full_name
      this.email.sender_id = this.auth.id
      this.email.creator_id = this.auth.id

      this.processSendEmailAction({
        email: this.email,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.email_sent'), {
            className: 'toastify-content success'
          })
          this.close()
          this.resetEmail()
        }
      })
    },
    resetEmail() {
      this.modelParam = null
      this.processResetEmailMutation()
      this.processSetHasChangeMutation(false)
      this.processSetDocumentsDossierMutation([])
      this.attachment_docs = []
      this.close()
    },
    cancelModel() {
      this.modelParam = null
      this.email.model = null
      this.email.model_id = null
      this.email.model_type = null
    },
    setHasCC() {
      if (this.hasCC) {
        this.email.cc = []
        this.email.cc_additional = []
      }

      this.hasCC = !this.hasCC
    },
    async afterDossierSelected() {
      this.email.model_type = 'App\\Models\\Dossier'
      this.email.model_id = this.email?.model?.id
      this.modelParam = 'dossier'
      await this.processFetchAllDocumentAttachedToDossierAction({
        dossier_id: this.email?.model?.id
      })
    },
    getSelectedOptions(value) {
      this.processFetchMessageTextConvertedAction({
        model: this.modelParam,
        model_id: this.email.model_id,
        message_text_id: value
      })
    },
    setHasBCC() {
      if (this.hasBCC) {
        this.email.cci = []
        this.email.cci_additional = []
      }
      this.hasBCC = !this.hasBCC
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
