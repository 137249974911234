<template>
  <button
    class="block rounded border p-2 text-left"
    :class="selected ? 'border-primary-300 shadow shadow-primary-300' : ''"
  >
    <span class="flex flex-col">
      <span class="flex flex-1 flex-row items-center">
        <span class="text-md flex-1 text-left font-bold">{{
          invoiceable.full_name
        }}</span>
        <span class="shrink-0 rounded border px-0.5">
          <FontAwesomeIcon :icon="['fal', 'user']" fixed-width size="sm" />
        </span>
      </span>
      <span>
        {{ invoiceable.street }}<br />
        {{ invoiceable.city }}
      </span>
      <span
        class="flex flex-row gap-1"
        v-if="invoiceable.phone_field && invoiceable.phone_field.length"
      >
        <span class="flex-shrink-0">
          <FontAwesomeIcon :icon="['fal', 'phone-alt']" />
        </span>
        <span class="flex-1">{{ invoiceable.phone_field }}</span>
      </span>
      <span
        class="flex flex-row gap-1"
        v-if="invoiceable.mobile_field && invoiceable.mobile_field.length"
      >
        <span class="flex-shrink-0">
          <FontAwesomeIcon :icon="['fal', 'phone-alt']" />
        </span>
        <span class="flex-1">{{ invoiceable.mobile_field }}</span>
      </span>
    </span>
  </button>
</template>

<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'InvoiceableCardSelector',
  components: { FontAwesomeIcon },
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    invoiceable: {
      type: Object,
      required: true
    }
  }
})
</script>
