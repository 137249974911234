<template>

  <div class="inline-flex justify-between w-full text-lg text-gray-600 font-medium mb-2">
    <span class="capitalize-first">{{ title }}</span>
  </div>
  <BaseSiteShowEditSelectSimple
      v-model="site"
      :dont-display-label="true"
      :edition-mode="true"
      :metadata="metadata.models"
      :full-model-response="true"
      @change="getSite"
  />
</template>

<script>
import {mapGetters} from "vuex";
import BaseSiteShowEditSelectSimple from "@c/BaseSiteShowEditSelectSimple";


export default {
  name: 'DropdownCompanyPlaceModalComponent',
  components: {BaseSiteShowEditSelectSimple},
  props: {
    title: {
      type: String,
      required: true
    },
    keyword: {
      type: String,
      required: true,
    }
  },
  emits: ['getAddress'],
  data() {
    return {
      site: {}
    }
  },
  computed: {
    ...mapGetters({
      dossier: "dossier/getDossier",
      dossierMetadata: "dossier/getDossierMetadata",
      metadata: 'metadata/getInitialMetadata'
    }),
  },
  methods: {
    getSite(value) {
      this.$emit('getAddress', {
        type: "FUN",
        id: value.id,
        label: value.name
      })
    },
  },
}
</script>
