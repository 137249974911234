<template>
  <div v-if="editionMode">
    <div v-if="defaultAjax" :class="cGroupClass">
      <div
        class="base-multiselect-simple relative rounded-md border border-slate-300 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
        :class="[label ? 'mt-2' : '']"
      >
        <label
          v-if="label"
          :for="name"
          class="absolute -top-2 left-2 z-10 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
          >{{ label }}&nbsp;
          <span v-if="label && required" class="text-red-600">*</span>
        </label>
        <multiselect
          :id="name"
          :key="defaultAjax"
          v-model="internalValue"
          :label="attributeLabel"
          :track-by="trackBy"
          :placeholder="$t('button.type_to_search')"
          open-direction="bottom"
          :options="options"
          :value="defaultAjax"
          :multiple="multiple"
          :allow-empty="true"
          :searchable="true"
          :loading="loading"
          :internal-search="internalSearch"
          :clear-on-select="true"
          :close-on-select="true"
          :options-limit="300"
          :limit="10"
          :limit-text="2"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="false"
          :taggable="taggable"
          :tag-placeholder="$t('button.add_tag_placeholder')"
          :select-label="$t('button.select')"
          :deselect-label="$t('button.unselect')"
          :selected-label="$t('button.selected')"
          @tag="addTag"
          @search-change="fetchOption"
        >
          <template #tag="{ option, remove }">
            <span class="custom__tag">
              <span>{{ option.name }}</span>
              <span class="custom__remove" @click="remove(option)">❌</span>
            </span>
          </template>
          <template #noOptions
            ><span>{{ $t('global.is_empty_list') }}</span></template
          >
        </multiselect>
      </div>
    </div>
    <template v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </div>

  <div v-else>
    <BaseShowLabel
      :label="label"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </div>
</template>
<script>
import apiClient from '@u/apiClient'
import multiselect from 'vue-multiselect'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel'

export default {
  name: 'BaseShowEditSelectAjax',
  components: { BaseShowLabel, multiselect },
  props: {
    editionMode: { type: Boolean, required: true },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    modelValue: { type: Object, required: false, default: null },
    taggable: { type: Boolean, required: false, default: false },
    defaultAjax: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    multiple: { type: Boolean, required: false, default: false },
    label: { type: String, required: false },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    fullModelResponse: { type: Boolean, required: false, default: false },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    internalSearch: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: [
    'update:modelValue',
    'update:stringValue',
    'change',
    'selected:value',
    'newTagSelected'
  ],
  data() {
    return {
      options: [],
      loading: false,
      defaultValue: this.defaultAjax
    }
  },
  computed: {
    internalValue: {
      get() {
        if (this.fullModelResponse) {
          return (
            this.options.find((option) => option === this.modelValue) ??
            this.defaultAjax
          )
        } else {
          return (
            this.options.find(
              (option) => option[this.trackBy] === this.modelValue
            ) ?? this.defaultAjax[this.trackBy]
          )
        }
      },
      set(newValue) {
        if (newValue === null) this.options = []
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
          this.$emit('change', newValue)
        } else {
          this.$emit('update:modelValue', newValue[this.trackBy])
          this.$emit('selected:value', newValue[this.trackBy])
          this.$emit('change', newValue)
        }
      }
    },
    cDisplayedValueWhenNotEditionMode() {
      if (this.defaultValue) {
        if (this.attributeLabel && this.defaultValue[this.attributeLabel]) {
          return this.defaultValue[this.attributeLabel]
        }
        return '-'
      }
      return '-'
    },
    cGroupClass() {
      return this.groupClass === '' ? '' : this.groupClass
    }
  },
  beforeMount() {
    if (this.defaultAjax && Object.keys(this.defaultAjax).length > 0)
      this.options.push(this.defaultAjax)
  },
  methods: {
    addTag(newTag) {
      const tag = {}
      tag[this.trackBy] = 'new'
      tag[this.attributeLabel] = newTag
      this.options.push(tag)
      this.$emit('newTagSelected', tag)
    },
    async fetchOption(keyword) {
      if (keyword.length > 2) {
        this.loading = true
        await apiClient
          .get(this.url + '?terms=' + keyword)
          .then(async (response) => {
            this.loading = false
            this.options = response.data
          })
      }
    }
  }
}
</script>

<style scoped>
.multiselect__tags {
  border: 0 !important;
}
</style>
