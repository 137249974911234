export default {
  setAutomationMetadata(state, automationMetadata) {
    state.automationMetadata = automationMetadata
  },
  setAutomationList(state, automationList) {
    state.automationList = automationList
  },
  setAutomation(state, automation) {
    state.automation = automation
  }
}
