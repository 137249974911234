<template>
  <div :class="cGroupClass">
    <template v-if="editionMode">
      <div class="grid grid-cols-2 gap-x-5">
        <div
          class="relative mt-2 rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
          :class="[
            internalDateIsAValidDate
              ? 'focus:border-blue-300  focus:border-slate-300 focus:ring-blue-300'
              : 'focus:border-red-300 focus:ring-red-300',
            'border-slate-300 focus:ring-1'
          ]"
        >
          <label
            v-if="label"
            :for="name"
            class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
            >{{ $t('attributes.date') }}
          </label>
          <template v-if="!isDatePicker">
            <input
              v-model="internalDate"
              v-mask="dateMask"
              type="text"
              class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              @input="updateDateInput"
            />
          </template>
          <template v-else>
            <datepicker
              v-model="datePickerValue"
              :disabled="false"
              :month-heading-format="monthFormat"
              :weekday-format="weekFormat"
              :input-format="datePickerFormat"
              :week-starts-on="startingDayOfTheWeek"
              :clearable="clearButtonVisible"
              :typeable="canTypeDateManually"
              :starting-view="viewType"
              :style="style"
              :readonly="false"
              :locale="locale"
              class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              @update:modelValue="handleDateSelectedInDatePicker"
            />
          </template>
        </div>
        <div class="flex gap-x-3">
          <div
            class="relative mt-2 rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
            :class="[
              internalTimeFromIsAValidTime
                ? 'focus:border-blue-300  focus:border-slate-300 focus:ring-blue-300'
                : 'focus:border-red-300 focus:ring-red-300'
            ]"
          >
            <label
              class="absolute -top-2 left-2 -mt-px inline-block w-full truncate bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
            >
              {{ $t('attributes.time_from') }}
            </label>
            <input
              v-if="!isTimePicker"
              ref="timeFromInput"
              v-model="internalTimeFrom"
              v-mask="timeMask"
              type="text"
              class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              @input="updateTimeFromInput"
              @click="selectAllText('timeFromInput')"
            />
            <datepicker
              v-else
              v-model="timeFromPickerValue"
              :month-heading-format="monthFormat"
              :weekday-format="weekFormat"
              :input-format="timePickerFormat"
              :week-starts-on="startingDayOfTheWeek"
              :clearable="clearButtonVisible"
              :typeable="canTypeDateManually"
              :starting-view="'time'"
              :minimum-view="'time'"
              :style="style"
              :readonly="false"
              :locale="locale"
              class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              :class="[
                'border-slate-300 focus:border-blue-300 focus:ring-1 focus:ring-blue-300'
              ]"
              @update:modelValue="handleTimeFromSelectedInTimePicker"
            />
          </div>

          <div
            class="relative mt-2 rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
            :class="[
              internalTimeToIsAValidTime
                ? 'focus:border-blue-300  focus:border-slate-300 focus:ring-blue-300'
                : 'focus:border-red-300 focus:ring-red-300'
            ]"
          >
            <label
              class="absolute -top-2 left-2 -mt-px inline-block w-full truncate bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
            >
              {{ $t('attributes.time_to') }}
            </label>
            <input
              v-if="!isTimePicker"
              ref="timeToInput"
              v-model="internalTimeTo"
              v-mask="timeMask"
              type="text"
              class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
              @input="updateTimeToInput"
              @click="selectAllText('timeToInput')"
            />
            <datepicker
              v-else
              v-model="timeToPickerValue"
              :month-heading-format="monthFormat"
              :weekday-format="weekFormat"
              :input-format="timePickerFormat"
              :week-starts-on="startingDayOfTheWeek"
              :clearable="clearButtonVisible"
              :typeable="canTypeDateManually"
              :starting-view="'time'"
              :minimum-view="'time'"
              :style="style"
              :readonly="false"
              :locale="locale"
              :class="[
                'border-slate-300 focus:border-blue-300 focus:ring-1 focus:ring-blue-300',
                cInputClass
              ]"
              @update:modelValue="handleTimeToSelectedInTimePicker"
            />
          </div>
        </div>
      </div>
      <template v-if="internalErrors">
        <div
          v-for="(error, index) in displayableErrors"
          :key="index"
          class="form-help text-red-600"
        >
          <template v-if="error === 'validation.date'">
            {{ $t(error, { attribute: $t('attributes.' + name) }) }}
          </template>
          <template v-if="error === 'validation.after'">
            {{
              $t(error, {
                attribute: $t('attributes.time_to'),
                date: internalTimeFrom
              })
            }}
          </template>
          <template v-else>
            {{ $t(error, { attribute: $t('attributes.' + name) }) }}
          </template>
        </div>
      </template>
    </template>
    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="displayedValueWhenNotInEditionMode"
      />
    </template>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import moment from 'moment'
import { fr } from 'date-fns/locale'
import * as _ from 'lodash-es'

export default {
  name: 'BaseShowEditIsoDateAndTimeRangeInput',
  components: { Datepicker },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    timeToValue: {
      type: String,
      required: false,
      default: null
    },
    timeToName: {
      type: String,
      required: false,
      default: 'date_to'
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    dateMask: {
      type: String,
      required: false,
      default: '##/##/####'
    },
    timeFormat: {
      type: String,
      required: false,
      default: 'HH:mm'
    },
    timeMask: {
      type: String,
      required: false,
      default: '##:##'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    isDatePicker: {
      type: Boolean,
      required: false,
      default: true
    },
    monthFormat: {
      type: String,
      required: false,
      default: 'LLLL yyyy'
    },
    weekFormat: {
      type: String,
      required: false,
      default: 'EE'
    },
    startingDayOfTheWeek: {
      type: Number,
      required: false,
      default: 1
    },
    canTypeDateManually: {
      type: Boolean,
      required: false,
      default: false
    },
    clearButtonVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    viewType: {
      type: String,
      required: false,
      default: 'day',
      validator: function (value) {
        const admittedTypes = ['day', 'month', 'year', 'time']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    datePickerFormat: {
      type: String,
      required: false,
      default: 'dd/MM/yyyy'
    },
    isTimePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    timePickerFormat: {
      type: String,
      required: false,
      default: 'hh:mm'
    }
  },
  emits: ['update:modelValue', 'update:timeToValue', 'change'],
  data() {
    return {
      internalDate: null,
      internalTimeFrom: null,
      internalTimeTo: null,
      internalErrors: null,
      locale: fr,
      style: {
        '--vdp-bg-color': '#ffffff',
        '--vdp-text-color': '#424242',
        '--vdp-box-shadow':
          '0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)',
        '--vdp-border-radius': '8px',
        '--vdp-heading-size': '2.5em',
        '--vdp-heading-weight': 'bold',
        '--vdp-heading-hover-color': '#eeeeee',
        '--vdp-arrow-color': 'currentColor',
        '--vdp-elem-color': 'currentColor',
        '--vdp-disabled-color': '#d5d9e0',
        '--vdp-hover-color': '#ffffff',
        '--vdp-hover-bg-color': '#4386C8',
        '--vdp-selected-color': '#ffffff',
        '--vdp-selected-bg-color': '#4386C8',
        '--vdp-elem-font-size': '0.8em',
        '--vdp-elem-border-radius': '3px',
        '--vdp-divider-color': '#d5d9e0'
      }
    }
  },
  computed: {
    timeFromPickerValue() {
      return new Date(moment(this.internalTimeFrom, this.timeFormat))
    },
    timeToPickerValue() {
      return new Date(moment(this.internalTimeTo, this.timeFormat))
    },
    datePickerValue() {
      return new Date(moment(this.internalDate, this.dateFormat))
    },
    displayableErrors() {
      return Object.values(this.internalErrors).flat()
    },
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === '' ? 'form-control' : this.inputClass
    },
    displayedValueWhenNotInEditionMode() {
      if (this.modelValue) {
        return `${this.internalDate ? this.internalDate + ':' : ''} ${
          this.internalTimeFrom || ''
        } ${this.internalTimeTo ? '- ' + this.internalTimeTo : ''}`
      }
      return null
    },
    internalDateIsAValidDate() {
      return (
        moment(this.internalDate, this.dateFormat).format(this.dateFormat) ===
        this.internalDate
      )
    },
    internalTimeFromIsAValidTime() {
      return (
        moment(this.internalTimeFrom, this.timeFormat).format(
          this.timeFormat
        ) === this.internalTimeFrom
      )
    },
    internalTimeToIsAValidTime() {
      return (
        moment(this.internalTimeTo, this.timeFormat).format(this.timeFormat) ===
        this.internalTimeTo
      )
    },
    joinedFormat() {
      return [this.dateFormat, this.timeFormat].join(' - ')
    },
    joinedDateAndTimeFrom() {
      let tempDate = null
      let tempTime = null

      if (
        !this.internalTimeFrom ||
        this.internalTimeFrom === '' ||
        !this.internalTimeFromIsAValidTime
      ) {
        tempTime = this.timeMask.replaceAll('#', '0')
      } else {
        tempTime = this.internalTimeFrom
      }

      if (!this.internalDate || !this.internalDateIsAValidDate) {
        tempDate = moment().format('DD/MM/YYYY')
      } else {
        tempDate = this.internalDate
      }

      return [tempDate, tempTime].join(' - ')
    },
    joinedDateAndTimeTo() {
      let tempDate = null
      let tempTime = null

      if (
        !this.internalTimeTo ||
        this.internalTimeTo === '' ||
        !this.internalTimeToIsAValidTime
      ) {
        tempTime = this.timeMask.replaceAll('#', '0')
      } else {
        tempTime = this.internalTimeTo
      }

      if (!this.internalDate || !this.internalDateIsAValidDate) {
        tempDate = moment().format('DD/MM/YYYY')
      } else {
        tempDate = this.internalDate
      }
      return [tempDate, tempTime].join(' - ')
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue) {
          this.internalDate = moment(newValue).format('DD/MM/YYYY')
          this.internalTimeFrom = moment(newValue).format(this.timeFormat)
        }
      },
      immediate: true,
      deep: true
    },
    timeToValue: {
      handler(newValue) {
        if (newValue) {
          this.internalTimeTo = moment(newValue).format(this.timeFormat)
        }
      },
      immediate: true,
      deep: true
    },
    errors: {
      handler(newValue) {
        this.internalErrors = _.pick(newValue, [this.name, this.timeToName])
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    selectAllText(arg) {
      console.log(this.$refs[arg].select())
      this.$refs[arg].select()
    },
    handleTimeFromSelectedInTimePicker(timePickerTime) {
      this.internalTimeFrom = moment(timePickerTime).format(this.timeFormat)
      this.updateTimeFromInput()
    },
    handleTimeToSelectedInTimePicker(timePickerTime) {
      this.internalTimeTo = moment(timePickerTime).format(this.timeFormat)
      this.updateTimeToInput()
    },
    handleDateSelectedInDatePicker(datePickerDate) {
      this.internalDate = moment(datePickerDate).format(this.dateFormat)
      this.updateDateInput()
    },
    updateDateInput() {
      if (this.internalDateIsAValidDate) {
        this.$emit(
          'update:modelValue',
          moment(this.joinedDateAndTimeFrom, this.joinedFormat).format()
        )
        if (this.internalTimeTo && this.internalTimeToIsAValidTime) {
          this.$emit(
            'update:timeToValue',
            moment(this.joinedDateAndTimeTo, this.joinedFormat).format()
          )
        }
      }
    },
    updateTimeFromInput() {
      if (this.internalTimeFromIsAValidTime) {
        console.log('updateTimeFromInput')
        this.$emit(
          'update:modelValue',
          moment(this.joinedDateAndTimeFrom, this.joinedFormat).format()
        )
      }
    },
    updateTimeToInput() {
      if (this.internalTimeToIsAValidTime) {
        this.$emit(
          'update:timeToValue',
          moment(this.joinedDateAndTimeTo, this.joinedFormat).format()
        )
      }
    }
  }
}
</script>
