<template>
  <template v-if="editionMode">
    <div class="flex flex-col">
      <BaseEditLabel :label="label" :required="required" />
      <div
        class="flex h-11 w-full cursor-pointer flex-row items-center gap-x-3 rounded-lg bg-gray-100"
        @click="onPickFile"
      >
        <button
          class="btn flex h-full flex-row gap-x-3 rounded-lg bg-primary-500 text-white outline-0"
        >
          <FontAwesomeIcon
            :icon="['fal', displayedFileName ? 'pen' : 'plus']"
          />
          {{ displayedFileName ? $t('button.edit') : $t('button.add') }}
        </button>
        <span
          class="cursor-default select-none font-light italic text-gray-500"
          >{{ displayedFileName }}</span
        >
      </div>
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </div>
    <input
      id="file_input"
      ref="fileInput"
      class="block hidden w-full cursor-pointer rounded-lg border bg-gray-50 bg-gray-50 text-sm text-gray-500 text-slate-300 placeholder-slate-300 focus:outline-none"
      type="file"
      accept=".doc,.docx"
      @change="onFilePicked"
    />
  </template>

  <template v-else>
    <BaseShowLabel :label="label" :model-value="displayedFileName" />
  </template>
</template>

<script>
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel'
export default {
  name: 'BaseShowEditFileInput',
  components: { BaseEditLabel },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    displayedFileName: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['file'],
  data() {
    return {
      internalValue: null
    }
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      this.$emit('file', event.target.files[0])
      this.$refs.fileInput.value = ''
    }
  }
}
</script>
