const TheAllSupplierOrdersPage = () =>
  import(
    /* webpackChunkName: "group-supplier-order" */ '@/views/supplierOrder/TheAllSupplierOrdersPage'
  )

export const supplierOrderSubPages = [
  {
    path: '/supplier-order',
    name: 'supplier_order_page_route',
    component: TheAllSupplierOrdersPage
  }
]
