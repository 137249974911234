<template>
  <div
    class=""
    :class="[
      mainSize,
      isSquare ? 'rounded-md' : 'rounded-full',
      shadowColorComputed,
      shadowColor ? 'shadow-md' : 'shadow-xl'
    ]"
  >
    <div class="relative">
      <div
        class="relative overflow-hidden bg-gradient-to-bl from-primary-500 to-primary-600 font-bold text-contrast-primary shadow-2xl ring-1"
        :class="[
          isSquare ? 'rounded-md' : 'rounded-full',
          mainSize,
          fontSize,
          ringColorComputed,
          borderColorComputed,
          borderSizeComputed
        ]"
      >
        <span
          v-if="!imgUrl || !imgAvailable"
          class="absolute inset-0 flex items-center justify-center"
          :class="[blinkIconEnabled ? 'animate-fadeoutfadein' : '']"
        >
          <span v-if="!icon">
            {{ initials }}
          </span>
          <FontAwesomeIcon v-else :icon="icon" />
        </span>
        <img
          v-else
          class="absolute inset-0 block h-full w-full items-center justify-center object-cover"
          :class="[blinkIconEnabled ? 'animate-fadeoutfadein' : '']"
          :src="imgUrl"
          :alt="initials"
          @error="imgNotAvailable"
        />
        <span
          v-if="blinkIconEnabled"
          class="absolute inset-0 flex items-center justify-center"
          :class="[blinkIconEnabled ? 'animate-fadeinfadeout' : '']"
        >
          <FontAwesomeIcon :icon="blinkIconComputed" />
        </span>
      </div>
      <div
        v-if="!hideStatus"
        class="absolute bottom-0 right-0 rounded-full ring-white"
        :class="[statusSize, statusColorComputed]"
      ></div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseAvatar',
  components: { FontAwesomeIcon },
  props: {
    initials: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: Array,
      required: false,
      default: null
    },
    imgUrl: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: (value) =>
        ['extra-small', 'small', 'medium', 'large', 'extra-large'].indexOf(
          value
        ) !== -1
    },
    ringColor: {
      type: String,
      required: false,
      default: null
    },
    borderColor: {
      type: String,
      required: false,
      default: null
    },
    shadowColor: {
      type: String,
      required: false,
      default: null
    },
    isSquare: {
      type: Boolean,
      required: false,
      default: false
    },
    hideStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    statusColor: {
      type: String,
      required: false,
      default: null
    },
    blinkIconEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    blinkIcon: {
      type: Array,
      required: false,
      default: () => ['fal', 'times']
    }
  },
  data() {
    return {
      imgAvailable: true
    }
  },
  computed: {
    mainSize() {
      switch (this.size) {
        case 'extra-small':
          return 'w-6 h-6'
        case 'small':
          return 'w-8 h-8'
        case 'large':
          return 'w-10 h-10'
        case 'extra-large':
          return 'w-12 h-12'
        default:
          return 'w-9 h-9'
      }
    },
    fontSize() {
      switch (this.size) {
        case 'extra-small':
          return 'text-xxs'
        case 'small':
          return 'text-xs'
        case 'large':
          return 'text-base'
        case 'extra-large':
          return 'text-xl'
        default:
          return 'text-sm'
      }
    },
    ringColorComputed() {
      return this.ringColor ?? 'ring-slate-300'
    },
    borderColorComputed() {
      return this.borderColor ?? 'border-white'
    },
    borderSizeComputed() {
      switch (this.size) {
        case 'extra-small':
          return 'border'
        case 'large':
          return 'border-[3px]'
        case 'extra-large':
          return 'border-4'
        case 'small':
        default:
          return 'border-2'
      }
    },
    statusSize() {
      switch (this.size) {
        case 'extra-small':
          return 'w-1.5 h-1.5 ring-1'
        case 'small':
          return 'w-1.5 h-1.5 ring-2'
        case 'large':
          return 'w-2 h-2 ring-2'
        case 'extra-large':
          return 'w-4 h-4 ring-4'
        default:
          return 'w-2 h-2 ring-2'
      }
    },
    statusColorComputed() {
      return this.statusColor ?? 'bg-slate-600'
    },
    shadowColorComputed() {
      return this.shadowColor ?? ''
    },
    blinkIconComputed() {
      return Array.isArray(this.blinkIcon)
        ? this.blinkIcon
        : ['fal', this.blinkIcon]
    }
  },
  methods: {
    imgNotAvailable() {
      this.imgAvailable = false
    }
  }
}
</script>
