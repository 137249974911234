<template>
  <nav class="space-y-1">
    <div>
      <!--#region settings -->
      <div class="my-5">
        <settings-nav-link-component
            route-name="sa_management_home_route"
            label="home"
            icon="home"
        />

        <settings-nav-link-component
            route-name="management_commands_route"
            label="commandes"
            icon="cogs"
        />
        <settings-nav-link-component
            route-name="management_db_route"
            label="DB"
            icon="database"
        />
      </div>
      <!--#endregion -->

    </div>
  </nav>
</template>

<script>
import SettingsNavLinkComponent from './SettingsNavLinkComponent'

export default {
  name: 'TheSaManagementNav',
  components: {
    SettingsNavLinkComponent
  },
  data() {
    return {}
  }
}
</script>
