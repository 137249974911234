<template>
  <div :class="cGroupClass">
    <label
      v-if="label"
      :for="name"
      class="capitalize-first"
      :class="cLabelClass"
      >{{ label }}&nbsp;<span v-if="label && required" class="text-red-600"
        >*</span
      >
    </label>
    <datepicker
      v-model="value"
      :month-heading-format="monthFormat"
      :weekday-format="weekFormat"
      :input-format="format"
      :week-starts-on="startingDayOfTheWeek"
      :clearable="clearButtonVisible"
      :disabled="disabled"
      :typeable="canTypeDateManually"
      :starting-view="viewType"
      :style="style"
      :locale="locale"
      :class="cInputClass"
    />

    <template v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import moment from 'moment'
import { fr } from 'date-fns/locale'

export default {
  name: 'BaseSimpleDatePicker',
  components: { Datepicker },
  props: {
    defaultValue: {},
    label: { type: String, require: false },
    name: { type: String, required: true },
    required: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false, default: false },
    canTypeDateManually: { type: Boolean, required: false, default: false },
    clearButtonVisible: { type: Boolean, required: false, default: false },
    viewType: {
      type: String,
      required: false,
      default: 'day',
      validator: function (value) {
        const admittedTypes = ['day', 'month', 'year', 'time']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    monthFormat: { type: String, required: false, default: 'LLLL yyyy' },
    weekFormat: { type: String, required: false, default: 'EE' },
    startingDayOfTheWeek: { type: Number, required: false, default: 1 },
    format: { type: String, required: false, default: 'dd/MM/yyyy' },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    errors: { type: Object, required: false }
  },
  data() {
    return {
      value: this.defaultValue,
      locale: fr,
      style: {
        '--vdp-bg-color': '#ffffff',
        '--vdp-text-color': '#424242',
        '--vdp-box-shadow':
          '0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)',
        '--vdp-border-radius': '8px',
        '--vdp-heading-size': '2.5em',
        '--vdp-heading-weight': 'bold',
        '--vdp-heading-hover-color': '#eeeeee',
        '--vdp-arrow-color': 'currentColor',
        '--vdp-elem-color': 'currentColor',
        '--vdp-disabled-color': '#d5d9e0',
        '--vdp-hover-color': '#ffffff',
        '--vdp-hover-bg-color': '#a30800',
        '--vdp-selected-color': '#ffffff',
        '--vdp-selected-bg-color': '#a30800',
        '--vdp-elem-font-size': '0.8em',
        '--vdp-elem-border-radius': '3px',
        '--vdp-divider-color': '#d5d9e0'
      }
    }
  },
  computed: {
    cInputDesabled() {
      return 'bg-gray-100 cursor-not-allowed dark:bg-slate-800 dark:border-transparent'
    },
    cInputClass() {
      return this.disabled
        ? 'w-full border shadow-sm py-2 px-3 rounded-md appearance-none placeholder-gray-500 dark:bg-slate-800 dark:border-transparent border-slate-300 focus:border-blue-300 focus:ring-blue-300 focus:ring-1' +
            'bg-gray-100 cursor-not-allowed dark:bg-slate-800 dark:border-transparent bg-gray-100'
        : 'w-full border shadow-sm py-2 px-3 rounded-md appearance-none placeholder-gray-500 dark:bg-slate-800 dark:border-transparent border-slate-300 focus:border-blue-300 focus:ring-blue-300 focus:ring-1'
    },
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cLabelClass() {
      return this.labelClass === '' ? 'form-label' : this.labelClass
    }
  },
  watch: {
    value(newValue) {
      this.$emit('change', {
        attribute: this.name,
        value: moment(newValue).format('DD/MM/YYYY')
      })
    }
  }
}
</script>
<style scoped></style>
