const WorkInProgressComponent = () =>
  import(
    /* webpackChunkName: "group-statistics" */ '@/views/wip/WorkInProgressComponent'
  )
const TheStatisticsIndexPage = () =>
  import(
    /* webpackChunkName: "group-statistics" */ '@/views/statistics/TheStatisticsIndexPage.vue'
  )

export const statisticsRootPages = {
  path: 'statistics',
  name: 'statistics_page_route',
  component: WorkInProgressComponent
}

export const statisticsSubPages = [
  {
    path: 'statistics/dashboard',
    name: 'dashboard_statistics_route',
    component: TheStatisticsIndexPage
  },
  {
    path: 'statistics/investigations',
    name: 'investigations_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/deceased',
    name: 'deceased_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/funeral',
    name: 'funeral_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/employees',
    name: 'employees_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/invoicing',
    name: 'invoicing_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/locations',
    name: 'locations_statistics_route',
    component: WorkInProgressComponent
  },
  {
    path: 'statistics/products',
    name: 'products_statistics_route',
    component: WorkInProgressComponent
  }
]
