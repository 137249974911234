<template>
  <div
    class="flex flex-col divide-y rounded-md border bg-white p-4 pt-3 shadow-xl"
  >
    <div v-if="modelNotification.id">
      <div class="flex flex-row pb-2">
        <div class="flex flex-1 flex-col">
          <div class="font-bold">{{ fromText }}</div>
          <div class="text-sm text-gray-600">{{ modelNotification.subject }}</div>
          <div class="text-xs">
            <div v-if="toValid">
              To: <span class="text-gray-400">{{ toText }}</span>
            </div>
            <div v-if="ccValid">
              Cc: <span class="text-gray-400">{{ ccText }}</span>
            </div>
            <div v-if="bccValid">
              Bcc: <span class="text-gray-400">{{ bccText }}</span>
            </div>
          </div>
        </div>
        <div class="shrink-0">
          <div class="text-right text-xs">
            {{ sendDateHour }}
          </div>
        </div>
      </div>
      <div class="min-h-[12rem] py-2">
        <div
          v-if="modelNotification.email_attachments"
          class="grid grid-cols-2 gap-2 md:grid-cols-3 xl:grid-cols-4"
        >
          <div
            v-for="attachement in Object.values(
            modelNotification.email_attachments
          )"
            :key="attachement.uuid"
          >
            <AttachmentComponent :attachment="attachement" />
          </div>
        </div>
        <div class="prose prose-sm" v-html="modelNotification.message"></div>
      </div>

      <div v-if="requireToShowButtons()" class="flex flex-row pt-2">
        <div
          v-if="hasModel() || hasModelType('document') || hasModelType('invoice')"
          class="flex flex-1 flex-row gap-2"
        >
          <BaseButton
            v-if="hasDossier()"
            icon="eye"
            :title="$t('button.go_to_dossier')"
            :size="'small'"
            @click="showDossier()"
          />
          <BaseButton
            v-if="hasModelType('document')"
            icon="eye"
            :title="$t('button.go_to_document')"
            :size="'small'"
            @click="showModel('document')"
          />
          <BaseButton
            v-if="hasModelType('invoice')"
            icon="eye"
            :title="$t('button.go_to_invoice')"
            :size="'small'"
            @click="showModel('invoice')"
          />
        </div>
        <div class="flex shrink-0 flex-row gap-2">
          <BaseButton
            v-if="isDraft()"
            icon="edit"
            :title="$t('button.edit')"
            :size="'small'"
            color="secondary"
            @click="
            $router.push({
              name: 'mail_box_edit_route',
              params: { message_id: modelNotification.id }
            })
          "
          />
          <BaseButton
            v-if="isDraft()"
            icon="paper-plane"
            :title="$t('button.send')"
            :size="'small'"
            :disabled="!toValid"
            @click="sendNotification"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      style="margin: 0 -15px;"
    >
      <MailBoxFormComponent
        :model-notification="modelNotification"
        @update:model-notification="editNotification"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import NotificationState from '@/assets/enums/notifications/NotificationState'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { mapActions, mapGetters } from 'vuex'
import mailboxTypes from '@/assets/enums/notifications/mailboxTypes'
import AttachmentComponent from '@/views/modelNotification/index/components/AttachmentComponent.vue'
import MailBoxFormComponent from '@/views/modelNotification/form/MailBoxFormComponent.vue'

export default defineComponent({
  name: 'MessageComponent',
  components: { MailBoxFormComponent, AttachmentComponent, BaseButton },
  props: {
    modelNotification: {
      type: Object,
      required: true
    }
  },
  emits: ['message-updated'],
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth'
    }),
    NotificationState() {
      return NotificationState
    },
    sendDateHour() {
      return this.$h.momentLocalised(
        this.modelNotification.send_date,
        'LLLL',
        this.$i18n.locale.substring(0, 2)
      )
    },
    toText() {
      return this.toValid
        ? this.modelNotification.to.map((item) => item.name).join(', ')
        : this.$t('global.undefined')
    },
    ccText() {
      return this.ccValid
        ? this.modelNotification.cc.map((item) => item.name).join(', ')
        : this.$t('global.undefined')
    },
    bccText() {
      return this.bccValid
        ? this.modelNotification.bcc.map((item) => item.name).join(', ')
        : this.$t('global.undefined')
    },
    fromText() {
      return this.modelNotification.from?.name
    },
    toValid() {
      return (
        Array.isArray(this.modelNotification.to) &&
        this.modelNotification.to.length
      )
    },
    ccValid() {
      return (
        Array.isArray(this.modelNotification.cc) &&
        this.modelNotification.cc.length
      )
    },
    bccValid() {
      return (
        Array.isArray(this.modelNotification.bcc) &&
        this.modelNotification.bcc.length
      )
    }
  },
  methods: {
    ...mapActions({
      processAddOrUpdateModelNotificationAction:
        'modelNotification/addOrUpdateModelNotification'
    }),
    sendNotification() {
      this.modelNotification.state = NotificationState.TO_BE_SENDED
      this.modelNotification.sender_id = this.auth.id
      this.modelNotification.sender = this.auth.full_name
      this.modelNotification.priority = 'false'

      if (!this.modelNotification.attachments) {
        this.modelNotification.attachments = []
      }

      this.processAddOrUpdateModelNotificationAction(
        this.modelNotification
      ).then((result) => {
        this.$h.toastSuccess(this.$t('toasts.email_sent'))

        this.$emit('message-updated')

        if (this.$route.params.path !== mailboxTypes.SENT) {
          this.$router.push({
            name: 'mail_box_page_group_id',
            params: { path: mailboxTypes.SENT, groupId: result.groupId }
          })
        }
      })
    },
    editNotification(newItem) {
      this.$emit('message-updated', newItem)
    },
    hasModel() {
      return (
        !!this.modelNotification?.model_id &&
        !!this.modelNotification?.model_type &&
        !!this.toValid
      )
    },
    requireToShowButtons() {
      return this.isDraft() || this.hasModel()
    },
    isDraft() {
      return this.modelNotification?.state === NotificationState.DRAFT
    },
    getModelType(typeName) {
      return this.modelNotification?.email_attachments?.map(
        (item) =>
          Object.keys(item?.custom_properties || {})
            .map((_k) => item.custom_properties[_k])
            .filter((_item) => _item?.type?.match(new RegExp(typeName, 'i')))[0]
      )[0]
    },
    hasModelType(typeName) {
      return !!this.getModelType(typeName)
    },
    hasDossier() {
      return !!this.modelNotification?.model_type?.match(/dossier/i)
    },
    showModel(typeName) {
      const model = this.getModelType(typeName)

      if (model) {
        const regexp = new RegExp(typeName, 'i')

        if ('document'.match(regexp) && this.hasDossier()) {
          this.$router.push({
            name: 'dossier_form_document_preview_route',
            params: {
              id: this.modelNotification.model_id,
              document_type: model.type,
              document_id: model.id
            }
          })
        } else if ('invoice'.match(regexp)) {
          this.$router.push({
            name: 'invoice_show_route',
            params: {
              invoicing_id: model.id
            }
          })
        }
      }
    },
    showDossier() {
      this.$router.push({
        name: 'dossier_form_edit_route',
        params: {
          id: this.modelNotification.model_id
        }
      })
    }
  }
})
</script>
