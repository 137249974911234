<template>
  <div
    id="dossier_layout"
    class="box relative mx-5 flex h-full flex-col overflow-y-scroll bg-gray-50"
  >
    <div
      v-if="$slots.cta || withEditionModeButton || withBackButton"
      class="sticky left-0 right-0 top-0 z-50 flex justify-between border-b bg-white/90 p-3"
    >
      <div class="flex justify-center gap-x-3">
        <div class="flex items-center gap-x-3">
          <FontAwesomeIcon v-if="icon" :icon="['fal', icon]" size="xl" />
          <!--          <h2 class="text-2xl font-bold capitalize-first">{{ title }}</h2>-->
        </div>
      </div>
      <div class="flex justify-center">
        <div class="flex gap-x-3" :class="ctaClass">
          <slot name="cta" />
          <BaseButton
            v-if="
              withEditionModeButton &&
              $route.meta.display_type &&
              $route.meta.switch_type_route
            "
            theme-color="bg-secondary text-contrast-secondary"
            :icon="$route.meta.display_type === 'view' ? 'pen' : 'times'"
            :title="
              $route.meta.display_type === 'view'
                ? $t('button.edit')
                : $t('button.cancel')
            "
            :disabled="formSubmitting"
            :display-loader="formSubmitting"
            @click="$router.push({ name: $route.meta.switch_type_route })"
          />
          <BaseButton
            v-if="withBackButton"
            color="dark"
            icon="long-arrow-left"
            :title="$t('button.back')"
            @click="$router.push({ name: backRoute })"
          />
        </div>
      </div>
    </div>
    <div class="flex-1" :class="[notScrollable ? 'min-h-0' : '']">
      <slot class="" />
    </div>
    <div
      v-if="!hideFooterBar"
      class="sticky bottom-0 left-0 right-0 z-50 flex flex-row items-center gap-x-3 border-t bg-white/90"
    >
      <div class="flex-1 px-3">
        <slot name="footer" />
      </div>
      <div class="flex shrink-0 flex-row gap-x-3 p-3">
        <BaseButton
          v-if="prevButtonCondition"
          theme-color="bg-secondary text-contrast-secondary"
          :title="$t('button.previous')"
          icon="chevron-left"
          :disabled="formSubmitting"
          :display-loader="formSubmitting"
          inverse-icon-order
          @click="
            handleMoveButtonWithoutSave(firstMenuPreviousEntry?.routeName)
          "
        />
        <BaseButton
          v-if="prevAndSaveButtonCondition"
          theme-color="bg-secondary text-contrast-secondary"
          icon="chevron-left"
          :title="$t('button.previous_and_save')"
          :disabled="formSubmitting"
          :display-loader="formSubmitting"
          inverse-icon-order
          @click="handleMoveAndSaveButton(firstMenuPreviousEntry?.routeName)"
        />
        <BaseButton
          v-if="nextAndSaveButtonCondition"
          theme-color="bg-secondary text-contrast-secondary"
          icon="chevron-right"
          :title="$t('button.next_and_save')"
          :disabled="formSubmitting"
          :display-loader="formSubmitting"
          @click="handleMoveAndSaveButton(firstMenuNextEntry?.routeName)"
        />
        <BaseButton
          v-if="nextButtonCondition"
          theme-color="bg-secondary text-contrast-secondary"
          icon="chevron-right"
          :title="$t('button.next')"
          :disabled="formSubmitting"
          :display-loader="formSubmitting"
          @click="handleMoveButtonWithoutSave(firstMenuNextEntry?.routeName)"
        />
        <BaseButton
          v-if="submitButtonCondition || displaySubmitButtonWithoutEditionMode"
          icon="paper-plane"
          :title="$t('button.save')"
          :disabled="formSubmitting || !dossierDataChanged"
          :display-loader="formSubmitting"
          @click="handleSubmitMethod($route.meta.switch_type_route)"
        />
        <!--#endregion -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { toRaw } from 'vue'
import BaseUnsavedChangesNotificationMixin from '@u/mixins/BaseUnsavedChangesNotificationMixin'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { PlaceType } from '@/assets/enums'
import MenuDossierMixin from '@u/mixins/Dossier/MenuDossierMixin'

export default {
  name: 'BaseDossierPageLayout',
  components: { BaseButton, FontAwesomeIcon },
  mixins: [BaseUnsavedChangesNotificationMixin, MenuDossierMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    ctaClass: {
      type: String,
      required: false,
      default: ''
    },
    withBackButton: {
      type: Boolean,
      required: false,
      default: false
    },
    withSubmitButton: {
      type: Boolean,
      required: false,
      default: true
    },
    withEditionModeButton: {
      type: Boolean,
      required: false,
      default: true
    },
    withNextButton: {
      type: Boolean,
      required: false,
      default: false
    },
    submitMethod: {
      type: Function,
      required: false,
      default: null
    },
    backRoute: {
      type: String,
      required: false,
      default: null
    },
    displaySubmitButtonWithoutEditionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFooterBar: {
      type: Boolean,
      required: false,
      default: false
    },
    notScrollable: {
      type: Boolean,
      required: false,
      default: false
    },
    dossierDataChanged: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['canceled'],
  data() {
    return {
      formSubmitting: false
    }
  },
  computed: {
    PlaceType() {
      return PlaceType
    },
    ...mapGetters({
      editionMode: 'dossier/getEditionMode',
      errors: 'auth/getErrors',
      dossierHasChanged: 'dossier/getHasChanged'
    }),
    prevAndSaveButtonCondition() {
      if (!this.withSubmitButton || !this.submitMethod) return false
      return this.firstMenuHasPreviousEntry && this.dossierDataChanged
    },
    nextAndSaveButtonCondition() {
      if (!this.withSubmitButton || !this.submitMethod) return false
      return this.firstMenuHasNextEntry && this.dossierDataChanged
    },
    prevButtonCondition() {
      return (
        this.firstMenuHasPreviousEntry &&
        this.withNextButton &&
        !this.dossierDataChanged
      )
    },
    nextButtonCondition() {
      return (
        this.firstMenuHasNextEntry &&
        this.withNextButton &&
        !this.dossierDataChanged
      )
    },
    submitButtonCondition() {
      if (!this.withSubmitButton || typeof this.submitMethod !== 'function')
        return false
      return this.editionMode
    },
    errorsIsEmpty() {
      return Object.keys(toRaw(this.errors)).length === 0
    }
  },
  watch: {
    '$route.meta.display_type'(newValue, oldValue) {
      if (this.$route.meta.display_type === 'view') {
        this.processSetEditionModeMutation(false)
      } else if (this.$route.meta.display_type === 'edit') {
        this.processSetEditionModeMutation(true)
      }
    }
  },
  beforeMount() {
    this.processSetLeaveRouteByButtonMutation(false)
    this.processSetLeaveRouteByNotificationMutation(false)
    if (this.$route.meta.display_type === 'view') {
      this.processSetEditionModeMutation(false)
    } else if (this.$route.meta.display_type === 'edit') {
      this.processSetEditionModeMutation(true)
    }
  },
  methods: {
    ...mapMutations({
      processSetEditionModeMutation: 'dossier/setEditionMode',
      processSetLeaveRouteByButtonMutation: 'dossier/setLeaveRouteByButton',
      processSetLeaveRouteByNotificationMutation:
        'dossier/setLeaveRouteByNotification'
    }),
    handleMoveAndSaveButton(route_name) {
      this.formSubmitting = true
      this.submitMethod()
        .then((_response) => {
          this.formSubmitting = false
          if (this.errorsIsEmpty) {
            //this.processSetLeaveRouteByButtonMutation(true)
            this.$router.push({
              name: route_name,
              params: { id: this.$route.params.id },
              meta: { isDossierPage: true }
            })
          }
        })
        .catch((_reason) => {
          this.formSubmitting = false
        })
    },
    handleMoveButton(route_name) {
      this.processSetLeaveRouteByButtonMutation(true)
      if (this.dossierHasChanged) {
        this.processSetLeaveRouteByNotificationMutation(true)
        this.handleUnsavedChangesNotification(
          {
            name: route_name,
            params: { id: this.$route.params.id }
          },
          this.submitMethod
        )
      } else {
        this.$router.push({
          name: route_name,
          params: { id: this.$route.params.id }
        })
      }
    },
    handleMoveButtonWithoutSave(route_name) {
      this.processSetLeaveRouteByNotificationMutation(false)
      this.$router.push({
        name: route_name,
        params: { id: this.$route.params.id }
      })
    },
    handleSubmitMethod(route_name) {
      if (typeof this.submitMethod === 'function') {
        this.formSubmitting = true
        this.submitMethod()
          .then((_response) => {
            this.formSubmitting = false
            if (this.errorsIsEmpty) {
              this.processSetLeaveRouteByButtonMutation(true)
              this.$router.push({
                name: route_name,
                params: { id: this.$route.params.id },
                meta: { isDossierPage: true }
              })
            }
          })
          .catch((_reason) => {
            this.formSubmitting = false
          })
      }
    },
    switchEditionMode(new_state) {
      if (this.editionMode) {
        this.$emit('canceled')
      }
      this.processSetEditionModeMutation(new_state)
    }
  }
}
</script>
