const ThePackIndexPage = () =>
  import('@/views/settings/invoicing/pack/index/ThePackIndexPage')
const ThePackFormTabComponent = () =>
  import('@/views/settings/invoicing/pack/form/ThePackFormTabComponent')
const ThePackInformationPage = () =>
  import(
    '@/views/settings/invoicing/pack/form/tabs/packInformation/ThePackInformationPage'
  )
const ThePackTranslationPage = () =>
  import(
    '@/views/settings/invoicing/pack/form/tabs/packTranslation/ThePackTranslationPage'
  )
const ThePackActivitiesPage = () =>
  import(
    '@/views/settings/invoicing/pack/form/tabs/packActivities/ThePackActivitiesPage'
  )

export const settingsPackRoute = [
  {
    path: 'pack',
    children: [
      {
        path: '',
        name: 'settings_list_pack_route',
        component: ThePackIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_pack_profile_route',
        props: true,
        component: ThePackFormTabComponent,
        children: [
          {
            path: '',
            component: ThePackInformationPage,
            name: 'settings_edit_pack_route'
          },
          {
            path: 'translation',
            component: ThePackTranslationPage,
            props: true,
            name: 'settings_edit_pack_translation_route'
          },
          {
            path: 'activities',
            component: ThePackActivitiesPage,
            props: true,
            name: 'settings_edit_pack_activity_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: false },
            { title: 'content_item_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_packs_route',
        component: ThePackFormTabComponent,
        children: [
          {
            path: '',
            component: ThePackInformationPage,
            name: 'settings_create_pack_route'
          },
          {
            path: 'translation',
            component: ThePackTranslationPage,
            props: true,
            name: 'settings_create_pack_translation_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_item_new_title', active: true }
          ]
        }
      }
    ]
  }
]
