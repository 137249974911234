<template>
  <h2
    class="text-2xl font-bold font-medium leading-6 text-gray-900 capitalize-first"
  >
    {{ label }}
  </h2>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>
