<template>
  <button
    class="flex flex-row items-center justify-center gap-2 rounded-md border bg-white p-2 font-bold"
    @click="isNotificationModalOpened = true"
  >
    <FontAwesomeLayers class="fa-lg">
      <FontAwesomeIcon :icon="['fal', 'bell']" />
      <FontAwesomeLayersText
        counter
        position="top-right"
        :value="unread_notifications"
      />
    </FontAwesomeLayers>
  </button>
  <BaseSlideOverModal
    v-model:is-modal-open="isNotificationModalOpened"
    width="xs"
    :title="$t('global.notifications')"
    hide-close-button
  >
    <template #body>
      <div class="flex h-full w-full flex-col gap-2 overflow-y-auto">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="flex flex-col rounded border p-2"
        >
          <div class="flex flex-row gap-1">
            <div
              class="shrink-0 pt-0.5"
              :class="[notification.icon?.color ? notification.icon.color : '']"
            >
              <FontAwesomeIcon
                v-if="notification.icon?.fa"
                fixed-width
                :icon="notification.icon.fa"
                size="lg"
              />
              <FontAwesomeIcon
                v-else
                fixed-width
                :icon="['fal', 'info-circle']"
                class="text-primary"
                size="lg"
              />
            </div>
            <div class="min-w-0 flex-1">
              <p
                v-if="notification.data.type === 'free_text'"
                class="whitespace-pre-line"
              >
                {{ notification.data.text ?? '' }}
              </p>
              <p
                v-else
                v-html="
                  getNotificationText(
                    notification.data.translation.key,
                    notification.data.translation.params
                  )
                "
              ></p>
              <div class="flex justify-end text-xs text-slate-500">
                {{ $h.formatDate(notification.created_at, 'LLLL') }}
              </div>
            </div>
            <div v-if="!notification.read_at" class="shrink-0 text-primary">
              <FontAwesomeIcon
                fixed-width
                :icon="['fas', 'circle']"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'

export default {
  name: 'NotificationComponent',
  components: {
    BaseSlideOverModal,
    FontAwesomeLayersText,
    FontAwesomeLayers,
    FontAwesomeIcon
  },
  data() {
    return {
      isNotificationModalOpened: false,
      unread_notifications: 2,
      notifications: []
    }
  },
  computed: {
    ...mapGetters({
      proposeItem: 'dossier/getProposeItem'
    })
  },
  watch: {
    isNotificationModalOpened(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.fetchNotification()
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      setProposeItem: 'dossier/setProposeItem'
    }),
    ...mapActions({
      processFetchNotificationsAction: 'notification/fetchNotifications'
    }),
    fetchNotification() {
      this.processFetchNotificationsAction().then((response) => {
        this.notifications = response.notifications
      })
    },
    handleNotificationToast(data) {
      if (data.type === 'free_text') {
        this.$h.toastInfo(data.text)
      } else {
        this.$h.toast(
          data.type ?? 'info',
          this.getNotificationText(
            data.translation.key,
            data.translation.params
          ),
          null,
          data.uuid
        )
      }
    },
    getNotificationText(key, params) {
      let final_params = {}
      for (let property in params) {
        if (params[property].type && params[property].type === 'translation') {
          final_params[property] = this.$t(params[property].value)
        } else {
          final_params[property] = params[property]
        }
      }
      return this.$t(`notifications.${key}`, final_params)
    }
  }
}
</script>
