import apiClient from '@u/apiClient'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}
export default {
  fetchAccountingDiaryListForExport({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('/invoice/export/account-diary')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  exportInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/invoice/export/account-diary`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  exportPDFInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/invoice/export-pdf`, {params: payload.data})
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchBankAccountListForExport({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('/invoice/export/bank-account')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  exportTreasury: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/invoice/export/bank-account`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  exportUnpaid: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/invoice/export-unpaid-invoice`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  invoiceExportExcel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/invoice/export-excel`, payload)
        .then((response) => {
          window.open(response.data.url)
          processSuccessCallback(payload)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
