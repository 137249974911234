<template>
  <div class="relative" :class="label ? 'mt-2' : ''">
    <label
      v-if="label"
      :for="name"
      class="absolute -top-2 left-2 z-10 -mt-px inline-block h-3.5 whitespace-nowrap rounded-b bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
      >{{ label }}
      <span v-if="label && required" class="text-red-600">&nbsp;*</span>
    </label>
    <div
      :id="`walkman-${name}`"
      class="divide-x overflow-hidden rounded-md border border-slate-300 shadow-sm"
      :class="[wFull ? 'flex flex-row' : 'inline-flex']"
      role="group"
    >
      <button
        v-for="(option, index) in options"
        :key="index"
        type="button"
        :disabled="!editionMode"
        :class="[
          option[trackBy] === selectedValues
            ? 'bg-primary-500 text-white hover:bg-primary-400 focus:bg-primary-400 active:bg-primary-400 '
            : 'bg-white text-gray-900 hover:bg-primary-100 focus:bg-primary-100 active:bg-primary-100',
          buttonClass,
          wFull ? 'flex-1' : ''
        ]"
        class="text-sm font-medium outline-0 capitalize-first disabled:cursor-not-allowed"
        @click="() => (selectedValues = option[trackBy])"
      >
        <span v-if="isIcon"
          ><FontAwesomeIcon :icon="['fas', option['icon']]"
        /></span>
        <span v-else class="block truncate">{{ option[attributeLabel] }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'WalkmanButton',
  components: { FontAwesomeIcon },
  props: {
    modelValue: {
      type: [String, Boolean],
      default: null,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: 'value'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'label'
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedBackground: {
      type: String,
      required: false,
      default: 'bg-gray-200'
    },
    size: {
      type: String,
      default: '',
      required: false
    },
    wFull: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value)
        emit('change', value)
      }
    })

    return { selectedValues }
  },
  computed: {
    buttonClass() {
      switch (this.size) {
        case 'extra-small':
          return 'px-2 py-1 gap-1 text-xs'
        case 'small':
          return 'px-3 py-1.5'
        case 'large':
          return 'px-5 py-3'
        case 'medium':
        default:
          return 'px-4 py-2'
      }
    }
  }
}
</script>
