import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      editionMode: 'dossier/getEditionMode',
      errors: 'auth/getErrors'
    }),
    errorsIsEmpty() {
      console.log('errorsIsEmpty')
      return Object.keys(this.errors).length < 1
    }
  },
  methods: {
    ...mapActions({
      fetchDossierById: 'dossier/fetchDossierByID'
    }),
    ...mapMutations({
      setDossierEditionModeMutation: 'dossier/setEditionMode',
      processSetLeaveRouteByButtonMutation: 'dossier/setLeaveRouteByButton'
    }),
    handleUnsavedChangesNotification(
      route_to,
      route_from,
      route_next,
      update_function
    ) {
      this.$h.unsavedChangesNotification(() => {
        // if (
        //   route_from.meta.display_type === 'edit' &&
        //   route_to.meta.display_type === 'view'
        // ) {
        //   // console.log('handleUnsavedChangesNotification fetch')
        //   this.fetchDossierById(this.$route.params.id)
        // }
        route_next()
      })
    }
  }
}
