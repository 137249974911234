export default {
  // #region setters
  setSelectedDossierTask(state, payload) {
    state.selectedDossierTask = payload
  },
  // #endregion
  // #region modifiers
  alterDossierTaskQuestionValue(state, payload) {
    if (payload.form === 'starting_form') {
      state.selectedDossierTask.starting_form[payload.index].value =
        payload.value
    } else if (payload.form === 'ending_form') {
      state.selectedDossierTask.ending_form[payload.index].value = payload.value
    }
  },

  updateDossierTaskInDossier(state, payload) {
    if (payload.parent_type === 'App\\Models\\Event') {
      if (state.dossier.events && state.dossier.events.length > 0) {
        state.dossier.events.forEach((event, index_event) => {
          if (event.dossier_tasks && event.dossier_tasks.length) {
            event.dossier_tasks.forEach((dossier_task, index_dossier_task) => {
              if (dossier_task.id === payload.id) {
                state.dossier.events[index_event].dossier_tasks[
                  index_dossier_task
                ] = payload
              }
            })
          }
        })
      }
    } else if (payload.parent_type === 'App\\Models\\Dossier') {
      if (state.dossier.dossier_tasks && state.dossier.dossier_tasks.length) {
        state.dossier.dossier_tasks.forEach(
          (dossier_task, index_dossier_task) => {
            if (dossier_task.id === payload.id) {
              state.dossier.dossier_tasks[index_dossier_task] = payload
            }
          }
        )
      }
    }
  },
  // #endregion
}
