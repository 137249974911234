<template>
  <div v-if="editionMode">
    <div
      v-if="defaultAjax"
      class="base-multiselect-simple relative mt-2 rounded-md border border-slate-300 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
      :class="cGroupClass"
    >
      <label
        v-if="label"
        :for="name"
        class="absolute -top-2 left-2 z-10 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
        >{{ label }}&nbsp;
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <multiselect
        :id="name"
        :key="defaultValue"
        v-model="defaultValue"
        :label="attributeLabel"
        :track-by="trackBy"
        placeholder="Type to search"
        open-direction="bottom"
        :options="options"
        :value="defaultAjax"
        :multiple="multiple"
        :searchable="true"
        :loading="loading"
        :internal-search="false"
        :clear-on-select="true"
        :close-on-select="true"
        :options-limit="300"
        :limit="300"
        :max-height="600"
        :option-height="104"
        :show-no-results="false"
        :hide-selected="true"
        :select-label="$t('button.select')"
        :deselect-label="$t('button.unselect')"
        :selected-label="$t('button.selected')"
        @search-change="fetchOption"
      >
        <template #option="{ option }">
          <div class="option__desc">
            <div class="flex gap-x-3 align-baseline">
              <div
                v-if="
                  displayedOptionIcon && (option.icon || option.searchable.icon)
                "
              >
                <font-awesome-icon
                  :icon="[
                    'fal',
                    globalSearch ? option.searchable.icon : option.icon
                  ]"
                  size="xl"
                />
              </div>
              <div>
                <div class="option__title font-semibold">
                  {{ option.title }}
                </div>
                <div class="option__desc mt-1 text-sm font-light">
                  <!--                  option.city[this.initialMetadata?.settings?.default_language] -->
                  {{
                    `${
                      globalSearch ? option.searchable.street : option.street
                    }, ${globalSearch ? option.searchable.zip : option.zip} ${
                      globalSearch ? option.searchable.city : option.city
                    }`
                  }}
                  <!--                  <p class="text-red-700">{{ typeof option.city }}</p>-->
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #noOptions
          ><span>{{ $t('global.is_empty_list') }}</span></template
        >
      </multiselect>
    </div>
  </div>

  <div v-else>
    <BaseShowLabel
      :label="label"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </div>
</template>
<script>
import apiClient from '@u/apiClient'
import multiselect from 'vue-multiselect'
import BaseEditLabel from '../../BaseLabel/BaseEditLabel.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseShowEditSelectWikiAjax',
  components: { multiselect },
  props: {
    editionMode: { type: Boolean, required: true },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    modelValue: { type: Object, required: false, default: null },
    taggable: { type: String, required: false, default: null },
    defaultAjax: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    url: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    multiple: { type: Boolean, required: false, default: false },
    label: { type: String, required: false },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    fullModelResponse: { type: Boolean, required: false, default: false },
    displayedOptionIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    globalSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'update:modelValue',
    'update:stringValue',
    'change',
    'selected:value'
  ],
  data() {
    return {
      options: [],
      loading: false,
      defaultValue: this.defaultAjax
    }
  },
  watch: {
    defaultValue(newValue) {
      if (newValue != null && newValue !== '') {
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
          this.$emit('change', newValue)
        } else {
          this.$emit('update:modelValue', newValue[this.trackBy])
          this.$emit('selected:value', newValue[this.trackBy])
          this.$emit('change', newValue)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      auth: 'auth/getAuth'
    }),
    cDisplayedValueWhenNotEditionMode() {
      if (this.attributeLabel in this.defaultValue) {
        if (
          typeof this.defaultValue[this.attributeLabel] === 'object' &&
          this.auth?.language in this.defaultValue[this.attributeLabel]
        ) {
          return this.defaultValue[this.attributeLabel][this.auth?.language]
        }
        return this.defaultValue.title
      }
      return '-'
    },
    cGroupClass() {
      return this.groupClass === '' ? '' : this.groupClass
    }
  },
  methods: {
    async fetchOption(keyword) {
      if (keyword.length > 2) {
        this.loading = true
        await apiClient
          .get(this.url + '?terms=' + keyword)
          .then(async (response) => {
            this.loading = false
            this.options = response.data
          })
      }
    }
  }
}
</script>

<style>
.v-select {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74 85 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'/></svg>");
  background-size: 18px;
  background-position: center right 0.6rem;
}

.v-select .vs__dropdown-toggle {
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #cbd5e0;
  background-repeat: no-repeat;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
}

.v-select .vs__open-indicator {
  display: none;
}

.v-select .vs__search {
  margin-block: 5px;
}

.v-select .vs__search {
  padding: 0;
}

.multiselect__option--highlight {
  background: #4386c8;
  outline: none;
  color: white;
}
</style>
