import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import initialEmailValue from "@/store/modules/email/initialEmailValue";

export default {
  namespaced: true,
  state() {
    return {
      hasChanged: false,
      modelNotificationList: [],
      modelNotificationSelected: [],
      metadata: [],
      modelNotification: initialEmailValue(),
      resetEmail: false
    }
  },
  getters,
  actions,
  mutations
}
