export default {
  setNotesIndex(state, noteList) {
    state.noteIndex = noteList
  },
  setNote(state, note) {
    state.note = note
  },
  setNoteMetadata(state, metadata) {
    state.noteMetadata = metadata
  }
}
