const WikiCityContactFormsTabsComponent = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/contacts/form/WikiCityContactFormsTabsComponent.vue'
  )
const WikiCityContactInformation = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/contacts/form/tabs/information/WikiCityContactInformation.vue'
  )
const WikiCityContactIndexPage = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/contacts/index/WikiCityContactIndexPage.vue'
  )

export const wikiCityContactRoutes = [
  {
    path: 'contacts',
    children: [
      {
        path: '',
        name: 'wiki_edit_city_contacts_page_route',
        component: WikiCityContactIndexPage,
        meta: {
          pre_page: ''
        }
      },
      {
        path: ':contact_id',
        name: 'wiki_city_edit_contact_page_route',
        component: WikiCityContactFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_contact_information_page_route',
            component: WikiCityContactInformation
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_contact_page_route',
        component: WikiCityContactFormsTabsComponent,
        children: [
          {
            path: '',
            name: 'wiki_city_create_contact_information_page_route',
            component: WikiCityContactInformation
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
