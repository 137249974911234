<template>
  <div class="mx-auto overflow-hidden bg-white shadow sm:rounded-lg">
    <div class="flex items-center justify-between">


      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-base text-3xl font-bold leading-6 text-gray-900">
          Database
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Management and backup of databases.
        </p>
      </div>
      <div>
        <BaseButton
            class="mr-5"
            color="warning"
            icon="database"
            title="dump DB"
            @click="handleDumpDBClick"
        />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">About</dt>
          <dd class="mt-1 text-sm text-gray-900">
            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
            incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
            consequat sint. Sit id mollit nulla mollit nostrud in ea officia
            proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit
            deserunt qui eu.
          </dd>
        </div>
        <div class="col-span-2">
          <dt class="text-sm font-medium text-gray-500">Dump List</dt>
          <dd v-if="dumpList" class="mt-1 text-sm text-gray-900">
            <ul
                role="list"
                class="divide-y divide-gray-200 rounded-md border border-gray-200"
            >
              <dump-component
                  v-for="dump in dumpList"
                  :key="dump.id"
                  :dump="dump"
              />
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {mapActions, mapGetters} from 'vuex'
import DumpComponent from '@/views/sa_management/database/DumpComponent.vue'

export default {
  name: 'TheSAMDatabasePage',
  components: {DumpComponent},
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      dumpList: 'saManagement/getDumpList',
      auth: 'auth/getAuth'
    }),
    deathPlaceTypesOptions() {
      return this.initialMetadata?.enums?.place_types?.filter(
          (place) => place.id !== 'LDEC' && place.id !== 'LCER'
      )
    }
  },
  mounted() {
    this.processFetchDumpListAction()
  },
  methods: {
    ...mapActions({
      processFetchDumpListAction: 'saManagement/fetchDumpList',
      processDumpDbAction: 'saManagement/dumpDb',
    }),
    handleDumpDBClick() {
      this.processDumpDbAction({
        successCallback: () => {
          this.$h.toastify('cette sauvegarde a été effectuée avec succés', {
            className: 'toastify-content success'
          })
        }
      })
    }

  }
}
</script>
