const TypeForm = Object.freeze({
  INPUT: 'input',
  INPUT_NUMBER: 'input_number',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  RADIO: 'radio',
  DATEPICKER: 'datepicker',
  SELECT_AJAX: 'select_ajax',
  BOOLEAN_RADIO: 'boolean_radio',
  CHECKBOX_LIST: 'checkbox_list',
  SELECT_CONTACT_DOSSIER: 'select_contact_dossier',
  SELECT_TRANSFERT_DOSSIER: 'select_transfert_dossier',
  SELECT_FUNERAILLES_DOSSIER: 'select_funerailles_dossier',
  SELECT_CEREMONY_DOSSIER: 'select_ceremony_dossier',
  SELECT_DEMANDEUR_FORM: 'select_demandeur_form',
})

export default TypeForm
