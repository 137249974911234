import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import initialEmailValue from '@/store/modules/email/initialEmailValue'

export default {
  namespaced: true,
  state() {
    return {
      hasChanged: false,
      isModalOpen: false,
      defaultAttachments: [],
      email: initialEmailValue()
    }
  },
  getters,
  actions,
  mutations
}
