import TheSupplierIndexPage from '@/views/settings/invoicing/supplier/index/TheSupplierIndexPage.vue'
const TheSupplierFormsTabComponent = () =>
  import(
    '@/views/settings/invoicing/supplier/form/TheSupplierFormsTabComponent.vue'
  )
const TheSupplierFormPage = () =>
  import(
    '@/views/settings/invoicing/supplier/form/tab/supplierInformation/TheSupplierFormPage.vue'
  )
const TheSupplierActivityPage = () =>
  import(
    '@/views/settings/invoicing/supplier/form/tab/supplierActivity/TheSupplierActivityPage.vue'
  )

export const settingsSupplierRoutes = [
  {
    path: 'supplier',
    children: [
      {
        path: '',
        name: 'settings_list_supplier_route',
        component: TheSupplierIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_supplier_info_route',
        props: true,
        component: TheSupplierFormsTabComponent,
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheSupplierFormPage,
            name: 'settings_edit_supplier_route'
          },
          {
            path: 'activities',
            component: TheSupplierActivityPage,
            props: true,
            name: 'settings_edit_supplier_activity_route'
          }
        ]
      },
      {
        path: 'new',
        name: 'settings_create_supplier_info_route',
        component: TheSupplierFormsTabComponent,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheSupplierFormPage,
            name: 'settings_create_supplier_route'
          },
          {
            path: '',
            component: TheSupplierFormPage,
            name: 'settings_create_supplier_activity_route'
          }
        ]
      }
    ]
  }
]
