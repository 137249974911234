import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      siteList: [],
      siteActivities: [],
      metadataActivities: [],
      site: {},
      siteMetadata: {},
      editionMode: false
    }
  },
  getters,
  actions,
  mutations
}
