<template>
  <div class=" mt-12 w-1/2 mx-auto flex flex-col gap-y-12 text-center text-theme-primary">
    <p class="mt-12 text-5xl capitalize-first">
      page under construction
    </p>
    <font-awesome-icon
      :icon="['fal', 'user-hard-hat']"
      size="10x"
    />
  </div>
</template>

<script>
export default {
  name: 'DossierInProgressComponent'
}
</script>
