<template>
  <div :class="[
      small ? 'h-8 text-xs px-2 py-1' : 'px-3 py-2',
      ` rounded-md border border-${internalColor} text-${internalColor} select-none flex items-center`]">
    <div :class="[
        small ? 'text-xs px-1 mr-1' : 'px-1.5 mr-2',
        ` text-white bg-${internalColor} rounded-full`]">
      {{ amount }}
    </div>
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "BaseDisabledButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: function (value) {
        const possibleTypes = [
          '',
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'dark',
          'info'
        ]
        return possibleTypes.indexOf(value) !== -1
      }
    },
    useThemeColor: {
      type: Boolean,
      required: false,
      default: false,
    },
    amount: {
      type: [String, Number],
      required: false,
      default: null
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    internalColor(){
      if(this.useThemeColor){
        return `theme-${this.color}`
      } else {
        switch (this.color){
          case 'secondary':
            return 'theme-secondary'
          case 'success':
            return 'theme-success'
          case 'warning':
            return 'theme-warning'
          case 'danger':
            return 'theme-danger'
            break
          case 'dark':
            break
          case 'info':
            return 'theme-faded'
          default:
            return 'theme-primary'
        }
      }
    }
  }
}
</script>
