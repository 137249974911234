export default {
  setLocationList(state, LocationList) {
    state.locationList = LocationList
  },
  setLocation(state, Location) {
    state.location = Location
  },
  setLocationMetadata(state, metadata) {
    state.locationMetadata = metadata
  },
  setLocationActivity(state, activity) {
    state.locationActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  }
}
