<template>
  <router-link
    v-if="!disabled"
    :to="{ name: routeName }"
    active-class="bg-blue-50 border-theme-secondary"
    class="flex space-x-2 border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
  >
    <font-awesome-icon
      :icon="['fal', icon]"
      fixed-width
    />&nbsp;
    <span class="cursor-pointer text-sm capitalize-first">{{ label }}</span>
  </router-link>

  <span
    v-else
    class="flex space-x-2 border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
  >
    <font-awesome-icon
      :icon="['fal', icon]"
      fixed-width
    />&nbsp;
    <span class="cursor-pointer text-sm capitalize-first">{{ label }}</span>
  </span>
</template>

<script>
export default {
  name: 'SettingsNavLinkComponent',
  props: {
    routeName: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
