<template>
  <div class="flex flex-col gap-3">
    <div class="flex flex-row items-center">
      <div class="flex-1">
        <h3 class="text-lg font-medium leading-none capitalize-first">
          {{ title }}
        </h3>
      </div>
      <div v-if="editionMode" class="flex-shrink-0">
        <BaseSearchInput v-model="search_value" size="small" />
      </div>
    </div>
    <div class="flex flex-row flex-wrap gap-2">
      <template v-for="user_id in userListFiltered" :key="user_id">
        <div class="relative">
          <button
            v-if="editionMode"
            class="absolute -right-0.5 -top-0.5 h-4 w-4 rounded-full text-xs text-white"
            :class="[usersId.includes(user_id) ? 'bg-red-700' : 'bg-green-700']"
            type="button"
            @click="() => clickUser(user_id)"
          >
            <FontAwesomeIcon
              :icon="['fal', usersId.includes(user_id) ? 'times' : 'plus']"
            />
          </button>
          <AvatarComponent size="8" :user_id="user_id" :badge="true" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AvatarComponent from '@c/AvatarComponent.vue'
import BaseSearchInput from '@c/addf-package/components/BaseInput/BaseSearchInput.vue'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'UsersSelectorComponent',
  components: { FontAwesomeIcon, BaseSearchInput, AvatarComponent },
  props: {
    usersId: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'User'
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:userId'],
  data() {
    return {
      search_value: ''
    }
  },
  computed: {
    ...mapGetters({
      userList: 'metadata/getUsersMetadata'
    }),
    userListFiltered() {
      if (this.search_value.length > 0) {
        return this.userList
          .filter((user) =>
            user.full_name
              .toLocaleLowerCase()
              .includes(this.search_value.toLocaleLowerCase())
          )
          .map((user) => user.id)
      }
      return this.userList
        .filter((user) => this.usersId.includes(user.id))
        .map((user) => user.id)
    }
  },
  watch: {
    usersId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('update:userId', newValue)
        }
      },
      deep: true
    }
  },
  methods: {
    clickUser(user_id) {
      if (!this.usersId.includes(user_id)) {
        this.usersId.push(user_id)
      } else {
        this.usersId.splice(this.usersId.indexOf(user_id), 1)
      }
      this.search_value = ''
    }
  }
}
</script>
