<template >
  <div
    :class="[
    selected ? 'border-blue-300' : ' border-gray-200',
    'px-6 py-3 max-w-sm bg-white rounded-lg border hover:bg-gray-50 shadow-md cursor-pointer select-none'
  ]"
  >
    <div class="flex flex-row gap-x-3">
      <font-awesome-icon v-if="icon" class="mt-1 text-gray-500 " :icon="['fal', icon]" size="xl" />
      <div>
        <h5 :class="[smallText ? 'font-medium' : 'mb-2 text-xl font-semibold tracking-tight text-gray-600']">{{ title }}</h5>
        <p v-if="description" class="font-normal text-gray-500 ">{{ description }}</p>
      </div>
    </div>
  </div>
</template >

<script >
export default {
  name: "WizardOptionCard",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    smallText: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script >
