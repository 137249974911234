export default () => {
  return {
    to: [],
    cc: [],
    bcc: [],
    subject: null,
    message: null,
    model_id: null,
    model_type: null,
    sender: null,
    sender_id: null,
    creator_id: null,
    model: null,
    attachable: [],
    attachments: []
  }
}
