export default {
    getTemplateList(state) {
        return state.templateList
    },
    getTemplate(state) {
        return state.template
    },
    getCurrentTemplateColumnList(state) {
        return state.currentTemplateColumnList
    },
    getFullColumnList(state) {
        return state.fullColumnsList
    },
    getColumn(state) {
        return state.column
    },
    getFilter(state) {
        return state.template?.filters
    },
    getSort(state) {
        return state.sort
    },
    getEdited(state) {
        return state.edited
    }
}
