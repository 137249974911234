import apiClient from '@u/apiClient'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch

  async fetchAddressList({commit}) {
    await apiClient
      .get('address')
      .then(async (response) => {
        commit('setAddressList', response.data.addresses)
        commit('setAddressMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchAddressList', reason)
      })
  },

  async fetchAddressById({commit}, id) {
    await apiClient
      .get(`address/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setAddress', response.data.address)
        }
      })
      .catch(async (reason) => {
        console.error('fetchAddressById', reason)
      })
  },

  async fetchNewAddress({commit}, payload) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient
      .get('model/new?model=Address', {
        params: {
          model: 'Address',
          ...payload.params
        }
      })
      .then(async (response) => {
        await commit('setAddress', response.data.Address)
        await commit('setAddressMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewAddress', reason)
      })
  },
  // endregion

  // region store
  async storeNewAddress({commit}, payload) {
    commit('/auth/setErrors', baseErrors, {root: true})
    await apiClient
      .post('address', payload.address)
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
          commit('setAddressList', response.data.addresses)
          commit('setAddressMetadata', response.data.metadata)
        }
      })
      .catch((reason) => {
        console.error('error storeNewAddress', reason)
      })
  },
  // endregion

  // region update
  async updateAddress({commit}, payload) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient
      .put(`address/${payload.id}`, payload.address)
      .then((response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
          commit('setAddressList', response.data.addresses)
          commit('setAddressMetadata', response.data.metadata)
        }
      })
      .catch((reason) => {
        console.error('updateAddress', reason)
      })
  },
  // endregion

  // region delete
  async deleteAddressById({commit}, payload) {
    await apiClient
      .delete(`address/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setAddressList', response.data.addresses)
          await commit('setAddressMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteAddressById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteAddressById({commit}, payload) {
    await apiClient
      .get(`address/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setAddressList', response.data.addresses)
          await commit('setAddressMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteAddressById', reason)
      })
  }
  // endregion
}
