/* eslint-disable camelcase */
import apiClient from '@/utils/apiClient'
import fileDownload from 'js-file-download'

const base_errors = {}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {

    // region fetch
    async fetchAllMaterialResources({commit}) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get('material-resource')
            .then((response) => {
                commit('setMaterialResourceList', response.data.material_resources)
                commit('setMaterialResourceMetadata', response.data.metadata)
            })
            .catch(async (reason) => {
                console.error('fetchAllMaterialResources', reason)
            })
    },

    async fetchMaterialResourceByID({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get(`material-resource/${payload.id}`)
            .then(async (response) => {
                await commit('setMaterialResource', response.data.material_resource)
                await commit('setMaterialResourceMetadata', response.data.metadata)
            })
            .catch(async (reason) => {
                console.error('fetchMaterialResourceByID', reason)
            })
    },
    async fetchNewMaterialResource({commit}) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get('model/new?model=materialResource')
            .then((response) => {
                commit('setMaterialResource', response.data.materialResource)
                commit('setMaterialResourceMetadata', response.data.metadata)
            })
            .catch(async (reason) => {
                await console.error(reason)
            })
    },
    async fetchMaterialResourceActivitiesByMaterialResourceID({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get(`material-resource/fetch-activities/${payload.material_resource_id}`)
            .then(async (response) => {
                await commit('setMaterialResourceActivity', response.data.activities)
            })
            .catch(async (reason) => {
                await console.error(reason)
            })
    },
    // endregion


    // region store
    async storeNewMaterialResource({commit}, payload) {
        let formData = new FormData()

        formData.append('data', JSON.stringify(payload.material_resource))

        if (payload.material_resource.new_master_image_file) {
            formData.append(
                'new_master_image_file',
                payload.material_resource.new_master_image_file
            )
        }

        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .post('material-resource', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if (response.status === 201) {
                    commit('setMaterialResource', response.data.material_resource)
                    processSuccessCallback(payload)
                }
            })
            .catch((reason) => {
                console.error('storeNewMaterialResource', reason)
            })
    },
    // endregion

    // region update
    async updateMaterialResource({commit}, payload) {
        let formData = new FormData()

        console.log(payload.material_resource);
        formData.append('data', JSON.stringify(payload.material_resource))

        formData.append('_method', 'PUT')
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .post(`material-resource/${payload.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if (response.status === 201) {
                    commit('setMaterialResource', response.data.material_resource)
                    processSuccessCallback(payload)
                }
            })
            .catch((reason) => {
                console.error('updateMaterialResource', reason)
            })
    },
    // endregion

    // region delete
    async deleteMaterialResourceById({commit}, payload) {
        await apiClient
            .delete(`material-resource/${payload.id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    commit('setMaterialResourceList', response.data.material_resources)
                    commit('setMaterialResourceMetadata', response.data.metadata)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                console.error('deleteMaterialResourceById', reason)
            })
    },
    // endregion

    // region undoDelete
    async undoDeleteMaterialResourceById({commit}, payload) {
        await apiClient
            .get(`material-resource/undo-delete/${payload.id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    commit('setMaterialResourceList', response.data.material_resources)
                    commit('setMaterialResourceMetadata', response.data.metadata)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                console.error('undoDeleteMaterialResourceById', reason)
            })
    }
    // endregion
}
