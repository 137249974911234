<template>
  <template v-if="user">
    <Popover>
      <Float auto-placement portal :offset="10">
        <PopoverButton class="outline-0">
          <UserAvatar
            :user-id="userId"
            :role="role"
            :size="size"
            :status-color="state_color"
            :blink-icon-enabled="blinkIconEnabled"
            :blink-icon="blinkIcon"
            :is-square="isSquare"
            :hide-status="hideStatus"
            :ring-color="ringColor"
            :border-color="borderColor"
            :shadow-color="shadowColor"
            :disponibility="disponibility"
          />
        </PopoverButton>
        <PopoverPanel
          v-slot="{ close }"
          class="z-10 w-screen max-w-sm px-4 sm:px-0 lg:w-72"
        >
          <div
            class="flex flex-col divide-y rounded-md border bg-white text-left shadow-2xl"
          >
            <div class="px-4 pb-3 pt-2">
              <h3 class="text-xl font-semibold text-slate-800">
                {{ user.full_name }}
              </h3>
              <div>
                <span
                  class="rounded px-1.5 py-0.5 text-xs"
                  :class="[state_color]"
                >
                  {{ state_text }}
                </span>
              </div>
            </div>
            <div v-if="contactChannel.length" class="px-4 py-3">
              <div class="flex flex-row gap-1">
                <button
                  v-for="contact in contactChannel"
                  :key="contact.key"
                  class="flex aspect-square w-8 items-center justify-center rounded border text-primary hover:bg-primary hover:text-white"
                  @click="
                    () => {
                      composeType = contact.key
                      isComposeDialogOpen = true
                    }
                  "
                >
                  <FontAwesomeIcon :icon="contact.icon" size="lg" />
                </button>
              </div>
            </div>
            <div
              v-if="
                user.phone_number?.length ||
                user.mobile_number?.length ||
                user.email?.length
              "
              class="flex flex-col gap-1 px-4 py-3 font-semibold text-slate-700"
            >
              <div v-if="user.phone_number" class="flex items-center gap-2">
                <FontAwesomeIcon
                  fixed-width
                  :icon="['fal', 'phone']"
                  class="shrink-0"
                />
                <div class="flex-1 truncate">{{ user.phone_number }}</div>
              </div>
              <div v-if="user.mobile_number" class="flex items-center gap-2">
                <FontAwesomeIcon
                  fixed-width
                  :icon="['fal', 'mobile']"
                  class="shrink-0"
                />
                <div class="flex-1 truncate">{{ user.mobile_number }}</div>
              </div>
              <div v-if="user.email" class="flex items-center gap-2">
                <FontAwesomeIcon
                  fixed-width
                  :icon="['fal', 'envelope']"
                  class="shrink-0"
                />
                <div class="flex-1 truncate">
                  <a
                    :href="`mailto:${user.email}`"
                    class="text-primary"
                    target="_blank"
                    >{{ user.email }}</a
                  >
                </div>
              </div>
            </div>
            <div v-if="$slots.panel" class="px-4 py-3">
              <slot name="panel" :close="close" :user-id="userId"></slot>
            </div>
          </div>
          <NotificationComposeDialog
            v-model:is-open="isComposeDialogOpen"
            :user-id="userId"
            :type="composeType"
          />
        </PopoverPanel>
      </Float>
    </Popover>
  </template>
</template>

<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import NotificationComposeDialog from '@c/Notification/Compose/NotificationComposeDialog.vue'
import UserAvatar from '@c/Avatar/UserAvatar.vue'

export default {
  name: 'UserAvatarPopover',
  components: {
    UserAvatar,
    NotificationComposeDialog,
    Float,
    PopoverPanel,
    PopoverButton,
    FontAwesomeIcon,
    Popover
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: Number,
      required: false,
      default: 9
    },
    badge: {
      type: Boolean,
      required: false,
      default: false
    },
    displayStatus: {
      type: Boolean,
      required: false,
      default: true
    },
    isSquare: {
      type: Boolean,
      required: false,
      default: false
    },
    hideStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    blinkIconEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    blinkIcon: {
      type: Array,
      required: false,
      default: () => ['fal', 'times']
    },
    borderColor: {
      type: String,
      required: false,
      default: null
    },
    ringColor: {
      type: String,
      required: false,
      default: null
    },
    shadowColor: {
      type: String,
      required: false,
      default: null
    },
    disponibility: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isComposeDialogOpen: false,
      composeType: null
    }
  },
  computed: {
    ...mapGetters({ getUser: 'metadata/getAllUserMetadata' }),
    contactAvailable() {
      return [
        {
          type: 'toast',
          gate: this.user.notify_me_by_notification
        },
        {
          type: 'database',
          gate: this.user.notify_me_by_notification
        },
        {
          type: 'sms',
          gate: this.user.notify_me_by_sms
        },
        {
          type: 'slack',
          gate: this.user.notify_me_by_slack
        }
      ]
        .filter((item) => item.gate)
        .map((item) => item.type)
    },
    contactChannel() {
      return [
        {
          key: 'toast',
          icon: ['fal', 'bell'],
          iconColor: 'text-primary'
        },
        {
          key: 'database',
          icon: ['fal', 'database'],
          iconColor: 'text-primary'
        },
        {
          key: 'sms',
          icon: ['fal', 'message-sms'],
          iconColor: 'text-primary'
        },
        {
          key: 'mail',
          icon: ['fal', 'envelope'],
          iconColor: 'text-primary'
        },
        {
          key: 'slack',
          icon: ['fab', 'slack'],
          iconColor: 'text-primary'
        }
      ].filter((item) => this.contactAvailable.includes(item.key))
    },
    user() {
      return this.getUser(this.userId)
    },
    state_color() {
      if (!this.user.online) {
        return 'bg-red-700 text-white'
      }
      switch (this.user.status) {
        case 'available':
          return 'bg-green-400 text-slate-800'
        case 'unavailable':
        case 'busy':
          return 'bg-yellow-500 text-slate-800'
        case 'offline':
          return 'bg-red-700 text-white'
        default:
          return 'bg-gray-300  text-slate-800'
      }
    },
    state_text() {
      if (!this.user.online) {
        return this.$t('enums.user.status.offline')
      }
      return this.$t(`enums.user.status.${this.user.status}`)
    }
  }
}
</script>
