export default {
  getSearchResult(state) {
    return state.searchResult
  },
  getSearchValue(state) {
    return state.searchValue
  },
  getCommandPaletteOpened(state) {
    return state.opened
  }
}
