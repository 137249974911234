<template>
  <div class="">
    <div
      v-if="supplierOrder.supplier_order_property"
      class="-mr-1 flex flex-col gap-y-2 px-1"
    >
      <div
        v-if="item"
        class="mx-auto flex w-full items-center gap-x-5 rounded-md bg-blue-300 px-5 py-2 text-white"
      >
        <FontAwesomeIcon :icon="['fal', 'tag']" />
        <p class="capitalize-first">{{ item.name }}</p>
      </div>
      {{ initialMetadata?.enums?.invoiceable_types }}
      <WalkmanButton
        :key="cInvoiceableTypes"
        v-model="supplierOrder.invoiceable_type"
        :label="$t('attributes.order_type')"
        :required="true"
        :options="cInvoiceableTypes"
        track-by="id"
        attribute-label="name"
        @change="supplierOrder.invoiceable_id = null"
      />

      <BaseShowEditSelectSimple
        v-if="
          initialMetadata?.models?.suppliers &&
          supplierOrder.invoiceable_type === InvoiceableType.SUPPLIER
        "
        v-model="supplierOrder.invoiceable_id"
        :label="$t('attributes.supplier')"
        name="supplier"
        :edition-mode="true"
        :options="initialMetadata?.models?.suppliers"
        :required="true"
        :errors="errors.invoiceable"
        @change="setInvoiceableID"
      />

      <BaseShowEditSelectSimple
        v-if="
          initialMetadata?.models?.companies &&
          supplierOrder.invoiceable_type === InvoiceableType.COMPANY
        "
        v-model="supplierOrder.invoiceable_id"
        :label="$t('attributes.company')"
        name="company"
        :edition-mode="true"
        :options="initialMetadata?.models?.companies"
        :required="true"
        :errors="errors.invoiceable"
        @change="setInvoiceableID"
      />
      <BaseFuneralHomeShowEditSelectSimple
        v-model="supplierOrder.funeral_home_id"
        :edition-mode="true"
        :metadata="initialMetadata.models"
        :errors="errors"
        :required="true"
      />
      <SupplierOrderDeliveryLocationInput
        :label="$t('global.delivery_address')"
        :properties="supplierOrder?.supplier_order_property"
        name="delivery_address"
        :errors="errors.delivery_address"
        @change="handleAddressInput"
      />

      <BaseShowEditDateTimePicker
        v-model:date="supplierOrder.supplier_order_property.delivery_date_time"
        v-model:time-undefined="
          supplierOrder.supplier_order_property
            .delivery_date_time_undefined_time
        "
        :label-date="$t('attributes.delivery_date')"
        name="delivery_date"
        :edition-mode="true"
        :errors="errors?.due_date"
      />

      <BaseShowEditInvoiceStatusInput
        v-model:modelValue="supplierOrder.status"
        :label="$t('attributes.status')"
        :invoice-doc-type="supplierOrder.doc_type"
        :edition-mode="true"
        @change="changeStatus"
      />
      <BaseShowEditTextArea
        v-model="supplierOrder.supplier_order_property.delivery_remarks"
        class="col-span-2 col-start-1"
        :label="$t('attributes.remarks')"
        :errors="errors.delivery_remarks"
        :edition-mode="true"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { InvoiceableType, DocTypes } from '@/assets/enums'
import SupplierOrderDeliveryLocationInput from '@ui/modal/supplierOrder/components/SupplierOrderDeliveryLocationInput'
import BaseFuneralHomeShowEditSelectSimple from '@c/BaseFuneralHomeShowEditSelectSimple'
import WalkmanButton from '@c/WalkmanButton'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseShowEditDateTimePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDateTimePickerv2.vue'

export default {
  name: 'SupplierOrderForm',
  components: {
    BaseShowEditDateTimePicker,
    FontAwesomeIcon,
    WalkmanButton,
    BaseFuneralHomeShowEditSelectSimple,
    SupplierOrderDeliveryLocationInput
  },
  props: {
    supplierOrderId: {
      type: [String, Number],
      required: false,
      default: null
    },
    supplierId: {
      type: [String, Number],
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    needToFetch: {
      type: Boolean,
      required: true
    },
    dossier: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['address'],
  data() {
    return {
      InvoiceableType: InvoiceableType,
      DocTypes: DocTypes
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      supplierOrder: 'invoicing/getInvoice',
      errors: 'auth/getErrors'
    }),
    cInvoiceableTypes() {
      let supplier = {}
      let company = {}

      supplier.id = InvoiceableType.SUPPLIER
      supplier.name = this.$t('attributes.supplier')
      company.id = InvoiceableType.COMPANY
      company.name = this.$t('attributes.company')

      return [supplier, company]

      // if(!this.initialMetadata?.enums?.invoiceable_types) return []
      // return this.initialMetadata?.enums?.invoiceable_types?.filter(type => [InvoiceableType.SUPPLIER, InvoiceableType.COMPANY].includes(type.id))
    }
  },
  beforeMount() {
    console.log('needToFetch: ', this.needToFetch)
    console.log('supplierOrderId: ', this.supplierOrderId)
    if (this.needToFetch) {
      if (this.supplierOrderId) {
        this.processFetchInvoiceIDAction({
          id: this.supplierOrderId,
          isSupplierOrder: true,
          supplier_order_index: null
        })
      } else {
        this.processFetchNewInvoiceAction({
          doc_type: DocTypes.SUPPLIER_ORDER,
          invoiceable_id: this.supplierId ?? null,
          funeral_home_id: this.dossier?.funeral_home_id ?? null
        })
      }
    }
  },
  methods: {
    ...mapActions({
      processUpdateInvoiceAction: 'invoicing/updateInvoice',
      processFetchInvoiceIDAction: 'invoicing/fetchInvoiceID',
      processFetchNewInvoiceAction: 'invoicing/fetchNewInvoice',
      processChangeInvoiceStatusAction: 'invoicing/changeInvoiceStatus',
      processFetchItemsDossier: 'dossier/fetchItemsDossier'
    }),
    ...mapMutations({
      processSetInvoiceInvoiceableIDMutation:
        'invoicing/setInvoiceInvoiceableID'
    }),
    changeStatus(newStatus) {
      if (!this.supplierOrder.id || !newStatus) return null
      this.processChangeInvoiceStatusAction({
        invoice_id: this.supplierOrder.id,
        form_data: {
          status: newStatus
        },
        successCallback: () => {
          if (this.dossier?.id) {
            this.processFetchItemsDossier({ dossier_id: this.dossier?.id })
          }
        }
      })
    },
    handleAddressInput() {
      this.$emit('address')
    },
    setInvoiceableID(id) {
      this.processSetInvoiceInvoiceableIDMutation(id)
    }
  }
}
</script>
