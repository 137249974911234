export default {
  toggleCollapsed(context) {
    context.commit('toggleCollapsed')
  },
  collapse(context) {
    context.commit('collapse')
  },
  uncollapse(context) {
    context.commit('uncollapse')
  }
}
