import apiClient from '@u/apiClient'

let base_errors = {deceased: {}, events: []}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {
    async fetchWikiContactById({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get(`wiki-contact/${payload.wiki_contact_id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setContact', response.data.wiki_contact)
                }
            })
            .catch(async (reason) => {
                await console.error('fetchWikiContactById', reason)
            })
    },
    async fetchNewWikiContact({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get(`model/new?model=wikiContact`)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setContact', response.data.wikiContact)
                }
            })
            .catch(async (reason) => {
                await console.error('fetchNewWikiContact', reason)
            })
    },
    async fetchWikiContactByCity({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .get(`wiki-contact/for-city/${payload.wiki_city_id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setWikiContactList', response.data.contacts)
                }
            })
            .catch(async (reason) => {
                await console.error('', reason)
            })
    },
    async storeWikiContact({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .post(`wiki-contact/${payload.wiki_place_id}`, payload.wiki_contact)
            .then(async (response) => {
                if (response.status === 200) {
                    commit('setContact', response.data.contact)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                await console.error('storeWikiContact', reason)
            })
    },
    async updateWikiContact({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient
            .put(`wiki-contact/${payload.wiki_contact_id}`, payload.wiki_contact)
            .then(async (response) => {
                if (response.status === 200) {
                    commit('setContact', response.data.contact)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                await console.error('updateWikiContact', reason)
            })
    },
    async attachWikiContactToWikiPlace({commit}, payload) {
        await apiClient
            .post(
                `wiki-contact/${payload.wiki_contact_id}/attach-to/${payload.wiki_place_id}?type=${payload.wiki_place_type}`
            )
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setPlace', response.data.place)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                await console.error('attachWikiContactToWikiPlace', reason)
            })
    },
    async detachWikiContactFromWikiPlace({commit}, payload) {
        await apiClient
            .post(
                `wiki-contact/${payload.wiki_contact_id}/detach-from/${payload.wiki_place_id}?type=${payload.wiki_place_type}`
            )
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setPlace', response.data.place)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                await console.error('detachWikiContactFromWikiPlace', reason)
            })
    }
}
