const Relationship = Object.freeze({
    EPOUX: 'epoux',
    EPOUSE: 'epouse',
    FILS: 'fils',
    FILLE: 'fille',
    BEAU_FILS: 'beau-fils',
    BELLE_FILLE: 'belle-fille',
    FRERE: 'frere',
    SOEUR: 'soeur',
    PERE: 'pere',
    MERE: 'mere',
    BEAU_PERE: 'beau-pere',
    BELLE_MERE: 'belle-mere',
    PETIT_FILS: 'petit-fils',
    PETITE_FILLE: 'petite-fille',
    GRAND_PERE: 'grand-pere',
    GRAND_MERE: 'grand-mere',
    ARRIERE_GRAND_PERE: 'arriere-grand-pere',
    ARRIERE_GRAND_MERE: 'arriere-grand-mere',
    ONCLE: 'oncle',
    TANTE: 'tante',
    NEVEU: 'neveu',
    NIECE: 'niece',
    PARRAIN: 'parrain',
    MARRAINE: 'marraine',
    FILLEUL: 'filleul',
    FILLEULE: 'filleule',
    COUSIN: 'cousin',
    COUSINE: 'cousine',
    COHABITANT_LEGALE: 'cohabitant-legal',
    COHABITANTE_LEGALE: 'cohabitante-legale',
    COMPAGNON: 'compagnon',
    COMPAGNE: 'compagne',
    LE_PUPILLE: 'le-pupille',
    LA_PUPILLE: 'la-pupille',
    TUTEUR: 'tuteur',
    TUTRICE: 'tutrice',
    BEAU_FRERE: 'beau-frere',
    BELLE_SOEUR: 'belle-soeur',
    DIVORCE: 'divorce',
    SEPARE: 'separe',
    AMI: 'ami',
    AMIE: 'amie',
    ADMINISTRATEUR_DE_BIEN: 'admin-de-biens',
    NOTAIRE: 'notaire',
    MOI_MEME: 'moi-meme',
    MANDATAIRE: 'mandataire',
    POMPES_FUNEBRES: 'agence'
})

export default Relationship
