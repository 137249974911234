<template>
  <div v-if="editionMode" class="base-multiselect-simple relative">
    <label
        v-if="label"
        :for="name"
        class="absolute -top-2 left-2 z-10 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first 0"
    >{{ label ? label : $t('attributes.user') }}&nbsp;
      <span class="text-red-600" v-if="label && required">*</span>
    </label>
    <multiselect
        v-if="metadata && metadata.users"
        :options="metadata.users"

        :modelValue="cDefautlValue"
        @update:modelValue="updateValueAction"
        :searchable="searchable"
        :label="attributeLabel"
        :track-by="trackBy"
        :required="required"
        class="relative block w-full rounded-none rounded-bl-md rounded-tl-md border-0 border-gray-300 bg-transparent focus:z-10 focus:border-0 focus:ring-0 sm:text-sm"
    >
      <template v-slot:noOptions><span>{{ $t('global.is_empty_list') }}</span></template>
    </multiselect>

    <template v-if="errors && errors[errorsKey]">
      <div
          v-for="(error, index) in errors[errorsKey]"
          :key="index"
          class="form-help text-red-600"
      >
        {{ $t(error, {attribute: $t('attributes.' + name)}) }}
      </div>
    </template>
  </div>

  <div v-else class="">
    <BaseShowLabel
        :label="label ? label : $t('attributes.user')"
        :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  name: 'BaseUserShowEditSelectSimple',
  components: {multiselect},
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false,
      default: 'user_id'
    },
    errors: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    errorsKey: {
      type: String,
      required: false,
      default: 'user_id'
    },
    metadata: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    cDisplayedValueWhenNotEditionMode() {
      if (this.cDefautlValue) {
        if (this.attributeLabel) {
          return this.cDefautlValue[this.attributeLabel]
        } else {
          return this.cDefautlValue
        }
      } else {
        return '-'
      }
    },
    cDefautlValue() {
      if (
          this.metadata &&
          this.metadata.users &&
          this.metadata.users.find((item) => item.id === this.modelValue)
      )
        return this.metadata.users.find((item) => item.id === this.modelValue)
      else return null
    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue != null) {
        this.$emit('update:modelValue', newValue[this.trackBy])
      } else {
        this.$emit('update:modelValue', null)
      }
    }
  }
}
</script>
<style></style>
