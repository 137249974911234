<template>
  <div class="flex flex-1 flex-col">
    <div class="flex-shrink-0">
      <h2 class="text-lg font-bold">
        {{ $t('global.search_in_funeral_manager') }}
      </h2>
    </div>
    <div class="flex flex-1 flex-col gap-3">
      <form class="flex flex-row items-start gap-3" @submit.prevent="search">
        <div class="w-48 shrink-0">
          <MultiSelectComponent
            v-model="searchParams.model_types"
            :values="formatedInvoiceableType"
            :label="$t('invoicing.type')"
          />
        </div>
        <div class="flex-1">
          <BaseShowEditInput
            v-model="searchParams.terms"
            :label="$t('attributes.name')"
            name="last_name"
            :required="true"
            :edition-mode="true"
            :errors="errors?.terms"
          />
          <div v-if="errorsForm.terms" class="text-xs text-red-600">
            {{ errorsForm.terms }}
          </div>
        </div>
        <div class="shrink-0">
          <BaseButton
            class="mt-2"
            :title="$t('button.check_availability')"
            icon="magnifying-glass"
            :disabled="!searchParams.terms.length"
            @click="search"
          />
        </div>
      </form>
      <div>
        <InvoiceablesTable
          :invoiceable-list="invoiceableList"
          @selected-invoiceable="
            (invoiceable) => (localSelectedInvoiceable = invoiceable)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import InvoiceablesTable from './InvoiceablesTable.vue'
import MultiSelectComponent from '@c/BaseFormComponent/MultiSelectComponent.vue'
import InvoiceableType from '@/assets/enums/invoicing/InvoiceableType'

export default {
  name: 'InvoiceableSelectorForm',
  components: {
    MultiSelectComponent,
    InvoiceablesTable,
    BaseButton,
    BaseShowEditInput
  },
  props: {
    selectedInvoiceable: {
      type: Object,
      default: null
    }
  },
  emits: ['update:selectedInvoiceable'],
  data() {
    return {
      searchParams: {
        terms: '',
        model_types: [
          InvoiceableType.CONTACT,
          InvoiceableType.SUPPLIER,
          InvoiceableType.COMPANY,
          InvoiceableType.PERSON
        ]
      },
      errors: [],
      errorsForm: {},
      invoiceableList: []
    }
  },
  computed: {
    formatedInvoiceableType() {
      return Object.entries(InvoiceableType).map(([value, key]) => {
        return {
          id: key,
          name: this.$t(`enums.invoicing.invoiceableType.${key}`)
        }
      })
    },
    localSelectedInvoiceable: {
      get() {
        return this.selectedInvoiceable
      },
      set(value) {
        this.$emit('update:selectedInvoiceable', value)
      }
    }
  },
  methods: {
    ...mapActions({
      processSearchForAnInvoiceableAction: 'invoicing/searchForAnInvoiceable'
    }),
    search() {
      if (this.searchParams.terms.length === 0) {
        return
      }
      this.processSearchForAnInvoiceableAction({
        searchParams: this.searchParams
      }).then((response) => {
        this.invoiceableList = response.invoiceables
      })
    }
  }
}
</script>

<style scoped></style>
