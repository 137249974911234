export default {
  //region access_app
  addAccessAppPermission(state) {
    state.user.permissions.push('access_app')
  },
  removeAccessAppPermission(state) {
    if (state.user.permissions.includes('access_app')) {
      console.log('case ok')
      state.user.permissions.splice(
        state.user.permissions.indexOf('access_app'),
        1
      )
    }
  },
  //endregion

  //region receive_commission
  addReceiveCommissionPermission(state) {
    state.user.permissions.push('receive_commission')
  },
  removeReceiveCommissionPermission(state) {
    if (state.user.permissions.includes('receive_commission')) {
      state.user.permissions.splice(
        state.user.permissions.indexOf('receive_commission'),
        1
      )
    }
  }
  //endregion
}
