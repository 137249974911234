import apiClient from '@u/apiClient'

export default {
  // region Fetch
  fetchAccountingCodeList: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get('accounting-code')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchAccountingCode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`accounting-code/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // endregion

  // region store
  storeAccountingCode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post('accounting-code', payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  updateAccountingCode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`accounting-code/${payload.id}`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
