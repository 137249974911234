export default {
  initialMetadata: {
    loaded: false,
    models: {
      users: [],
      funeralHomes: []
    }
  },
  mainKey: 1
}
