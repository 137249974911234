/* eslint-disable camelcase */
import apiClient from '@u/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchAllVersionNotes({commit}, payload) {
    await apiClient
        .get("version-note/")
        .then(async (response) => {
          if (response.status === 200) {
            commit('setVersionNoteList', response.data.version_notes)
          }
        })
        .catch(async (reason) => {
          console.error('fetchAllVersionNotes reason', reason)
        })
  },
  // endregion

}
