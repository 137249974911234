<template>
  <Popover>
    <Float
      auto-placement
      :arrow="5"
      :offset="10"
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverButton class="border-0 ring-0">
        <FontAwesomeIcon
          :icon="['fal', icon]"
          fixed-width
          class="mx-2 text-gray-600"
        />
      </PopoverButton>
      <PopoverPanel
        class="z-10 w-80 overflow-hidden rounded-md border border-gray-300 bg-white shadow-lg"
      >
        <FloatArrow
          class="absolute h-5 w-5 rotate-45 transform border border-gray-300 bg-white"
        />
        <div class="relative flex flex-col bg-white p-3 pt-2 text-xs">
          <h3 class="text-base font-bold">{{ contact.full_name }}</h3>
          <div class="flex flex-row">
            <div class="flex flex-1 flex-col">
              <div v-if="contact.phone_field">
                {{ contact.phone_field }}
              </div>
              <div v-if="contact.mobile_field">
                {{ contact.mobile_field }}
              </div>
              <div v-if="contact.email">
                {{ contact.email }}
              </div>
            </div>
            <div class="flex flex-1 flex-col items-end">
              <div v-if="contact.street || contact.zip || contact.city">
                {{ contact.street }}<br />
                {{ contact.zip + ' ' + contact.city }}
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Float, FloatArrow } from '@headlessui-float/vue'

export default {
  name: 'ContactPopoverComponent',
  components: { Popover, Float, FloatArrow, PopoverButton, PopoverPanel },
  props: {
    contact: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: 'circle-info'
    }
  }
}
</script>
