const TheScheduleSettingAgendaPage = () =>
  import('@/views/settings/schedule/agenda/TheScheduleSettingAgendaPage')

export const settingsAgendaRoutes = [
  {
    path: 'agenda',
    name: 'settings_list_agenda_route',
    component: TheScheduleSettingAgendaPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_company_title', active: true }
      ]
    }
  }
]
