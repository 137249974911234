<template>
  <div class="flex flex-col gap-x-2">
    <div class="my-2 flex items-center justify-between gap-x-3 px-5">
      <BaseButton
        v-if="aSupplierIsAlreadySelected"
        :title="$t('button.reset')"
        class="mt-0.5"
        icon="trash"
        color="warning"
        @click="handleSelectSupplier(null)"
      />
      <BaseSimpleSearchInput v-model:modelValue="filterText" />
    </div>
    <template v-if="filteredSuppliers?.length < 1">
      <div class="mx-auto mt-12 text-center text-slate-300">
        {{
          $t('global.there_is_no_yet', {
            settings: $t('attributes.supplier')
          })
        }}
      </div>
    </template>
    <template v-else>
      <div class="px-10">
        <ul role="list" class="divide-y divide-gray-200">
          <li
            v-for="(supplier, index) in filteredSuppliers"
            :key="index"
            class="flex py-4"
          >
            <div class="grid grid-cols-12 gap-y-5">
              <div
                class="col-span-11 flex cursor-pointer"
                @click="handleSelectSupplier(supplier)"
              >
                <font-awesome-icon
                  :icon="['fal', icon]"
                  size="2xl"
                  :class="[
                    modelValue === supplier.id ? 'text-blue-300' : '',
                    'mr-2 mt-1 cursor-pointer'
                  ]"
                />
                <div class="ml-3 cursor-pointer">
                  <p
                    :class="[
                      modelValue === supplier.id
                        ? 'text-theme-primary'
                        : 'text-gray-900',
                      'select-none text-sm font-medium'
                    ]"
                  >
                    {{ supplier.name }}
                  </p>
                  <p
                    v-if="supplier.tva"
                    :class="[
                      modelValue === supplier.id
                        ? 'text-blue-300'
                        : 'text-slate-300',
                      'flex select-none items-center text-sm text-gray-500'
                    ]"
                  >
                    {{ supplier.tva }}
                  </p>
                </div>
              </div>
              <div class="col-end-auto">
                <div class="flex">
                  <font-awesome-icon
                    :icon="['fal', 'circle-check']"
                    size="xl"
                    class="mr-2 mt-1 cursor-pointer text-green-500"
                    @click.stop="handleSelectSupplier(supplier)"
                  />
                  <font-awesome-icon
                    :icon="['fal', 'pen-circle']"
                    size="xl"
                    class="mr-2 mt-1 cursor-pointer text-yellow-500"
                    @click.stop="handleEditSupplier(supplier)"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectSupplierList',
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user-helmet-safety'
    }
  },
  emits: ['update:modelValue', 'editSupplier'],
  data() {
    return {
      filterText: ''
    }
  },
  computed: {
    ...mapGetters({
      supplierList: 'supplier/getSupplierList',
      dossier: 'dossier/getDossier'
    }),
    filteredSuppliers() {
      if (!this.filterText || this.filterText?.length < 1) {
        this.supplierList.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
        return this.supplierList ?? []
      }
      return this.supplierList?.filter((supplier) => {
        return (
          supplier?.name
            ?.toLowerCase()
            ?.includes(this.filterText.toLowerCase()) ||
          supplier?.first_name
            ?.toLowerCase()
            ?.includes(this.filterText.toLowerCase()) ||
          supplier?.last_name
            ?.toLowerCase()
            ?.includes(this.filterText.toLowerCase())
        )
      })
    },
    aSupplierIsAlreadySelected() {
      return this.modelValue !== null
    }
  },
  methods: {
    handleSelectSupplier(supplier) {
      if (!supplier) this.$emit('update:modelValue', null)
      this.$emit('update:modelValue', supplier.id)
    },
    handleEditSupplier(supplier) {
      this.$emit('editSupplier', supplier.id)
    }
  }
}
</script>
