const TheFuneralHomePartnerIndexPage = () =>
  import(
    '@/views/settings/management/funeralHomePartner/index/TheFuneralHomePartnerIndexPage'
  )

export const settingsFuneralHomePartnerRoutes = [
  {
    path: 'funeral-home-partner',
    name: 'settings_list_funeral_home_partner_route',
    component: TheFuneralHomePartnerIndexPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_item_title', active: true }
      ]
    }
  }
]
