export default {
  getModelNotificationList(state){
    return state.modelNotificationList
  },
  getModelNotificationSelected(state){
    return state.modelNotificationSelected
  },
  getModelNotification(state){
    return state.modelNotification
  },
  getMetadata(state){
    return state.metadata
  },
  getModelNotificationStateHasChanged(state) {
    return state.hasChanged
  },
  getResetEmail(state){
    return state.resetEmail
  },
}
