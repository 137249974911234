const NoteIndex = () =>
  import(/* webpackChunkName: "group-note" */ '@/views/note/NoteIndex')
const WorkInProgressComponent = () =>
  import(
    /* webpackChunkName: "group-note" */ '@/views/wip/WorkInProgressComponent'
  )

export const noteRootPages = {
  path: '/note',
  name: 'note_index_route',
  component: WorkInProgressComponent
}
