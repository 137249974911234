import Gender from '@/assets/enums/person/Gender'

const Civility = Object.freeze({
  MONSIEUR: 'monsieur',
  MADAME: 'madame',
  MADEMOISELLE: 'mademoisel',
  ENFANT: 'enfant',
  DOCTEUR: 'docteur',
  PROFESSEUR: 'professeur',
  MAITRE: 'maitre',
  REVEREND: 'reverend',
  SOEUR: 'soeur',
  ECUYER: 'écuyer',
  ECUYER_F: 'écuyer_f',
  MESDAMES: 'mesdames',
  MESSIEURS: 'messieurs',
  MADAMEMONSIEUR: 'mad-mons',
  ABBE: 'abbe',
  ALTESSE: 'altesse',
  AMBASSADEUR: 'ambassadeur',
  AMBASSADRICE: 'ambassadrice',
  ARCHIDUC: 'archiduc',
  BARON: 'baron',
  BARONNE: 'baronne',
  BARON_BARONNE: 'baron-baronne',
  CHEVALIER: 'chevalier',
  COMTE: 'comte',
  COMTESSE: 'comtesse',
  COMTE_COMTESSE: 'comte-comtesse',
  DAME: 'dame',
  EMPEREUR: 'empereur',
  FAMILLE: 'famille',
  GRAND_DUC: 'grandduc',
  INDIVISION: 'indivision',
  MARQUIS: 'marquis',
  MARQUISE: 'marquise',
  MESSIRE: 'messire',
  MONSEIGNEUR: 'monseigneur',
  PRINCE: 'prince',
  PRINCESSE: 'princesse',
  PRINCE_PRINCESSE: 'prince-princesse',
  SA_MAJESTE: 'sa-majeste',
  SON_ALTESSE_ROYALE: 'son-altesse-royale',
  SON_ALTESSE_SERENISSIME: 'son-altesse-serenissime',
  SON_EXCELLENCE: 'son-excellence',
  SUCCESSION: 'succession',
  VICOMTE: 'vicomte',
  VICOMTESSE: 'vicomtesse',
  VICOMTE_VICOMTESSE: 'vicomte-vicomtesse',
  RELIGIEUSE_DE_LA_DOCTRINE_CHRETIENNE: 'religieuse'
})

const CivilityGender = Object.freeze({
  [Civility.MONSIEUR]: Gender.MALE,
  [Civility.MADAME]: Gender.FEMALE,
  [Civility.MADEMOISELLE]: Gender.FEMALE,
  [Civility.ENFANT]: null,
  [Civility.DOCTEUR]: null,
  [Civility.PROFESSEUR]: null,
  [Civility.MAITRE]: null,
  [Civility.REVEREND]: Gender.MALE,
  [Civility.SOEUR]: Gender.FEMALE,
  [Civility.ECUYER]: Gender.MALE,
  [Civility.ECUYER_F]: Gender.FEMALE,
  [Civility.MESDAMES]: Gender.FEMALE,
  [Civility.MESSIEURS]: Gender.MALE,
  [Civility.MADAMEMONSIEUR]: Gender.MALE,
  [Civility.ABBE]: Gender.MALE,
  [Civility.ALTESSE]: Gender.MALE,
  [Civility.AMBASSADEUR]: Gender.MALE,
  [Civility.AMBASSADRICE]: Gender.FEMALE,
  [Civility.ARCHIDUC]: Gender.MALE,
  [Civility.BARON]: Gender.MALE,
  [Civility.BARONNE]: Gender.FEMALE,
  [Civility.BARON_BARONNE]: Gender.MALE,
  [Civility.CHEVALIER]: Gender.MALE,
  [Civility.COMTE]: Gender.MALE,
  [Civility.COMTESSE]: Gender.FEMALE,
  [Civility.COMTE_COMTESSE]: Gender.MALE,
  [Civility.DAME]: Gender.FEMALE,
  [Civility.EMPEREUR]: Gender.MALE,
  [Civility.FAMILLE]: Gender.MALE,
  [Civility.GRAND_DUC]: Gender.MALE,
  [Civility.INDIVISION]: Gender.FEMALE,
  [Civility.MARQUIS]: Gender.MALE,
  [Civility.MARQUISE]: Gender.FEMALE,
  [Civility.MESSIRE]: Gender.MALE,
  [Civility.MONSEIGNEUR]: Gender.MALE,
  [Civility.PRINCE]: Gender.MALE,
  [Civility.PRINCESSE]: Gender.FEMALE,
  [Civility.PRINCE_PRINCESSE]: Gender.MALE,
  [Civility.SA_MAJESTE]: null,
  [Civility.SON_ALTESSE_ROYALE]: null,
  [Civility.SON_ALTESSE_SERENISSIME]: null,
  [Civility.SON_EXCELLENCE]: null,
  [Civility.SUCCESSION]: null,
  [Civility.VICOMTE]: Gender.MALE,
  [Civility.VICOMTESSE]: Gender.FEMALE,
  [Civility.VICOMTE_VICOMTESSE]: Gender.MALE,
  [Civility.RELIGIEUSE_DE_LA_DOCTRINE_CHRETIENNE]: Gender.FEMALE
})

export function getGender(civility) {
  return CivilityGender[civility]
}

export default Civility
