import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      documentsList: [],
      sharedDocumentsList: [],
      documentsHeaders: [],
      metadata: [],
      document: null,
      editionMode: false,
      documentActivities: null
    }
  },
  getters,
  actions,
  mutations
}
