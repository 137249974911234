<template>
  <div
    :class="{
      'mobile-menu--dashboard': dashboardLayout,
      'mobile-menu--light': layout == 'top-menu'
    }"
    class="mobile-menu flex md:hidden print:hidden"
  >
    <div class="flex w-full content-center">
      <div class="w-full">
        <div class="mobile-menu-bar">
          <a href="javascript:;">
            <BarChart2Icon
              class="h-8 w-8 -rotate-90 transform text-gray-600 dark:text-white"
              @click="toggleMobileMenu"
            />
          </a>
        </div>
        <transition @enter="enter" @leave="leave">
          <ul v-if="activeMobileMenu" class="mobile-menu-box hidden py-5">
            <!-- BEGIN: First Child -->
            <template v-for="(menu, menuKey) in formattedMenu">
              <li
                v-if="menu === 'devider'"
                :key="menu + menuKey"
                class="menu__devider my-6"
              />
              <li v-else :key="menu + menuKey">
                <a
                  href="javascript:;"
                  class="menu"
                  :class="{
                    'menu--active font-extrabold text-slate-300': menu.active,
                    'menu--open': menu.activeDropdown
                  }"
                  @click="linkTo(menu, router)"
                >
                  <div class="menu__icon">
                    <FontAwesomeIcon :icon="['fal', menu.icon]" />
                  </div>
                  <div class="menu__title">
                    {{ $t(`navigation.${menu.title}`) }}
                    <div
                      v-if="menu.subMenu"
                      class="menu__sub-icon"
                      :class="{ 'rotate-180 transform': menu.activeDropdown }"
                    >
                      <ChevronDownIcon />
                    </div>
                  </div>
                </a>
                <!-- BEGIN: Second Child -->
                <transition @enter="enter" @leave="leave">
                  <ul v-if="menu.subMenu && menu.activeDropdown">
                    <li
                      v-for="(subMenu, subMenuKey) in menu.subMenu"
                      :key="subMenuKey"
                    >
                      <a
                        href="javascript:;"
                        class="menu"
                        :class="{
                          'menu--active font-extrabold text-slate-300 underline':
                            subMenu.active
                        }"
                        @click="linkTo(subMenu, router)"
                      >
                        <div class="menu__icon">
                          <FontAwesomeIcon
                            :icon="['fal', subMenu.icon]"
                            size="xs"
                          />
                        </div>
                        <div class="menu__title">
                          {{ $t(`navigation.${subMenu.title}`) }}
                          <div
                            v-if="subMenu.subMenu"
                            class="menu__sub-icon"
                            :class="{
                              'rotate-180 transform': subMenu.activeDropdown
                            }"
                          >
                            <ChevronDownIcon />
                          </div>
                        </div>
                      </a>
                      <!-- BEGIN: Third Child -->
                      <transition @enter="enter" @leave="leave">
                        <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                          <li
                            v-for="(
                              lastSubMenu, lastSubMenuKey
                            ) in subMenu.subMenu"
                            :key="lastSubMenuKey"
                          >
                            <a
                              href="javascript:;"
                              class="menu"
                              :class="{ 'menu--active': lastSubMenu.active }"
                              @click="linkTo(lastSubMenu, router)"
                            >
                              <div class="menu__icon">
                                <ZapIcon />
                              </div>
                              <div class="menu__title">
                                {{ lastSubMenu.title }}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </transition>
                      <!-- END: Third Child -->
                    </li>
                  </ul>
                </transition>
                <!-- END: Second Child -->
              </li>
            </template>
            <!-- END: First Child -->
          </ul>
        </transition>
      </div>
    </div>
    <div class="absolute right-5 top-5">
      <InfoUserModalComponent />
    </div>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@u/helper'
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from './index'

// import {nestedMenu} from "../sideMenu/index";
// import {nestedMenu} from "../layouts/side-menu";
import { nestedMenu } from '../main-layout'
import InfoUserModalComponent from '../top-bar/InfoUserModalComponent'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  components: { FontAwesomeIcon, InfoUserModalComponent },
  props: {
    layout: {
      type: String,
      default: 'side-menu'
    },
    dashboardLayout: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const mobileMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value)
      }
    )

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value)
    })

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  }
})
</script>
