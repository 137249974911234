<template >
  <div
    :class="[
      modelValue ? ' cursor-pointer hover:bg-gray-50' : 'bg-white',
      'box shadow-md p-5 flex flex-col m-y-5 rounded-md'
    ]"
    @click="handleCardClick"
  >
    <div class="flex flex-row align-baseline p-x-5">
      <template v-if="modelValue">
        <div v-if="!checkDisplay" class="flex flex-row gap-x-5 items-center" >
          <p class="flex-shrink-0 mt-2 font-bold text-xl capitalize-first select-none">{{ label }}:</p >
          <slot name="value" />
        </div>
        <p v-else class="w-full mt-2 font-bold text-xl capitalize-first select-none">
          {{ label }}: <span class="text-green-5OO"><FontAwesomeIcon :icon="['fas', 'circle-check']"/></span>
        </p >
      </template>
      <p v-else class="mt-2 font-bold text-xl capitalize-first select-none">{{ placeholder}}</p >
    </div >
    <transition
      enter-active-class="duration-500 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-100"
    >
      <div v-if="isOpen" class="mt-8 cursor-default" @click.stop="">
        <slot name="default"/>
      </div >
    </transition >
  </div >
</template >

<script >


export default {
  name: "WizardCard",
  components: { },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    checkDisplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:isOpen'],
  data() {
    return {
      test: 0
    }
  },
  methods: {
    handleCardClick() {
      if (this.modelValue){
        console.log('handleCardClick')
        this.$emit('update:isOpen', !this.isOpen)
      }
    }
  },
}
</script >
