export default {
  getDocuments(state) {
    return state.documentsList
  },
  getSharedDocuments(state) {
    return state.sharedDocumentsList
  },
  getDocumentsHeaders(state) {
    return state.documentsHeaders
  },
  getmetadata(state) {
    return state.metadata
  },
  getDocument(state) {
    return state.document
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getDocumentActivities(state) {
    return state.documentActivities
  }
}
