import apiClient from '@u/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region store
  storeNewBank: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`/bank`, {
          name : (payload.payment.new_issuing_bank || payload.payment.single_use_issuing_bank),
          bankType : payload.payment.single_use_issuing_bank ? "single_use_issuing_bank" : "new_issuing_bank"
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
          console.log('on est la aussi')

        })
    })
  },
  //end region
}
