export default {
  setPersonList(state, personList) {
    state.personList = personList
  },
  resetDossierConcernedPerson(state){
    state.dossier['concernedPerson'] = {}
  },
  setDossierConcernedPersonAttributeByValue(state, payload){
    state.dossier.concernedPerson[payload.attribute] = payload.value
  },
  setDossierConcernedPerson(state, concernedPerson){
    state.dossier.concernedPerson = concernedPerson
  },
  setPerson(state, person){
    state.person = person
  },
}
