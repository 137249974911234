<template>

  <div
      class="px-14 pt-20 mx-auto my-10 box relative mx-5 flex h-full flex flex-col gap-4 p-4 overflow-y-scroll bg-gray-50">
home


  </div>

</template>
<script>
import {mapGetters} from 'vuex'
import PageSection from "@c/PageSection.vue";
import BaseSettingsCheckBoxGroup from "@/views/settings/general/baseSettingsComponents/BaseSettingsCheckBoxGroup.vue";
import LanguageAndCountrySettingsComponent
  from "@/views/settings/general/tabs/general/components/LanguageAndCountrySettingsComponent.vue";
import VatRateAndPriceEncodingTypeSettingsComponent
  from "@/views/settings/general/tabs/general/components/VatRateAndPriceEncodingTypeSettingsComponent.vue";
import DateSettingsComponent from "@/views/settings/general/tabs/general/components/DateSettingsComponent.vue";
import BaseSettingsSimpleCheck from "@/views/settings/general/baseSettingsComponents/BaseSettingsSimpleCheck.vue";
import BaseSettingsRadioGroup from "@/views/settings/general/baseSettingsComponents/BaseSettingsRadioGroup.vue";
import BaseSettingsInput from "@/views/settings/general/baseSettingsComponents/BaseSettingsInput.vue";
import BaseSettingsInputNumber from "@/views/settings/general/baseSettingsComponents/BaseSettingsInputNumber.vue";


export default {
  name: 'TheGeneralSettingsComponent',
  components: {
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      settings: 'settings/getSettings',
      auth: 'auth/getAuth'
    }),
    deathPlaceTypesOptions() {
      return this.initialMetadata?.enums?.place_types?.filter(place => place.id !== 'LDEC' && place.id !== 'LCER')
    }
  },

}
</script>
