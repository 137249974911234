<template>
  <SelectComponent
    v-model="selectedValues"
    :values="civilities"
    :label="$t('attributes.civility')"
    :required="required"
    name="civility"
    :errors="errors"
    :edition-mode="editionMode"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from 'vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent'

export default {
  name: 'SelectCivility',
  components: { SelectComponent },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    modelValue: {
      type: [Array, Number],
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return { selectedValues }
  },
  computed: {
    ...mapGetters('metadata', { civilities: 'getCivilityMetadata' })
  }
}
</script>
