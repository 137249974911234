<template>
  <tr>
    <td
      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
    >
      {{ dossier.reference }}
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
      {{ dossierType }}
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      {{ dossier?.concernedPerson?.full_name }}
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
      {{ relation }}
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
      {{ role }}
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShowConcernedPersonModalTableRow',
  props: {
    dossier: {
      type: Object,
      required: true
    },
    person: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      dossierTypes: 'metadata/getMetadataDossierTypes'
    }),
    dossierType() {
      if (!this.dossier.type) return '-'
      return this.dossierTypes.find((type) => type.id === this.dossier.type)
        ?.name
    },
    relation() {
      if (!this.dossier.concernedPerson) {
        return '--'
      }
      if (this.dossier.concernedPerson.full_name === this.person.name) {
        return '-'
      } else {
        if (!this.dossier.contacts || this.dossier.contacts.length < 1) {
          return '-'
        } else {
          let relationship = this.dossier.contacts.find(
            (contact) => contact.id === this.person.id
          ).relationship
          if (!relationship) return '-'
          return this.initialMetadata?.enums?.relationship?.find(
            (relation) => relation.id === relationship
          )?.name
        }
      }
    },
    role() {
      return '-'
    }
  }
}
</script>
