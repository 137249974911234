<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div
    class="dark-mode-switcher box fixed bottom-0 left-0 z-50 mb-10 ml-10 flex h-12 w-40 cursor-pointer items-center justify-center rounded-full border shadow-md dark:bg-slate-800"
    @click="switchMode"
  >
    <div class="mr-4 text-gray-700 dark:text-gray-300">Dark Mode</div>
    <div
      :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
      class="dark-mode-switcher__toggle border"
    />
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheDarkModeSwitcher',
  computed: {
    ...mapGetters('darkMode', ['darkMode'])
  },
  methods: {
    ...mapActions('darkMode', ['setDarkMode']),
    setDarkModeClass() {
      this.darkMode
        ? document.documentElement.classList.add('dark')
        : document.documentElement.classList.remove('dark')
    },
    // setDarkModeClass() {
    //   this.darkMode
    //     ? cash('html').addClass('dark')
    //     : cash('html').removeClass('dark')
    // },
    switchMode() {
      this.setDarkMode(!this.darkMode)
      this.setDarkModeClass()
    }
  },
  mounted() {
    this.setDarkModeClass()
  }
}
</script>
