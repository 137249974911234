<template>
  <div v-if="editionMode" class="">
    <div
      class="base-multiselect-simple relative mt-2 rounded-md border border-slate-400 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
    >
      <label
        :for="name"
        class="absolute -top-2 left-2 z-10 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
        >{{ label ? label : $t('attributes.language') }}&nbsp;
        <span v-if="required" class="text-red-600">*</span>
      </label>
      <multiselect
        v-if="options"
        :options="options"
        :model-value="cDefautlValue"
        :placeholder="cPlaceholder"
        :searchable="searchable"
        :label="attributeLabel"
        :track-by="trackBy"
        :required="required"
        @update:modelValue="updateValueAction"
      >
        <template #noOptions
          ><span>{{ $t('global.is_empty_list') }}</span></template
        >
      </multiselect>
    </div>

    <template v-if="errors && errors.language">
      <div
        v-for="(error, index) in errors.language"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </div>

  <div v-else class="">
    <BaseShowLabel
      :label="label ? label : $t('attributes.language')"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseLanguageShowEditSelectSimple',
  components: { multiselect },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: 'language'
    },
    errors: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    metadata: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    withAvailableLanguages: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      availableLanguages: 'metadata/getAvailableLanguages'
    }),
    cDisplayedValueWhenNotEditionMode() {
      if (this.cDefautlValue) {
        if (this.attributeLabel) {
          return this.cDefautlValue[this.attributeLabel]
        } else {
          return this.cDefautlValue
        }
      } else {
        return '-'
      }
    },
    cPlaceholder() {
      if (this.placeholder) return this.placeholder
      return this.$t('button.select_an_option')
    },
    cDefautlValue() {
      if (
        this.metadata &&
        this.metadata.languages &&
        this.metadata.languages.find((item) => item.id === this.modelValue)
      )
        return this.metadata.languages.find(
          (item) => item.id === this.modelValue
        )
      else return null
    },
    options() {
      if (this.withAvailableLanguages) {
        return this.metadata?.languages?.filter((language) =>
          this.availableLanguages?.includes(language.id)
        )
      }
      return this.metadata?.languages
    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue != null) {
        this.$emit('update:modelValue', newValue[this.trackBy])
      }
    }
  }
}
</script>
