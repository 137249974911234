import getters from '@/store/modules/contact/getters'
import mutations from '@/store/modules/contact/mutations'
import actions from '@/store/modules/contact/actions'

export default {
  namespaced: true,
  state() {
    return {
      contactList: [],
      roleContactList: [],
      contact: {},
      metadata: []
    }
  },
  getters,
  actions,
  mutations
}
