<template >
  <div >
    <template v-if="thereIsALotOfOptions">
      <BaseShowEditSelectSimple
        v-model="internalValue"
        :label="label"
        :placeholder="label"
        :name="name"
        :edition-mode="editionMode"
        :options="options"
        :track-by="trackBy"
        :attribute-label="attributeLabel"
        :required="required"
        :errors="errors"
      />
    </template >
    <template v-else>
      <BaseShowEditRadioGroup
        v-model="internalValue"
        name="death_place_type"
        :extra_id="extraId"
        :label="label"
        :options="options"
        :track-by="trackBy"
        :attribute-label="attributeLabel"
        :edition-mode="editionMode"
        :translatable="false"
        :is-vertical="false"
        :required="required"
        :errors="errors"
      />
    </template >
  </div >
</template >

<script >
export default {
  name: "BaseShowEditDynamicRadioSelect",
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    numberOfOptionsThreshold: {
      type: Number,
      required: false,
      default: 4
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true,
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    extraId: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    thereIsALotOfOptions() {
      if(!this.options){
        return false
      }
      
      return this.options?.length >= this.numberOfOptionsThreshold
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  },
  methods: {
    getElement(item) {
      let temp = this.options?.find((data) => data[this.trackBy] === item).name
      if ( temp) {
        return temp
      }
      return null
    },
  }
}
</script >
