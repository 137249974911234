export default {
  setSearchResult(state, result) {
    state.searchResult = result
  },
  resetSearchResult(state) {
    state.searchResult = []
  },
  setSearchValue(state, searchValue) {
    state.searchValue = searchValue
  },
  resetSearchValue(state) {
    //state.searchValue = ''
  },
  setCommandPaletteOpened(state, opened) {
    state.opened = opened
  }
}
