import {
  addCoffinArrivalIfDossierDoesntHave,
  sortEvents,
  updateCoffinArrival
} from '../helpers/helpers'

export default {
  setDossierEventsKey(state) {
    state.dossier.events = []
  },

  setCeremonySpeaker(state, payload) {
    state.dossier.events[payload.eventIndex].properties.ceremony_speaker_id =
      payload.contact
  }, // #region setters

  setEventTimeSlotInDossierEvent(state, payload) {
    state.dossier.events[payload.index].timeslots = payload.timeslots
  },

  addEventInDossier(state, payload) {
    state.dossier.events.push(payload)
  }, // #endregion

  // #region modifiers

  alterDossierEventAttributeByIndex(state, payload) {
    state.dossier.events[payload.index][payload.attribute] = payload.value
  },
  alterDossierEventArrayAttributeByIndex(state, payload) {
    Object.keys(payload.attributes).forEach((key) => {
      state.dossier.events[payload.index][key] = payload.attributes[key]
    })
  },
  alterDossierEventPropertiesAttributeByIndex(state, payload) {
    state.dossier.events[payload.index].properties[payload.attribute] =
      payload.value
  },

  deleteDossierEventKeyByIndex(state, payload) {
    delete state.dossier.events[payload.index][payload.attribute]
  },

  deleteDossierEventById(state, payload) {
    state.dossier.events.splice(payload.id, 1)
  },

  removeEventFromDossier(state, payload) {
    if (payload.type === 'CRE') {
      state.dossier.events = state.dossier.events.filter(
        (obj) => obj.type !== 'CON'
      )
      state.dossier.properties.conservation = false
    }
    state.dossier.events = state.dossier.events.filter(
      (obj) => obj.type !== payload.type
    )
    sortEvents(state)
  },
  deleteALlProvidedTypeEvent(state, payload) {
    state.dossier.events = state.dossier.events.filter(
      (event) => event.type !== payload.type
    )
  },

  cleanDossierEventsFromTypes(state) {
    const acceptedEventTypes = ['CRE', 'CAR', 'CER', 'REC']
    state.dossier.events = state.dossier.events.filter((event) =>
      acceptedEventTypes.includes(event.type)
    )
    sortEvents(state)
  },

  alterDossierEventValuesByEventType(state, payload) {
    addCoffinArrivalIfDossierDoesntHave(state)

    const objIndex = state.dossier?.events?.findIndex(
      (obj) => obj.type === payload.type
    )
    if (objIndex !== -1) {
      state.dossier.events[objIndex][payload.attribute] = payload.value
    } else {
      state.dossier.events.push(payload)
    }
    updateCoffinArrival(state)
    sortEvents(state)
  },

  alterOrPushEventInDossier(state, payload) {
    const index = state.dossier?.events?.findIndex(
      (obj) => obj.type === payload.type
    )
    if (index === -1) {
      state.dossier.events.push({ ...payload })
    } else {
      state.dossier.events[index] = {
        ...state.dossier.events[index],
        ...payload
      }
    }
  }

  // #endregion
}
