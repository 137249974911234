<template>
  <Menu as="div" class="relative z-100 text-left">
    <MenuButton class="inline-flex justify-center">
      <div
        :class="[
          mainButtonClass,
          'flex w-full flex-row items-center gap-2 rounded-md border px-2 py-1'
        ]"
      >
        <p>
          {{ selected ? selectedOption.name : label}}
        </p>
        <FontAwesomeIcon :icon="['fas', 'caret-down']" />
      </div>
    </MenuButton>
    
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="focus:outline-none absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="px-1 py-1"
        >
          <MenuItem
            v-if="option"
            class="cursor-pointer"
            @click="handleSelected(option)"
          >
            <div
              :class="[
                option.id === modelValue
                  ? 'bg-blue-100 hover:bg-blue-200'
                  : 'bg-white hover:bg-gray-50',
                'group flex w-full items-center rounded-md px-2 py-2 text-sm font-bold text-gray-600'
              ]"
            >
              {{ option.name }}
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseDropDownInput',
  components: { Menu, MenuButton, MenuItems, MenuItem },
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    fullModelResponse: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'selected'],
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    selectedOption() {
      if(!this.fullModelResponse){
        return this.options?.find((option) => option.id === this.modelValue)
      }
      return this.options?.find((option) => option === this.modelValue)
    },
    selected() {
      if (!this.options) {
        return false
      }
  
      if (!this.fullModelResponse) {
        return !!this.options?.find((option) => option.id === this.modelValue)
      }
      return !!this.options?.find((option) => option === this.modelValue)
    },
    mainButtonClass(){
      if (this.selected){
        if(this.selectedOption.bg_color){
          return `bg-theme-${this.selectedOption.bg_color} text-white hover:bg-white hover:text-theme-${this.selectedOption.bg_color} border-theme-${this.selectedOption.bg_color}`
        }
        return 'bg-blue-200 hover:bg-blue-300 text-gray-600'
      } else {
        return 'bg-white hover:bg-gray-50 text-gray-600'
      }
    }
  },
  methods: {
    handleSelected(variant) {
      if (this.fullModelResponse) {
        this.$emit('selected', variant)
        this.$emit('update:modelValue', variant)
      } else {
        this.$emit('selected', variant.id)
        this.$emit('update:modelValue', variant.id)
      }
    }
  }
}
</script>
