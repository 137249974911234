<template>
  <div class="flex flex-col gap-y-3">
    <BaseEditLabel v-if="label" :label="label" :required="required" />
    <div class="w-full">
      <div class="flex justify-between">
        <template v-for="(option, index) in options" :key="index">
          <BaseStepsInputOption
            v-model="internalValue"
            :option="option"
            :option-index="index"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BaseEditLabel from "@c/addf-package/components/BaseLabel/BaseEditLabel";
import BaseStepsInputOption from "@c/addf-package/components/steps/baseStepsinput/components/BaseStepsInputOption";
export default {
  name: "BaseStepsInput",
  components: { BaseStepsInputOption, BaseEditLabel },
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  }
}
</script>
