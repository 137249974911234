export default {
  getUserList(state) {
    return state.usersList
  },
  getUser(state) {
    return state.user
  },
  getUserMetadata(state) {
    return state.metadata
  },
  getUserMetadataActivities(state) {
    return state.metadataActivities
  },
  getUserActivities(state) {
    return state.userActivities
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getErrorImport(state) {
    return state.errorImport
  }
}
