import apiClient from "@u/apiClient";

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {
    async fetchDumpList({commit, getters}, payload) {
        await apiClient
            .get(`/sa-management/fetch-dump-list`)
            .then(async (response) => {
                // console.log('res', response)
                if (response.data) {
                    await commit('setDumpList', response.data.dump_list)
                }
            })
            .catch(async (reason) => {
                console.error('fetchDumpList', reason)
            })
    },

    async dumpDb({commit, getters}, payload) {
        console.log('dumpDb')
        await apiClient
            .get(`/sa-management/direct-dump`)
            .then(async (response) => {
                // console.log('res', response)
                if (response.data.success) {
                    payload.successCallback()
                }
            })
            .catch(async (reason) => {
                console.error('fetchDumpList', reason)
            })
    },

    async restoreDump({commit, getters}, payload) {
        console.log('in restore')
        await apiClient
            .post(`/sa-management/restore-dump`, {dump: payload.dump})
            .then(async (response) => {
                if (response.status === 200) {
                    payload.successCallback(response.data.key)
                }
            })
            .catch(async (reason) => {
                console.error('fetchDumpList', reason)
            })
    },

}
