<template>
  <div class="grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2">
    <div>
      <BaseShowEditDatePickerv2
        v-model:date="contact.wedding_date"
        :label="$t('attributes.date')"
        :today-default="false"
        :edition-mode="editionMode"
        name="wedding_date"
      />
    </div>
    <BaseShowEditInput
      v-model="contact.wedding_place"
      :label="$t('attributes.place')"
      :placeholder="$t('attributes.place')"
      name="place"
      :edition-mode="editionMode"
    />
    <BaseShowEditInput
      v-model="contact.wedding_nb"
      type="number"
      min="1"
      max="10"
      :label="$t('attributes.wedding')"
      :placeholder="$t('attributes.wedding')"
      name="wedding"
      :edition-mode="editionMode"
    />
  </div>
</template>

<script>
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'

export default {
  name: 'ContactCardMaritalStatusWeddingComponent',
  components: { BaseShowEditDatePickerv2 },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    }
  }
}
</script>
