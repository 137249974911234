export default {
  getAutomationList(state) {
    return state.automationList
  },
  getAutomationMetadata(state) {
    return state.automationMetadata
  },
  getAutomation(state) {
    return state.automation
  }
}
