export default {
  getInvoicingCategoryList(state) {
    return state.invoicingCategoryList
  },
  getInvoicingCategory(state) {
    return state.invoicingCategory
  },
  getMetadata(state) {
    return state.metadata
  },
  getEditionMode(state) {
    return state.editionMode
  }
}
