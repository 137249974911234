<template>
  <div class="dropdown" data-placement="bottom-end">
    <button
      class="dropdown-toggle SettingsNavLinkComponent group inline-flex items-center justify-between gap-3 rounded-md border border-slate-300 px-3 py-2 font-medium text-gray-600 text-opacity-90 hover:text-opacity-100"
      aria-expanded="false"
    >
      <span class="text-xs font-medium capitalize-first">{{
        $t('button.column')
      }}</span>
      <font-awesome-icon :icon="['fal', 'chevron-down']"></font-awesome-icon>
    </button>
    <div class="dropdown-menu">
      <div class="dropdown-menu__content box p-2">
        <div>
          <template v-for="column in columns.filter((item) => item.toggleable)">
            <a
              href="#"
              class="block flex items-center rounded-md bg-white p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:bg-slate-800 dark:hover:bg-slate-800"
            >
              <span
                class="mr-5 flex-1 whitespace-nowrap capitalize-first"
                @click="clickColumnVisibility(column.field)"
              >
                {{ $t(`attributes.${column.title}`) }}</span
              >
              <!--              <font-awesome-icon v-if='!column.hidden' class="flex-shrink-0 w-4 h-4 text-gray-700 dark:text-gray-300"-->
              <!--                                 :icon='["fal","check"]'-->
              <!--              ></font-awesome-icon >-->

              <Switch
                :model-value="column.hidden"
                :class="[
                  !column.hidden ? 'bg-blue-500' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                ]"
                @update:modelValue="clickColumnVisibility(column.field)"
              >
                <span class="sr-only">Use setting</span>
                <span
                  :class="[
                    !column.hidden ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  ]"
                >
                  <span
                    :class="[
                      !column.hidden
                        ? 'opacity-0 duration-100 ease-out'
                        : 'opacity-100 duration-200 ease-in',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    ]"
                    aria-hidden="true"
                  >
                    <svg
                      class="h-3 w-3 text-slate-300"
                      fill="none"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    :class="[
                      !column.hidden
                        ? 'opacity-100 duration-200 ease-in'
                        : 'opacity-0 duration-100 ease-out',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    ]"
                    aria-hidden="true"
                  >
                    <svg
                      class="h-3 w-3 text-blue-400"
                      fill="currentColor"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                      />
                    </svg>
                  </span>
                </span>
              </Switch>
            </a>
          </template>
        </div>
        <div class="mt-3 flex hidden items-center space-x-3">
          <button data-dismiss="dropdown" class="btn btn-secondary">
            apply
          </button>
          <button class="btn btn-primary">cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Switch } from '@headlessui/vue'

export default {
  name: 'ColumnVisibilityDropDownComponent',
  components: { Switch },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    updateColumnVisibility: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      columns: this.modelValue
    }
  },
  methods: {
    clickColumnVisibility(key) {
      this.columns.map((item) => {
        if (item.field === key) {
          item.hidden = !item.hidden
        }
      })
      this.updateColumnVisibility(this.columns)
      // let modelValueInJson = JSON.stringify(this.columns)
      // localStorage.setItem('InvoiceColumnsVisibility', modelValueInJson)
      // this.columns
      // this.$emit('update:modelValue', this.columns);
    }
  }
}
</script>
