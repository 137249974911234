const Partners = Object.freeze({
  ENAOS: 'enaos',
  FRAGMENT: 'fragment',
  LEGACIO: 'legacio',
  FUNE_UP: 'fune_up',
  YOUSIGN: 'yousign',
  CREMAGEST: 'cremagest',
  SORENIR: 'sorenir'
})

export default Partners
