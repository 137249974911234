<template >
  <div >
    <BaseShowEditTextArea
      v-model="contact.remarks"
      :label="$t('global.note')"
      :placeholder="$t('global.note')"
      :edition-mode="editionMode"
    />
  </div >
</template >

<script >
export default {
  name: "ContactCardNoteComponent",
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
  },
}
</script >
