export default {
  getNoteIndex(state) {
    return state.noteIndex
  },
  getNote(state) {
    return state.note
  },
  getNoteMetadata(state) {
    return state.noteMetadata
  }
}
