import apiClient from '@u/apiClient'

let base_errors = {deceased: {}, events: []}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {
    async deleteWikiItemById({commit}, payload) {
        await apiClient
            .delete(`wiki-items/delete/${payload.wiki_item_id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setItemList', {
                        item_list: response.data.items,
                        parent_is_a_place: payload.parent_is_a_place
                    })
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                await console.error('deleteWikiItemById', reason)
            })
    },
    async undoDeleteWikiItemById({commit}, payload) {
        await apiClient
            .get(`wiki-items/undo-delete/${payload.wiki_item_id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setItemList', {
                        item_list: response.data.items,
                        parent_is_a_place: payload.parent_is_a_place
                    })
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                await console.error('undoDeleteWikiItemById', reason)
            })
    }
}
