export default {
  setTasksList(state, tasksList) {
    state.tasksList = tasksList
  },
  setTask(state, task) {
    state.task = task
    if (!('users' in state.task)) {
      state.task.users = []
    }
  },
  setTaskMetadata(state, metadata) {
    state.taskMetadata = metadata
  },
  alterTask(state, payload) {
    state.task[payload.attribute] = payload.value
  },
  setTaskActivity(state, activity) {
    state.taskActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setTempUsers(state, payload) {
    if (!!payload.fh) {
      state.tempUsers[payload.fh] = payload.users
    } else {
      state.tempUsers = payload
    }
  },
  setTempExecutorUsers(state, payload) {
    if (!!payload.fh) {
      state.tempExecutorUsers[payload.fh] = payload.users
    } else {
      state.tempExecutorUsers = payload
    }
  },
  addUserResponsibleToTask(state, payload) {
    let obj = state.task.users?.find((o) => o.funeral_home_id === payload.fh_id)
    if (!!obj) {
      obj['manager'] = payload.user
    } else {
      state.task.users.push({
        funeral_home_id: payload.fh_id,
        manager: payload.user
      })
    }
  },
  deleteUserResponsibleToTask(state, payload) {
    let obj = state.task.users?.find((o) => o.funeral_home_id === payload.fh_id)
    if (!!obj) {
      obj['manager'] = null
    }
  },
  deleteUserExecutorToTask(state, payload) {
    let obj = state.task.users?.find((o) => o.funeral_home_id === payload.fh_id)
    let index = Object.keys(obj['executors']).find(
      (key) => obj['executors'][key] === payload.user
    )
    obj['executors'].splice(index, 1)
  },
  addExecutorsToTask(state, payload) {
    let obj = state.task.users?.find((o) => o.funeral_home_id === payload.fh_id)
    if (!!obj) {
      if (obj['executors']) {
        if (!obj['executors'].includes(payload.user)) {
          obj['executors'].push(payload.user)
        }
      } else {
        obj['executors'] = [payload.user]
      }
    } else {
      state.task.users.push({
        funeral_home_id: payload.fh_id,
        executors: [payload.user]
      })
    }
    console.log(state.task.users)
  }
}
