export default {
  getCities(state) {
    return state.cities
  },
  getClosestCities(state) {
    return state.closestCities
  },
  getFavoriteCities(state) {
    return state.favoriteCities
  },
  getCity(state) {
    return state.city
  },

  getCityID(state) {
    return state.city_id
  }
}
