<template>
  <div v-if="editionMode" :class="cGroupClass">
    <div
      class="relative rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
      :class="[label ? 'mt-2' : '']"
    >
      <div
        class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
      >
        <div class="flex items-center">
          <label v-if="label" :for="name" class="capitalize-first"
            >{{ label }}&nbsp;
            <span v-if="label && required" class="text-red-600">*</span>
          </label>
          <div
            v-if="displayTimeDifference && timeDifference"
            class="text-xs text-gray-500 capitalize-first"
          >
            ( {{ timeDifference }} )
          </div>
        </div>
      </div>
      <input
        v-if="!isDatePicker"
        :id="name"
        v-model="internalValue"
        v-mask="mask"
        :name="name"
        type="text"
        :class="[cInputClass]"
        :placeholder="placeholder"
        @input="updateInput"
      />
      <datepicker
        v-else
        v-model="datePickerValue"
        :month-heading-format="monthFormat"
        :weekday-format="weekFormat"
        :input-format="datePickerFormat"
        :week-starts-on="startingDayOfTheWeek"
        :clearable="clearButtonVisible"
        :typeable="canTypeDateManually"
        :starting-view="viewType"
        :style="style"
        :readonly="false"
        :locale="locale"
        :class="['cursor-default', cInputClass]"
        @update:modelValue="handleDateSelectedInDatePicker"
      />
      <ErrorContainer :error-key="name" :errors="errors" />
    </div>
  </div>
  <div v-else class="">
    <BaseShowLabel
      :label="label"
      :model-value="internalValue"
      :additional-information="
        displayTimeDifference && timeDifference !== 'Invalid date'
          ? timeDifference
          : null
      "
    />
  </div>
</template>

<script>
import BaseEditLabel from '../BaseLabel/BaseEditLabel.vue'
import BaseShowLabel from '../BaseLabel/BaseShowLabel.vue'
import moment from 'moment'

import Datepicker from 'vue3-datepicker'
import { fr } from 'date-fns/locale'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditIsoDateInput',
  components: { ErrorContainer, BaseEditLabel, BaseShowLabel, Datepicker },
  props: {
    customReferenceDate: {
      type: String,
      required: false,
      default: null
    },
    customReferenceDateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    displayTimeDifference: {
      type: Boolean,
      require: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    mask: {
      type: Array,
      required: false,
      default() {
        return ['##/##/####']
      }
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    isDatePicker: {
      type: Boolean,
      required: false,
      default: true
    },
    monthFormat: {
      type: String,
      required: false,
      default: 'LLLL yyyy'
    },
    weekFormat: {
      type: String,
      required: false,
      default: 'EE'
    },
    startingDayOfTheWeek: {
      type: Number,
      required: false,
      default: 1
    },
    canTypeDateManually: {
      type: Boolean,
      required: false,
      default: false
    },
    clearButtonVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    viewType: {
      type: String,
      required: false,
      default: 'day',
      validator: function (value) {
        const admittedTypes = ['day', 'month', 'year', 'time']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    datePickerFormat: {
      type: String,
      required: false,
      default: 'dd/MM/yyyy'
    }
  },
  data() {
    return {
      internalValue: null,
      locale: fr,
      style: {
        '--vdp-bg-color': '#ffffff',
        '--vdp-text-color': '#424242',
        '--vdp-box-shadow':
          '0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)',
        '--vdp-border-radius': '8px',
        '--vdp-heading-size': '2.5em',
        '--vdp-heading-weight': 'bold',
        '--vdp-heading-hover-color': '#eeeeee',
        '--vdp-arrow-color': 'currentColor',
        '--vdp-elem-color': 'currentColor',
        '--vdp-disabled-color': '#d5d9e0',
        '--vdp-hover-color': '#ffffff',
        '--vdp-hover-bg-color': '#4386C8',
        '--vdp-selected-color': '#ffffff',
        '--vdp-selected-bg-color': '#4386C8',
        '--vdp-elem-font-size': '0.8em',
        '--vdp-elem-border-radius': '3px',
        '--vdp-divider-color': '#d5d9e0'
      }
    }
  },
  computed: {
    datePickerValue() {
      return new Date(moment(this.internalValue, this.dateFormat))
    },
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === ''
        ? 'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm'
        : this.inputClass
    },
    cDisplayedValueWhenNotEditionMode() {
      return this.internalValueIsAValidDate
        ? moment(this.internalValue).format(this.dateFormat)
        : null
    },
    timeDifference() {
      if (this.customReferenceDate) {
        if (this.internalValueIsAValidDate) {
          return moment(this.internalValue, this.dateFormat)
            .lang('fr')
            .from(
              moment(this.customReferenceDate, this.customReferenceDateFormat)
            )
        }
        return null
      } else {
        if (this.internalValueIsAValidDate) {
          return moment(this.internalValue, this.dateFormat)
            .lang('fr')
            .from(moment().startOf('day'))
        }
        return null
      }
    },
    internalValueIsAValidDate() {
      let subValidation = moment(this.internalValue, this.dateFormat).format(
        this.dateFormat
      )

      if (subValidation === this.internalValue) {
        return moment(this.internalValue, this.dateFormat).isValid()
      }
      return false
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue) {
          this.internalValue = moment(newValue).format(this.dateFormat)
        } else {
          this.internalValue = null
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleDateSelectedInDatePicker(datePickerDate) {
      this.internalValue = moment(datePickerDate).format(this.dateFormat)
      if (this.internalValueIsAValidDate) {
        this.$emit(
          'update:modelValue',
          moment(this.internalValue, this.dateFormat).format()
        )
      }
    },
    updateInput(event) {
      // j'ai ajouté ci-dessous parce que si la date est vidée, on ne remontait pas la valeur vide
      if (event.target.value.length === 0) {
        this.$emit('update:modelValue', '')
      }
      if (this.internalValueIsAValidDate) {
        this.$emit(
          'update:modelValue',
          moment(event.target.value, this.dateFormat).format()
        )
      }
    }
  }
}
</script>
