<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-100" @close="close">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="flex w-full max-w-xl transform flex-col gap-4 overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-xl font-medium leading-6 text-gray-900 capitalize-first"
              >
                {{ $t('global.automation') }}
              </DialogTitle>

              <div class="flex flex-col gap-2">
                <template
                  v-for="proposedItem in proposedItems"
                  :key="proposedItem.uuid"
                >
                  <div
                    v-for="item in proposedItem.items"
                    :key="item.id"
                    class="flex flex-row items-center rounded border p-2"
                  >
                    <div class="flex-1">
                      <h4 class="text-base font-semibold">
                        {{ item.name[$i18n.locale] }}
                      </h4>
                      <p class="text-xs">{{ item.remarks[$i18n.locale] }}</p>
                    </div>
                    <div class="flex shrink-0 flex-row gap-2">
                      <BaseButton
                        :title="$t('button.add')"
                        size="extra-small"
                        :disabled="loading"
                        @click="
                          () =>
                            addItemToDossier(item.id, proposedItem.dossier_id)
                        "
                      />
                    </div>
                  </div>
                </template>
              </div>

              <div class="flex justify-end">
                <BaseButton
                  theme-color="bg-secondary text-contrast-secondary"
                  :title="$t('button.close')"
                  :icon="'times'"
                  @click="close"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AutomationSuggestion',
  components: {
    Dialog,
    DialogTitle,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    BaseButton
  },
  data() {
    return {
      isOpen: false,
      proposedItems: [],
      channel: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  watch: {
    proposedItems: {
      handler(newValue) {
        this.isOpen = newValue.length > 0
      },
      deep: true
    }
  },
  mounted() {
    this.channel = this.$pusher.channel(`private-user.${this.user.id}`)
    this.channel.bind('propose_item', (data) => {
      this.proposedItems.push(data)
    })
  },
  unmounted() {
    this.channel.unbind(`private-user.${this.user.id}`)
  },
  methods: {
    ...mapActions({
      processAddItemsToDossierAction: 'dossier/addItemsToDossier'
    }),
    addItemToDossier(item_id, dossier_id) {
      this.loading = true
      this.processAddItemsToDossierAction({
        data: {
          items: [{ id: item_id, quantity: 1 }]
        },
        id: dossier_id
      })
        .then((response) => {
          this.proposedItems = this.proposedItems
            .map((proposedItem) => {
              return {
                ...proposedItem,
                items: proposedItem.items.filter((item) => item.id !== item_id)
              }
            })
            .filter((proposedItem) => proposedItem.items.length > 0)
          this.$h.toastSuccess(this.$t('toasts.items_added_to_dossier'))
        })
        .finally((_) => {
          this.loading = false
        })
    },
    close() {
      this.isOpen = false
      setTimeout(() => (this.proposedItems = []), 250)
    }
  }
}
</script>
