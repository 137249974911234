import TheMailBoxComposePage from '@/views/modelNotification/form/TheMailBoxComposePage.vue'
import ThreadListComponent from '@/views/modelNotification/index/components/ThreadListComponent.vue'
import ThreadComponent from '@/views/modelNotification/index/components/ThreadComponent.vue'
import mailboxTypes from '@/assets/enums/notifications/mailboxTypes'

const TheMailBoxIndexPage = () =>
  import('@/views/modelNotification/index/TheMailBoxIndexPage.vue')
const TheMailBoxShowPage = () =>
  import('@/views/modelNotification/show/TheMailBoxShowPage')

export const modelNotificationRootPages = [
  {
    path: 'mailbox',
    name: 'mail_box_page_route',
    to: { name: 'mail_box_page' },
    component: TheMailBoxIndexPage,
    meta: {
      type: 'list'
    },
    children: [
      {
        path: '',
        redirect: { name: 'mail_box_page_path', params: { path: 'inbox' } },
        name: 'mail_box_page'
      },
      {
        path: 'compose',
        name: 'mail_box_compose_route',
        component: TheMailBoxComposePage,
        props: true,
        children: [
          {
            path: '',
            name: 'mail_box_create_route',
            props: true
          },
          {
            path: ':message_id',
            name: 'mail_box_edit_route',
            props: true
          }
        ]
      },
      {
        path: ':path',
        name: 'mail_box_page_path',
        props: true,
        component: ThreadListComponent,
        beforeEnter: (to, from, next) => {
          if (Object.values(mailboxTypes).includes(to.params.path)) {
            next()
            return
          } else {
            console.log('path doest not exist')
          }
        },
        children: [
          {
            path: ':groupId',
            name: 'mail_box_page_group_id',
            props: true,
            component: ThreadComponent
          }
        ]
      }
      // {
      //   path: 'inbox',
      //   name: 'mail_box_page_default_route',
      //   props: { box: 'inbox' },
      //   component: ThreadListComponent
      // },
      // {
      //   path: 'unread',
      //   name: 'mail_box_unread_route',
      //   props: { box: 'unread' },
      //   component: ThreadListComponent
      // },
      // {
      //   path: 'starred',
      //   name: 'mail_box_starred_route',
      //   props: { box: 'starred' },
      //   component: ThreadListComponent
      // },
      // {
      //   path: 'snoozed',
      //   name: 'mail_box_snoozed_route',
      //   props: { box: 'snoozed' },
      //   component: ThreadListComponent
      // },
      // {
      //   path: 'sent',
      //   name: 'mail_box_sent_route',
      //   props: { box: 'sent' },
      //   component: ThreadListComponent
      // },
      // {
      //   path: 'draft',
      //   name: 'mail_box_draft_route',
      //   props: { box: 'draft' },
      //   component: ThreadListComponent
      // },
      // {
      //   path: 'trash',
      //   name: 'mail_box_trash_route',
      //   props: { box: 'trash' },
      //   component: ThreadListComponent
      // },
    ]
  }
]
