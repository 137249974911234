/* eslint-disable camelcase */
import apiClient from '@u/apiClient'
// import router from '@/router'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchItemCategoryIndex({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('item-category')
      .then(async (response) => {
        await commit('setItemCategoryList', response.data.item_categories)
        await commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchItemCategoryIndex', reason)
      })
  },
  async fetchItemCategoryByID({ commit }, id) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`item-category/${id}`)
      .then(async (response) => {
        commit('setItemCategory', response.data.item_category)
      })
      .catch(async (reason) => {
        console.error('fetchItemCategoryByID', reason)
      })
  },
  async fetchNewItemCategory({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=itemCategory')
      .then(async (response) => {
        console.log('data', response.data.itemCategory)
        commit('setItemCategory', response.data.itemCategory)
      })
      .catch(async (reason) => {
        console.error('fetchNewItemCategory', reason)
      })
  },
  // endregion

  // region store
  async storeNewItemCategory({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post('item-category', payload.item_category)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setItemCategory', response.data.item_categories)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('storeNewItemCategory', reason)
      })
  },
  // endregion

  // region update
  async updateItemCategory({ commit }, payload) {
    console.log('action', payload.item_category)
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`item-category/${payload.id}`, payload.item_category)
      .then(async (response) => {
        if (response.status === 201) {
          await commit('setItemCategoryList', response.data.item_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('updateItemCategory', reason)
      })
  },
  // endregion

  // region update
  async updateMultipleItemCategory({ commit }, payload) {
    console.log('action', payload)
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post(`item-category/multiple-item-update`, payload)
      .then(async (response) => {
        if (response.status === 201) {
          await commit('setItemCategoryList', response.data.item_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('updateMultipleItemCategory', reason)
      })
  },
  // region delete
  async deleteItemCategoryById({ commit }, payload) {
    await apiClient
      .delete(`item-category/item-category-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setItemCategoryList', response.data.item_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteItemCategoryById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteItemCategoryById({ commit }, payload) {
    await apiClient
      .get(`item-category/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setItemCategoryList', response.data.item_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteItemCategoryById', reason)
      })
  },
  // endregion

}
