<template>
  <div v-if="editionMode" class="">
    <div>
      <BaseEditLabel :label="label" :required="required" />
      <multiselect
        :options="metadata"
        multiple
        v-model="internalValue"
        :placeholder="placeholder"
        :searchable="searchable"
        :label="attributeLabel"
        :track-by="trackBy"
        :required="required"
      />
    </div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </div>
  <div v-else class="flex flex-col">
    <BaseShowLabel :label="label" :required="required" />
    <div v-if="modelValue.length >= 1">
      <span
        v-for="(item, index) in modelValue"
        class="mr-3 inline-block"
        :key="index"
      >
        <font-awesome-icon
          :icon="['fal', 'check-square']"
          class="mr-1 mt-2 text-blue-900"
        />
        {{ getElement(item) }}
      </span>
    </div>
    <template v-else>
      {{ '-' }}
    </template>
  </div>
</template>

<script>
import BaseEditLabel from '../../BaseLabel/BaseEditLabel.vue'
import multiselect from 'vue-multiselect'

export default {
  name: 'BaseShowEditSelectMultiple',
  components: { multiselect, BaseEditLabel },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    modelValue: {
      type: Array,
      default() {
        return []
      },
      require: true
    },
    errors: {
      type: Object,
      required: false
    },
    metadata: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    selectClass: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    fullModelResponse: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    internalValue: {
      get() {
        if(this.fullModelResponse){
          return this.modelValue
        } else {
          return this.modelValue.reduce((old, curr) => {
            old.push(this.metadata?.find(option => option[this.trackBy] === curr))
            return old
          }, [])
        }
      },
      set(newValue) {
        if(this.fullModelResponse){
          this.$emit('update:modelValue', newValue)
          this.$emit('change', newValue)
        } else {
          let temp = []
          newValue.reduce((old, curr) => {
            old.push(curr[this.trackBy])
            return old
          }, temp)
          this.$emit('update:modelValue', temp)
          this.$emit('change', temp)
        }
      }
    },
  },
  methods: {
    getElement(item) {
      if (
        this.metadata &&
        this.metadata.find((data) => data[this.trackBy] === item)[this.attributeLabel]
      ) {
        return this.metadata.find((data) => data[this.trackBy] === item)[this.attributeLabel]
      }
      return null
    },
  }
}
</script>
