<template>
  <div class="flex flex-col sm:flex-row items-center mt-8">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BasePageHeader'
}
</script>
