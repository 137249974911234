<template>
  <div class="flex w-full flex-col gap-2 py-2">
    <h2 class="text-xl font-bold capitalize-first">
      {{ $t('attributes.other_infos') }}
    </h2>
    <div>
      <div class="grid grid-cols-1 gap-2 md:grid-cols-4">
        <div v-if="false" class="col-start-1">
          <BaseShowEditInput
            v-model="invoice.reference_quote"
            :label="$t('attributes.reference_quote')"
            :errors="errors.reference_quote"
            :placeholder="$t('attributes.reference_quote')"
            edition-mode
          />
        </div>
        <div v-if="false" class="col-start-1">
          <BaseShowEditInput
            v-model="invoice.reference_extern"
            :label="$t('attributes.reference_extern')"
            :errors="errors.reference_extern"
            :placeholder="$t('attributes.reference_extern')"
            edition-mode
          />
        </div>
        <div class="col-start-1">
          <SelectLanguages v-model="invoice.language" edition-mode />
        </div>
        <div class="col-span-2 col-start-1">
          <BaseShowEditTextArea
            v-model="invoice.remarks"
            :label="$t('attributes.remarks')"
            :errors="errors.remarks"
            edition-mode
            name="remarks"
          />
        </div>
        <div class="col-span-2 col-start-1">
          <BaseShowEditTextArea
            v-model="invoice.note_intern"
            :label="$t('attributes.note_intern')"
            :errors="errors.note_intern"
            edition-mode
            name="note_intern"
          />
        </div>
        <div
          v-if="
            invoice.doc_type === 'QUO' && $route.query.replaceContact === 'true'
          "
          class="col-span-2 col-start-1"
        >
          <TheInvoiceWizardInvoiceableStep
            v-model:invoice="invoice"
            v-model:have-dossier="invoice.dossier_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditTextArea from '@c/addf-package/components/BaseShowEditInput/BaseShowEditTextArea.vue'
import TheInvoiceWizardInvoiceableStep from '@/views/invoicing/form/steps/components/TheInvoiceWizardInvoiceableStep.vue'

export default {
  name: 'TheInvoiceWizardFinalInformationStep',
  components: {
    TheInvoiceWizardInvoiceableStep,
    BaseShowEditTextArea,
    BaseShowEditInput,
    SelectLanguages
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  emits: ['update:invoice'],
  data() {
    return {
      isOpen: false,
      displayUpdateInvoice: false,
      updatedInvoiceCompany: null,
      updatedInvoiceFuneralHome: null
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      errors: 'auth/getErrors',
      initialMetadata: 'metadata/getInitialMetadata',
      companies: 'metadata/getCompaniesMetadata',
      funeralHomes: 'metadata/getFuneralHomesMetadata'
    }),
    localInvoice: {
      get() {
        return this.invoice
      },
      set(value) {
        this.$emit('update:invoice', value)
      }
    }
  },
  methods: {
    ...mapActions({
      updateInvoice: 'invoicing/updateInvoice',
      processFetchCompanyByIdAction: 'company/fetchCompanyByID'
    }),
    submitForm() {
      if (this.invoice.id) {
        this.updateInvoice({
          id: this.invoice.id,
          invoice: this.invoice
        }).then((response) => {
          this.$h.toastSuccess(
            this.$t('attributes.invoice') + ' ' + this.$t('toasts.updated')
          )
        })
      }
    }
  },
  mounted() {
    if (!this.invoice.language) {
      this.invoice.language = this.auth.language
    }
  }
}
</script>
