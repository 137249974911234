<template>
  <div class="flex flex-col gap-1">
    <div class="flex flex-row items-center">
      <div class="flex flex-1 flex-col">
        <h2 class="text-base font-bold">
          {{ invoiceable.full_name }}
          {{ invoiceable.is_deceased ? `(${$t('attributes.dead')})` : null }}
        </h2>
        <div
          v-if="invoiceable.type === 'App\\Models\\Person'"
          class="flex flex-row gap-2"
        >
          <div
            v-if="invoiceable.relationship"
            class="rounded-md bg-primary px-2 py-0.5 text-xs text-contrast-primary capitalize-first"
          >
            {{ relationshipLabel }}
          </div>
          <div
            v-if="invoiceable.is_purchaser"
            class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
          >
            {{ $t('enums.contact.contactDossierTypes.purchaser') }}
          </div>
          <div
            v-if="invoiceable.is_handler"
            class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
          >
            {{ $t('enums.contact.contactDossierTypes.handler') }}
          </div>
          <div
            v-if="invoiceable.is_co_handler"
            class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
          >
            {{ $t('enums.contact.contactDossierTypes.co_handler') }}
          </div>
        </div>
      </div>
      <span
        v-if="
          !invoiceableDossierSelector &&
          invoiceable.type === 'App\\Models\\Person'
        "
        class="shrink-0"
      >
        <BaseDropDownMenu :options="menuOptions" :is-button="false" />
      </span>
      <span v-else-if="invoice.status === Status.DRAFT" class="shrink-0">
        <InvoiceableDossierSelector
          v-if="invoice.dossier?.id"
          v-model:invoiceable="localInvoiceable"
          v-model:invoiceable-type="localInvoiceableType"
          v-model:invoiceable-id="localInvoiceableId"
          :invoice-id="invoice.id"
          :selected-id="invoiceable.id"
          @invoiceable-changed="invoiceableChanged"
        />
      </span>
    </div>
    <p>
      <span>{{ invoiceable.street }}<br /></span>
      <span v-if="invoiceable.extra_street && invoiceable.extra_street.length">
        {{ invoiceable.extra_street }}<br />
      </span>
      <span>{{ [invoiceable.zip, invoiceable.city].join(' ') }}</span>
    </p>
    <div v-if="invoiceable.phone_field && invoiceable.phone_field.length">
      <div
        class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
      >
        {{ $t('attributes.phone') }}
      </div>
      <div class="font-medium leading-tight text-gray-900">
        {{ invoiceable.phone_field }}
      </div>
    </div>
    <div v-if="invoiceable.mobile_field && invoiceable.mobile_field.length">
      <div
        class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
      >
        {{ $t('attributes.mobile') }}
      </div>
      <div class="font-medium leading-tight text-gray-900">
        {{ invoiceable.mobile_field }}
      </div>
    </div>
    <ShowPersonModal
      v-model:open="isModalPersonOpen"
      :person-id="invoiceable.id"
      @person-updated="personUpdated"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import InvoiceableDossierSelector
  from '@/views/invoicing/show/tabs/information/components/information/components/InvoiceableDossierSelector.vue'
import { mapGetters } from 'vuex'
import BaseDropDownMenu from '@c/addf-package/components/dropdown/BaseDropDownMenu.vue'
import ShowPersonModal from '@c/Person/component/ShowPersonModal.vue'
import Status from '@/assets/enums/invoicing/Status'

export default defineComponent({
  name: 'InvoiceableSelector',
  components: { ShowPersonModal, BaseDropDownMenu, InvoiceableDossierSelector },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    invoiceable: {
      type: Object,
      required: true
    },
    invoiceableId: {
      type: String,
      required: true
    },
    invoiceableType: {
      type: String,
      required: true
    },
    invoiceableDossierSelector: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'invoiceable-changed',
    'update:invoiceableType',
    'update:invoiceableId',
    'update:invoiceable'
  ],
  data() {
    return {
      isModalPersonOpen: false,
      replaceContact: false
    }
  },
  computed: {
    Status() {
      return Status
    },
    ...mapGetters({
      getRelationshipMetadata: 'metadata/getRelationshipMetadata'
    }),
    relationshipLabel() {
      if (this.invoiceable.relationship) {
        return this.getRelationshipMetadata(this.invoiceable.relationship)
      }
    },
    menuOptions() {
      return [
        [
          {
            label: this.$t('invoicing.edit_contact'),
            icon: 'pen',
            gate: true,
            action: () => (this.isModalPersonOpen = true)
          },
          {
            label: this.$t('invoicing.replace_contact'),
            icon: 'address-card',
            gate: true,
            disabled: this.invoice.status !== Status.DRAFT,
            action: () => {
              this.replaceContact = true
              this.$router.push({ name: 'invoice_edit_route', query: { replaceContact: this.replaceContact } })
            }
          }
        ]
      ]
    },
    localInvoiceable: {
      get() {
        return this.invoiceable
      },
      set(value) {
        return this.$emit('update:invoiceable', value)
      }
    },
    localInvoiceableId: {
      get() {
        return this.invoiceableId
      },
      set(value) {
        return this.$emit('update:invoiceableId', value)
      }
    },
    localInvoiceableType: {
      get() {
        return this.invoiceableType
      },
      set(value) {
        return this.$emit('update:invoiceableType', value)
      }
    }
  },
  methods: {
    invoiceableChanged() {
      this.$emit('invoiceable-changed')
    },
    personUpdated() {
      this.$emit('invoiceable-changed')
    }
  }
})
</script>
