<template>
  <SelectComponent
    v-model="selectedValues"
    :values="relationshipList"
    :label="$t('attributes.relationship')"
    :required="required"
    name="relationship"
    :errors="errors"
    :edition-mode="editionMode"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from 'vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent'
import ContactSection from '../../../assets/enums/contact/ContactSection'

export default {
  name: 'SelectRelationship',
  components: { SelectComponent },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    modelValue: {
      type: [Array, Number],
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    section: {
      type: String,
      required: false,
      default: ContactSection.GENERAL,
      validator: (value) =>
        [
          ContactSection.ETAT_CIVIL,
          ContactSection.PARENT,
          ContactSection.ENFANT,
          ContactSection.GENERAL
        ].indexOf(value) !== -1
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return { selectedValues }
  },
  computed: {
    ...mapGetters('metadata', {
      relationships: 'getRelationshipsMetadata',
      relationshipFamily: 'getRelationshipFamilyMetadata',
      relationshipMaritalStatus: 'getRelationshipMaritalStatusMetadata'
    }),
    relationshipList() {
      switch (this.section) {
        case ContactSection.PARENT:
          return this.relationshipFamily
        case ContactSection.ETAT_CIVIL:
          return this.relationshipMaritalStatus
        default:
          return this.relationships
      }
    }
  }
}
</script>
