export default {
  getMaterialResourceList(state) {
    return state.materialResourceList
  },
  getMaterialResource(state) {
    return state.materialResource
  },
  getMaterialResourceMetadata(state) {
    return state.materialResourceMetadata
  },
  getMaterialResourceActivity(state) {
    return state.materialResourceActivity
  },
  getEditionMode(state) {
    return state.editionMode
  },
}
