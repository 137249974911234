import * as _ from 'lodash-es'

export default {
  data() {
    return {
      filters: []
    }
  },
  computed: {
    filteredEntries() {
      let tempEntries = this.entries

      const resultsMap = new Map()
      if (this.filters.length) {
        this.filters.forEach((filter, index) => {
          const allNotNull = Object.values(filter).every(
            (value) => value !== null
          )
          if (allNotNull) {
            let attribute = this.metadata.filters?.find(
              (availableFilter) => availableFilter.id === filter.column
            )?.attributes

            if (this.$h.operators[filter.condition]) {
              tempEntries.forEach((entri, idx) => {
                let filterValues = filter.values.id ?? filter.values
                const entriValue = _.get(entri, attribute) // Utilisez la méthode get de lodash pour accéder à la propriété imbriquée
                //on applique la comparaison grâce à l'opérateur.
                const conditionResult = this.$h.operators[filter.condition](
                  entriValue,
                  filterValues
                )

                // Si c'est le premier filtre ou un "where", remplacez simplement le résultat
                if (filter.whereType === 'where' || index === 0) {
                  resultsMap.set(idx, conditionResult)
                } else if (filter.whereType === 'orWhere' && conditionResult) {
                  // Si c'est un "orWhere" et que la condition est vraie, définissez le résultat à vrai
                  resultsMap.set(idx, true)
                }
              })
            }
          }
        })

        tempEntries = tempEntries.filter((_, idx) => resultsMap.get(idx))
      }

      return tempEntries
    }
  },
  methods: {
    handleUpdateFilters(value) {

      this.filters = value
    }
  }
}
