<template>
  <div class="fm-form-input mt-2">
    <label>{{ label }}</label>
    <div class="flex gap-2 !bg-white p-3">
      <label
        v-for="option in options"
        :key="option.id"
        class="flex cursor-pointer flex-row gap-2 text-xs font-medium text-gray-900"
      >
        <input
          v-model="localModelValue"
          class="shrink-0 ring-0 checked:ring-0 focus:outline-0"
          type="radio"
          :value="option.value"
        />
        <span class="inline flex-1 capitalize-first">
          {{ $t(`enums.yesNo.${option.key}`) }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSimpleBooleanRadioGroup',
  props: {
    name: {
      type: String,
      required: true,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: 'radio'
    },
    modelValue: {
      type: Number,
      required: true
    }
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      value: this.defaultValue,
      temp_name: this.name + this.extra_id,
      options: [
        {
          value: true,
          key: 0
        },
        {
          value: false,
          key: 1
        }
      ]
    }
  },
  computed: {
    localModelValue: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
