export default {
  setContactList(state, contactList) {
    state.contactList = contactList
  },
  setRoleContactList(state, roleContactList) {
    state.roleContactList = roleContactList
  },
  setContact(state, contact) {
    state.contact = contact
  },
  setContactMetadata(state, metadata) {
    state.metadata = metadata
  }
}
