<template>
  <button
    class="flex flex-col rounded border p-2 pt-1.5 text-left"
    :class="[
      selected ? 'border-primary shadow shadow-primary' : 'border-slate-300'
    ]"
  >
    <span class="font-semibold">{{ bankAccount.name }}</span>
    <span class="text-xs">
      {{ $t('attributes.journal_code') }}: {{ bankAccount.journal_code }}
    </span>
    <span class="text-xs">
      {{ $t('attributes.account') }}: {{ bankAccount.accounting_code }}
    </span>
    <span class="flex flex-row gap-2">
      <span
        class="block rounded bg-green-500 px-1 text-center text-xs text-white"
      >
        {{ nbExported }}
      </span>
      <span
        class="block rounded bg-orange-500 px-1 text-center text-xs text-white"
      >
        {{ nbNonExported }}
      </span>
    </span>
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SimpleTooltip from '@ui/SimpleTooltip.vue'

export default {
  name: 'BankAccountButton',
  components: { SimpleTooltip, FontAwesomeIcon },
  props: {
    bankAccount: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    nbExported() {
      return this.bankAccount?.payments.filter(
        (payment) => payment.exported === 1
      )?.length
    },
    nbNonExported() {
      return this.bankAccount?.payments.filter(
        (payment) => payment.exported === 0
      )?.length
    }
  }
}
</script>
