<template>
  <div
    class="flex aspect-[6/2] cursor-pointer flex-row rounded-lg border bg-white py-2 md:flex md:px-2"
    :class="[selected ? 'border-primary-300 ' : 'border-slate-300']"
  >
    <div class="flex flex-1 flex-col">
      <div class="flex flex-1 flex-row justify-between gap-2">
        <div class="flex w-1/6 flex-col items-center justify-center">
          <span
            class="flex items-center justify-center rounded-sm border border-gray-500 p-2 text-gray-500"
          >
            <FontAwesomeIcon :icon="['fal', 'user']" size="lg" />
          </span>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex flex-row">
            <h2 class="flex-1 text-gray-800">
              {{ contactName }}
            </h2>
            <span @click.stop="handleEditContact(contact)">
              <FontAwesomeIcon
                :icon="['fal', 'pen-circle']"
                size="xl"
                class="cursor-pointer text-yellow-500"
              />
            </span>
          </div>

          <div v-if="contact.email" class="flex flex-row">
            <span class="text-xs text-gray-500">{{ contact.email }}</span>
          </div>
          <div v-if="contact.phone_field" class="w-full">
            <FontAwesomeIcon :icon="['fal', 'phone']" size="xs" class="mr-2" />
            <span class="text-xs text-gray-500">{{ contact.phone_field }}</span>
          </div>
          <div v-if="contact.mobile_field" class="w-full">
            <FontAwesomeIcon :icon="['fal', 'phone']" size="xs" class="mr-2" />
            <span class="text-xs text-gray-500">{{
              contact.mobile_field
            }}</span>
          </div>
        </div>
      </div>

      <!--      commenté par aurelie: suppression pour l'instant de la liste des agences -->
      <!--      <FuneralHomeContactBadge :contact="contact" contact-class="w-10/12"/>-->
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SelectContactCard',
  components: { FontAwesomeIcon },
  // components: {FuneralHomeContactBadge},
  props: {
    contact: {
      type: Object,
      required: false,
      default: null
    },
    isWikiContact: {
      type: Boolean,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user'
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['editContact'],
  computed: {
    contactName() {
      if (!this.isWikiContact) {
        if (this.contact?.is_company) {
          return this.contact.company_name
        }

        return this.contact.full_name ?? ''
      }

      return `${this.contact.last_name ?? ''} ${this.contact.first_name ?? ''}`
    }
  },
  methods: {
    handleEditContact(contact) {
      this.$emit('editContact', contact.id)
    }
  }
}
</script>
