import apiClient from '@/utils/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async fetchAllSupplierOrders({ commit }) {
    await apiClient
      .get('supplier-order')
      .then(async (response) => {
        await commit('setInvoiceIndex', response.data.invoices)
        // await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch((reason) => {
        console.error('setErrors fetchAllSupplierOrders', reason)
      })
  },

  async fetchDraftSupplierOrderBySupplierId({ commit }, payload) {
    await apiClient
      .get(`invoicing/draft-supplier-order/${payload.supplier_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setInvoiceIndex', response.data.invoices)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchDraftSupplierOrderBySupplierId', reason)
      })
  },

  async fetchCateringSupplierOrderByDossierId({ commit }, payload) {
    await apiClient
      .get(`catering-order-by-dossier/${payload.dossier_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setInvoiceIndex', response.data.invoices)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchCateringSupplierOrderByDossierId', reason)
      })
  },

  async receiveOrder({ commit }, payload) {
    await apiClient
      .put(
        `supplier-order/receive/${payload.supplier_order_id}`,
        payload.reception
      )
      .then(async (response) => {
        await console.log('retrieveOrder', response)
        if (response.status === 200) {
          processSuccessCallback(payload)
          commit('setInvoiceIndex', response.data.invoices)
        }
      })
      .catch(async (reason) => {
        await console.error('retrieveOrder', reason)
      })
  }
}
