const TheCompanyIndexPage = () =>
  import('@/views/settings/corporation/company/index/TheCompanyIndexPage')
const TheCompanyFormPage = () =>
  import(
    '@/views/settings/corporation/company/form/tabs/companyInformation/TheCompanyFormPage'
  )
const TheCompanyActivityPage = () =>
  import(
    '@/views/settings/corporation/company/form/tabs/companyActivities/TheCompanyActivityPage'
  )
const TheCompanyFormsTabComponent = () =>
  import(
    '@/views/settings/corporation/company/form/TheCompanyFormsTabComponent'
  )
const TheCompanyInvoicingPage = () =>
  import(
    '@/views/settings/corporation/company/form/tabs/companyInvoicing/TheCompanyInvoicingPage'
  )
const TheCompanyGalleryPage = () =>
  import(
    '@/views/settings/corporation/company/form/tabs/companyHeader/TheCompanyHeaderPage.vue'
  )
const TheCompanyFuneralHomePage = () =>
  import(
    '@/views/settings/corporation/company/form/tabs/companyFuneralHome/TheCompanyFuneralHomePage.vue'
  )

export const settingsCompanyRoutes = [
  {
    path: 'company',
    children: [
      {
        path: '',
        name: 'settings_list_company_route',
        component: TheCompanyIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_company_info_route',
        props: true,
        component: TheCompanyFormsTabComponent,
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheCompanyFormPage,
            name: 'settings_edit_company_route'
          },
          {
            path: 'invoicing',
            component: TheCompanyInvoicingPage,
            props: true,
            name: 'settings_edit_company_invoicing_route'
          },
          {
            path: 'gallery',
            component: TheCompanyGalleryPage,
            props: true,
            name: 'settings_edit_company_gallery_route'
          },
          {
            path: 'funeral-home',
            component: TheCompanyFuneralHomePage,
            props: true,
            name: 'settings_edit_company_funeral_home_route'
          },
          {
            path: 'activities',
            component: TheCompanyActivityPage,
            props: true,
            name: 'settings_edit_company_activity_route'
          }
        ]
      },
      {
        path: 'new',
        name: 'settings_create_company_info_route',
        component: TheCompanyFormsTabComponent,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        },
        children: [
          {
            path: '',
            component: TheCompanyFormPage,
            name: 'settings_create_company_route'
          },
          {
            path: '',
            component: TheCompanyInvoicingPage,
            name: 'settings_create_company_invoicing_route'
          },
          {
            path: 'gallery',
            component: TheCompanyGalleryPage,
            props: true,
            name: 'settings_create_company_gallery_route'
          },
          {
            path: '',
            component: TheCompanyFormPage,
            name: 'settings_create_company_activity_route'
          }
        ]
      }
    ]
  }
]
