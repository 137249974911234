import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      materialResourceList: [],
      materialResource: {},
      materialResourceActivity: {},
      materialResourceMetadata: {},
      editionMode: false,
    }
  },
  getters,
  actions,
  mutations
}
