import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      tasksList: [],
      task: {},
      taskActivity: {},
      metadataActivities: [],
      taskMetadata: {},
      editionMode: false,
      tempUsers: {},
      tempExecutorUsers: {}
    }
  },
  getters,
  actions,
  mutations
}
