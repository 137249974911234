<template>
  <div class="flex justify-end">
    <BaseButton
      v-if="editionMode"
      icon="copy"
      :title="$t('global.copy_deceased_address')"
      @click="copyDeceasedAddress"
    />
  </div>
  <div class="grid grid-cols-1 gap-5 py-5 md:grid-cols-2">
    <div class="col-start-1">
      <BaseShowEditInput
        v-model="contact.street"
        :edition-mode="editionMode"
        :label="$t('attributes.road')"
        :errors="errors?.street"
        name="street"
      />
    </div>
    <div v-if="canDisplay">
      <BaseShowEditInput
        v-model="contact.extra_street"
        :edition-mode="editionMode"
        :label="$t('attributes.extra_street')"
        name="extra_street"
        :errors="errors?.extra_street"
      />
    </div>
    <div>
      <SelectCity
        v-model:cityId="contact.city_id"
        v-model:zip="contact.zip"
        v-model:city="contact.city"
        v-model:city-object="contact.city_object"
        :country="contact.country"
        name="city_id"
        :errors="errors?.concernedPerson?.city_id"
        :edition-mode="editionMode"
        allow-tags
      />
    </div>
    <div>
      <SelectCountries
        v-model="contact.country"
        :edition-mode="editionMode"
        :errors="errors.country"
      />
    </div>
    <div>
      <BaseShowEditInput
        v-model="contact.email"
        :edition-mode="editionMode"
        :label="$t('attributes.email')"
        name="email"
        :errors="errors?.email"
      />
    </div>
    <!--    {{errors}}-->
    <div>
      <BaseShowEditPhoneInput
        v-model:countryValue="contact.mobile_country"
        v-model:numberValue="contact.mobile_field"
        :edition-mode="editionMode"
        :errors="errors"
        :label="$t('attributes.mobile')"
        :metadata="metadata?.enums"
        name="mobile"
      />
    </div>
    <div>
      <BaseShowEditPhoneInput
        v-model:countryValue="contact.phone_country"
        v-model:numberValue="contact.phone_field"
        :edition-mode="editionMode"
        :errors="errors"
        :label="$t('attributes.phone')"
        :metadata="metadata?.enums"
        name="phone"
      />
    </div>
    <InputViewerSelector path="global.contact.office_phone_field">
      <BaseShowEditPhoneInput
        v-model:countryValue="contact.office_phone_country"
        v-model:numberValue="contact.office_phone_field"
        :edition-mode="editionMode"
        :errors="errors"
        :label="$t('attributes.office_phone')"
        :metadata="metadata?.enums"
        name="office_phone"
      />
    </InputViewerSelector>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ContactType} from '@/assets/enums'
import SelectCountries from '@c/InitialStateFormComponent/Enums/SelectCountries.vue'
import SelectCity from '@c/SelectAjaxComponent/SelectCity.vue'
import BaseShowEditPhoneInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditPhoneInput.vue'
import InputViewerSelector from '@c/InputViewerSelector.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'

export default {
  name: 'ContactCardAddressComponent',
  components: {
    BaseShowEditInput,
    InputViewerSelector,
    BaseShowEditPhoneInput,
    SelectCity,
    SelectCountries
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      ContactType: ContactType
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata',
      dossier: 'dossier/getDossier'
    }),
    canDisplay() {
      return [
        ContactType.GENERAL_CONTACT,
        ContactType.MARITAL_STATUS,
        ContactType.BASIC,
      ].includes(this.contactType);
    }
  },
  watch: {
    'contact.country'(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (
          !this.contact.phone_field ||
          (this.contact.phone_field && this.contact.phone_field.length === 0)
        ) {
          this.contact.phone_country = newValue
        }
        if (
          !this.contact.mobile_field ||
          (this.contact.mobile_field && this.contact.mobile_field.length === 0)
        ) {
          this.contact.mobile_country = newValue
        }
        if (
          !this.contact.office_phone_field ||
          (this.contact.office_phone_field &&
            this.contact.office_phone_field.length === 0)
        ) {
          this.contact.office_phone_country = newValue
        }
      }
    }
  },
  methods: {
    copyDeceasedAddress() {
      this.contact.extra_street = this.dossier.concernedPerson.extra_street
      this.contact.street = this.dossier.concernedPerson.street
      this.contact.city = this.dossier.concernedPerson.city
      this.contact.city_id = this.dossier.concernedPerson.city_id
      this.contact.country = this.dossier.concernedPerson.country ?? null
      this.contact.zip = this.dossier.concernedPerson.zip
      this.contact.city_object = this.dossier.concernedPerson.city_object
    }
  }
}
</script>
