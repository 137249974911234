<template>
  <template v-if="editionMode">
    <div class="mt-2">
      <BaseEditLabel :label="label" :required="required" />
      <div class="mt-1 flex rounded-md shadow-sm" @click="ShowContactListModal">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <font-awesome-icon
              :icon="['fal', icon]"
              size="lg"
              class="mr-2 text-gray-500"
            />
          </div>
          <input
            :id="name"
            v-model="cModelValue"
            :name="name"
            :placeholder="label"
            class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-blue-300 focus:ring-indigo-500 sm:text-sm"
            :disabled="editionMode"
          />
        </div>
        <button
          type="button"
          class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-300 focus:outline-none"
        >
          <span>{{ $t('global.select') }}</span>
        </button>
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </div>
  </template>
  <template v-else>
    <BaseShowLabel :label="label" :model-value="cModelValue" />
  </template>
</template>
<script>
import { mapGetters } from 'vuex'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditContactInput',
  components: { ErrorContainer },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: 'user-tie'
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Array,
      required: false,
      default: null
    }
  },
  emits: ['change'],
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata'
    }),
    cModelValue() {
      return (
        this.metadata.models.contacts?.find(
          (contact) => contact.id === parseInt(this.modelValue)
        )?.full_name ?? ''
      )
    }
  },
  methods: {
    ShowContactListModal() {
      if (this.editionMode) {
        this.$emit('change', { role: this.role, name: this.name })
      }
    }
  }
}
</script>
