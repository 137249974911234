import initialEmailValue from './initialEmailValue'

export default {
    setHasChanged(state, status) {
        // console.log(status)
        state.hasChanged = status
    },
    setEmail(state, email) {
        state.email = email
    },
    resetEmail(state) {
        state.email = initialEmailValue()
        state.defaultAttachments = []
    },
    addMailAttachment(state, attachment) {
        // console.log(attachment)
        if (!state.email.attachments.find((att) => att.name === attachment.name)) {
            state.email.attachments.push(attachment)
        }
    },
    addPredifinedTextMessageToEmail(state, text) {
        state.email.message = text
    },
    removeEmailAttachmentByIndex(state, index) {
        if (state.email.attachments[index] !== undefined) {
            state.email.attachments.splice(index, 1)
        }
    },
    setTo(state, to) {
        state.email.to.push(to)
    },
    removeDefaultAttachmentByIndex(state, index) {
        if (state.defaultAttachments[index] !== undefined) {
            state.defaultAttachments.splice(index, 1)
            state.email.attachable.splice(index, 1)
        }
    },
    setIsModalOpen(state, status) {
        state.isModalOpen = status
    },
    setAttachable(state, attachable) {
        state.email.attachable = attachable
    },
    addAttachable(state, newAttachable) {
        if (
            !state.email.attachable.find((att) => att.name === newAttachable.name)
        ) {
            state.email.attachable.push(newAttachable)
        }
    },
    joinAttachable(state, newAttachables) {
        state.email.attachable = [
            ...new Set([...state.email.attachable, ...newAttachables])
        ]
    },
    setDefaultAttachments(state, defaultAttachments) {
        state.defaultAttachments = defaultAttachments
    },
    addDefaultAttachment(state, newDefaultAttachment) {
        if (
            !state.defaultAttachments.find(
                (att) => att.name === newDefaultAttachment.name
            )
        ) {
            state.defaultAttachments.push(newDefaultAttachment)
        }
    },
    joinDefaultAttachments(state, newDefaultAttachments) {
        state.defaultAttachments = [
            ...new Set([...state.defaultAttachments, ...newDefaultAttachments])
        ]
    }
}
