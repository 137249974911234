import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      supplierList: [],
      supplier: {},
      supplierActivity: [],
      metadataActivities: [],
      editionMode: false,
      metadata: []
    }
  },
  getters,
  actions,
  mutations
}
