<template>
  <div class="rela w-full max-w-full">
    <!--region mode edition-->
    <template v-if="editionMode">
      <div
        :id="`base-show-edit-radio-walkman-${name}`"
        class="w-full max-w-full"
        :class="
          displaySelect ? 'z-100 -translate-y-[999rem] overflow-hidden' : ''
        "
      >
        <WalkmanButton
          v-model="localModelValue"
          :label="label"
          :options="options"
          :track-by="trackBy"
          :attribute-label="attributeLabel"
          :name="name"
        />
      </div>
      <template v-if="displaySelect">
        <BaseShowEditSelectSimple
          v-model="localModelValue"
          :label="label"
          :name="name"
          :options="options"
          edition-mode
        />
      </template>
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>
    <!--endregion-->

    <!--region mode affichage-->
    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="displayedValueWhenNotInEditionMode"
      />
    </template>
    <!--endregion-->
  </div>
</template>

<script>
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'BaseShowEditRadio',
  components: { WalkmanButton, ErrorContainer, BaseShowLabel },
  props: {
    options: { type: [Array, Object], required: true },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    label: { type: String, required: false },
    name: { type: String, required: true },
    errors: { type: Object, required: false },
    attributeLabel: { type: String, required: false, default: 'name' },
    trackBy: { type: String, required: false, default: 'id' },
    required: { type: Boolean, required: false, default: false },
    extra_id: { required: false, default: '' },
    isVertical: { type: Boolean, required: false, default: false },
    editionMode: {
      type: Boolean,
      required: true
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    numberOfOptionsThreshold: {
      type: Number,
      required: false,
      default: 4
    },
    autoSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      intersectionObserver: null,
      displaySelect: false,
      value: this.defaultValue,
      temp_name: this.name + this.extra_id
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.$emit('change', value)
      }
    },
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cPlacement() {
      return this.isVertical ? '' : 'flex flex-col sm:flex-row mt-2 '
    },
    displayedValueWhenNotInEditionMode() {
      if (this.options) {
        let value = this.options.find(
          (option) => option[this.trackBy] === this.modelValue
        )
        if (value) {
          return value[this.attributeLabel]
        }
        return null
      }
      return null
    }
  },
  beforeUnmount() {
    if (this.autoSelect) {
      this.intersectionObserver.disconnect()
    }
  },
  mounted() {
    if (this.autoSelect) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          console.log(entries[0].target, entries[0].isIntersecting)
          this.displaySelect = !entries[0].isIntersecting
        },
        {
          root: document.getElementById(
            `base-show-edit-radio-walkman-${this.name}`
          ),
          threshold: 1
        }
      )
      setTimeout(() => {
        this.intersectionObserver.observe(
          document.getElementById(`walkman-${this.name}`)
        )
      }, 1000)
    }
  }
}
</script>
