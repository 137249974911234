export default {
  getAccountingDiaryList(state) {
    return state.accountingDiaryList
  },
  getAccountingDiaryMetadata(state) {
    return state.accountingDiaryMetadata
  },
  getAccountingDiary(state) {
    return state.accountingDiary
  }
}
