<template>
  <RadioGroup v-model="selected" :class="groupClass" :disabled="disabled">
    <RadioGroupLabel class="sr-only"> Server size</RadioGroupLabel>
    <div class="space-y-4">
      <RadioGroupOption
        v-for="(option,index) in options"
        :key="option"
        v-slot="{ active, checked }"
        as="template"
        :value="index"
      >
        <div
          :class="[
            active ? 'ring-1 ring-indigo-500 ring-offset-2 ' : '',
            'relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm focus:outline-none hover:border-slate-300 sm:flex sm:justify-between'
          ]"
        >
          <div class="flex content-center w-full">
            <RadioGroupLabel as="div"
                             class="flex flex-1 font-medium content-center justify-between w-full text-gray-900">
              <font-awesome-icon
                v-if="checked"
                :icon="['fal', 'check-circle']"
                size="2x"
                class="text-indigo-500"
              />
              <font-awesome-icon
                v-else
                :icon="['fal', 'circle']"
                size="2x"
                class="text-gray-400"/>
              <span>{{ option }}</span>
            </RadioGroupLabel>
          </div>
          <div
            :class="[
              checked ? 'border-indigo-500' : 'border-transparent',
              'pointer-events-none absolute -inset-px rounded-lg border-2'
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script>
import {ref} from 'vue'
import {RadioGroup, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue'

export default {
  name: 'BaseRadioGroup',
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    groupClass: {
      type: String,
      required: true
    },
    disabled: {type: Boolean, required: false, default: false}
  },
  setup(props) {
    const selected = ref(props.value)
    return {selected}
  },
  watch: {
    selected(newValue) {

      this.$emit('change', {
        value: [newValue],
        attribute: this.name
      })
    }
  }
}
</script>
