/* eslint-disable camelcase */
import apiClient from '@u/apiClient'
// import router from '@/router'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchInvoicingCategoryIndex({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('invoicing-category')
      .then(async (response) => {
        await commit('setInvoicingCategoryList', response.data.invoicing_categories)
        await commit('setMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchInvoicingCategoryIndex', reason)
      })
  },
  async fetchInvoicingCategoryByID({ commit }, id) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`invoicing-category/${id}`)
      .then(async (response) => {
        commit('setInvoicingCategory', response.data.invoicing_category)
      })
      .catch(async (reason) => {
        console.error('fetchInvoicingCategoryByID', reason)
      })
  },
  async fetchNewInvoicingCategory({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=invoicingCategory')
      .then(async (response) => {
        console.log('data', response.data.invoicingCategory)
        commit('setInvoicingCategory', response.data.invoicingCategory)
      })
      .catch(async (reason) => {
        console.error('fetchNewInvoicingCategory', reason)
      })
  },
  // endregion

  // region store
  async storeNewInvoicingCategory({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post('invoicing-category', payload.invoicing_category)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setInvoicingCategory', response.data.invoicing_categories)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('storeNewInvoicingCategory', reason)
      })
  },
  // endregion

  // region update
  async updateInvoicingCategory({ commit }, payload) {
    console.log('action', payload.invoicing_category)
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`invoicing-category/${payload.id}`, payload.invoicing_category)
      .then(async (response) => {
        if (response.status === 201) {
          await commit('setInvoicingCategoryList', response.data.invoicing_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('updateInvoicingCategory', reason)
      })
  },
  // endregion

  // region update
  async updateMultipleInvoicingCategory({ commit }, payload) {
    console.log('action', payload)
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post(`invoicing-category/multiple-invoicing-update`, payload)
      .then(async (response) => {
        if (response.status === 201) {
          await commit('setItemCategoryList', response.data.invoices_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('updateMultipleItemCategory', reason)
      })
  },
  // region delete
  async deleteInvoicingCategoryById({ commit }, payload) {
    await apiClient
      .delete(`invoicing-category/invoicing-category-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setInvoicingCategoryList', response.data.invoicing_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteInvoicingCategoryById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteInvoicingCategoryById({ commit }, payload) {
    await apiClient
      .get(`invoicing-category/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setInvoicingCategoryList', response.data.invoicing_categories)
          await commit('setMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteInvoicingCategoryById', reason)
      })
  },
  // endregion

}
