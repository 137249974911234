<template>
  <div v-if="version" class="flex justify-between ">
    <h1 class="text-xl font-bold block">version {{ version.version }}</h1>
    <BaseButton
        :title="unreadVersion ? $t('button.mark_as_read') : $t('button.mark_as_unread')"
        :icon="unreadVersion ? 'eye' : 'eye-slash'"
        @click="$emit('confirmReadingVersionNote', version)"
    />
  </div>
  <div
      v-for="(version_type, idx) in versionTextSorted" :key="idx"
      class="border px-10 py-2 my-5 rounded-lg box "
      :class="version_type.requester === auth?.tenant?.id ? 'border-theme-primary' : 'border-gray-200'"
  >
    <div class="grid grid-cols-5 gap-x-5 ">
      <div class="col-span-1">
        <span
            v-if="version_type.requester"
            class="inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-sm font-medium text-indigo-800">
          <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3"/>
          </svg>
          {{ version_type.requester }}
        </span>
      </div>
      <div class="col-span-3">
        <div class="font-bold  capitalize-first" :class="!version_type.requester ? 'mt-4' : ''">
          {{ version_type.title }}
        </div>
        <div class="font-medium capitalize-first text-xs ml-8 mt-4">
          {{ version_type.description }}
        </div>
        <div class="mt-3">
          <img v-for="url in version_type.files" :key="url" :src="url" alt="image" class="w-36 ">
        </div>
      </div>


      <div class="col-span-1 text-right">
                <span
                    class="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 font-medium text-white"
                    :class="[`bg-theme-${initialMetadata?.enums?.version_note_type?.find(type => type.id === version_type.type)?.bg_color}`]"
                >
                {{ initialMetadata?.enums?.version_note_type?.find(type => type.id === version_type.type)?.name }}
              </span>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'VersionCardComponent',
  props: {
    version: {
      type: Object,
      required: true
    }
  },
  emits: ['confirmReadingVersionNote'],
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      auth: "auth/getAuth"
    }),
    versionTextSorted() {
      let tenants_version_text = this.version?.text?.filter(text => text.requester === this.auth?.tenant?.id)
      let others_version_text = this.version?.text?.filter(text => text.requester !== this.auth?.tenant?.id)
      return tenants_version_text.concat(others_version_text)
    },
    unreadVersion() {
      return this.initialMetadata?.models?.unreaded_version_notes?.some(version_note => version_note.id === this.version.id)
    }
  }
}
</script>
