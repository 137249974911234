export default Object.freeze({
  TO_BE_SENDED: 0,
  SENT: 1,
  DELIVERED: 2,
  OPEN: 3,
  BOUNCE: 4,
  SPAM: 5,
  REJECT: 6,
  DRAFT: 7,
  TRASH: 8
})
