import { Country } from '@/assets/enums'

import flag_BE from '../../images/flags/be.svg'
import flag_FR from '../../images/flags/fr.svg'
import flag_DE from '../../images/flags/de.svg'
import flag_IT from '../../images/flags/it.svg'
import flag_LU from '../../images/flags/lu.svg'
import flag_NL from '../../images/flags/nl.svg'
import flag_PT from '../../images/flags/pt.svg'
import flag_ES from '../../images/flags/es.svg'
import flag_CH from '../../images/flags/ch.svg'
import flag_UK from '../../images/flags/uk.svg'
import flag_AF from '../../images/flags/af.svg'
import flag_AL from '../../images/flags/al.svg'
import flag_DZ from '../../images/flags/dz.svg'
import flag_AS from '../../images/flags/as.svg'
import flag_AD from '../../images/flags/ad.svg'
import flag_AO from '../../images/flags/ao.svg'
import flag_AI from '../../images/flags/ai.svg'
import flag_AQ from '../../images/flags/aq.svg'
import flag_AG from '../../images/flags/ag.svg'
import flag_AR from '../../images/flags/ar.svg'
import flag_AM from '../../images/flags/am.svg'
import flag_AW from '../../images/flags/aw.svg'
import flag_AU from '../../images/flags/au.svg'
import flag_AT from '../../images/flags/at.svg'
import flag_AZ from '../../images/flags/az.svg'
import flag_BS from '../../images/flags/bs.svg'
import flag_BH from '../../images/flags/bh.svg'
import flag_BD from '../../images/flags/bd.svg'
import flag_BB from '../../images/flags/bb.svg'
import flag_BY from '../../images/flags/by.svg'
import flag_BZ from '../../images/flags/bz.svg'
import flag_BJ from '../../images/flags/bj.svg'
import flag_BM from '../../images/flags/bm.svg'
import flag_BT from '../../images/flags/bt.svg'
import flag_BO from '../../images/flags/bo.svg'
import flag_BA from '../../images/flags/ba.svg'
import flag_BW from '../../images/flags/bw.svg'
import flag_BV from '../../images/flags/bv.svg'
import flag_BR from '../../images/flags/br.svg'
import flag_IO from '../../images/flags/io.svg'
import flag_BN from '../../images/flags/bn.svg'
import flag_BG from '../../images/flags/bg.svg'
import flag_BF from '../../images/flags/bf.svg'
import flag_BI from '../../images/flags/bi.svg'
import flag_KH from '../../images/flags/kh.svg'
import flag_CM from '../../images/flags/cm.svg'
import flag_CA from '../../images/flags/ca.svg'
import flag_CV from '../../images/flags/cv.svg'
import flag_KY from '../../images/flags/ky.svg'
import flag_CF from '../../images/flags/cf.svg'
import flag_TD from '../../images/flags/td.svg'
import flag_CL from '../../images/flags/cl.svg'
import flag_CN from '../../images/flags/cn.svg'
import flag_CX from '../../images/flags/cx.svg'
import flag_CC from '../../images/flags/cc.svg'
import flag_CO from '../../images/flags/co.svg'
import flag_KM from '../../images/flags/km.svg'
import flag_CG from '../../images/flags/cg.svg'
import flag_CD from '../../images/flags/cd.svg'
import flag_CK from '../../images/flags/ck.svg'
import flag_CR from '../../images/flags/cr.svg'
import flag_CI from '../../images/flags/ci.svg'
import flag_HR from '../../images/flags/hr.svg'
import flag_CU from '../../images/flags/cu.svg'
import flag_CY from '../../images/flags/cy.svg'
import flag_CZ from '../../images/flags/cz.svg'
import flag_DK from '../../images/flags/dk.svg'
import flag_DJ from '../../images/flags/dj.svg'
import flag_DM from '../../images/flags/dm.svg'
import flag_DO from '../../images/flags/do.svg'
import flag_EC from '../../images/flags/ec.svg'
import flag_EG from '../../images/flags/eg.svg'
import flag_SV from '../../images/flags/sv.svg'
import flag_GQ from '../../images/flags/gq.svg'
import flag_ER from '../../images/flags/er.svg'
import flag_EE from '../../images/flags/ee.svg'
import flag_ET from '../../images/flags/et.svg'
import flag_FK from '../../images/flags/fk.svg'
import flag_FO from '../../images/flags/fo.svg'
import flag_FJ from '../../images/flags/fj.svg'
import flag_FI from '../../images/flags/fi.svg'
import flag_GF from '../../images/flags/gf.svg'
import flag_PF from '../../images/flags/pf.svg'
import flag_TF from '../../images/flags/tf.svg'
import flag_GA from '../../images/flags/ga.svg'
import flag_GM from '../../images/flags/gm.svg'
import flag_GE from '../../images/flags/ge.svg'
import flag_GH from '../../images/flags/gh.svg'
import flag_GI from '../../images/flags/gi.svg'
import flag_GR from '../../images/flags/gr.svg'
import flag_GL from '../../images/flags/gl.svg'
import flag_GD from '../../images/flags/gd.svg'
import flag_GP from '../../images/flags/gp.svg'
import flag_GU from '../../images/flags/gu.svg'
import flag_GT from '../../images/flags/gt.svg'
import flag_GN from '../../images/flags/gn.svg'
import flag_GW from '../../images/flags/gw.svg'
import flag_GY from '../../images/flags/gy.svg'
import flag_HT from '../../images/flags/ht.svg'
import flag_HM from '../../images/flags/hm.svg'
import flag_VA from '../../images/flags/va.svg'
import flag_HN from '../../images/flags/hn.svg'
import flag_HK from '../../images/flags/hk.svg'
import flag_HU from '../../images/flags/hu.svg'
import flag_IS from '../../images/flags/is.svg'
import flag_IN from '../../images/flags/in.svg'
import flag_ID from '../../images/flags/id.svg'
import flag_IR from '../../images/flags/ir.svg'
import flag_IQ from '../../images/flags/iq.svg'
import flag_IE from '../../images/flags/ie.svg'
import flag_IL from '../../images/flags/il.svg'
import flag_JM from '../../images/flags/jm.svg'
import flag_JP from '../../images/flags/jp.svg'
import flag_JO from '../../images/flags/jo.svg'
import flag_KZ from '../../images/flags/kz.svg'
import flag_KE from '../../images/flags/ke.svg'
import flag_KI from '../../images/flags/ki.svg'
import flag_KP from '../../images/flags/kp.svg'
import flag_KR from '../../images/flags/kr.svg'
import flag_KW from '../../images/flags/kw.svg'
import flag_KG from '../../images/flags/kg.svg'
import flag_LA from '../../images/flags/la.svg'
import flag_LV from '../../images/flags/lv.svg'
import flag_LB from '../../images/flags/lb.svg'
import flag_LS from '../../images/flags/ls.svg'
import flag_LR from '../../images/flags/lr.svg'
import flag_LY from '../../images/flags/ly.svg'
import flag_LI from '../../images/flags/li.svg'
import flag_LT from '../../images/flags/lt.svg'
import flag_MO from '../../images/flags/mo.svg'
import flag_MK from '../../images/flags/mk.svg'
import flag_MG from '../../images/flags/mg.svg'
import flag_MW from '../../images/flags/mw.svg'
import flag_MY from '../../images/flags/my.svg'
import flag_MV from '../../images/flags/mv.svg'
import flag_ML from '../../images/flags/ml.svg'
import flag_MT from '../../images/flags/mt.svg'
import flag_MH from '../../images/flags/mh.svg'
import flag_MQ from '../../images/flags/mq.svg'
import flag_MR from '../../images/flags/mr.svg'
import flag_MU from '../../images/flags/mu.svg'
import flag_YT from '../../images/flags/yt.svg'
import flag_MX from '../../images/flags/mx.svg'
import flag_FM from '../../images/flags/fm.svg'
import flag_MD from '../../images/flags/md.svg'
import flag_MC from '../../images/flags/mc.svg'
import flag_MN from '../../images/flags/mn.svg'
import flag_MS from '../../images/flags/ms.svg'
import flag_MA from '../../images/flags/ma.svg'
import flag_MZ from '../../images/flags/mz.svg'
import flag_MM from '../../images/flags/mm.svg'
import flag_NA from '../../images/flags/na.svg'
import flag_NR from '../../images/flags/nr.svg'
import flag_NP from '../../images/flags/np.svg'
// import flag_AN from '../../images/flags/an.svg'
import flag_NC from '../../images/flags/nc.svg'
import flag_NZ from '../../images/flags/nz.svg'
import flag_NI from '../../images/flags/ni.svg'
import flag_NE from '../../images/flags/ne.svg'
import flag_NG from '../../images/flags/ng.svg'
import flag_NU from '../../images/flags/nu.svg'
import flag_NF from '../../images/flags/nf.svg'
import flag_MP from '../../images/flags/mp.svg'
import flag_NO from '../../images/flags/no.svg'
import flag_OM from '../../images/flags/om.svg'
import flag_PK from '../../images/flags/pk.svg'
import flag_PW from '../../images/flags/pw.svg'
import flag_PS from '../../images/flags/ps.svg'
import flag_PA from '../../images/flags/pa.svg'
import flag_PG from '../../images/flags/pg.svg'
import flag_PY from '../../images/flags/py.svg'
import flag_PE from '../../images/flags/pe.svg'
import flag_PH from '../../images/flags/ph.svg'
import flag_PN from '../../images/flags/pn.svg'
import flag_PL from '../../images/flags/pl.svg'
import flag_PR from '../../images/flags/pr.svg'
import flag_QA from '../../images/flags/qa.svg'
import flag_RE from '../../images/flags/re.svg'
import flag_RO from '../../images/flags/ro.svg'
import flag_RU from '../../images/flags/ru.svg'
import flag_RW from '../../images/flags/rw.svg'
import flag_SH from '../../images/flags/sh.svg'
import flag_KN from '../../images/flags/kn.svg'
import flag_LC from '../../images/flags/lc.svg'
import flag_PM from '../../images/flags/pm.svg'
import flag_VC from '../../images/flags/vc.svg'
import flag_WS from '../../images/flags/ws.svg'
import flag_SM from '../../images/flags/sm.svg'
import flag_ST from '../../images/flags/st.svg'
import flag_SA from '../../images/flags/sa.svg'
import flag_SN from '../../images/flags/sn.svg'
import flag_RS from '../../images/flags/rs.svg'
import flag_ME from '../../images/flags/me.svg'
import flag_SC from '../../images/flags/sc.svg'
import flag_SL from '../../images/flags/sl.svg'
import flag_SG from '../../images/flags/sg.svg'
import flag_SK from '../../images/flags/sk.svg'
import flag_SI from '../../images/flags/si.svg'
import flag_SB from '../../images/flags/sb.svg'
import flag_SO from '../../images/flags/so.svg'
import flag_ZA from '../../images/flags/za.svg'
import flag_GS from '../../images/flags/gs.svg'
import flag_LK from '../../images/flags/lk.svg'
import flag_SD from '../../images/flags/sd.svg'
import flag_SR from '../../images/flags/sr.svg'
import flag_SJ from '../../images/flags/sj.svg'
import flag_SZ from '../../images/flags/sz.svg'
import flag_SE from '../../images/flags/se.svg'
import flag_SY from '../../images/flags/sy.svg'
import flag_TW from '../../images/flags/tw.svg'
import flag_TJ from '../../images/flags/tj.svg'
import flag_TZ from '../../images/flags/tz.svg'
import flag_TH from '../../images/flags/th.svg'
import flag_TL from '../../images/flags/tl.svg'
import flag_TG from '../../images/flags/tg.svg'
import flag_TK from '../../images/flags/tk.svg'
import flag_TO from '../../images/flags/to.svg'
import flag_TT from '../../images/flags/tt.svg'
import flag_TN from '../../images/flags/tn.svg'
import flag_TR from '../../images/flags/tr.svg'
import flag_TM from '../../images/flags/tm.svg'
import flag_TC from '../../images/flags/tc.svg'
import flag_TV from '../../images/flags/tv.svg'
import flag_UG from '../../images/flags/ug.svg'
import flag_UA from '../../images/flags/ua.svg'
import flag_AE from '../../images/flags/ae.svg'
import flag_GB from '../../images/flags/gb.svg'
import flag_US from '../../images/flags/us.svg'
import flag_UM from '../../images/flags/um.svg'
import flag_UY from '../../images/flags/uy.svg'
import flag_UZ from '../../images/flags/uz.svg'
import flag_VU from '../../images/flags/vu.svg'
import flag_VE from '../../images/flags/ve.svg'
import flag_VN from '../../images/flags/vn.svg'
import flag_VG from '../../images/flags/vg.svg'
import flag_VI from '../../images/flags/vi.svg'
import flag_WF from '../../images/flags/wf.svg'
import flag_EH from '../../images/flags/eh.svg'
import flag_YE from '../../images/flags/ye.svg'
import flag_ZM from '../../images/flags/zm.svg'
import flag_ZW from '../../images/flags/zw.svg'

const CountryFlags = Object.freeze({
  [Country.BELGIUM]: flag_BE,
  [Country.FRANCE]: flag_FR,
  [Country.GERMANY]: flag_DE,
  [Country.ITALY]: flag_IT,
  [Country.LUXEMBOURG]: flag_LU,
  [Country.NETHERLANDS]: flag_NL,
  [Country.PORTUGAL]: flag_PT,
  [Country.SPAIN]: flag_ES,
  [Country.SWITZERLAND]: flag_CH,
  [Country.UNITED_KINGDOM]: flag_UK,
  [Country.AFGHANISTAN]: flag_AF,
  [Country.ALBANIA]: flag_AL,
  [Country.ALGERIA]: flag_DZ,
  [Country.AMERICAN_SAMOA]: flag_AS,
  [Country.ANDORRA]: flag_AD,
  [Country.ANGOLA]: flag_AO,
  [Country.ANGUILLA]: flag_AI,
  [Country.ANTARCTICA]: flag_AQ,
  [Country.ANTIGUA_AND_BARBUDA]: flag_AG,
  [Country.ARGENTINA]: flag_AR,
  [Country.ARMENIA]: flag_AM,
  [Country.ARUBA]: flag_AW,
  [Country.AUSTRALIA]: flag_AU,
  [Country.AUSTRIA]: flag_AT,
  [Country.AZERBAIJAN]: flag_AZ,
  [Country.BAHAMAS]: flag_BS,
  [Country.BAHRAIN]: flag_BH,
  [Country.BANGLADESH]: flag_BD,
  [Country.BARBADOS]: flag_BB,
  [Country.BELARUS]: flag_BY,
  [Country.BELIZE]: flag_BZ,
  [Country.BENIN]: flag_BJ,
  [Country.BERMUDA]: flag_BM,
  [Country.BHUTAN]: flag_BT,
  [Country.BOLIVIA]: flag_BO,
  [Country.BOSNIA_AND_HERZEGOVINA]: flag_BA,
  [Country.BOTSWANA]: flag_BW,
  [Country.BOUVET_ISLAND]: flag_BV,
  [Country.BRAZIL]: flag_BR,
  [Country.BRITISH_INDIAN_OCEAN_TERRITORY]: flag_IO,
  [Country.BRUNEI_DARUSSALAM]: flag_BN,
  [Country.BULGARIA]: flag_BG,
  [Country.BURKINA_FASO]: flag_BF,
  [Country.BURUNDI]: flag_BI,
  [Country.CAMBODIA]: flag_KH,
  [Country.CAMEROON]: flag_CM,
  [Country.CANADA]: flag_CA,
  [Country.CAPE_VERDE]: flag_CV,
  [Country.CAYMAN_ISLANDS]: flag_KY,
  [Country.CENTRAL_AFRICAN_REPUBLIC]: flag_CF,
  [Country.CHAD]: flag_TD,
  [Country.CHILE]: flag_CL,
  [Country.CHINA]: flag_CN,
  [Country.CHRISTMAS_ISLAND]: flag_CX,
  [Country.COCOS_KEELING_ISLANDS]: flag_CC,
  [Country.COLOMBIA]: flag_CO,
  [Country.COMOROS]: flag_KM,
  [Country.CONGO]: flag_CG,
  [Country.CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE]: flag_CD,
  [Country.COOK_ISLANDS]: flag_CK,
  [Country.COSTA_RICA]: flag_CR,
  [Country.COTE_D_IVOIRE]: flag_CI,
  [Country.CROATIA]: flag_HR,
  [Country.CUBA]: flag_CU,
  [Country.CYPRUS]: flag_CY,
  [Country.CZECH_REPUBLIC]: flag_CZ,
  [Country.DENMARK]: flag_DK,
  [Country.DJIBOUTI]: flag_DJ,
  [Country.DOMINICA]: flag_DM,
  [Country.DOMINICAN_REPUBLIC]: flag_DO,
  [Country.ECUADOR]: flag_EC,
  [Country.EGYPT]: flag_EG,
  [Country.EL_SALVADOR]: flag_SV,
  [Country.EQUATORIAL_GUINEA]: flag_GQ,
  [Country.ERITREA]: flag_ER,
  [Country.ESTONIA]: flag_EE,
  [Country.ETHIOPIA]: flag_ET,
  [Country.FALKLAND_ISLANDS_MALVINAS]: flag_FK,
  [Country.FAROE_ISLANDS]: flag_FO,
  [Country.FIJI]: flag_FJ,
  [Country.FINLAND]: flag_FI,
  [Country.FRENCH_GUIANA]: flag_GF,
  [Country.FRENCH_POLYNESIA]: flag_PF,
  [Country.FRENCH_SOUTHERN_TERRITORIES]: flag_TF,
  [Country.GABON]: flag_GA,
  [Country.GAMBIA]: flag_GM,
  [Country.GEORGIA]: flag_GE,
  [Country.GHANA]: flag_GH,
  [Country.GIBRALTAR]: flag_GI,
  [Country.GREECE]: flag_GR,
  [Country.GREENLAND]: flag_GL,
  [Country.GRENADA]: flag_GD,
  [Country.GUADELOUPE]: flag_GP,
  [Country.GUAM]: flag_GU,
  [Country.GUATEMALA]: flag_GT,
  [Country.GUINEA]: flag_GN,
  [Country.GUINEA_BISSAU]: flag_GW,
  [Country.GUYANA]: flag_GY,
  [Country.HAITI]: flag_HT,
  [Country.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: flag_HM,
  [Country.VATICAN_CITY_STATE]: flag_VA,
  [Country.HONDURAS]: flag_HN,
  [Country.HONG_KONG]: flag_HK,
  [Country.HUNGARY]: flag_HU,
  [Country.ICELAND]: flag_IS,
  [Country.INDIA]: flag_IN,
  [Country.INDONESIA]: flag_ID,
  [Country.IRAN_ISLAMIC_REPUBLIC_OF]: flag_IR,
  [Country.IRAQ]: flag_IQ,
  [Country.IRELAND]: flag_IE,
  [Country.ISRAEL]: flag_IL,
  [Country.JAMAICA]: flag_JM,
  [Country.JAPAN]: flag_JP,
  [Country.JORDAN]: flag_JO,
  [Country.KAZAKHSTAN]: flag_KZ,
  [Country.KENYA]: flag_KE,
  [Country.KIRIBATI]: flag_KI,
  [Country.KOREA_DEMOCRATIC_PEOPLE_S_REPUBLIC_OF]: flag_KP,
  [Country.KOREA_REPUBLIC_OF]: flag_KR,
  [Country.KUWAIT]: flag_KW,
  [Country.KYRGYZSTAN]: flag_KG,
  [Country.LAO_PEOPLE_DEMOCRATIC_REPUBLIC]: flag_LA,
  [Country.LATVIA]: flag_LV,
  [Country.LEBANON]: flag_LB,
  [Country.LESOTHO]: flag_LS,
  [Country.LIBERIA]: flag_LR,
  [Country.LIBYAN_ARAB_JAMAHIRIYA]: flag_LY,
  [Country.LIECHTENSTEIN]: flag_LI,
  [Country.LITHUANIA]: flag_LT,
  [Country.MACAO]: flag_MO,
  [Country.MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF]: flag_MK,
  [Country.MADAGASCAR]: flag_MG,
  [Country.MALAWI]: flag_MW,
  [Country.MALAYSIA]: flag_MY,
  [Country.MALDIVES]: flag_MV,
  [Country.MALI]: flag_ML,
  [Country.MALTA]: flag_MT,
  [Country.MARSHALL_ISLANDS]: flag_MH,
  [Country.MARTINIQUE]: flag_MQ,
  [Country.MAURITANIA]: flag_MR,
  [Country.MAURITIUS]: flag_MU,
  [Country.MAYOTTE]: flag_YT,
  [Country.MEXICO]: flag_MX,
  [Country.MICRONESIA_FEDERATED_STATES_OF]: flag_FM,
  [Country.MOLDOVA_REPUBLIC_OF]: flag_MD,
  [Country.MONACO]: flag_MC,
  [Country.MONGOLIA]: flag_MN,
  [Country.MONTSERRAT]: flag_MS,
  [Country.MOROCCO]: flag_MA,
  [Country.MOZAMBIQUE]: flag_MZ,
  [Country.MYANMAR]: flag_MM,
  [Country.NAMIBIA]: flag_NA,
  [Country.NAURU]: flag_NR,
  [Country.NEPAL]: flag_NP,
  // [Country.NETHERLANDS_ANTILLES]: flag_AN,
  [Country.NEW_CALEDONIA]: flag_NC,
  [Country.NEW_ZEALAND]: flag_NZ,
  [Country.NICARAGUA]: flag_NI,
  [Country.NIGER]: flag_NE,
  [Country.NIGERIA]: flag_NG,
  [Country.NIUE]: flag_NU,
  [Country.NORFOLK_ISLAND]: flag_NF,
  [Country.NORTHERN_MARIANA_ISLANDS]: flag_MP,
  [Country.NORWAY]: flag_NO,
  [Country.OMAN]: flag_OM,
  [Country.PAKISTAN]: flag_PK,
  [Country.PALAU]: flag_PW,
  [Country.PALESTINIAN_TERRITORY_OCCUPIED]: flag_PS,
  [Country.PANAMA]: flag_PA,
  [Country.PAPUA_NEW_GUINEA]: flag_PG,
  [Country.PARAGUAY]: flag_PY,
  [Country.PERU]: flag_PE,
  [Country.PHILIPPINES]: flag_PH,
  [Country.PITCAIRN]: flag_PN,
  [Country.POLAND]: flag_PL,
  [Country.PUERTO_RICO]: flag_PR,
  [Country.QATAR]: flag_QA,
  [Country.REUNION]: flag_RE,
  [Country.ROMANIA]: flag_RO,
  [Country.RUSSIAN_FEDERATION]: flag_RU,
  [Country.RWANDA]: flag_RW,
  [Country.SAINT_HELENA]: flag_SH,
  [Country.SAINT_KITTS_AND_NEVIS]: flag_KN,
  [Country.SAINT_LUCIA]: flag_LC,
  [Country.SAINT_PIERRE_AND_MIQUELON]: flag_PM,
  [Country.SAINT_VINCENT_AND_THE_GRENADINES]: flag_VC,
  [Country.SAMOA]: flag_WS,
  [Country.SAN_MARINO]: flag_SM,
  [Country.SAO_TOME_AND_PRINCIPE]: flag_ST,
  [Country.SAUDI_ARABIA]: flag_SA,
  [Country.SENEGAL]: flag_SN,
  [Country.SERBIA]: flag_RS,
  [Country.MONTENEGRO]: flag_ME,
  [Country.SEYCHELLES]: flag_SC,
  [Country.SIERRA_LEONE]: flag_SL,
  [Country.SINGAPORE]: flag_SG,
  [Country.SLOVAKIA]: flag_SK,
  [Country.SLOVENIA]: flag_SI,
  [Country.SOLOMON_ISLANDS]: flag_SB,
  [Country.SOMALIA]: flag_SO,
  [Country.SOUTH_AFRICA]: flag_ZA,
  [Country.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: flag_GS,
  [Country.SRI_LANKA]: flag_LK,
  [Country.SUDAN]: flag_SD,
  [Country.SURINAME]: flag_SR,
  [Country.SVALBARD_AND_JAN_MAYEN]: flag_SJ,
  [Country.SWAZILAND]: flag_SZ,
  [Country.SWEDEN]: flag_SE,
  [Country.SYRIAN_ARAB_REPUBLIC]: flag_SY,
  [Country.TAIWAN_PROVINCE_OF_CHINA]: flag_TW,
  [Country.TAJIKISTAN]: flag_TJ,
  [Country.TANZANIA_UNITED_REPUBLIC_OF]: flag_TZ,
  [Country.THAILAND]: flag_TH,
  [Country.TIMOR_LESTE]: flag_TL,
  [Country.TOGO]: flag_TG,
  [Country.TOKELAU]: flag_TK,
  [Country.TONGA]: flag_TO,
  [Country.TRINIDAD_AND_TOBAGO]: flag_TT,
  [Country.TUNISIA]: flag_TN,
  [Country.TURKEY]: flag_TR,
  [Country.TURKMENISTAN]: flag_TM,
  [Country.TURKS_AND_CAICOS_ISLANDS]: flag_TC,
  [Country.TUVALU]: flag_TV,
  [Country.UGANDA]: flag_UG,
  [Country.UKRAINE]: flag_UA,
  [Country.UNITED_ARAB_EMIRATES]: flag_AE,
  [Country.U_K]: flag_GB,
  [Country.UNITED_STATES]: flag_US,
  [Country.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: flag_UM,
  [Country.URUGUAY]: flag_UY,
  [Country.UZBEKISTAN]: flag_UZ,
  [Country.VANUATU]: flag_VU,
  [Country.VENEZUELA]: flag_VE,
  [Country.VIET_NAM]: flag_VN,
  [Country.VIRGIN_ISLANDS_BRITISH]: flag_VG,
  [Country.VIRGIN_ISLANDS_U_S]: flag_VI,
  [Country.WALLIS_AND_FUTUNA]: flag_WF,
  [Country.WESTERN_SAHARA]: flag_EH,
  [Country.YEMEN]: flag_YE,
  [Country.ZAMBIA]: flag_ZM,
  [Country.ZIMBABWE]: flag_ZW
})

export default CountryFlags
