import apiClient from '@u/apiClient'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch

  async fetchTasksList({ commit }) {
    await apiClient
      .get('task')
      .then(async (response) => {
        // commit('setAddressList', response.data.addresses)
        // commit('setAddressMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchTasksList', reason)
      })
  },
  async fetchTaskDossierList({ commit }, payload) {
    await apiClient
      .get(`/task-dossier?with-task-done=${payload}`)
      .then(async (response) => {
        commit('setTaskDossierList', response.data.task_dossiers)
      })
      .catch(async (reason) => {
        console.error('fetchTasksList', reason)
      })
  },

  fetchNewTaskDossier: ({ commit }, payload) => {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=TaskDossier', {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchTaskDossierById: ({ commit }, payload) => {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/task-dossier/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storeTaskDossier: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`task-dossier`, payload.task_dossier)
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  async updateTaskDashboard({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    console.log('payload.id', payload.id)
    await apiClient
      .put(`tasks-dossier-dashboard/${payload.id}`, payload.task)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          commit('setTaskDossier', response.data.task)
          commit('dossier/setDossierInList', response.data.dossier, {
            root: true
          })
        }
      })
      .catch((reason) => {
        console.error('updateTask', reason)
      })
  }
  // endregion
}
