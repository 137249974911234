<template>
  <div class="loading-container cursor-progress flex h-full max-h-full items-center justify-center text-gray-600">
    <div class="flex flex-row items-center gap-5 rounded bg-white/75 p-3">
      <div class="relative h-16 w-16">
        <span class="spinner-rotate absolute flex h-16 w-16 items-center justify-center">
          <FontAwesomeIcon
            :icon="['fal', 'spinner-third']"
            fixed-width
            spin
            size="4x"
          />
        </span>
        <div class="absolute flex h-16 w-16 items-center justify-center">
          <svg
            viewBox="0 0 193.1 193.2"
            style="fill: currentColor"
            class="h-8 w-8"
            xml:space="preserve"
          >
            <path
              d="M192.2,4.1L192.2,4.1L192.2,4.1c0-0.2,0-0.2,0-0.2l0,0l0,0l0,0l0,0l0,0c0-1-0.4-1.9-1.2-2.7l0,0
	c-0.2-0.2-0.4-0.4-0.6-0.4c0,0-0.2,0-0.2-0.2c-0.2,0-0.4-0.2-0.4-0.2c-0.2,0-0.2,0-0.4-0.2c-0.2,0-0.2,0-0.4-0.2c-0.2,0-0.6,0-0.8,0
	c-0.8,0-1.6,0.2-2.1,0.6L186,0.8l-1,0.6L96.2,60.7L6.2,1l0,0C6,1,6,0.8,5.8,0.8c0,0-0.2,0-0.2-0.2c-0.2,0-0.2-0.2-0.4-0.2
	C5,0.4,5,0.4,4.8,0.2H4.7c-0.2,0-0.4,0-0.8,0C1.7,0.2,0.2,1.9,0,3.9c0,0,0,0,0,0.2c0,0,0,0,0,0.2l0.4,171.9l0,0l0,0
	c0,1.4,0.6,2.5,1.7,3.1l0,0l16.3,11.6l0,0c0,0,0.2,0.2,0.4,0.2l0.2,0.2c0,0,0.2,0,0.4,0.2c0.2,0,0.2,0,0.4,0.2H20c0.2,0,0.4,0,0.8,0
	c2.1,0,3.9-1.7,3.9-3.9l0,0l0,0l-0.2-67.7l34.1,22.1l0.2,0.2c0.6,0.4,1.4,0.6,1.9,0.6c2.1,0,3.7-1.6,3.9-3.7v-0.2v-0.2v-25.2v-0.2
	v-0.2c0-1.2-0.8-2.3-1.6-3.1l0,0L24.2,84.8l0,0V52.4l69.8,45.4l0,0c0.6,0.4,1.4,0.6,2.1,0.6c0.8,0,1.4-0.2,1.9-0.6l0,0l0.2-0.2
	c0,0,0,0,0.2,0l69.8-45.4L168,178.7l0,0v8.9l0,0l0,0c0,2.1,1.7,3.9,3.9,3.9c0.2,0,0.4,0,0.8,0h0.2c0.2,0,0.2,0,0.4-0.2
	c0.2,0,0.2,0,0.4-0.2c0.2,0,0.2-0.2,0.2-0.2c0.2,0,0.2-0.2,0.4-0.2l0,0l16.3-11.6l0,0c1-0.8,1.7-1.7,1.7-3.1l0,0l0,0L192.2,4.1z
	 M7,1.6L7,1.6L7,1.6 M7.8,3.1V2.9C7.8,2.9,7.8,3.1,7.8,3.1 M7.4,2.3C7.4,2.3,7.4,2.1,7.4,2.3C7.4,2.1,7.4,2.3,7.4,2.3 M17.7,190.3
	C17.7,190.3,17.8,190.3,17.7,190.3C17.8,190.3,17.7,190.3,17.7,190.3 M16.9,188.7v0.2C16.9,188.7,16.9,188.7,16.9,188.7 M17.3,189.5
	c0,0,0,0.2,0.2,0.2C17.3,189.7,17.3,189.5,17.3,189.5 M188.4,7.8c0.2,0,0.6,0,0.8,0C188.9,7.8,188.6,7.8,188.4,7.8 M189.5,7.8
	c0.2,0,0.2,0,0.4-0.2C189.7,7.6,189.7,7.6,189.5,7.8 M190.3,7.4C190.5,7.2,190.5,7.2,190.3,7.4C190.5,7.2,190.5,7.2,190.3,7.4
	 M191.1,6.8L191.1,6.8L191.1,6.8"
            />
          </svg>
        </div>
      </div>
      <span v-if="displayLabel" class="text-3xl font-bold">
        {{ cLabel }}
      </span>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'LoadingComponent',
  components: { FontAwesomeIcon },
  props: {
    displayLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    cLabel() {
      return this.label ?? this.$t('global.loading')
    }
  }
}
</script>

<style>
.loading-container {
  user-select: none;
}

.spinner-rotate {
  animation: rotate infinite 2s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
