<template >
  <div v-if="editionMode" class="z-10">
    <BaseEditLabel :label="label" :required="required" />
    <multiselect
      :options="options"
      :placeholder="placeholder"
      :modelValue="cDefautlValue"
      @update:modelValue="updateValueAction"
      :searchable="searchable"
      :label="attributeLabel"
      :track-by="trackBy"
      :disabled="disabled"
      :required="required"
      :showPointer="showPointer"
      :hideSelected="hideSelected"
    >
      <template v-if="cDefautlValue" v-slot:singleLabel="props">
        <div class="option__desc">
          <div class="flex gap-x-3 items-center">
            <div class="h-8 w-8 flex-shrink-0 mr-2">
              <img :src="props.option.logo_url" alt="funeral home logo" />
            </div >
            <div >
              <div class="option__title">
                {{ props.option.name }}
              </div >
              <!--              <div v-if="props.option.email" class="option__desc mt-1 text-sm font-light">-->
              <!--                {{ props.option.email }}-->
              <!--              </div >-->
            </div >
          </div >
        </div >
      </template >

      <template v-slot:option="{ option }">
        <div class="option__desc">
          <div class="flex gap-x-3 align-baseline">
            <div class="h-8 w-8 flex-shrink-0 mr-2">
              <img :src="option.logo_url" alt="funeral home logo" />
            </div >
            <div >
              <div class="option__title">
                {{ option.name }}
              </div >
              <!--              <div v-if="option.email" class="option__desc mt-1 text-sm font-light">-->
              <!--                {{ option.email }}-->
              <!--              </div >-->
            </div >
          </div >
        </div >
      </template >

      <template v-slot:noOptions><span>{{ $t('global.is_empty_list') }}</span></template>
    </multiselect >
    <div v-if="Object.keys(errors).length" class="form-help text-red-600">
      <div v-for="(error, index) in errors" :key="index">
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div >
    </div >
  </div >

  <div v-else class="">
    <BaseShowLabel
      :label="label"
      :model-value="cDefautlValue ? cDefautlValue[attributeLabel] : null"
    />
  </div >
</template >

<script >
import multiselect from 'vue-multiselect'
import BaseEditLabel from '../../BaseLabel/BaseEditLabel.vue'
import BaseShowLabel from '../../BaseLabel/BaseShowLabel.vue'
import { mapGetters } from "vuex";

export default {
  name: 'BaseFormattedFuneralHomeShowEditSelectSimple',
  components: {
    multiselect,
    BaseEditLabel,
    BaseShowLabel
  },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: '',
      require: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    selectClass: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    showPointer: {
      type: Boolean,
      required: false,
      default: true
    },
    hideSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    canTrackByBeNull: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    ...mapGetters({ initialMetadata: 'metadata/getInitialMetadata' }),
    options() {
      return this.initialMetadata?.models.funeralHomes
    },
    cDefautlValue() {
      if (!this.options || !this.modelValue) {
        return null
      }
      return this.options.find(option => option[this.trackBy] === this.modelValue)
    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue) {
        this.$emit('update:modelValue', newValue[this.trackBy])
        this.$emit('change', newValue[this.trackBy])
      }
      else {
        if (this.canTrackByBeNull) {
          this.$emit('update:modelValue', null)
          this.$emit('change', null)
        }
      }
    }
  }
}

</script >
<style >
.multiselect__tags {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 224, var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
  var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.375rem;
  border-width: 1px;
}

.multiselect__input {
  box-shadow: transparent;
  border: 0;
}
</style >
