import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      packList: [],
      pack: {},
      packActivity: [],
      metadataActivities: [],
      metadata: {},
      editionMode: false
    }
  },
  getters,
  actions,
  mutations
}
