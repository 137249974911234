<template>
  <div
    class="relative mt-2 rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
  >
    <label
      :for="name"
      class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
    >
      {{ label }}&nbsp;<span v-if="required" class="text-red-600">*</span>
    </label>

    <textarea
      :id="name"
      ref="ta"
      v-model.trim="newValue"
      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
      :placeholder="placeholder"
      :name="name"
      rows="4"
      :disabled="disabled"
    />

    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextArea',
  props: {
    name: { type: String, required: true },
    value: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    errors: { type: Object, required: false }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  watch: {
    newValue(newValue) {
      this.$emit('change', {
        value: newValue,
        attribute: this.name
      })
    }
  },
  methods: {
    setTextAtPosition(text) {
      this.$refs.ta.value =
        this.$refs.ta.value.slice(0, this.$refs.ta.selectionStart) +
        text +
        this.$refs.ta.value.slice(this.$refs.ta.selectionStart)
    }
  }
}
</script>
