export default {
  // namespaced: true,
  // state() {
  //   return {
  //     listItemsIndex: [],
  //     listItem: {},
  //     listItemMetadata: {}
  //   }
  // },
  // getters,
  // actions,
  // mutations
}
