<template>
  <div class="flex flex-1 flex-col gap-2">
    <div class="shrink-0 px-5 text-xl font-bold capitalize">
      {{ $t('attributes.' + path) }}
    </div>
    <div class="flex min-h-0 flex-1 flex-row">
      <div class="flex flex-1 flex-row divide-x">
        <div class="flex w-72 flex-col">
          <div class="min-h-0 flex-1 overflow-y-auto px-2">
            <ThreadPreviewComponent
              v-for="thread in threadList"
              :key="thread.id"
              :thread="thread"
            />
          </div>
        </div>
        <div class="flex min-w-0 flex-1 flex-col px-1.5">
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import ThreadPreviewComponent from '@/views/modelNotification/index/components/ThreadPreviewComponent.vue'

export default defineComponent({
  name: 'ThreadListComponent',
  components: { ThreadPreviewComponent },
  props: {
    path: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      threadList: []
    }
  },
  watch: {
    path(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchPath(newValue)
      }
    }
  },
  mounted() {
    this.fetchPath(this.path)
  },
  methods: {
    ...mapActions({
      processFetchMessagesListAction: 'modelNotification/fetchMessagesList'
    }),
    fetchPath(path) {
      this.loading = true
      this.threadList = []
      this.processFetchMessagesListAction({ path }).then((response) => {
        this.threadList = response.data.model_notifications
        this.loading = false
      })
    }
  }
})
</script>
