<template>
  <div class="">
    <a
      href=""
      class="flex gap-2 items-center rounded-md p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-slate-700"
      @click.prevent="switchMode"
    >
      <FontAwesomeIcon
        v-if="!darkModeIconSwitcher"
        :icon="['fal', 'moon']"
        fixed-width
      />
      <FontAwesomeIcon v-else fixed-width :icon="['fal', 'sun']" />
      DarkMode
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import cash from 'cash-dom/dist/cash'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'TheDarkModeSwitcher2',
  components: {FontAwesomeIcon},
  computed: {
    ...mapGetters('darkMode', ['darkMode']),

    darkModeIconSwitcher() {
      return this.darkMode
    }
  },
  methods: {
    ...mapActions('darkMode', ['setDarkMode']),
    setDarkModeClass() {
      this.darkMode
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    },
    // setDarkModeClass() {
    //   this.darkMode
    //     ? cash('html').addClass('dark')
    //     : cash('html').removeClass('dark')
    // },
    switchMode() {
      this.setDarkMode(!this.darkMode)
      this.setDarkModeClass()
    }
  },
  mounted() {
    this.setDarkModeClass()
  }
}
</script>
