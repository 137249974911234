<template>
  <div
    class="mb-8 inline-flex w-full justify-between text-lg font-medium text-gray-600"
  >
    <span class="capitalize-first">{{ title }}</span>
  </div>

  <!--#region IF NO ADDRESSES -->
  <template v-if="dossierDoesNotHaveAddresses">
    <div class="mx-auto text-center text-slate-300">
      {{ $t('validation.there_is_no_addresses_for_this_dossier_yet') }}
    </div>
  </template>
  <!--#endregion -->

  <template v-else>
    <div
      v-for="(address, index) in dossierMetadata.addresses"
      :key="index"
      class="mb-3"
      @click="handleAddressSelected(address)"
    >
      <label
        class="relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm sm:flex sm:justify-between"
      >
        <span class="text-left">
          <span class="text-left font-medium capitalize text-gray-900"
            >{{ address.label }}
          </span>
          <span class="flex text-sm">
            <span v-if="address[0]" class="text-gray-500">
              <span
                v-if="address[0]['type'] !== 'DOM'"
                class="block capitalize-first sm:inline"
              >
                {{ addressName(address[0]) }}
              </span>
              <span class="block sm:inline">{{ streetName(address[0]) }} </span>
            </span>
            <span v-else class="text-gray-500">
              <span
                v-if="address.type !== 'DOM'"
                class="block capitalize-first sm:inline"
              >
                {{ addressName(address) }}
              </span>
              <span class="block sm:inline" v-html="streetName(address)" />
            </span>
          </span>
        </span>
        <span
          class="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-left"
        >
          <BaseButton :title="$t('button.select')" color="dark" />
        </span>
        <span
          class="pointer-events-none absolute -inset-px rounded-lg border-2"
          aria-hidden="true"
        ></span>
      </label>
    </div>
  </template>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DropdownPlaceDossierModalComponent',
  props: {
    title: {
      type: String,
      required: true
    },
    keyword: {
      type: String,
      required: true
    }
  },
  emits: ['getAddress'],
  data() {
    return {
      funeral_home: null
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'dossier/getDossier',
      dossierMetadata: 'dossier/getDossierMetadata',
      metadata: 'metadata/getInitialMetadata',
      auth: 'auth/getAuth'
    }),
    dossierDoesNotHaveAddresses() {
      !this.dossierMetadata.addresses ||
        this.dossierMetadata.addresses.length === 0
    },
    addressName() {
      return (address) => {
        if (!!address.name && address.name.constructor === Object) {
          if (this.auth.language in address.name) {
            return address.name[this.auth.language]
          }
        }
        return address.name ?? ''
      }
    },
    streetName() {
      return (place) => {
        if ('address' in place) {
          place = place.address
        }

        const street = place.street ?? ''
        const zip = place.zip ?? ''
        const city = place.city ?? ''

        return `<br/>${street} <br/> ${zip} ${city}`
      }
    }
  },
  mounted() {
    console.log('mouted')
    console.log(this.dossierMetadata)
  },
  methods: {
    handleAddressSelected(address) {
      console.log('address handleAddressSelected', address)
      let address_formatted = {}
      if (address[0]) {
        address_formatted = {
          id: address[0].id,
          type: address[0].type,
          label: address[0].name
        }
      } else {
        address_formatted = {
          id: address.id,
          type: address.type,
          label: address.name
        }
      }

      this.$emit('getAddress', address_formatted)
    }
  }
}
</script>
