<template>
  <div class="modal-body">
    <div class="modal-body">
      <div class="my-5 z-30 box drop-shadow-lg px-7 py-6 text-center">
        <DossierPlaceModalComponent
            :title="$t('global.dossier_place')"
            keyword="dossier"
            @get-address="handlePickGetAddress"
        />
      </div>
      <div class="mt-5 mb-10 z-20 box drop-shadow-lg px-7 py-6 text-center">
        <DropdownCompanyPlaceModalComponent
            :title="$t('global.company_place')"
            keyword="company"
            @get-address="handlePickGetAddress"
        />
      </div>
      <OtherPlaceModalComponent
          @get-address="handlePickGetAddress"
      />

    </div>
  </div>
</template>

<script>
import OtherPlaceModalComponent from "@ui/modal/place/components/OtherPlaceModalComponent";
import DropdownCompanyPlaceModalComponent from "@ui/modal/place/components/CompanyPlaceModalComponent";
import DossierPlaceModalComponent from "@ui/modal/place/components/DossierPlaceModalComponent";

export default {
  name: "BasePlaceModalContent",
  components: {DropdownCompanyPlaceModalComponent, OtherPlaceModalComponent, DossierPlaceModalComponent},
  props: {
    placeType: {
      type: String,
      required: false,
      default: null
    },
    placeId: {
      type: [String, Number],
      required: false,
      default: null
    },
    placeLabel: {
      type: String,
      required: false,
      default: null
    },
    placeOther: {
      type: String,
      required: false,
      default: null
    },
  },
  emits: ['getAddress', 'update:placeType', 'update:placeId', 'update:placeLabel', 'update:placeOther'],
  methods: {
    handlePickGetAddress(payload) {
      console.log("payload", payload)
      this.$emit('getAddress', payload)
    }
  },
}
</script>
