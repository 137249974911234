<template>
  <div class="flex items-center justify-between bg-white">
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Previous</a
      >
      <a
        href="#"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Next</a
      >
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm capitalize text-gray-700">
          {{ $t('global.button.dashboard.agenda.view') }}
          <span class="font-medium">{{ entryFrom }}</span>
          {{ $t('global.to') }}
          <span class="font-medium">{{ entryTo }}</span>
          {{ $t('global.of_pagination') }}
          <span class="font-medium">{{ entriesLength }}</span>
          {{ $t('global.elements') }}
        </p>
      </div>
      <div>
        <nav
          class="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <a
            href="#"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            @click="$emit('update:currentPage', 1)"
          >
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: mini/chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <template v-for="index in displayPages" :key="index">
            <a
              v-if="currentPage === index"
              href="#"
              aria-current="page"
              class="border-theme-primary-500 text-theme-primary-500 relative z-10 inline-flex items-center border bg-blue-100 px-4 py-2 text-sm font-medium focus:z-20"
              @click="$emit('update:currentPage', index)"
              >{{ index }}
            </a>
            <a
              v-else
              href="#"
              class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              @click="$emit('update:currentPage', index)"
              >{{ index }}</a
            >
          </template>

          <a
            href="#"
            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            @click="$emit('update:currentPage', totalPages)"
          >
            <span class="sr-only">Next</span>
            <!-- Heroicon name: mini/chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaginationComponent',
  props: {
    entriesLength: {
      type: Number,
      required: true
    },
    pageLength: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  emits: ['update:currentPage'],
  data() {
    return {
      totalBullets: 5
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.entriesLength / this.pageLength)
    },
    displayPages() {
      let totalPages = this.totalPages
      let currentPage = this.currentPage

      let numShown = 5 // This sets the number of page tabs
      numShown = Math.min(numShown, totalPages)
      let first = currentPage - Math.floor(numShown / 2)
      first = Math.max(first, 1)
      first = Math.min(first, totalPages - numShown + 1)
      return [...Array(numShown)].map((k, i) => i + first)
    },
    entryFrom() {
      if (this.currentPage === 1) {
        return 1
      }
      return (this.currentPage - 1) * this.pageLength + 1
    },
    entryTo() {
      if (this.currentPage === 1) {
        if (this.pageLength > this.entriesLength) {
          return this.entriesLength
        }
        return this.pageLength
      }
      if (this.currentPage * this.pageLength > this.entriesLength) {
        return this.entriesLength
      }

      return this.currentPage * this.pageLength
    }
  }
}
</script>
