<template>
  <div
    id="notification-with-actions-content"
    class="toastify-content flex hidden"
  >
    <FontAwesomeIcon :icon="['fal', 'database']" />
    <div class="ml-4 mr-4">
      <div class="font-medium capitalize-first">{{ text }}</div>

      <div class="mt-1.5 flex justify-center font-medium">
        <BaseButton
          id="undo_btn"
          color="success"
          icon="undo-alt"
          button-classes="m-2"
          :is-outline="true"
          :title="$t('attributes.undo')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'ToastUndoComponent',
  components: { FontAwesomeIcon },
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>
