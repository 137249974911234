export default {

  setMaterialResourceList(state, materialResourcesList) {
    state.materialResourceList = materialResourcesList

  },
  setMaterialResource(state, materialResource) {
    state.materialResource = materialResource
  },
  setMaterialResourceMetadata(state, metadata) {
    state.materialResourceMetadata = metadata
  },
  setMaterialResourceActivity(state, activity) {
    state.materialResourceActivity = activity
  },
  deleteFuneralHomeFromMaterialResource(state, payload) {
    state.materialResource.funeral_homes = state.materialResource?.funeral_homes?.filter(funeral_home => {
      return funeral_home.id !== payload.funeralHome
    })
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
}
