export default {
  setDossierItems(state, items) {
    state.dossier.items = items
  },
  removeItemFromDossier(state, item) {
    let index = state.dossier.items.indexOf(item)

    if (index === -1) {
      return console.error('addItemToDossier: item does not exists')
    }
    return state.dossier.items.splice(index, 1)
  },
}
