import apiClient from '@u/apiClient'

// const base_errors = { deceased: {}, events: [] }
const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // #region store

  async storeDossierTask({ commit }, obj) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post('dossier-task', obj.event)
      .then(async (response) => {
        if (response.status === 201) {
          if (obj.successCallback) {
            obj.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        await commit('setErrors', reason)
      })
  },

  // #endregion

  // #region update

  updateDossierTask: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`task-dossier/${payload.id}`, payload.task)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierTaskStatus: ({ commit, state }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`task-dossier/update-status/${payload.id}`, {
          new_status: payload.new_status
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // #endregion

  //region delete
  deleteTaskDossierById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`task-dossier/${payload.task_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  undoDeleteTaskDossierById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`task-dossier/${payload.task_id}/undo-delete`)
        .then((response) => {
          resolve(response.date)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  //endregion
}
