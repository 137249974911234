const TheBankAccountIndexPage = () =>
  import('@/views/settings/invoicing/bankAccount/index/TheBankAccountIndexPage')
const TheBankAccountInformationPage = () =>
  import(
    '@/views/settings/invoicing/bankAccount/form/tabs/bankAccountInformation/TheBankAccountInformationPage'
  )
const TheBankAccountFormsTabComponent = () =>
  import(
    '@/views/settings/invoicing/bankAccount/form/TheBankAccountFormsTabComponent'
  )
const TheBankAccountActivityPage = () =>
  import(
    '@/views/settings/invoicing/bankAccount/form/tabs/bankAccountActivity/TheBankAccountActivityPage'
  )

export const settingsBankAccountRoutes = [
  {
    path: 'bank-account',
    children: [
      {
        path: '',
        name: 'settings_list_bank_account_route',
        component: TheBankAccountIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_bank_account_profile_route',
        props: true,
        component: TheBankAccountFormsTabComponent,
        children: [
          {
            path: '',
            component: TheBankAccountInformationPage,
            name: 'settings_edit_bank_account_route'
          },
          {
            path: 'activities',
            component: TheBankAccountActivityPage,
            props: true,
            name: 'settings_edit_bank_account_activity_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_bank_accounts_route',
        component: TheBankAccountFormsTabComponent,
        children: [
          {
            path: '',
            component: TheBankAccountInformationPage,
            name: 'settings_create_bank_account_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        }
      }
    ]
  }
]
