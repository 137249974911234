<template>
  <div>
    <BaseButton
      :title="$t('global.supplier_order')"
      color="info"
      @click="isSupplierOrderModalOpen = true"
    />
  </div>
  <SupplierOrderModal
    :dossier-id="dossierId"
    :open="isSupplierOrderModalOpen"
    :supplier-id="supplierId"
    :item="item"
    :refresh-function="refreshFunction"
    @close="isSupplierOrderModalOpen = false"
  />
</template>

<script>
import SupplierOrderModal from "@ui/modal/supplierOrder/SupplierOrderModal";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SupplierOrderButton",
  components: { SupplierOrderModal },
  props: {
    supplierId: {
      type: [String, Number],
      required: true
    },
    dossierId: {
      type: [String, Number],
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    refreshFunction: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      isSupplierOrderModalOpen: false
    }
  },
}
</script>
