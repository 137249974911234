const TheDuplicateIndexCardPage = () =>
  import('@/views/settings/corporation/address/TheAddressIndexPage.vue')
const TheIdenticalModelsToMergePage = () =>
  import('@/views/settings/Duplicate/TheDuplicateModelsToMergePage.vue')

export const settingsAddressRoutes = [
  {
    path: 'address-card',
    children: [
      {
        path: '',
        name: 'settings_duplicate_card_route',
        component: TheDuplicateIndexCardPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: 'merge-models',
        name: 'settings_duplicate_models_merge_route',
        props: true,
        component: TheIdenticalModelsToMergePage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      }
    ]
  }
]
