export default {
  getItemList(state) {
    return state.itemsList
  },
  getSelectedItem(state) {
    return state.item
  },
  getItemMetadata(state) {
    return state.itemMetadata
  },
  getItemActivity(state) {
    return state.itemActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getCategorisedItemList(state) {
    return state.categorisedItemList
  },
  getPacksList(state) {
    return state.packsList
  },
  getErrorImport(state) {
    return state.errorImport
  }
}
