<template>
  <div class="flex w-full flex-col gap-2 py-2">
    <h1 class="text-xl font-bold capitalize-first">
      {{ $t('invoicing.recipient') }}
    </h1>
    <div class="flex flex-col">
      <InvoiceableSelector
        v-model:selected-invoiceable="invoiceable"
        v-model:dossier-id="invoice.dossier_id"
      />
    </div>
  </div>
</template>

<script>
import InvoiceableSelector from '@c/Invoiceable/InvoiceableSelector.vue'

export default {
  name: 'TheInvoiceWizardInvoiceableStep',
  components: {
    InvoiceableSelector
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    haveDossier: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:invoice'],
  data() {
    return {
      invoiceable: null
    }
  },
  computed: {
    localInvoice: {
      get() {
        return this.invoice
      },
      set(value) {
        this.$emit('update:invoice', value)
      }
    }
  },
  watch: {
    invoiceable(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localInvoice.invoiceable_id = newValue?.id
        this.localInvoice.invoiceable_type = newValue?.type
        this.localInvoice.invoiceable = newValue
      }
    }
  }
}
</script>
