export default {
  // #region setters
  setErrors(state, payload) {
    state.partnerSharingErrors = payload
  },
  setPartnerData(state, payload) {
    state.partnerData = payload
  },
  setPartnerSharing(state, payload) {
    state.partnerSharing = payload
  }
  // #endregion
}
