<template>
  <div class="fm-form-input" :class="label ? 'mt-2' : ''">
    <label> {{ label }}&nbsp; </label>
    <div class="flex !flex-col !items-start gap-2 !bg-white p-3">
      <label
        v-for="option in options"
        :key="option.id"
        class="flex cursor-pointer flex-row gap-2 text-xs font-medium text-gray-900"
      >
        <input
          v-model="value"
          class="form-check-input shrink-0"
          type="radio"
          :value="option"
          :checked="value === option.id"
        />
        <div class="inline flex-1 capitalize-first">{{ option.name }}</div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseSimpleRadioGroup',
  props: {
    options: {
      type: [Array, Object],
      required: true
    },
    modelValue: {
      type: [Array, Object],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get: function () {
        return this.modelValue
      },
      set: function (newValue) {
        this.$emit('update:modelValue', newValue.id)
      }
    }
  }
}
</script>
