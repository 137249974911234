/* eslint-disable camelcase,no-unused-vars */
import apiClient from '@u/apiClient'
import router from '@/router'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  fetchCheckStatus({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('fetch-check-status')
        .then((response) => {
          commit('setCheckDataStatus', response.data)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewCheckDeposit({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=CheckDeposit')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchCheckDepositById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`check-deposit/${payload.check_deposit}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchCheckDepositList({ commit }) {
    await apiClient
      .get('fetch-check-deposit')
      .then(async (response) => {
        await commit('setCheckDepositList', response.data.check_deposits)
        await commit('setCheckMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error('fetchChecksList', reason)
      })
  },

  fetchCheckByStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`fetch-checks-by-status/${payload.status}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // endregion

  // region store
  storeCheckDeposit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post('check-deposit', payload.check_deposit)
        .then((response) => {
          resolve(response?.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  async updateCheckDeposit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`check-deposit/${payload.id}`, payload.check_deposit)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  //region delete
  async deleteCheckDeposit({ commit }, payload) {
    await apiClient
      .delete(`check-deposit/delete/${payload.id}`)
      .then(async (response) => {
        await commit('setCheckDepositList', response.data.check_deposits)
        await commit('setCheckMetadata', response.data.metadata)
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error(reason)
      })
  },
  async undoDeleteCheckDeposit({ commit }, payload) {
    await apiClient
      .get(`check-deposit/undo-delete/${payload.id}`)
      .then(async (response) => {
        await commit('setCheckDepositList', response.data.check_deposits)
        await commit('setCheckMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error('undoDeleteCheckDeposit', reason)
      })
  }
  //endregion
}
