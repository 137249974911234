const TheScheduleSettingPlanningPage = () =>
  import('@/views/settings/schedule/planning/TheScheduleSettingPlanningPage')

export const settingsPlanningRoutes = [
  {
    path: 'planning',
    name: 'settings_list_planning_route',
    component: TheScheduleSettingPlanningPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_company_title', active: true }
      ]
    }
  }
]
