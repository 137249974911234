export default {
  getPackList(state) {
    return state.PackList
  },
  getPackMetadata(state) {
    return state.metadata
  },
  getPack(state) {
    return state.pack
  },
  getPackActivity(state) {
    return state.packActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
  getEditionMode(state) {
    return state.editionMode
  }
}
