<template>
  <BaseSlideOverModal
    v-model:is-modal-open="open"
    width="2xl"
    :title="selectedSupplier?.name"
    @close="close"
  >
    <template #subtitle>
      <p
        v-if="selectedSupplier?.email"
        class="text-sm font-medium text-slate-300"
      >
        {{ selectedSupplier?.email }}
      </p>
      <BaseShowPhoneLabel
        v-if="selectedSupplier?.phone_field && selectedSupplier?.phone_country"
        :model-value="selectedSupplier?.phone_field"
        :country-value="selectedSupplier?.phone_country"
        value-class="font-medium text-sm text-slate-300"
      />
      <BaseShowPhoneLabel
        v-if="
          selectedSupplier?.mobile_field && selectedSupplier?.mobile_country
        "
        :model-value="selectedSupplier?.mobile_field"
        :country-value="selectedSupplier?.mobile_country"
        value-class="font-medium text-sm text-slate-300"
      />
    </template>
    <template #cta>
      <template v-if="internalTab === 'list'">
        <BaseButton
          :title="$t('button.add_supplier_order')"
          icon="plus"
          size="small"
          @click="handleCreateSupplierOrderButton"
        />
      </template>
      <template v-else-if="internalTab === 'form'">
        <div class="flex gap-x-1">
          <BaseButton
            v-if="!dossier?.id"
            :title="$t('button.back')"
            color="dark"
            icon="long-arrow-left"
            size="small"
            @click="resumeToList"
          />
          <BaseButton
            :key="formButtonTitle"
            :title="formButtonTitle"
            icon="paper-plane"
            size="small"
            @click="submitForm"
          />
        </div>
      </template>
      <template v-else>
        <BaseButton
          :title="$t('button.back')"
          color="dark"
          icon="long-arrow-left"
          size="small"
          @click="resumeToForm"
        />
      </template>
    </template>

    <template #body>
      <div
        class="scrollbar-custom flex h-full w-full flex-col justify-between overflow-scroll"
      >
        <SupplierOrderList
          v-if="internalTab === 'list'"
          :item="item"
          :supplier-id="supplierId"
          :dossier-id="dossier?.id"
          @edit-supplier-order="handleEditSupplierOrder"
        />
        <SupplierOrderForm
          v-else-if="internalTab === 'form'"
          v-model:order-type="orderType"
          :supplier-order-id="supplierOrderId ?? selectedSupplierOrderId"
          :supplier-id="supplierId"
          :item="item"
          :need-to-fetch="needToFetch"
          :dossier="dossier"
          @address="handleAddress"
        />
        <template v-else>
          <BasePlaceModalContent
            v-if="supplierOrder"
            @get-address="handleAddressSelection"
          />
        </template>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DocTypes, InvoiceableType } from '@/assets/enums'
import moment from 'moment'
import SupplierOrderList from '@ui/modal/supplierOrder/components/SupplierOrderList'
import SupplierOrderForm from '@ui/modal/supplierOrder/components/SupplierOrderForm'
import BasePlaceModalContent from '@ui/modal/place/components/BasePlaceModalContent'

export default {
  name: 'SupplierOrderModal',
  components: { BasePlaceModalContent, SupplierOrderForm, SupplierOrderList },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    supplierId: {
      type: [String, Number],
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: false,
      default: null
    },
    forceForm: {
      type: Boolean,
      required: false,
      default: false
    },
    dossier: {
      type: Object,
      required: false,
      default: null
    },
    refreshFunction: {
      type: Function,
      required: false,
      default: null
    },
    supplierOrderId: {
      type: [String, Number],
      required: false,
      default: null
    },
    closeAfterCreation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['close', 'update:modelValue', 'change'],
  data() {
    return {
      internalTab: 'list',
      selectedSupplierOrderId: null,
      needToFetch: true,
      DocTypes: DocTypes,
      orderType: null,
      InvoiceableType: InvoiceableType
    }
  },
  computed: {
    ...mapGetters({
      supplierList: 'supplier/getSupplierList',
      supplierOrder: 'invoicing/getInvoice',
      initialMetadata: 'metadata/getInitialMetadata',
      dossier: 'dossier/getDossier'
    }),
    selectedSupplier() {
      if (this.supplierList < 1) return null
      return this.supplierList.find(
        (supplier) => supplier.id === this.supplierId
      )
    },
    formButtonTitle() {
      if (!this.supplierOrder) return null
      if (this.supplierOrder.invoiceable_type === InvoiceableType.SUPPLIER) {
        return this.supplierOrder.id
          ? this.$t('button.update_supplier_order')
          : this.$t('button.save_supplier_order')
      }
      return this.supplierOrder.id
        ? this.$t('button.update_company_order')
        : this.$t('button.save_company_order')
    }
  },
  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          if (this.supplierId) {
            this.forceForm
              ? (this.internalTab = 'form')
              : (this.internalTab = 'list')
            this.processFetchDraftSupplierOrderBySupplierIdAction({
              supplier_id: this.supplierId
            })
          } else {
            this.forceForm
              ? (this.internalTab = 'form')
              : (this.internalTab = 'list')

            if (this.dossier?.id) {
              this.internalTab = 'form'
              this.needToFetch = true
              if (this.supplierOrderId)
                this.selectedSupplierOrderId = this.supplierOrderId
              this.selectedSupplierOrderId
            }
          }
        }
      },
      immediate: true,
      deep: true
    },
    selectedSupplierOrderId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.needToFetch = true
      }
    }
  },
  beforeMount() {
    if (this.supplierList?.length < 1) this.processFetchSupplierListAction()
  },
  methods: {
    ...mapActions({
      processFetchDraftSupplierOrderBySupplierIdAction:
        'invoicing/fetchDraftSupplierOrderBySupplierId',
      processFetchSupplierListAction: 'supplier/fetchSupplierList',
      processStoreNewInvoiceAction: 'invoicing/storeNewInvoice',
      processUpdateInvoiceAction: 'invoicing/updateInvoice',
      processUpdateInvoiceItemAction: 'invoicing/updateInvoiceItem',
      processFetchAddressesByDossier: 'dossier/fetchAddressesByDossier'
    }),
    ...mapMutations({
      processSetInvoiceSupplierOrderAddressPropertyMutation:
        'invoicing/setInvoiceSupplierOrderAddressProperty',
      processSetInvoiceInvoiceableMutation: 'invoicing/setInvoiceInvoiceable'
    }),
    handleAddress() {
      this.needToFetch = false
      if (this.$route.name !== 'supplier_order_page_route') {
        this.processFetchAddressesByDossier({ dossier_id: this.dossier?.id })
      }
      this.internalTab = 'address'
    },
    handleAddressSelection(payload) {
      this.processSetInvoiceSupplierOrderAddressPropertyMutation(payload)
      this.internalTab = 'form'
    },
    setInvoiceable() {
      if (!this.supplierOrder.invoiceable_id) return null

      if (this.supplierOrder.invoiceable_type === InvoiceableType.SUPPLIER) {
        this.processSetInvoiceInvoiceableMutation(
          this.initialMetadata?.models?.suppliers?.find(
            (supplier) => supplier.id === this.supplierOrder.invoiceable_id
          )
        )
      } else if (
        this.supplierOrder.invoiceable_type === InvoiceableType.COMPANY
      ) {
        this.processSetInvoiceInvoiceableMutation(
          this.initialMetadata?.models?.companies?.find(
            (supplier) => supplier.id === this.supplierOrder.invoiceable_id
          )
        )
      } else {
        return null
      }
    },
    submitForm() {
      if (this.selectedSupplierOrderId) {
        if (this.supplierId) {
          this.supplierOrder.invoiceable_id = this.supplierId
        }
        this.setInvoiceable()
        this.processUpdateInvoiceAction({
          id: this.supplierOrder.id,
          invoice: this.supplierOrder,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.supplier_order_updated'), {
              className: 'toastify-content success'
            })
            if (!this.dossier?.id) {
              this.selectedSupplierOrderId = null
              this.processFetchDraftSupplierOrderBySupplierIdAction({
                supplier_id: this.supplierId
              })
              this.internalTab = 'list'
              this.needToFetch = true
            } else {
              this.refreshFunction()
              this.close()
            }
          }
        })
      } else {
        this.supplierOrder.doc_type = DocTypes.SUPPLIER_ORDER
        if (!this.dossier?.id && this.supplierId) {
          this.supplierOrder.invoiceable_id = this.supplierId
        }
        this.setInvoiceable()

        if (this.$route.name !== 'supplier_order_page_route') {
          this.supplierOrder.dossier_id = this.dossier?.id
          this.supplierOrder.supplier_order_property.type = 'CAT' //TODO: A CHANGER
        }

        this.supplierOrder.emission_date = moment().format()
        this.supplierOrder.due_date =
          this.supplierOrder.supplier_order_property.delivery_date_time

        delete this.supplierOrder.supplier_order_property.delivery_place_label

        this.processStoreNewInvoiceAction({
          invoice: this.supplierOrder,
          successCallback: (id) => {
            this.$h.toastify(this.$t('toasts.supplier_order_created'), {
              className: 'toastify-content success'
            })
            if (
              !this.dossier?.id &&
              this.$route.name !== 'supplier_order_page_route'
            ) {
              this.processUpdateInvoiceItemAction({
                invoice_id: id,
                content: { item_dossier_id: this.item.id },
                successCallback: () => {
                  this.$h.toastify(this.$t('toasts.supplier_order_created'), {
                    className: 'toastify-content success'
                  })
                  if (!this.dossier?.id) {
                    this.selectedSupplierOrderId = null
                    this.processFetchDraftSupplierOrderBySupplierIdAction({
                      supplier_id: this.supplierId
                    })
                    this.internalTab = 'list'
                    this.needToFetch = true
                  } else {
                    this.needToFetch = true
                    this.refreshFunction()
                    this.close()
                  }
                }
              })
            } else {
              this.needToFetch = true
              this.refreshFunction()
              if (this.closeAfterCreation) {
                this.close()
              } else {
                this.processFetchDraftSupplierOrderBySupplierIdAction({
                  supplier_id: this.supplierId
                })
                this.internalTab = 'list'
              }
            }
          }
        })
      }
    },
    resumeToForm() {
      this.internalTab = 'form'
    },
    resumeToList() {
      this.internalTab = 'list'
      this.selectedSupplierOrderId = null
    },
    handleEditSupplierOrder(supplier_order_id) {
      this.selectedSupplierOrderId = supplier_order_id
      this.internalTab = 'form'
    },
    handleCreateSupplierOrderButton() {
      this.needToFetch = true
      this.selectedSupplierOrderId = null
      this.internalTab = 'form'
    },
    close() {
      this.selectedSupplierOrderId = null
      this.$emit('close')
    }
  }
}
</script>
