<template>
  <div class="wysiwyg-container" :id="label">
    <div v-if="editionMode" class="col-span-12 mt-4">
      <label
        class="mb-6 inline-block text-sm font-medium capitalize text-gray-500"
        >{{ label }}</label
      >
      <BaseWysiwyg
        ref="editorForm"
        v-model="modelValue"
        name="contact_remarks"
        :value="modelValue"
        @change.self="
          $emit('getInputValue', { value: modelValue, attribute: name })
        "
      />
    </div>
    <div v-else class="col-span-12 mt-2 xl:col-span-6">
      <div class="text-sm font-medium text-gray-500 capitalize-first">
        {{ label }}
      </div>
      <div v-if="modelValue" class="w-full" v-html="modelValue"></div>
      <div v-else>-</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ParticularityWysiwygComponent',
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['getInputValue']
}
</script>
