export default {
  getAddressList(state) {
    return state.addressList
  },
  getAddress(state) {
    return state.address
  },
  getFuneralHomeAddress(state) {
    return state.funeralHomeAddress
  },
  getAddressMetadata(state) {
    return state.metadata
  }
}
