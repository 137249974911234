export default {
  // Obtient la valeur de paramètre en fonction de l'identifiant de la maison funéraire (funeralHomeId) et de la clé du paramètre (settingKey).
// Si les métadonnées initiales (initialMetadata) ou les paramètres ne sont pas définis, retourne null (ou fournit une valeur par défaut ou génère une erreur).
  getSettingValue(funeralHomeId, settingKey, initialMetadata) {
    if (!initialMetadata || !initialMetadata.settings) {
      return null; // ou fournir une valeur par défaut ou générer une erreur
    }

    if (funeralHomeId && initialMetadata.settings.event_settings_to_fh) {
      return initialMetadata.fh_settings?.[funeralHomeId]?.[settingKey] ?? null;
    }

    return initialMetadata.settings?.[settingKey] ?? null;
  }

}
