<template>
  <BaseSlideOverModal
    v-model:is-modal-open="open"
    width="xl"
    :title="getTitle"
    @close="close"
  >
    <template #cta>
      <div class="mt-1 flex h-full gap-x-1">
        <BaseButton
          :title="getTitleFormButton"
          icon="paper-plane"
          size="small"
          @click="submitForm"
        />
      </div>
    </template>
    <template #body>
      <div class="flex h-full w-full flex-col gap-3 overflow-y-scroll">
        <div class="flex flex-row-reverse">
          <BaseCheckBox
            v-model="accountingDiary.closed"
            :label="$t('attributes.closed')"
            edition-mode
          />
        </div>
        <div class="grid grid-cols-2 gap-x-3 gap-y-1">
          <div class="col-span-2">
            <BaseShowEditInput
              v-model="accountingDiary.name"
              :label="$t('attributes.name')"
              :errors="errors.name"
              :placeholder="$t('attributes.name')"
              :edition-mode="true"
              :required="true"
            />
          </div>
          <div class="col-span-2">
            <SelectComponent
              v-model="accountingDiary.doc_type"
              :label="$t('attributes.type')"
              name="doc_type"
              :values="doc_types"
              :edition-mode="true"
              required
            />
          </div>
          <div class="col-span-2">
            <SelectCompany
              v-model="accountingDiary.company_id"
              :error-key="errors.company_id"
              :edition-mode="true"
              required
              name="company_id"
            />
          </div>
          <div class="col-span-2" v-if="accountingDiary.company_id">
            <SelectFuneralHome
              v-model="accountingDiary.funeral_homes_ids"
              edition-mode
              :error-key="errors.funeral_homes"
              :filter-company-id="accountingDiary.company_id"
              required
              multiple
            />
          </div>
          <div>
            <BaseShowEditDatePickerv2
              v-model:date="accountingDiary.from"
              :edition-mode="true"
              :label="$t('attributes.start_date')"
              name="from"
              :required="false"
              :errors="errors.from"
            />
          </div>
          <div>
            <BaseShowEditDatePickerv2
              v-model:date="accountingDiary.to"
              :edition-mode="true"
              :label="$t('attributes.end_date')"
              name="to"
              :required="false"
              :errors="errors.to"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <h3 class="text-base font-semibold text-gray-700 capitalize-first">
            {{ $t('attributes.accounting') }}
          </h3>
          <div class="flex flex-col gap-1">
            <div>
              <MultiSelectComponent
                v-model="accountingDiary.doc_roles"
                :values="doc_roles"
                :label="$t('attributes.doc_role')"
                display-key="name"
                required
                name="doc_roles"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="accountingDiary.payment_period"
                :label="$t('attributes.due_date')"
                :min="0"
                :max="365"
                :type="'number'"
                :errors="errors.payment_period"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="accountingDiary.journal_code"
                :label="$t('attributes.journal_code')"
                :errors="errors.journal_code"
              />
            </div>
            <div>
              <ReferenceConfigurator
                v-model:reference-array="accountingDiary.reference_pattern"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'
import BaseShowEditSwitch from '@c/BaseShowEditSwitch.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import SelectFuneralHome from '@c/InitialStateFormComponent/SelectFuneralHome.vue'
import SelectCompany from '@c/InitialStateFormComponent/SelectCompany.vue'
import MultiSelectComponent from '@c/BaseFormComponent/MultiSelectComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import ReferenceConfigurator from '@c/GlobalFormComponents/ReferenceConfigurator.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'

export default {
  name: 'AccountingDiaryModal',
  components: {
    BaseCheckBox,
    ReferenceConfigurator,
    SelectComponent,
    BaseShowEditInput,
    BaseButton,
    MultiSelectComponent,
    SelectCompany,
    SelectFuneralHome,
    BaseShowEditDatePickerv2,
    BaseShowEditSwitch,
    BaseSlideOverModal
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    accountingDiary: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      errors: 'auth/getErrors',
      doc_roles: 'metadata/getInvoiceDocRoles',
      doc_types: 'metadata/getInvoiceDocTypes'
    }),
    funeralHomesByCompany() {
      if (!this.accountingDiary.company_id) {
        return []
      }

      return this.initialMetadata?.models?.funeralHomes?.filter(
        (funeralHome) =>
          funeralHome?.company_id === this.accountingDiary?.company_id
      )
    },
    getTitleFormButton() {
      return this.$t(this.accountingDiary.id ? 'button.edit' : 'button.add')
    },
    getTitle() {
      return this.accountingDiary.id
        ? this.accountingDiary.name
        : this.$t('button.add_new_m', {
            var: this.$t('attributes.accounting_diary')
          })
    }
  },
  watch: {
    'accountingDiary.company_id'(newValue, oldValue) {
      console.log('watch')
      this.accountingDiary.funeral_homes_ids = []
    }
  },
  methods: {
    ...mapActions({
      processStoreAccountingDiaryAction: 'accountingDiary/storeAccountingDiary',
      processUpdateAccountingDiaryAction:
        'accountingDiary/updateAccountingDiary'
    }),
    submitForm() {
      if (this.accountingDiary.id) {
        this.processUpdateAccountingDiaryAction({
          id: this.accountingDiary.id,
          accounting_diary: this.accountingDiary,
          successCallback: () => {
            this.$emit('close')
            this.$h.toastify(
              this.$t('toasts.updated_m', {
                var: this.$t('attributes.accounting_diary')
              }),
              { className: 'toastify-content success' }
            )
          }
        })
      } else {
        this.processStoreAccountingDiaryAction({
          accounting_diary: this.accountingDiary,
          successCallback: () => {
            this.$emit('close')
            this.$h.toastify(
              this.$t('toasts.created_m', {
                var: this.$t('attributes.accounting_diary')
              }),
              { className: 'toastify-content success' }
            )
          }
        })
      }
    }
  },
  mounted() {
    console.log('this.accountingDiary')
    console.log(this.accountingDiary)
  }
}
</script>
