<template>
  <template v-if="label">
    <BaseEditLabel :label="label" :required="required" />
  </template>
  <Listbox v-slot="{ open }" v-model="selectedValues" :disabled="disabled">
    <div class="group relative">
      <ListboxButton
        class="relative w-full cursor-default rounded-md border border-transparent bg-white py-1 pl-0 text-left transition-all sm:text-sm"
        :class="[
          open ? 'border-gray-200 pl-2' : '',
          disabled ? '' : 'group-hover:border-gray-200 group-hover:pl-2'
        ]"
      >
        <div class="flex flex-row items-center gap-2">
          <span
            :data-tint="selectedColor"
            class="h-3 w-3 flex-shrink-0 rounded-sm bg-gradient-to-br from-primary-400 to-primary-600"
            >&nbsp;</span
          >
          <span
            :class="[
              selected ? '' : 'text-gray-500',
              'flex-1 truncate text-left'
            ]"
          >
            {{ selectedValuesLabel }}
          </span>
          <span
            class="flex-shrink-0 px-2 text-gray-500 opacity-0"
            :class="[
              open ? 'opacity-100' : '',
              disabled ? '' : 'group-hover:opacity-100'
            ]"
          >
            <FontAwesomeIcon :icon="['fal', 'chevron-down']" />
          </span>
        </div>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-90 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="(status, index) in options"
            :key="index"
            v-slot="{ active, selected }"
            :value="status.id"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-primary-300 text-white' : 'text-gray-900',
                'relative cursor-default select-none px-2 py-1'
              ]"
            >
              <div class="flex flex-row items-center gap-2">
                <span
                  :data-tint="status.bg_color"
                  class="from to h-3 w-3 rounded-sm bg-gradient-to-br from-primary-400 to-primary-600"
                  >&nbsp;</span
                >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate'
                  ]"
                  >{{ status.name.toLocaleLowerCase() }}</span
                >
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
  <div
    v-for="(error, index) in errors"
    :key="index"
    class="form-help text-red-600"
  >
    {{ $t(error, { attribute: $t('attributes.' + name) }) }}
  </div>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'StatusInput',
  components: {
    FontAwesomeIcon,
    BaseEditLabel,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Array,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue', 'status-changed', 'change'],
  computed: {
    selectedValuesLabel() {
      return (
        this.options
          .find((status) => status.id === this.modelValue)
          ?.name.toLocaleLowerCase() ?? this.$t('global.select')
      )
    },
    selected() {
      return this.options.find((option) => option.id === this.modelValue)
    },
    selectedColor() {
      return this.options.find((status) => status.id === this.modelValue)
        ?.bg_color
    },
    selectedValues: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('status-changed', newValue)
        this.$emit('change', newValue)
      }
    }
  }
}
</script>
