export default {
  getCheckDataStatus(state) {
    return state.checkDataStatus
  },
  getCheckDepositList(state) {
    return state.checkDepositList
  },
  getCheckListByType(state) {
    return state.checkListByType
  },
  getCheckDeposit(state) {
    return state.checkDeposit
  },
  getCheckMetadata(state) {
    return state.checkMetadata
  },
  getEditedCheckList(state) {
    return state.editedCheckList
  }
}
