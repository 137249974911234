<template>
  <div
    class="flex flex-nowrap gap-2 justify-start items-center mb-3"
    :class="[customContainerClasses]"
  >
    <label
      v-if="label"
      class="text-xl"
      :class="[customLabelClasses]"
    >
      {{ label }}
    </label>
    <button
      type="button"
      class="transition-co lors relative inline-flex flex-shrink-0 rounded-full border-2 border-transparent duration-200 ease-in-out"
      :class="[customSwitchClasses, buttonClasses, sizeButton, cursorStyle]"
      :disabled="disabled"
      role="switch"
      aria-checked="false"
      @click="toggleSwitch"
    >
      <span class="sr-only">Use setting</span>

      <span
        aria-hidden="true"
        class="pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        :class="[spanClasses, sizeAria]"
      ></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseSwitch',
  props: {
    modelValue: {
      type: [Boolean],
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    customContainerClasses: {
      type: Array,
      required: false,
      default: () => []
    },
    customSwitchClasses: {
      type: Array,
      required: false,
      default: () => []
    },
    customLabelClasses: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: [
    'change',
    'update:model-value'
  ],
  data() {
    return {
      isChecked: this.modelValue
    }
  },
  computed: {
    buttonClasses() {
      return this.isChecked ? 'bg-theme-primary' : 'bg-gray-200'
    },
    spanClasses() {
      return this.isChecked ? 'translate-x-5' : 'translate-x-0'
    },
    sizeButton() {
      return this.size === 'normal' ? 'h-6 w-11' : 'h-3.5 w-9'
    },
    sizeAria() {
      return this.size === 'normal' ? 'h-5 w-5' : 'h-2.5 w-2.5'
    },
    cursorStyle() {
      return this.disabled ? 'cursor-default' : 'cursor-pointer'
    }
  },
  watch: {
    isChecked(newValue) {
      this.$emit('update:model-value', this.isChecked)
      this.$emit('change', {attribute: this.name, value: newValue})
    },
    modelValue(newVal, oldVal) {
      this.isChecked = newVal
    }
  },
  mounted() {
    this.isChecked = this.modelValue
  },
  methods: {
    toggleSwitch() {
      this.isChecked = !this.isChecked
    }
  }
}
</script>
