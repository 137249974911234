<template>
  <div class="flex flex-row justify-between p-1 my-1 align-baseline select-none cursor-default">
    <div class="flex flex-col">
      <p class="text-sm text-gray-700">{{ item.name }}<span class="text-gray-500 text-sm select-none ml-2">{{ item.item_dossier?.dossier?.reference }}</span></p>
      <p class="text-xs text-gray-500">{{ $t('attributes.quantity') }}: {{ item.quantity }}</p>
    </div>
    <div>
      <p class="text-xs">{{ internalPrice + ' €' }}</p>
      <p class="text-xs">{{ item.status }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SupplierOrderItemCard",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    internalPrice(){
      return this.initialMetadata?.settings?.price_encoding_type === 'HTVA' ? this.item.price_htva : this.item.price_tvac
    }
  }
}
</script>
