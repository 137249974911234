<template>
  <div v-if="editionMode" :class="groupClass">
    <div>
      <SelectComponent
        v-model="internalValue"
        :values="values"
        track-by="id"
        display-key="name"
        label="funerailles"
        name="funerailles"
      />
    </div>
  </div>
</template>

<script>
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'

export default {
  name: 'BaseFuneraillesSelectSimple',
  components: {
    SelectComponent
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'contact'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    dontDisplayLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    fullModelResponse: {type: Boolean, required: false, default: false},
    dossierId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    values() {
      return [
        {
          id: 'auto',
          name: this.$t('global.auto')
        },
        {
          id: 'cre',
          name: this.$t('attributes.cremation')
        },
        {
          id: 'bur',
          name: this.$t('attributes.burial')
        },
        {
          id: 'cer',
          name: this.$t('attributes.ceremony')
        }
      ]
    },
    internalValue: {
      get() {
        if (!this.modelValue) {
          return null
        }
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  },

}
</script>
