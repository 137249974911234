<template>
  <PageSection>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-2 items-center">
      <BaseSelectSimpleAjax
        :key="cParent"
        v-model="city.parent_id"
        label-class="text-sm text-gray-500 capitalize"
        url="city-for-select"
        :label="$t('attributes.parent_id')"
        name="parent_id"
        attribute-label="full_name"
        :default-ajax="cParent"
        :errors="errors.parent_id"
      />
      <TranslationComponent
        :model="city"
        :language="initialMetadata?.settings?.default_language"
        :edition-mode="true"
        name-class=""
        :with-title="false"
        :with-short-name="false"
        :with-variante-name="false"
        :with-remarks="false"
        :with-internal-note="false"
        :required="true"
        name="name"
      />
      <BaseCountryShowEditSelectSimple
        v-model="city.country"
        :edition-mode="true"
        :required="true"
        :metadata="initialMetadata?.enums"
        name="country"
        :errors="errors"
      />
      <BaseShowEditInput
        v-model="city.zip"
        :label="$t('attributes.zip')"
        :edition-mode="true"
        name="zip"
        :required="true"
        :errors="errors.zip"
      />
      <!--      <BaseShowEditSelectSimple-->
      <!--          v-model="city.region_id"-->
      <!--          :label="$t('attributes.region_id')"-->
      <!--          name="name"-->
      <!--          :edition-mode="true"-->
      <!--          :options="regions"-->
      <!--          :required="true"-->
      <!--          :errors="errors.region_id"-->
      <!--      />-->
      <BaseShowEditInput
        v-model="city.latitude"
        :label="$t('attributes.latitude')"
        :edition-mode="true"
        name="latitude"
        :errors="errors.latitude"
        :required="true"
      />
      <BaseShowEditInput
        v-model="city.longitude"
        :label="$t('attributes.longitude')"
        :edition-mode="true"
        name="longitude"
        :errors="errors.longitude"
        :required="true"
      />
      <BaseShowEditInput
        v-model="city.web"
        :label="$t('attributes.web')"
        :edition-mode="true"
        name="web"
        :errors="errors.web"
      />
      <BaseShowEditInput
        v-model="city.enaos_id"
        :label="$t('attributes.enaos_id')"
        :edition-mode="true"
        name="enaos_id"
        :errors="errors.enaos_id"
      />
    </div>
  </PageSection>
</template>

<script>
import PageSection from "@c/PageSection.vue";
import BaseWikiCityMixin from "@u/mixins/wiki/BaseWikiCityMixin";

export default {
  name: "WikiCityInformation",
  components: {PageSection},
  mixins: [BaseWikiCityMixin],
  computed: {
    regions() {
      return this.initialMetadata?.models?.wiki_regions.map((region) => {
        return {
          id: region.id,
          name: region.name[this.$i18n.locale]
        }
      })
    },
    cParent() {
      if (!this.city.parent.name || !this.city.parent_id) return {}
      return {
        id: this.city.parent.id,
        full_name: `${this.city.parent.zip} ${this.city.parent.name[this.initialMetadata?.settings?.default_language]}`
      }
    }
  }
}
</script>
