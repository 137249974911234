<template>
  <div class="flex flex-col gap-3">
    <ViewSelector
      v-model:view="localDisplayOptions.view"
      v-model:timeline-group-by="localDisplayOptions.timelineGroupBy"
      v-model:timeline-location-filter="
        localDisplayOptions.timelineLocationFilter
      "
    />
    <TimeSelector
      v-if="['timeGridDay', 'timeGridWeek'].includes(localDisplayOptions.view)"
      v-model:slot-time="localDisplayOptions.slotTime"
    />
  </div>
</template>

<script>
import ViewSelector from '@c/Dashboard/Content/Agenda/Panel/ViewSelector.vue'
import TimeSelector from '@c/Dashboard/Content/Agenda/Panel/TimeSelector.vue'

let default_display_options = {
  view: 'timeGridWeek',
  slotTime: {
    min: '06:00:00',
    max: '20:00:00'
  }
}

export default {
  name: 'DisplayOptionsContent',
  components: { TimeSelector, ViewSelector },
  props: {
    displayOptions: {
      type: Object,
      required: true
    }
  },
  emits: ['update:displayOptions'],
  data() {
    return {
      localDisplayOptions: {
        ...default_display_options,
        ...(this.displayOptions || {})
      }
    }
  },
  watch: {
    localDisplayOptions: {
      handler(newValue) {
        this.$emit('update:displayOptions', newValue)
      },
      deep: true
    }
  }
}
</script>

<style scoped></style>
