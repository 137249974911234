export default {
  getEvents(state) {
    return state.eventsList
  }, getEventsUnplanned(state) {
    return state.eventsListUnplanned
  },
  getEvent(state) {
    return state.event
  },

  getPlanningMetadata(state) {
    return state.planningMetadata
  },
  getEventMetadata(state) {
    return state.eventMetadata
  },
  getEventDisponibilities(state) {
    return state.eventDisponibilities
  },
  getCalendarState(state) {
    return state.calendarState
  },
  getEventDossierAddresses(state){
    return state.eventDossierAddresses
  }
}
