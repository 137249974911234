export default {
  setBankAccountList(state, bankAccountList) {
    state.bankAccountList = bankAccountList
  },
  setBankAccount(state, bankAccount) {
    state.bankAccount = bankAccount
  },
  setBankAccountMetadata(state, metadata) {
    state.bankAccountMetadata = metadata
  },
  addOrRemoveFuneralHomeToBankAccount(state, payload) {
    let funeral_home_formatted = {}
    payload.values.forEach((funeral_home) => {
      funeral_home_formatted[funeral_home.id] = { default: false }
    })

    return state.bankAccount.funeral_homes = Object.assign(
        funeral_home_formatted
    )
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setBankAccountActivity(state, activity) {
    state.bankAccountActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
}
