import apiClient from '@u/apiClient'
const base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async createItemVariante({ commit }, payload) {
    apiClient
      .put(`item-new-variant/${payload.item}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setItem', response.data.item)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  }
}
