import apiClient from '@u/apiClient'

export default {
  async fetchAnalyticsDashboardDetails({ commit }, payload) {
    console.log(payload.id)
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`analytics/dashboard/${payload.id}`)
        .then(async (response) => {
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async fetchDashboardElement({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`analytics/dashboard-element/${payload.id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  }
}
