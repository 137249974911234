<template>
  <BaseCenteredModal
    :title="previewViewer ? '' : media.name"
    :large="!previewViewer"
    :translucent="previewViewer"
    :is-modal-open="open"
    @close="$emit('close')"
  >
    <template #body>
      <div class="h-[calc(100vh-10rem)] flex-1">
        <iframe
          v-if="media.mime_type === 'application/pdf'"
          class="h-full w-full"
          :src="media.preview_url"
          title="dossier attachment display"
        />
        <iframe
          v-else-if="
            media.mime_type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          "
          class="h-full w-full"
          :src="`https://docs.google.com/viewer?url=${media.original_url}&embedded=true`"
          title="dossier attachment display"
        />
        <div v-else class="flex justify-center">
          <img
            :alt="media.name"
            :src="media.original_url"
          />
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'

export default {
  name: 'ModalViewMedia',
  components: { BaseCenteredModal },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    media: {
      type: Object,
      required: true
    },
    previewViewer: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  emits: ['close'],
  data() {
    return {
      docViewer: 'https://view.officeapps.live.com/op/embed.aspx?src='
    }
  }
}
</script>
