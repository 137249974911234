export default {
  isAuthenticated(state, getters) {
    if (!state.user || !getters.isSessionStillValid || !state.token)
      return false

    return state.user && state.token && getters.isSessionStillValid
  },
  getAuthLocale(state) {
    return state.user?.language
  },
  getAuth(state) {
    return state.user
  },
  getUser(state) {
    return state.user
  },
  getToken(state) {
    return state.token
  },
  getAuthRole(state) {
    return state.user && state.user?.roles[0]
  },
  getAuthRoles(state) {
    return state?.user?.roles ?? []
  },
  getAuthPermissions(state) {
    return state.user && state.user?.permissions
  },

  getErrors(state) {
    return state.errors || {}
  },
  isSessionStillValid(state) {
    return Date.now() < new Date(state.sessionExpiration).getTime()
  }
}
