<template>
  <template v-if="editionMode">
    <div
      class="relative mt-2 rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
    >
      <label
        for="national_number"
        class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
        >{{ $t('attributes.national_number') }}
        <span v-if="required" class="text-red-600">*</span>
      </label>
      <input
        v-model="localModelValue"
        v-mask="getMask"
        type="text"
        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
      />
      <div
        v-if="validNationalNumber !== null"
        class="absolute inset-y-0 right-0 mr-2 flex items-center"
        :class="[validNationalNumber ? 'text-green-500' : 'text-red-600']"
      >
        <FontAwesomeIcon
          :icon="['fal', validNationalNumber ? 'check' : 'times']"
        />
      </div>
    </div>
    <ErrorContainer :error-key="'national_number'" :errors="errors" />
  </template>
  <template v-else>
    <BaseShowLabel
      :label="$t('attributes.national_number')"
      :model-value="localModelValue"
      :masked="true"
      :mask="getMask"
    />
  </template>
</template>

<script>
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import { Country } from '@/assets/enums'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseShowEditNationalNumberInput',
  components: { FontAwesomeIcon, ErrorContainer, BaseShowLabel },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    country: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      validNationalNumber: null
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        if (this.country === Country.BELGIUM) {
          this.validNationalNumber = this.validateBelgianNationalNumber(value)
        }
        this.$emit('update:modelValue', value)
      }
    },
    getMask() {
      if (this.country === Country.BELGIUM) {
        return '##.##.##-###.##'
      }
      return '##################################'
    }
  },
  watch: {
    country(newValue, oldValue) {
      if (newValue !== Country.BELGIUM) {
        this.validNationalNumber = null
      } else {
        this.validNationalNumber = this.validateBelgianNationalNumber(
          this.modelValue
        )
      }
    }
  },
  mounted() {
    if (this.country === Country.BELGIUM && this.modelValue?.length) {
      this.validNationalNumber = this.validateBelgianNationalNumber(
        this.modelValue
      )
    }
  },
  methods: {
    validateBelgianNationalNumber(number) {
      if (!number) {
        return false
      }
      number = number.replace(/[^0-9]/g, '').substring(0, 11)

      if (!/\d{11}/.test(number)) {
        return false
      }

      let to_control = number.substring(0, 9)
      let control = number.substring(9, 11)

      let date = number.substring(0, 6)
      let date_year = parseInt(date.substring(0, 2), 10)
      let date_month = parseInt(date.substring(2, 4), 10)
      let date_day = parseInt(date.substring(4, 6), 10)
      let today_year = parseInt(
        new Date().getFullYear().toString().substring(2, 4),
        10
      )

      if (date_month < 1 || date_month > 12 || date_day < 1 || date_day > 31) {
        return false
      }

      // apres l'an 2000, le check se fait avec un 2 devant
      if (date_year < today_year) {
        to_control = '2' + to_control.toString()
      }

      to_control = parseInt(to_control, 10)
      let modulo = 97 - (to_control % 97)
      modulo = Math.floor(modulo)
      modulo = modulo < 10 ? '0' + modulo : '' + modulo

      return control === modulo
    }
  }
}
</script>
