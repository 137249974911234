import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      chunkSize: 20,
      page: 1,
      totalPages: 0,
      entriesLength: 0
    }
  },
  computed: {
    ...mapGetters({ initialMetadata: 'metadata/getInitialMetadata' })
  },
  methods: {
    handleChangePage(value) {
      if (value === 'up') {
        if (this.page < this.chunkedItems.length) {
          this.page = this.page + 1
        }
      } else if (value === 'down') {
        if (this.page > 1) {
          this.page = this.page - 1
        }
      } else {
        this.page = value
      }
    }
  }
}
