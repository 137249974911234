import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      funeralHomesList: [],
      funeralHome: {},
      funeralHomeActivities: [],
      metadataActivities: [],
      editionMode: false,
      metadata: { columns: [] }
    }
  },
  getters,
  actions,
  mutations
}
