export default {
  setContactList(state, contactList) {
    state.contactList = contactList
  },
  setWikiContactList(state, wikiContactList) {
    state.wikiContactList = wikiContactList
  },
  setContact(state, contact) {
    state.contact = contact
  }
}
