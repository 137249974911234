<template>
  <div v-if="label">
    <BaseEditLabel :label="label" :required="required" />
  </div>
  <div class="inline-flex rounded-md" role="group">
    <button
      v-for="(option, index) in options"
      :key="index"
      type="button"
      :class="[
        option[trackBy] === selectedValues
          ? 'cursor-default bg-gray-200 text-primary'
          : 'bg-white text-gray-900 hover:bg-gray-50',
        index === 0
          ? 'rounded-l-lg'
          : index === options.length - 1
          ? 'rounded-r-lg'
          : ''
      ]"
      class="border border-gray-200 px-4 py-2 text-sm font-medium capitalize-first focus:z-10"
      @click="() => (selectedValues = option[trackBy])"
    >
      <FontAwesomeIcon :icon="option.icon" />
    </button>
  </div>
</template>

<script>
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel'
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'WalkmanButtonIcon',
  components: { FontAwesomeIcon, BaseEditLabel },
  props: {
    modelValue: {
      type: [String, Boolean],
      default: null,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: 'value'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'label'
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value)
        emit('change', value)
      }
    })
    return { selectedValues }
  }
}
</script>
