<template>
  <div>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          as="div" class="cursor-pointer"
        >
          <FontAwesomeIcon :icon="['fas', 'face-smile']" class="text-gray-500" />
<!--          class="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"-->
        
        </MenuButton>
      </div>
      
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute right-1 bottom-6 mt-2 w-28 h-6 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-100 items-center"
        >
          <div class="flex justify-center gap-x-2 pt-1">
            <div
              class="flex justify-center cursor-pointer hover:bg-gray-200 rounded"
              v-for="(reaction, index) in initialMetadata?.enums?.comment_reaction"
              :key="index"
              @click="handleEmitReaction(reaction)"
            >
              <MenuItem>
                <span>{{ reaction }}</span>
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>


<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { mapGetters } from "vuex";

export default {
  name: "ReactionOptionMenu",
  components: { Menu, MenuButton, MenuItems, MenuItem },
  emits: ['reactionSelection'],
  computed: {
    ...mapGetters({initialMetadata: 'metadata/getInitialMetadata'})
  },
  methods: {
    handleEmitReaction(reaction) {
      console.log('handleEmitReaction')
      this.$emit('reactionSelection', reaction)
    }
  },
}
</script>
