export default {
  getBankAccountList(state) {
    return state.bankAccountList
  },
  getBankAccount(state) {
    return state.bankAccount
  },
  getBankAccountMetadata(state) {
    return state.bankAccountMetadata
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getBankAccountActivity(state) {
    return state.bankAccountActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
}
