/* eslint-disable camelcase */
import moment from 'moment'

export function addCoffinArrivalIfDossierDoesntHave(state) {
  if (!state.dossier.events.filter((obj) => obj.type === 'CAR').length) {
    state.dossier.events.push({ type: 'CAR' })
  }
}

export function getArrivingBeforeValue(first_event, state) {
  const first_event_kind = state.dossierMetadata.event_kinds.filter(
    (item) => item.id == first_event.event_kind
  )[0]

  if (first_event_kind !== undefined) {
    return first_event_kind.arriving_before
  } else {
    return 20
    // todo settings default arriving_before
  }
}

export function setCoffinArrivingValues(state, index, first_event) {
  if (
    first_event.date_from !== undefined &&
    first_event.time_from !== undefined
  ) {
    state.dossier.events[index].date_from = first_event.date_from
    state.dossier.events[index].date_to = first_event.date_to
    state.dossier.events[index].time_to = first_event.time_from
    const duration = getArrivingBeforeValue(first_event, state)
    state.dossier.events[index].time_from = moment(
      first_event.date_from + ' ' + first_event.time_from,
      'DD/MM/YYYY hh:mm'
    )
      .subtract(duration, 'minutes')
      .format('HH:mm')
  }
}

export function getFirstEvent(state) {
  return state.dossier.events
    .sort(
      (a, b) =>
        Date.parse(
          moment(a.date_from + ' ' + a.time_from, 'DD/MM/YYYY HH:mm')
        ) -
        Date.parse(moment(b.date_from + ' ' + b.time_from, 'DD/MM/YYYY HH:mm'))
    )
    .filter((item) => item.type !== 'CAR')[0]
}

export function updateCoffinArrival(state) {
  sortEvents(state)
  const first_event = getFirstEvent(state)
  const index = state.dossier.events?.findIndex((obj) => obj.type === 'CAR')
  setCoffinArrivingValues(state, index, first_event)
}

export function deleteCoffinArrival(state) {
  sortEvents(state)
  state.dossier.events.splice(0, 1)
}

export function sortEvents(state) {
  state.dossier.events = state.dossier.events.sort(
    (a, b) =>
      Date.parse(moment(a.date_from + ' ' + a.time_from, 'DD/MM/YYYY HH:mm')) -
      Date.parse(moment(b.date_from + ' ' + b.time_from, 'DD/MM/YYYY HH:mm'))
  )
}
