export default {
  setAccountingDiaryList(state, accountingDiaryList) {
    state.accountingDiaryList = accountingDiaryList
  },
  setAccountingDiaryMetadata(state, accountingDiaryMetadata) {
    state.accountingDiaryMetadata = accountingDiaryMetadata
  },
  setAccountingDiary(state, accountingDiary) {
    state.accountingDiary = accountingDiary
  }
}
