<template>
  <main class="relative flex h-full max-h-full flex-1">
    <div
        class="h-full max-h-full flex-1 overflow-hidden rounded-lg bg-white shadow"
    >
      <div
          class="h-full max-h-full divide-y divide-gray-200 lg:flex lg:flex-row lg:divide-y-0 lg:divide-x"
      >
        <aside
            class="lg:2/12 h-full max-h-full overflow-scroll overflow-x-hidden py-6 scrollbar-thin scrollbar-track-white scrollbar-thumb-gray-300 md:w-3/12"
        >
          <settings-nav/>
        </aside>
        <div class="flex h-full max-h-full md:w-9/12 lg:w-10/12">
          <router-view/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {mapActions} from 'vuex'

import SettingsNav from './nav/TheSaManagementNav.vue'

export default {
  name: 'TheSaManagementPage',
  components: {
    SettingsNav
  },
  methods: {
    ...mapActions('sideMenu', ['collapse'])
  }
}
</script>
