<template>
  <div class="relative overflow-y-scroll border">
    <table class="w-full table-auto">
      <thead class="sticky top-0 bg-gray-100">
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
          >
            {{ $t('attributes.name') }}
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
          >
            {{ $t('attributes.address') }}
          </th>
          <th
            scope="col"
            class="relative py-3.5 pl-3 pr-4 capitalize-first sm:pr-6"
          >
            <span class="sr-only">{{ $t('button.see') }}</span>
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <template v-for="invoiceable in invoiceableList" :key="invoiceable.id">
          <InvoiecableRow
            :invoiceable="invoiceable"
            @selection="$emit('selected-invoiceable', invoiceable)"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import InvoiecableRow from './InvoiceableRow.vue'

export default defineComponent({
  name: 'InvoiceablesTable',
  components: { InvoiecableRow },
  props: {
    invoiceableList: {
      type: Array,
      required: true
    },
    selectedInvoiceable: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  emits: ['update:selectedInvoiceable', 'selected-invoiceable'],
  computed: {
    localSelectedInvoiceable: {
      get() {
        return this.selectedInvoiceable
      },
      set(value) {
        this.$emit('update:selectedInvoiceable', value)
      }
    }
  }
})
</script>
