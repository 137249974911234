<template>
  <div class="min-w-0 flex-1">
    <SettingsPageFull>
      <template #cta>
        <BaseButton
          v-if="$can('manage_content')"
          :title="$t('button.add')"
          color="primary"
          icon="plus"
          @click="addDossierNumbering"
        />
      </template>

      <template #title>
        <div class="flex space-x-3">
          <span class="text-3xl font-bold capitalize-first">
            {{ $t('global.numberings') }}
          </span>
        </div>
      </template>

      <template #body>
        <BaseTableCardsComponent
          v-if="dossierNumberings && metadata"
          :entries="dossierNumberings"
          :actions="cActions"
          :storage-name="'dt-accounting-diary-index'"
          :select-entry="(dossier_numbering) => console.log(dossier_numbering)"
          :table-metadata="metadata"
          base-card-title="name"
        >
          <template #table-cell="{ index, data: dossier_numbering, column }">
            <td
              v-if="index === 0"
              class="sticky top-0 z-10 border-b border-gray-200 bg-gray-50 bg-opacity-75 text-left text-sm font-semibold text-gray-900"
              :class="index === 0 ? 'py-3.5 pl-4 pr-3 sm:pl-6' : 'px-3 py-3.5'"
            >
              <div class="flex flex-row items-center gap-2">
                <div>
                  {{ dossier_numbering.name }}
                </div>
              </div>
            </td>
          </template>
        </BaseTableCardsComponent>
      </template>
    </SettingsPageFull>
  </div>
  <DossierNumberingModal
    v-model:opened="modalOpened"
    :dossier-numbering-id="selected_numbering_id"
    @need-refresh="loadDossierNumberingList"
  />
</template>

<script>
import PageSection from '@c/PageSection.vue'
import SectionTitle from '@c/SectionTitle.vue'
import BaseTableCardsComponent from '@c/BaseTableCardsComponent'
import TranslationComponent from '@c/addf-package/components/translation/TranslationComponent'
import { mapActions, mapGetters } from 'vuex'
import AccountingDiaryModal from '@ui/modal/accountingDiary/AccountingDiaryModal.vue'
import SettingsPageFull from '@c/SettingsPageFull.vue'
import DossierNumberingModal from '@ui/modal/dossierNumbering/DossierNumberingModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheDossierNumberingIndexPage',
  components: {
    BaseButton,
    DossierNumberingModal,
    SettingsPageFull,
    AccountingDiaryModal,
    SectionTitle,
    PageSection,
    BaseTableCardsComponent,
    TranslationComponent
  },
  data() {
    return {
      modalOpened: false,
      dossierNumberings: [],
      metadata: null,
      loading: false,
      selected_numbering_id: null
    }
  },
  computed: {
    ...mapGetters({}),
    cActions() {
      return [
        {
          label: this.$t('button.edit'),
          icon: 'edit',
          bgColor: 'bg-gradient-to-b from-gray-100 to-gray-200',
          fgColor: 'text-gray-700',
          clickFunction: (dossier_numbering) =>
            this.editDossierNumbering(dossier_numbering.id)
        },
        {
          label: this.$t('button.delete'),
          icon: 'trash',
          bgColor: 'bg-gradient-to-b from-red-600 to-red-700',
          fgColor: 'text-white',
          clickFunction: (dossier_numbering) =>
            this.deleteDossierNumbering(dossier_numbering.id)
        }
      ]
    }
  },
  mounted() {
    this.loadDossierNumberingList()
  },
  methods: {
    ...mapActions({
      processFetchDossierNumberingsListAction:
        'dossierNumbering/fetchDossierNumberingsList',
      processDeleteDossierNumberingAction:
        'dossierNumbering/deleteDossierNumbering',
      processUndoDeleteDossierNumberingAction:
        'dossierNumbering/undoDeleteDossierNumbering'
    }),
    loadDossierNumberingList() {
      this.loading = true
      this.processFetchDossierNumberingsListAction()
        .then((response) => {
          this.dossierNumberings = response.dossier_numberings
          this.metadata = response.metadata
        })
        .finally((_) => {
          this.loading = false
        })
    },
    addDossierNumbering() {
      this.selected_numbering_id = null
      this.modalOpened = true
    },
    editDossierNumbering(selected_numbering_id) {
      this.selected_numbering_id = selected_numbering_id
      this.modalOpened = true
    },
    deleteDossierNumbering(dossier_numbering_id) {
      this.processDeleteDossierNumberingAction({
        dossier_numbering_id: dossier_numbering_id
      }).then((response) => {
        this.loadDossierNumberingList()
        this.undoDeleteDossierNumbering(dossier_numbering_id)
      })
    },
    undoDeleteDossierNumbering(dossier_numbering_id) {
      this.$h.toastAction(
        'delete',
        this.$t('toasts.deleted_m', { var: this.$t('global.numbering') }),
        null,
        [
          {
            title: this.$t('button.cancel'),
            class: 'text-red-700',
            action: () => {
              this.processUndoDeleteDossierNumberingAction({
                dossier_numbering_id: dossier_numbering_id
              }).then((response) => {
                this.loadDossierNumberingList()
                this.$h.toastSuccess(
                  this.$t('toasts.reseted_m', {
                    var: this.$t('global.numbering')
                  })
                )
              })
            }
          }
        ]
      )
    }
  }
}
</script>
