import cityMutations from './city/mutations'
import placeMutations from './place/mutations'
import itemMutations from './items/mutations'
import contactMutations from './contact/mutations'

export default {
  ...cityMutations,
  ...placeMutations,
  ...itemMutations,
  ...contactMutations
}
