import { Partners } from '@/assets/enums'

import enaos from '../../images/partners/enaos.svg'
import yousign from '../../images/partners/yousign.svg'
import fragment from '../../images/partners/fragment_mini.png'
import funeup from '../../images/partners/funeup.svg'
import cremagest from '../../images/partners/cremagest.svg'
import sorenir from '../../images/partners/sorenir.svg'
import legacio from '../../images/partners/legacio.svg'

const PartnersLogo = Object.freeze({
  [Partners.ENAOS]: enaos,
  [Partners.FRAGMENT]: fragment,
  [Partners.FUNE_UP]: funeup,
  [Partners.YOUSIGN]: yousign,
  [Partners.CREMAGEST]: cremagest,
  [Partners.SORENIR]: sorenir,
  [Partners.LEGACIO]: legacio
})

export default PartnersLogo
