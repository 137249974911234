<template>
  <Menu as="div" class="relative z-10 inline-block text-left">
    <div>
      <MenuButton class="z-50 block h-5 w-5">
        <!--        <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300 z-40"/>-->
        <FontAwesomeIcon
          :icon="['fal', icon]"
          :class="`h-5 w-5 text-${iconColor} z-20`"
          :size="iconSize"
        />
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="z-200 absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 overflow-visible rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="z-100 px-1 py-1">
          <template v-for="option in options" :key="option">
            <MenuItem v-slot="{ active }">
              <div
                :class="[
                  active
                    ? 'bg-white text-theme-primary'
                    : 'bg-white text-black',
                  'elect-none group z-100 flex w-full cursor-pointer items-center rounded-md px-2 py-2 text-sm'
                ]"
                @click="() => option.clickFunction(entry)"
              >
                <div class="flex">
                  <FontAwesomeIcon
                    :icon="['fal', option.icon]"
                    class="mr-2 h-4 w-4"
                  ></FontAwesomeIcon>
                  {{ option.label }}
                </div>
              </div>
            </MenuItem>
          </template>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'DotMenu',
  components: { FontAwesomeIcon, Menu, MenuButton, MenuItems, MenuItem },
  props: {
    entry: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: 'ellipsis-vertical'
    },
    iconColor: {
      type: String,
      required: false,
      default: 'gray-600'
    },
    iconSize: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
