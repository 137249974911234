const WikiCityCrematoriumInformation = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/crematorium/form/tabs/information/WikiCityCrematoriumInformation.vue'
  )
const WikiCityCrematoriumFormsTabsComponent = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/crematorium/form/WikiCityCrematoriumFormsTabsComponent.vue'
  )
const WikiCityCrematoriumIndexPage = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/crematorium/index/WikiCityCrematoriumIndexPage.vue'
  )
const WikiPlaceItemsTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceItemsTab.vue'
  )
const WikiPlaceTranslationTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceTranslationTab.vue'
  )
const WikiPlaceContactTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceContactTab.vue'
  )

const WikiPlaceDocumentsTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceDocumentsTab.vue'
    )

export const wikiCityCrematoriumRoutes = [
  {
    path: 'crematorium',
    children: [
      {
        path: '',
        name: 'wiki_edit_city_crematorium_page_route',
        component: WikiCityCrematoriumIndexPage,
        props: true,
        meta: {
          pre_page: '',
          next_page: ''
        }
      },
      {
        path: ':crematorium_id',
        name: 'wiki_city_edit_crematorium_page_route',
        component: WikiCityCrematoriumFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_crematorium_information_page_route',
            component: WikiCityCrematoriumInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_edit_crematorium_translation_page_route',
            component: WikiPlaceTranslationTab
          },
          {
            path: 'document',
            name: 'wiki_city_edit_crematorium_documents_page_route',
            component: WikiPlaceDocumentsTab
          },
          {
            path: 'contact',
            name: 'wiki_city_edit_crematorium_contact_page_route',
            component: WikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_edit_crematorium_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_crematorium_page_route',
        component: WikiCityCrematoriumFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_create_crematorium_information_page_route',
            component: WikiCityCrematoriumInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_create_crematorium_translation_page_route',
            component: WikiPlaceTranslationTab
          },
          {
            path: 'contact',
            name: 'wiki_city_create_crematorium_contact_page_route',
            component: WikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_create_crematorium_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
