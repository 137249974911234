const SPECIAL_CHARS = [
  //        " ", ".", "'", "\"", "/", "$",
  //        "!", "(", ")", "#", "&", "?",
  'à',
  'â',
  'ä',
  'á',
  'ã',
  'å',
  'î',
  'ï',
  'ì',
  'í',
  'ô',
  'ö',
  'ò',
  'ó',
  'õ',
  'ø',
  'ù',
  'û',
  'ü',
  'ú',
  'é',
  'è',
  'ê',
  'ë',
  'ç',
  'ÿ',
  'ñ',
  'Â',
  'Ê',
  'Î',
  'Ô',
  'Û',
  'Ä',
  'Ë',
  'Ï',
  'Ö',
  'Ü',
  'À',
  'Æ',
  'æ',
  'Ç',
  'É',
  'È',
  'Œ',
  'œ',
  'Ù'
]
const REPLACE_CHARS = [
  //    "-", "-", "-", "-", "-", "-",
  //    "-", "-", "-", "-", "-", "-",
  'a',
  'a',
  'a',
  'a',
  'a',
  'a',
  'i',
  'i',
  'i',
  'i',
  'o',
  'o',
  'o',
  'o',
  'o',
  'o',
  'u',
  'u',
  'u',
  'u',
  'e',
  'e',
  'e',
  'e',
  'c',
  'y',
  'n',
  'A',
  'E',
  'I',
  'O',
  'U',
  'A',
  'E',
  'I',
  'O',
  'U',
  'A',
  'AE',
  'ae',
  'C',
  'E',
  'E',
  'OE',
  'oe',
  'U'
]


export default {
  convertToTitleCase(str) {
    if (!str) {
      return ''
    }
    return str
      .toLowerCase()
      .replace(/(^|\s)[a-z\u00E0-\u00FC]/g, (s) => s.toUpperCase())
  },

  removeAccent(value) {
    if (typeof value === 'string') {
      return value.replace(
        new RegExp(SPECIAL_CHARS.join('|'), 'g'),
        (str) => REPLACE_CHARS[SPECIAL_CHARS.indexOf(str)]
      )
    }

    return value
  },

  toSlug(value, concat = '-') {
    if (typeof value === 'string') {
      value = value.toLowerCase().trim()
      value = this.removeAccent(value)
      const match = value.match(/[a-z0-9]+/gi)
      if (match) {
        value = match.join(' ')
      }
      value = value.trim()
      return value.replace(/( )+/gi, concat)
    }

    return value
  },

  toFormRequestName(value) {
    return this.toSlug(value, '_').toUpperCase()
  },

  /**
   * @param {string} color
   * @param {number} alpha
   * @returns {{red: number, green: number, blue: number, alpha: number} | null}
   */
  hexColorToRgb(color, alpha = 100) {
    if (color?.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)) {
      return {
        red: parseInt(color.slice(1, 3), 16),
        green: parseInt(color.slice(3, 5), 16),
        blue: parseInt(color.slice(5, 7), 16),
        alpha: alpha,
      }
    }

    return null
  }
}
