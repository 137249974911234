<template class="overflow-y-scroll">
  <div
    v-if="supplier && initialMetadata"
    :class="[
      wide ? 'grid grid-cols-1 gap-x-3 md:grid-cols-2' : 'grid grid-cols-1'
    ]"
  >
    <BaseShowEditInput
      v-model="supplier.name"
      :label="$t('attributes.name')"
      :placeholder="$t('attributes.name')"
      name="name"
      :errors="errors.name"
      :required="true"
      :edition-mode="true"
    />
    <BaseShowEditInput
      v-model="supplier.tva"
      :label="$t('attributes.TVA')"
      :placeholder="$t('attributes.TVA')"
      name="tva"
      :errors="errors.tva"
      :required="true"
      :edition-mode="true"
    />
    <BaseShowEditInput
      v-model="supplier.street"
      :label="$t('attributes.road')"
      :placeholder="$t('attributes.road')"
      :edition-mode="true"
      :errors="errors.street"
      name="road"
    />

    <BaseShowEditSelectAjax
      v-if="
        [Country.BELGIUM, Country.LUXEMBOURG, Country.FRANCE].includes(
          supplier.country
        )
      "
      :key="cCity"
      v-model="wiki_city"
      :edition-mode="true"
      url="city-for-select"
      :label="$t('attributes.city')"
      name="city"
      attribute-label="full_name"
      :full-model-response="true"
      :default-ajax="cCity"
      :errors="cityErrors"
      @change="getInputValue"
    />
    <div v-else class="grid grid-cols-1 gap-x-3 md:grid-cols-2">
      <BaseShowEditInput
        v-model="supplier.zip"
        :label="$t('attributes.zip')"
        name="zip"
        :errors="errors.zip"
        :placeholder="$t('attributes.zip')"
        :edition-mode="true"
      />

      <BaseShowEditInput
        v-model="supplier.city"
        :label="$t('attributes.city')"
        name="city"
        :errors="errors.city"
        :placeholder="$t('attributes.city')"
        :edition-mode="true"
      />
    </div>
    <BaseCountryShowEditSelectSimple
      v-model="supplier.country"
      :metadata="initialMetadata.enums"
      :edition-mode="true"
    />
    <BaseLanguageShowEditSelectSimple
      v-model="supplier.language"
      :metadata="initialMetadata.enums"
      :with-available-languages="true"
      :edition-mode="true"
    />
    <BaseShowEditInput
      v-model="supplier.email"
      :label="$t('attributes.email')"
      :placeholder="$t('attributes.email')"
      :edition-mode="true"
      :errors="errors.email"
      name="email"
    />
    <BaseShowEditPhoneInput
      v-model:countryValue="supplier.phone_country"
      v-model:numberValue="supplier.phone_field"
      :label="$t('attributes.phone')"
      :edition-mode="true"
      name="phone"
      :metadata="initialMetadata.enums"
      :errors="errors"
    />
    <BaseShowEditPhoneInput
      v-model:countryValue="supplier.mobile_country"
      v-model:numberValue="supplier.mobile_field"
      :label="$t('attributes.mobile')"
      :edition-mode="true"
      name="mobile"
      :metadata="initialMetadata.enums"
      :errors="errors"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Country } from '@/assets/enums'

export default {
  name: 'SupplierForm',
  props: {
    supplier: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    wide: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      wiki_city: null,
      Country: Country
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    cCity() {
      const fullName = this.supplier?.zip
        ? this.supplier?.zip + ' ' + this.supplier?.city
        : ''
      return {
        id: this.supplier?.city_id,
        full_name: fullName
      }
    },
    cityErrors() {
      let tmp = []
      if (this.errors?.city) tmp.push(...this.errors.city)
      if (this.errors?.zip) tmp.push(...this.errors.zip)
      return tmp
    }
  },
  methods: {
    getInputValue() {
      console.log('wikicity', this.wiki_city)
      this.supplier.city = this.wiki_city.city
      this.supplier.zip = this.wiki_city.zip
      this.supplier.city_id = this.wiki_city.id
    }
  }
}
</script>
