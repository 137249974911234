<template>
  <div class="flex h-full flex-row">
    <div class="flex w-72 flex-shrink-0 flex-col gap-y-4">
      <TheNewInvoicingNav />
    </div>
    <div class="mx-2 flex min-w-0 flex-1 flex-col gap-y-3">
      <div class="box flex h-full flex-col gap-3 p-5 pt-4">
        <div class="flex shrink-0 flex-col">
          <h2 class="text-2xl font-bold">Export</h2>
          <div class="flex flex-row gap-2">
            <button
              class="rounded border px-2 py-1 text-left text-base font-semibold capitalize-first"
              :class="[
                view === 'accounting_diaries'
                  ? 'bg-primary text-white'
                  : 'bg-slate-300 text-black'
              ]"
              @click="view = 'accounting_diaries'"
            >
              {{ $t('navigation.content_accounting_diaries_title') }}
            </button>
            <button
              class="rounded border px-2 py-1 text-left text-base font-semibold capitalize-first"
              :class="[
                view === 'treasury'
                  ? 'bg-primary text-white'
                  : 'bg-slate-300 text-black'
              ]"
              @click="view = 'treasury'"
            >
              {{ $t('attributes.treasury') }}
            </button>
            <button
              class="rounded border px-2 py-1 text-left text-base font-semibold capitalize-first"
              :class="[
                view === 'documents'
                  ? 'bg-primary text-white'
                  : 'bg-slate-300 text-black'
              ]"
              @click="view = 'documents'"
            >
              {{ $t('global.documents') }}
            </button>
          </div>
        </div>
        <div class="min-h-0 flex-1">
          <ExportAccountingDiaries v-if="view === 'accounting_diaries'" />
          <ExportTreasury v-if="view === 'treasury'" />
          <ExportDocuments v-if="view === 'documents'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheNewInvoicingNav from '@/views/invoicing/components/TheNewInvoicingNav.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import BaseShowEditCheckBoxSimple from '@c/BaseShowEditCheckBoxSimple.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'
import ExportAccountingDiaries from '@/views/invoicing/index/accountingExport/component/ExportAccountingDiaries.vue'
import ExportTreasury from '@/views/invoicing/index/accountingExport/component/ExportTreasury.vue'
import ExportDocuments from '@/views/invoicing/index/accountingExport/component/ExportDocuments.vue'

export default {
  name: 'TheAccountingExportPage',
  components: {
    ExportDocuments,
    ExportTreasury,
    ExportAccountingDiaries,
    BaseCheckBox,
    BaseShowEditCheckBoxSimple,
    BaseShowEditDatePickerv2,
    BaseButton,
    TheNewInvoicingNav
  },
  data() {
    return {
      view: 'accounting_diaries'
    }
  }
}
</script>
