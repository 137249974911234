const Languages = Object.freeze({
  FRENCH_BELGIUM: 'fr_BE',
  FRENCH_FRANCE: 'fr_FR',
  FRENCH_LUXEMBURG: 'fr_LU',
  FRENCH_SWITZERLAND: 'fr_CH',

  NEDERLANDS_BELGIUM: 'nl_BE',
  NEDERLANDS_NEDERLAND: 'nl_NL',

  ENGLISH_UK: 'en_UK',

  GERMAN_BELGIUM: 'de_BE',
  GERMAN_DEUTSCHLAND: 'de_DE',
  GERMAN_SWITZERLAND: 'de_CH',

  ITALIAN_ITALY: 'it_IT',
  ITALIAN_SWITZERLAND: 'it_CH',

  LUXEMBOURGISH: 'lb',

  SPANISH_SPAIN: 'sp_SP',
  PORTUGUESE_PORTUGAL: 'pt_PT',
  ROMANIA: 'ro_RO'
})

export default Languages
