<template >
  <div v-if="editionMode" :class="groupClass">
    <div >
      <BaseEditLabel
        v-if="!dontDisplayLabel"
        :label="label ? label : $t('attributes.site')"
        :required="required"
      />
      <multiselect
        v-if="metadata?.sites"
        v-model="cDefaultValue"
        :label="attributeLabel"
        :options="metadata.sites"
        :placeholder="placeholder"
        :required="required"
        :searchable="searchable"
        :track-by="trackBy"
        :select-label="$t('button.select')"
        :deselect-label="$t('button.unselect')"
        :selected-label="$t('button.selected')"
        @update:model-value="updateValueAction"
      />
    </div >
    <template v-if="errors?.site_id">
      <div
        v-for="(error, index) in errors?.site_id"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div >
    </template >
    <template v-else>
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div >
    </template >
  </div >
  <div v-else class="flex flex-col">
    <BaseShowLabel
      :display-only-label="true"
      :label="label ? label : $t('attributes.site')"
      :required="required"
    />
    <div v-if="metadata && metadata.sites && modelValue">
      <span class="mr-3 inline-block">
        <font-awesome-icon
          :icon="['fal', 'check-square']"
          class="mr-1 mt-2 text-blue-900"
        />
        {{ cDefaultValue?.name }}
      </span >
    </div >
    <template v-else>
      {{ '-' }}
    </template >
  </div >
</template >

<script >
import multiselect from 'vue-multiselect'

export default {
  name: 'BaseSiteShowEditSelectSimple',
  components: { multiselect },
  props: {
    name: {
      type: String,
      required: false,
      default: 'site_id'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    metadata: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    dontDisplayLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    selectClass: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    errorType: {
      type: String,
      required: false,
      default: null
    },
    fullModelResponse: { type: Boolean, required: false, default: false }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    cDefaultValue() {
      return (
        this.metadata?.sites?.find((option) => option.id === this.modelValue) ?? null
      )
    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue) {
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
        }
        else {
          this.$emit('update:modelValue', newValue[this.trackBy])
        }
        this.$emit('change', newValue)
      }
      else {
        this.$emit('update:modelValue', null)
        this.$emit('change', null)
      }
    }
  }
}
</script >
