<template>
  <template v-if="!city">
    <LoadingComponent/>
  </template>
  <template v-else>
    <BaseWikiCityPageLayout
      :title="pageTitle"
    >
      <template #cta>
        <BaseButton
          :title="$t('button.back')"
          color="dark"
          icon="long-arrow-left"
          @click="handleRedirectBackButton"
        />
      </template>
      <TabContentFormComponent
        v-if="$route.meta?.type"
        :edition-mode="$route.meta.type === 'edit'"
        :with-footer="false"
        :in-a-scrollable-page="true"
        :tabs="tabs"
      />
      <template #footer>
        <BaseButton
          :title="mainButtonTitle"
          :icon="mainButtonIcon"
          @click="submitForm"
        />
      </template>
    </BaseWikiCityPageLayout>
  </template>
</template>

<script>
import {mapGetters} from "vuex";
import BaseWikiCityFormsTabsMixin from "@u/mixins/wiki/BaseWikiCityFormsTabsMixin";
import TabContentFormComponent from "@c/TabContentFormComponent.vue";
import LoadingComponent from "@c/TheLoadingComponent.vue";

export default {
  name: "WikiCityFormTabComponent",
  components: {LoadingComponent, TabContentFormComponent},
  mixins: [BaseWikiCityFormsTabsMixin],
  data() {
    return {
      formSubmitted: false,
    }
  },
  computed: {
    ...mapGetters({
      city: "wiki/getCity",
    }),
    tabs() {
      return [
        {
          label: this.$t('global.informations'),
          icon: 'info-square',
          routeName: `${this.routeType}_wiki_city_information_page_route`,
          gate: true,
          field_list: []
        },
        {
          label: this.$t('attributes.city'),
          icon: 'city',
          routeName: `${this.routeType}_wiki_city_cities_page_route`,
          gate: true,
          field_list: []
        },
      ]
    },
    pageTitle() {
      if (this.city.id) {
        return this.city.name[this.initialMetadata?.settings?.default_language]
      } else {
        return this.$t('button.add_new_wiki_city')
      }
    },
  },
  async beforeMount() {
    if (this.isInEditMode) {
      await this.processFetchCityByIdAction({city_id: this.$route.params.city_id})
    } else {
      await this.processFetchNewCityAction()
    }
  },
  methods: {
    submitForm() {
      if (!this.formIsComplete()) {
        return
      }

      if (this.isInEditMode) {
        this.processUpdateCityAction({
          city_id: this.$route.params.city_id,
          city: this.city,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.wiki_city_updated'), {className: 'toastify-content success'})
            this.handleRedirectBackButton()
          }
        })
      } else {
        this.processStoreCityAction({
          city: this.city,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.wiki_city_created'), {className: 'toastify-content success'})
            this.handleRedirectBackButton()
          }
        })
      }
    },
    formIsComplete() {
      return !!this.city?.name &&
        !!this.city?.country &&
        !!this.city?.zip &&
        !!this.city?.latitude &&
        !!this.city?.longitude
    },
    handleRedirectBackButton() {
      if (this.isInEditMode) {
        this.$router.push({
          name: 'wiki_city_municipal_administration_index_page_route',
          params: {id: this.$route.params.city_id}
        })
      } else {
        this.$router.push({name: 'wiki_city_page_route'})
      }
    }
  }
}
</script>
