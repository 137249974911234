<template>
  <template v-if="editionMode">
    <div>
      <BaseEditLabel
        v-if="label"
        class="mb-1"
        :label="label"
        :required="required"
      />
      <div :class="cPlacement">
        <div v-for="(option, index) in options" :key="index">
          <div class="flex items-center gap-x-2">
            <input
              :id="`checkbox-${option.name}`"
              v-model="internalValue"
              type="checkbox"
              :value="option.id"
              class="mt-2 h-4 w-4 cursor-pointer rounded border-slate-300 text-blue-300 ring-0 focus:ring-0"
            />
            <BaseEditLabel class="mt-2" :label="option.name" />
          </div>
        </div>
        <div
          v-for="(error, index) in errors"
          :key="index"
          class="form-help text-red-600"
        >
          {{ $t(error, { attribute: $t('attributes.' + name) }) }}
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <template v-if="options.length > 0">
      <BaseEditLabel
        v-if="modelValue.length > 0"
        :label="label"
        :required="required"
      />
      <br />
      <span
        v-for="(item, index) in modelValue"
        :key="index"
        class="mr-3 inline-block"
      >
        <labelWitchCheckBoxChecked
          v-if="getElement(item)"
          :element="getElement(item)"
          :translatable="false"
        />
      </span>
    </template>
    <template v-else>
      <span>-</span>
    </template>
  </template>
</template>

<script>
export default {
  name: 'BaseShowEditCheckBoxList',
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    formDisplayClass: {
      type: String,
      required: false,
      default: 'grid grid-cols-1 md:grid-cols-3'
    },
    isVertical: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    },
    cPlacement() {
      return !this.isVertical
        ? 'flex flex-row gap-x-2 items-center'
        : 'flex flex-col mt-2'
    }
  },
  mounted() {
    if(!Array.isArray(this.modelValue)) this.$emit('update:modelValue', []);
  },
  methods: {
    getElement(item) {
      if (!this.options) return null
      return this.options?.find((option) => option.id === item)
    }
  }
}
</script>
