<template>
  <div v-if="editionMode">
    <SelectComponent
      v-model="selectedValues"
      :name="name"
      :values="this.companies"
      :label="label ?? $t('attributes.company')"
      display-key="name"
      :multiple="multiple"
      :favorite="favoriteAsArray"
      :required="required"
      :edition-mode="editionMode"
      :alphabetical-order="true"
    />
    <ErrorContainer :error-key="errorKey" :errors="errors" />
  </div>
  <BaseShowLabel
    v-else
    :label="label ?? $t('attributes.company')"
    :model-value="selectedLabel"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from 'vue'
import MultiSelectComponent from '@c/BaseFormComponent/MultiSelectComponent'
import SelectComponent from '@c/BaseFormComponent/SelectComponent'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'SelectCompany',
  components: { ErrorContainer, SelectComponent },
  props: {
    modelValue: {
      type: [Array, Number],
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    errorKey: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return { selectedValues }
  },
  computed: {
    ...mapGetters('metadata', { companies: 'getCompaniesMetadata' }),
    favoriteAsArray() {
      return this.companies
        .filter((company) => company.favorite === 1)
        .map((company) => company.id)
    },
    selectedLabel() {
      return this.companies.find((company) => company.id === this.modelValue)
        ?.name
    },
    orderItems() {
      return this.companies.sort((a, b) => a.name.localeCompare(b.name))
    }
  }
}
</script>
