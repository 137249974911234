const TheListItemIndexPage = () =>
  import('@/views/settings/management/ListItem/index/TheListItemIndexPage')

export const settingsListItemRoutes = [
  {
    path: 'list-item',
    name: 'settings_list_items_route',
    component: TheListItemIndexPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_item_title', active: true }
      ]
    }
  }
]
