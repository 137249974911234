<template>
  <!-- class='overflow-x-auto' -->
  <div>
    <table class="table">
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="whitespace-nowrap border-b-2 dark:border-slate-600"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ActivityTableRow
          v-for="(activity, index) in activities"
          :key="index"
          :activity="activity"
          :index-value="index"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import ActivityTableRow from './ActivityTableRow.vue'
export default {
  name: 'ActivityTable',
  components: { ActivityTableRow },
  props: { activities: { type: Object, required: true } },
  data() {
    return {
      headers: [
        '',
        this.$t('attributes.user'),
        this.$t('attributes.event'),
        this.$t('attributes.date')
      ]
    }
  }
}
</script>
