<template>
  <Popover v-if="openai_enabled" v-slot="{ open }" data-tint="openai">
    <Float
      portal
      :offset="5"
      :auto-placement="{
        autoAlignment: true,
        allowedPlacements: ['bottom-end', 'top-end']
      }"
    >
      <PopoverButton
        :class="open ? '' : 'text-opacity-90'"
        class="rounded border border-gray-300 bg-primary-600 px-2 py-1 leading-none text-white outline-none"
      >
        <FontAwesomeIcon :icon="['fas', 'brain-circuit']" />
      </PopoverButton>

      <PopoverPanel
        v-slot="{ close }"
        class="z-10 mt-1 w-screen max-w-sm transform px-4 sm:max-w-xl sm:px-0"
      >
        <div
          data-tint="openai"
          class="flex h-96 flex-col gap-1 rounded-md border bg-white p-2 shadow-2xl"
        >
          <div class="flex shrink-0 flex-row">
            <h2 class="text-xl font-bold">
              <FontAwesomeIcon
                :icon="['fas', 'brain-circuit']"
                class="text-violet-700"
              />
              Funeral Manager AI Assistant
            </h2>
          </div>
          <div class="flex min-h-0 flex-1 flex-col justify-between">
            <div class="flex flex-col gap-2">
              <div>
                <div class="flex shrink-0 flex-row">
                  <h2 class="text-base font-semibold">Messages</h2>
                </div>
                <WalkmanButton
                  v-model="message_group"
                  :options="messageGroupOptions"
                  size="extra-small"
                />
                <div class="mt-2 grid flex-1 grid-cols-2 gap-2">
                  <button
                    v-for="email_message_type in messageGroupChoices"
                    :key="email_message_type.id"
                    class="rounded-md px-1.5 py-1 text-left text-xs"
                    :class="[
                      email_message_type.id === message_type
                        ? 'bg-primary-700 text-white'
                        : 'bg-primary-200 text-slate-700'
                    ]"
                    @click="() => (message_type = email_message_type.id)"
                  >
                    {{ email_message_type.label }}
                  </button>
                </div>
              </div>
              <div>
                <div class="flex shrink-0 flex-row">
                  <h2 class="text-base font-semibold">Ton</h2>
                </div>
                <div>
                  <WalkmanButton
                    v-model="tone"
                    :options="toneOptions"
                    size="extra-small"
                  />
                </div>
              </div>
            </div>
            <div>
              <SelectLanguages v-model="language" edition-mode required />
            </div>
          </div>
          <div class="flex shrink-0 flex-row justify-end">
            <BaseButton
              icon="paper-plane"
              :title="$t('button.send')"
              :disabled="loading"
              :display-loader="loading"
              size="extra-small"
              @click="() => fetchMessage(close)"
            />
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapGetters } from 'vuex'
import { Float } from '@headlessui-float/vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'

export default {
  name: 'OpenAIPopover',
  components: {
    SelectLanguages,
    BaseButton,
    WalkmanButton,
    Float,
    Popover,
    PopoverPanel,
    FontAwesomeIcon,
    PopoverButton
  },
  props: {
    modelNotification: {
      type: Object,
      required: true
    }
  },
  emits: ['update-message'],
  data() {
    return {
      tone: 'empathique',
      message_group: null,
      message_type: null,
      language: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      openai_enabled: 'metadata/getOpenAIEnabled',
      email_messages_type: 'metadata/getOpenAiEmailMessagesType'
    }),
    messageGroupOptions() {
      return this.email_messages_type.map((item) => {
        return {
          value: item.id,
          label: item.label
        }
      })
    },
    messageGroupChoices() {
      return (
        this.email_messages_type.find((item) => item.id === this.message_group)
          ?.values ?? []
      )
    },
    toneOptions() {
      return [
        {
          value: 'empathique',
          label: 'empathique'
        },
        {
          value: 'ferme',
          label: 'ferme'
        }
      ]
    }
  },
  mounted() {
    this.language = this.$i18n.locale
    this.message_group = this.email_messages_type[0]?.id
  },
  methods: {
    ...mapActions({
      processFetchMessageOpenAIAction: 'modelNotification/fetchMessageOpenAI'
    }),
    fetchMessage(close) {
      this.loading = true
      this.processFetchMessageOpenAIAction({
        message_type: this.message_type,
        model_notification_id: this.modelNotification.id,
        tone: this.tone,
        language: this.language
      })
        .then((response) => {
          this.$emit('update-message', response.message)
          close()
        })
        .finally((_) => {
          this.loading = false
        })
    }
  }
}
</script>
