<template>
  <button
    class="flex flex-col rounded border p-2 pt-1.5 text-left"
    :class="[
      selected ? 'border-primary shadow shadow-primary' : 'border-slate-300'
    ]"
  >
    <span class="font-semibold">{{ accountDiary.name }}</span>
    <span class="text-xs">
      {{ $h.formatDate(accountDiary.from, 'DD MMM YYYY') }} >
      {{ $h.formatDate(accountDiary.to, 'DD MMM YYYY') }}
    </span>
    <span class="flex flex-row gap-2">
      <span
        class="block rounded bg-green-500 px-1 text-center text-xs text-white"
      >
        {{ nbExported }}
      </span>
      <span
        class="block rounded bg-orange-500 px-1 text-center text-xs text-white"
      >
        {{ nbNonExported }}
      </span>
    </span>
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SimpleTooltip from '@ui/SimpleTooltip.vue'

export default {
  name: 'AccountDiaryButton',
  components: { SimpleTooltip, FontAwesomeIcon },
  props: {
    accountDiary: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    nbExported() {
      return this.accountDiary?.invoices.filter(
        (invoice) => invoice.exported === 1
      )?.length
    },
    nbNonExported() {
      return this.accountDiary?.invoices.filter(
        (invoice) => invoice.exported === 0
      )?.length
    }
  }
}
</script>
