<template>
  <div v-if="editionMode" :class="cGroupClass" class="">
    <BaseEditLabel :label="label" :required="required" />
    <input
      :id="name"
      v-mask="mask"
      :name="name"
      type="text"
      :value="modelValue"
      :class="cInputClass"
      :placeholder="placeholder"
      class="border-slate-300 focus:border-blue-300 focus:ring-1 focus:ring-blue-300"
      @input="updateInput"
    />
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
  <div v-else class="">
    <BaseShowLabel :label="label" :model-value="modelValue" />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import BaseEditLabel from '../BaseLabel/BaseEditLabel.vue'
import BaseShowLabel from '../BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditDateInput',
  directives: { mask },
  components: { ErrorContainer, BaseEditLabel, BaseShowLabel },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    mask: {
      type: Array,
      required: false,
      default() {
        return ['##/##/####']
      }
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    }
  },
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === '' ? 'form-control' : this.inputClass
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
