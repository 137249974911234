const TheItemCategorySettingPage = () =>
  import(
    '@/views/settings/invoicing/itemCategory/index/TheItemCategoryIndexPage.vue'
  )
const TheItemCategoryFormTabComponent = () =>
  import(
    '@/views/settings/invoicing/itemCategory/form/TheItemCategoryFormTabComponent.vue'
  )
const TheItemCategoryInformationPage = () =>
  import(
    '@/views/settings/invoicing/itemCategory/form/tabs/itemCategoryInformation/TheItemCategoryInformationPage.vue'
  )
const TheItemCategoryTranslationPage = () =>
  import(
    '@/views/settings/invoicing/itemCategory/form/tabs/itemCategoryTranslation/TheItemCategoryTranslationPage.vue'
  )
const TheAccountingDiaryIndexPage = () =>
  import(
    '@/views/settings/invoicing/accountingDiary/TheAccountingDiaryIndexPage.vue'
  )

export const settingsAccountingDiaryRoutes = [
  {
    path: 'accounting-diary',
    name: 'settings_accounting_diaries_route',
    component: TheAccountingDiaryIndexPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_company_title', active: true }
      ]
    }
  }
]
