<template>
  <div>
    <div>
      <TheDarkModeSwitcher />
      <div class="block grid-cols-2 gap-4 md:grid">
        <LoginLeftSide />

        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script>
import TheDarkModeSwitcher from '../layouts/dark-mode-switcher/TheDarkModeSwitcher.vue'
import LoginForm from './LoginForm'
import LoginLeftSide from './LoginLeftSide'

/*
 * @group Pages
 * Renders the Home Page
 */
export default {
  name: 'LoginPage',
  components: {
    TheDarkModeSwitcher,
    LoginForm,
    LoginLeftSide
  },
  data() {
    return {}
  },
  mounted() {
    document.body.classList.remove('main', 'error-page')
    document.body.classList.add('login')
  }
}
</script>
