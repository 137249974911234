<template>
  <button
      type="button"
      class="flex h-9 flex-shrink-0 font-sm py-2 px-2 border border-transparent rounded-md shadow-sm font-medium focus:outline-none items-center gap-x-3"
      :class="buttonClasses"
      aria-expanded="false"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="[iconPrefix, icon]"
    />
    <div v-if="amount" :class="`px-1.5 text-white bg-theme-${color} rounded-full`">
      {{ amount }}
    </div>
    <template v-if="title">
      <span class="capitalize-first">{{ title }}</span>
    </template>
  </button>
</template>
<script>
export default {
  name: "BaseThemeButton",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: function(value) {
        const possibleTypes = ['', 'primary', 'secondary', 'success', 'warning', 'danger', 'dark', 'info']
        return possibleTypes.indexOf(value) !== -1
      }
    },
    scopedClasses: {
      type: String,
      default:''
    },
    type: {
      type: String,
      default: 'button',
      required: false,
      validator: function(value) {
        return ['button','submit'].indexOf(value) !== -1
      }
    },
    cursor: {
      type: String,
      required: false,
      default: 'pointer',
      validator: (val) =>  ['pointer', 'default', 'move', 'text', 'help', 'not-allowed', 'none', 'context-menu', 'grabbing'].indexOf(val) !== -1
    },
    iconPrefix: {
      type: String,
      required:false,
      default: 'fal'
    },
    textColor: {type: String, required: false, default: 'white'},
    isOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    isRounded: {
      type: Boolean,
      required: false,
      default: false
    },
    isSoft: {
      type: Boolean,
      required: false,
      default: false
    },
    amount: {
      type: [String, Number],
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function (value) {
        const possibleSizes = ['', 'small', 'medium', 'large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
  },
  computed: {
    buttonTextColorClass() {
      if(!this.isOutline){
      return this.textColor ? `text-${this.textColor}` : ''
      }
      else {
        return `text-theme-${this.color} hover:text-white`
      }
    },
    buttonBgColorClass(){
      if(!this.isOutline){
        return this.color ? `bg-theme-${this.color}` : ''
      }
      else {
        return `border-1 border-${this.color} hover:border-white hover:bg-theme-${this.color}`
      }
    },
    buttonCursorClass(){
      return `cursor-${this.cursor}`
    },
    buttonSizeClass() {
      switch (this.size) {
        case 'small':
          return 'btn-sm'
        case 'large':
          return 'btn-lg'
        case 'medium':
        default:
          return ''
      }
    },
    buttonTypeClass() {
      if (this.isOutline) {
        return `btn-outline-theme-${ this.color }`
      }
      if (this.isSoft) {
        return `btn-theme-${ this.color }-soft`
      }
      return this.color === 'primary' ? 'btn bg-theme-primary' : `btn bg-theme-${ this.color }`
    },
    buttonClasses() {
      return [
          this.scopedClasses,
          this.buttonCursorClass,
          this.buttonTextColorClass,
          this.buttonBgColorClass,
          this.buttonSizeClass,
          this.buttonTypeClass,
          this.isOutline ? `border border-theme-${this.color}` : ''
      ].join(' ')
    }
  }
}
</script>
