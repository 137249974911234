<template>
  <Popover
    v-if="initialMetadata?.models?.message_texts"
    v-slot="{ open }"
    class="relative"
  >
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="rounded border border-gray-300 px-2 py-1 leading-none"
    >
      <FontAwesomeIcon :icon="['far', 'feather']" />
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute right-0 z-10 mt-1 w-screen max-w-sm transform px-4 sm:max-w-xl sm:px-0"
      >
        <div
          class="min-h-42 flex max-h-64 flex-col gap-1 overflow-y-scroll rounded-md border bg-white p-2 shadow-2xl"
        >
          <template v-if="initialMetadata.models?.message_texts?.email?.length">
            <button
              v-for="message_text in initialMetadata.models.message_texts.email"
              :key="message_text.id"
              class="truncate rounded bg-gray-50 px-2 py-1 text-left hover:bg-primary-500 hover:text-white"
              @click="getSelectedOptions(message_text.id, close)"
            >
              {{ message_text.name }}
            </button>
          </template>
          <div
            v-else
            class="flex h-36 flex-row items-center justify-center font-bold"
          >
            {{ $t('global.is_empty_list') }}
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'MessageTextsPopover',
  components: { Popover, PopoverPanel, FontAwesomeIcon, PopoverButton },
  props: {
    modelNotification: {
      type: Object,
      required: true
    }
  },
  emits: ['update-message'],
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    })
  },
  methods: {
    ...mapActions({
      processFetchMessageTextConvertedAction:
        'modelNotification/fetchMessageTextConverted'
    }),
    getSelectedOptions(value, close) {
      this.processFetchMessageTextConvertedAction({
        model: this.modelNotification.model ? 'dossier' : null,
        model_id: this.modelNotification.model
          ? this.modelNotification.model.id
          : null,
        message_text_id: value
      }).then((response) => {
        this.$emit('update-message', response.text)
      })
      close()
    }
  }
}
</script>
