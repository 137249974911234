import apiClient from '@u/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async sendEmail({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()
    formData.append('data', JSON.stringify(payload.email))

    if (payload.email.attachments?.length > 0) {
      payload.email.attachments.forEach((file) =>
        formData.append('attachments[]', file)
      )
    }

    await apiClient
      .post(`notification/send-email`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        await console.log('sendEmail', response)
        if (response.status === 202) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.log('sendEmail', reason)
      })
  },

  async fetchMessageTextConverted({ commit }, payload) {
    await apiClient
      .post('/convert-message-text', payload)
      .then((response) => {
        if (response.status === 200) {
          commit('addPredifinedTextMessageToEmail', response.data.text)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('fetchMessageTextConverted', reason)
      })
  },
}
