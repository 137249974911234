export default {
  getTasksList(state) {
    return state.tasksList
  },
  getTask(state) {
    return state.task
  },
  getTaskMetadata(state) {
    return state.taskMetadata
  },
  getTaskActivity(state) {
    return state.taskActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
  getEditionMode(state) {
    return state.editionMode
  },
  getTempUsers(state) {
    return state.tempUsers
  },
  getTempExecutorUsers(state) {
    return state.tempExecutorUsers
  }
}
