<template>
  <Popover v-slot="{ open }">
    <Float autoplacement :show="show" transition-name="fade">
      <PopoverButton
        @click="toggle"
        :class="open ? '' : 'text-opacity-90'"
        class="z-50 block h-5 w-5 focus-visible:outline-0"
      >
        <FontAwesomeIcon
          :icon="['fal', 'ellipsis-vertical']"
          class="z-20 h-4 w-4 text-gray-600"
        />
      </PopoverButton>
      <PopoverPanel
        static
        class="z-10 mt-3 w-screen max-w-sm px-4 sm:px-0 lg:max-w-xl"
      >
        <div
          class="overflow-hidden rounded-lg bg-white p-3 pt-2 shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div v-if="invoiceables === null" class="px-10 py-6">
            <LoadingComponent :display-label="false" />
          </div>
          <div v-else class="flex flex-col gap-3">
            <h2 class="text-xl font-medium">{{ $t('global.contacts') }}</h2>
            <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
              <InvoiceableCardSelector
                v-for="invoiceable in invoiceables"
                :key="invoiceable.id"
                @click="() => selectContact(invoiceable)"
                :invoiceable="invoiceable"
                :selected="invoiceable.id === selectedId"
              />
            </div>
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>
</template>

<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import LoadingComponent from '@c/TheLoadingComponent.vue'
import { mapActions } from 'vuex'
import InvoiceableCardSelector from '@/views/invoicing/show/tabs/information/components/information/components/InvoiceableCardSelector.vue'

export default defineComponent({
  name: 'InvoiceableDossierSelector',
  components: {
    InvoiceableCardSelector,
    LoadingComponent,
    PopoverPanel,
    PopoverButton,
    Popover,
    FontAwesomeIcon,
    Float
  },
  emits: [
    'invoiceable-changed',
    'update:invoiceableType',
    'update:invoiceableId',
    'update:invoiceable'
  ],
  data() {
    return {
      show: false,
      invoiceables: null
    }
  },
  props: {
    invoiceId: {
      type: String,
      required: true,
      default: null
    },
    invoiceable: {
      type: Object,
      required: true
    },
    invoiceableId: {
      type: String,
      required: true
    },
    invoiceableType: {
      type: String,
      required: true
    },
    selectedId: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    ...mapActions({
      processFetchInvoiceableListAction: 'invoicing/fetchInvoiceableList'
    }),
    toggle() {
      this.show = !this.show
    },
    selectContact(contact) {
      this.$emit('update:invoiceable', contact)
      this.$emit('update:invoiceableId', contact.id)
      this.$emit('update:invoiceableType', 'App\\Models\\Person')
      this.show = false
      this.$emit('invoiceable-changed')
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue && this.invoiceId && this.invoiceables == null) {
        this.processFetchInvoiceableListAction({
          invoiceId: this.invoiceId,
          filter: ['familyMembers']
        }).then((invoiceables) => (this.invoiceables = invoiceables))
      }
    }
  }
})
</script>

<style>
.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-active {
  transition: all 0.2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
