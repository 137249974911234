<template>
  <div class="flex flex-col gap-y-2">
    <div
      v-if="item"
      class="mx-auto flex w-full items-center gap-x-5 rounded-md bg-blue-300 px-5 py-2 text-white"
    >
      <FontAwesomeIcon :icon="['fal', 'tag']" />
      <p class="capitalize-first">{{ item.name }}</p>
    </div>
    <template v-if="invoiceIndex?.length < 1">
      <div class="mx-auto mt-12 text-center text-slate-300">
        {{
          $t('global.there_is_no_yet', {
            settings: $t('global.supplier_order')
          })
        }}
      </div>
    </template>
    <template v-else>
      <div class="pr-5">
        <div v-for="(invoice, index) in invoiceIndex" :key="index">
          <SupplierOrderCard
            :supplier-order="invoice"
            :supplier-id="supplierId"
            :item="item"
            :dossier-id="dossierId"
            @edit-supplier-order="handleEditSupplierOrder"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SupplierOrderCard from '@ui/modal/supplierOrder/components/SupplierOrderCard'

export default {
  name: 'SupplierOrderList',
  components: { SupplierOrderCard },
  props: {
    item: {
      type: Object,
      required: true
    },
    supplierId: {
      type: [String, Number],
      required: true
    },
    dossierId: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  emits: ['editSupplierOrder'],
  computed: {
    ...mapGetters({
      invoiceIndex: 'invoicing/getInvoiceIndex'
    })
  },
  methods: {
    handleEditSupplierOrder(supplier_order_id) {
      this.$emit('editSupplierOrder', supplier_order_id)
    }
  }
}
</script>
