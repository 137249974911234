export default {
    setDocumentsList(state, documentsList) {
        state.documentsList = documentsList
    },
    setSharedDocumentsList(state, documentsList) {
        state.sharedDocumentsList = documentsList
    },
    setDocumentsHeaders(state, headers) {
        state.documentsHeaders = headers
    },
    setMetadata(state, metadata) {
        state.metadata = metadata
    },
    setDocument(state, document) {
        state.document = document
    },
    alterDocumentQuestionValue(state, payload) {
        state.document.form[payload.index].value = payload.value
    },
    setEditionMode(state, editionMode) {
        state.editionMode = editionMode
    },
    setDocumentActivities(state, documentActivities) {
        state.documentActivities = documentActivities
    },
    setDocumentTemplate(state, file) {
        state.document.template = file
        state.document.template_extension = '.' + file.name.split('.').pop()
        state.document.template_name = file.name.split('.').shift()
    },
    addFormFieldToDocument(state, payload) {
        if (!state.document.form) {
            state.document.form = []
        }
        state.document.form.push(payload)
    },
    deleteFormFieldFromDocument(state, payload) {
        state.document.form.splice(payload.index, 1)
    }
}
