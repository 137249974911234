export default {
  getSettings(state) {
    return state.settings
  },
  getSettingsMetadata(state) {
    return state.settingsMetadata
  },

  getSettingsEventKinds(state) {
    return state.settingsEvent_kinds
  },

  getSystemSettings(state) {
    return state.systemSettings
  },
  getGeneralSystemSettings(state) {
    return state.systemSettings.general
  },
  getInvoicingSystemSettings(state) {
    return state.systemSettings.invoicing
  },
  getEventSystemSettings(state) {
    return state.systemSettings.event
  },
  getPackageVersion(state) {
    return state.packageVersion
  }
}
