<template>
  <div v-if="errorKey" class="form-help-container">
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ Array.isArray(error) ? error.join(',') : error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorContainer',
  props: {
    errors: {
      type: Object,
      required: true
    },
    errorKey: {
      type: String,
      required: true
    }
  },
  onErrorCaptured(err) {
    return false
  }
}
</script>
