<template>
  <div class="flex flex-row md:flex-row flex-1 justify-end mt-4 sm:mt-0">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BasePageHeaderActions'
}
</script>

<style scoped>
</style>
