export default {
  setPackList(state, packList) {
    state.PackList = packList
  },
  setPack(state, pack) {
    state.pack = pack
  },
  setMetadata(state, metadata) {
    state.metadata = metadata
  },
  setPackActivity(state, activity) {
    state.packActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  removeItemFromPack(state, id) {
    if (state.pack.items_id[id].quantity > 1) {
      return (state.pack.items_id[id].quantity -= 1)
    }
    delete state.pack.items_id[id]
    state.pack.items = state.pack.items.filter((item) => item.id !== id)
  },
  addItemToPack(state, payload) {
    if (!state.pack.items_id) {
      state.pack.items_id = {}
    }

    if (Object.keys(state.pack.items_id).includes(payload.item.id)) {
      return (state.pack.items_id[payload.item.id].quantity += 1)
    }

    state.pack.items_id[payload.item.id] = {
      ...payload.item.pivot,
      item: payload.item
    }
  }
}
