<template >
  <div
    :class="`flex gap-x-1 flex-shrink-0 col-span-2 text-center items-center flex-grow-0 rounded-lg ${bgColor} px-2 py-1 text-${size} font-medium ${internalTextColor} capitalize-first`"
  >
    <FontAwesomeIcon v-if="icon" :icon="['fal', icon]" :size="size" />
    <div class="capitalize-first">{{ text }}</div>
  </div >
</template >
<script >
export default {
  name: 'BaseBadge',
  props: {
    icon: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'sm'
    },
    useThemeColor: {
      type: Boolean,
      required: false,
      default: false
    },
    textColor: {
      type: String,
      required: false,
      default: null,
    }
  },
  computed: {
    bgColor() {
      if(this.useThemeColor){
        return `bg-theme-${this.color}`
      }
      else{
        switch (this.color) {
          case 'danger':
            return 'bg-red-100'
          case 'success':
            return 'bg-green-100'
          case 'primary':
            return 'bg-blue-100'
          case 'warning':
            return 'bg-yellow-100'
          default:
            return 'bg-gray-100';
        }
      }
    },
    internalTextColor() {
      if(this.useThemeColor){
        return this.textColor
      }
      else {
        switch (this.color) {
          case 'danger':
            return 'text-red-800'
          case 'success':
            return 'text-green-800'
          case 'primary':
            return 'text-blue-800'
          case 'warning':
            return 'text-yellow-800'
          default:
            return 'text-gray-800';
        }
      }
    }
  },
}
</script >
