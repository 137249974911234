<template>
  <div v-if="editionMode" :class="groupClass">
    <label
      v-if="label"
      :class="cLabelClass"
      class="relative left-2 top-4 z-10 inline-block rounded-md bg-white px-1 text-xs font-medium leading-none text-gray-900 capitalize-first"
      >{{ label }}&nbsp;
      <span v-if="isRequired" class="text-red-600">*</span></label
    >
    <multiselect
      :options="options"
      :placeholder="placeholder"
      :model-value="cDefautlValue"
      :searchable="searchable"
      :label="attributeLabel"
      :track-by="trackBy"
      :disabled="disabled"
      :required="required"
      :show-pointer="false"
      @update:modelValue="updateValueAction"
    >
      <template #singleLabel="{ option }">
        <BaseLabel
          :icon="option.icon"
          :content="option.name"
          :color="option.bg_color"
        />
      </template>

      <template #option="{ option }">
        <div class="option__desc">
          <div class="flex gap-x-3 align-baseline">
            <div>
              <div class="option__title">
                <BaseLabel
                  :icon="option.icon"
                  :content="option.name"
                  :color="option.bg_color"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:noOptions><span>{{ $t('global.is_empty_list') }}</span></template>
    </multiselect>

    <div v-if="errors" class="form-help text-red-600">
      <div v-for="(error, index) in errors" :key="index">
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </div>
  </div>

  <div v-else class="">
    <BaseShowLabel
      :label="label"
      :model-value="cDefautlValue ? cDefautlValue[attributeLabel] : null"
    />
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import BaseShowLabel from '../../BaseLabel/BaseShowLabel.vue'

export default {
  name: 'BaseShowEditSelectSimpleColored',
  components: {
    multiselect,
    BaseShowLabel
  },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: '',
      require: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    selectClass: { type: String, required: false, default: '' },
    errors: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    canTrackByBeNull: {
      type: Boolean,
      required: false,
      default: true
    },
    fullModelResponse: { type: Boolean, required: false, default: false }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    cDefautlValue() {
      if (
        this.options &&
        this.options.find((option) => option[this.trackBy] === this.modelValue)
      ) {
        return this.options.find(
          (option) => option[this.trackBy] === this.modelValue
        )
      } else {
        return null
      }
    },
    cLabelClass() {
      return this.labelClass === '' ? 'form-label' : this.labelClass
    },
    isRequired() {
      return this.required
    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue) {
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
          this.$emit('workSelect', newValue)
        } else {
          this.$emit('update:modelValue', newValue[this.trackBy])
          this.$emit('change', newValue[this.trackBy])
        }
      } else {
        if (this.canTrackByBeNull) {
          this.$emit('update:modelValue', null)
          this.$emit('change', null)
        }
      }
    }
  }
}
</script>
