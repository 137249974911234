export default {
  setFuneralHomesList(state, funeralHomesList) {
    state.funeralHomesList = funeralHomesList
  },
  setFuneralHome(state, funeralHome) {
    state.funeralHome = funeralHome
  },
  setFuneralHomeMetadata(state, metadata) {
    state.metadata = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setFuneralHomeActivities(state, funeralHomeActivities) {
    state.funeralHomeActivities = funeralHomeActivities
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setFuneralHomeAddress(state, address) {
    state.funeralHome.address = address
    state.funeralHome.address_id = address.id
  },
  alterFuneralHomeForAddingBankAccount(state, bank_account) {
    state.funeralHome.default_bank_account_id = bank_account.id
  },
  alterFuneralHomeForUpdatePartners(state, payload) {
    let funeral_home = state.funeralHomesList.find(
      (funeral_home) => funeral_home.id === payload.funeralHome.id
    )
    funeral_home.funeral_home_partner[payload.partner] =
      !funeral_home.funeral_home_partner[payload.partner]
  },
  addOrRemoveBankAccountToFuneralHome(state, payload) {
    let bank_collection_formatted = {}
    payload.values.forEach((bank) => {
      bank_collection_formatted[bank.id] = { default: false }
    })

    return state.funeralHome['bank_account_ids'] = Object.assign(
        bank_collection_formatted
    )
  },
}
