import apiClient from '@u/apiClient'
let base_errors = { deceased: {}, events: [] }
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region fetch
  async fetchSupplierList({commit}){
    await apiClient
      .get(`supplier`)
      .then(async response => {
        if(response.status === 200){
          commit('setSupplierList', response.data.suppliers)
          commit('setMetadata', response.data.metadata)
        }
      })
      .catch(async reason => {
        await console.error('fetchSupplierList', reason)
      })
  },
  async fetchSupplierById({commit}, payload){
      commit('auth/setErrors', base_errors, {root: true})
    await apiClient
      .get(`supplier/${payload.supplier_id}`)
      .then(async response => {
        if(response.status === 200){
          commit('setSupplier', response.data.supplier)
        }
      })
      .catch(async reason => {
        await console.error('fetchSupplierById', reason)
      })
  },
  async fetchNewSupplier({commit}){
      commit('auth/setErrors', base_errors, {root: true})
    await apiClient
      .get(`model/new?model=supplier`)
      .then(async response => {
        if(response.status === 200){
          commit('setSupplier', response.data.supplier)
        }
      })
      .catch(async reason => {
        await console.error('fetchNewSupplier', reason)
      })
  },
  async fetchSupplierActivitiesBySupplierID({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient
        .get(`supplier/fetch-activities/${payload.supplier_id}`)
        .then(async (response) => {
            await commit('setMetadataActivities', response.data.metadata)
            await commit('setSupplierActivity', response.data.activities)
        })
        .catch(async (reason) => {
          await console.error('fetchSupplierActivitiesByCompanyID', reason)
        })
  },
  //endregion

    //region store
    async storeSupplier({commit}, payload){
        commit('auth/setErrors', base_errors, {root: true})
    await apiClient
      .post(`supplier`, payload.supplier)
      .then(async response => {
        if(response.status === 201){
          commit('setSupplier', response.data.supplier)
          processSuccessCallback(payload)
        }
      })
      .catch(async reason => {
        await console.error('storeSupplier', reason)
      })
  },
    //endregion

    //region update
    async updateSupplier({commit}, payload){
        commit('auth/setErrors', base_errors, {root: true})
    await apiClient
      .put(`supplier/${payload.supplier_id}`, payload.supplier)
      .then(async response => {
        if(response.status === 201){
          commit('setSupplier', response.data.supplier)
          processSuccessCallback(payload)
        }
      })
      .catch(async reason => {
        await console.error('updateSupplier', reason)
      })
  },
    //endregion

    // region delete
    async deleteSupplierById({commit}, payload) {
        await apiClient
            .delete(`supplier/supplier-delete/${payload.id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setSupplierList', response.data.suppliers)
                    await commit('setMetadata', response.data.metadata)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                console.error('deleteSupplierById', reason)
            })
    },
    // endregion

    // region undoDelete
    async undoDeleteSupplierById({commit}, payload) {
        await apiClient
            .get(`supplier/undo-delete/${payload.id}`)
            .then(async (response) => {
                if (response.status === 200) {
                    await commit('setSupplierList', response.data.suppliers)
                    await commit('setMetadata', response.data.metadata)
                    processSuccessCallback(payload)
                }
            })
            .catch(async (reason) => {
                console.error('undoDeleteSupplierById', reason)
            })
    }
    // endregion
}
