export default {
    setTemplateList(state, templateList) {
        state.templateList = templateList
    },
    setTemplate(state, template) {
        state.template = template
    },
    setCurrentTemplateColumnList(state, columnList) {
        state.currentTemplateColumnList = columnList
    },
    setFullColumnList(state, columnList) {
        state.fullColumnsList = columnList
    },
    setColumn(state, column) {
        state.column = column
    },
    setFilter(state, filter) {
        state.template.filters = filter
    },
    setSort(state, sort) {
        state.sort = sort
    },
    setEdited(state, edited) {
        state.edited = edited
    },
    reorderColumns(state, payload) {
        let columns = state.currentTemplateColumnList
        console.log(columns)
        let targetColumn = columns[payload.oldIndex]
        columns.splice(payload.oldIndex, 1)
        columns.splice(payload.newIndex, 0, targetColumn)
        state.columnList = columns
    }
}
