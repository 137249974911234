import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      sessionExpiration:
        (localStorage.vuex &&
          JSON.parse(localStorage.vuex).auth.sessionExpiration) ||
        null,
      token: null,
      user: null,
      errors: []
    }
  },
  getters,
  actions,
  mutations
}
