export default {
    setItemCategoryList(state, itemCategoryList) {
        state.itemCategoryList = itemCategoryList
    },
    setItemCategory(state, itemCategory) {
        state.itemCategory = itemCategory
    },
    setMetadata(state, metadata) {
        state.metadata = metadata
    },
    setEditionMode(state, editionMode) {
        state.editionMode = editionMode
    },
}
