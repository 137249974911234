<template>
  <div
    class="flex h-full max-h-full flex-1 flex-col"
    :class="[contrast ? 'divide-y' : '']"
  >
    <div
      v-if="$slots.title || $slots.cta"
      class="z-50 mx-auto flex shrink-0 flex-row gap-3 p-5 text-center sm:flex sm:w-2/6 sm:flex-col sm:justify-center md:flex md:w-full md:flex-row md:justify-between"
    >
      <div class="flex flex-1 gap-x-3">
        <slot name="title"></slot>
      </div>
      <div v-if="$slots.cta" class="flex flex-shrink-0 justify-center">
        <slot name="cta"></slot>
      </div>
    </div>

    <div
      class="flex min-h-0 flex-1 flex-col @container/dossier-main"
      :class="[contrast ? 'bg-gray-50' : 'bg-white px-5']"
    >
      <slot name="body"></slot>
    </div>
    <div
      v-if="$slots.footer"
      class="z-50 mx-auto flex shrink-0 flex-row gap-3 p-5 text-center sm:flex sm:w-2/6 sm:flex-col sm:justify-center md:flex md:w-full md:flex-row md:justify-between"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SettingsPageFull',
  props: {
    contrast: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
