<template>
  <TransitionRoot as="template" :show="localeIsModalOpen" class="z-100">
    <Dialog as="div" class="relative z-100" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-blue-100 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div
          class="flex h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="[
                !translucent ? 'bg-white text-left shadow-xl' : '',
                fullScreen ? 'h-screen' : 'max-h-4/5',
                'relative flex transform flex-col  rounded-lg px-4 pb-4 pt-5 transition-all sm:my-8  sm:p-6 sm:pt-5',
                sizeClass
              ]"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <div class="flex flex-row gap-x-3">
                  <slot name="header" />
                  <button
                    type="button"
                    :class="[
                      !translucent
                        ? 'bg-white text-slate-300 hover:text-gray-500'
                        : 'text-gray-700 hover:text-gray-900',
                      'rounded-md focus:outline-none'
                    ]"
                    @click="close"
                  >
                    <FontAwesomeIcon :icon="['fal', 'times']" size="xl" />
                  </button>
                </div>
              </div>
              <div
                :class="[
                  !translucent ? 'sm:flex' : '',
                  'flex min-h-0 flex-1 flex-col sm:items-start'
                ]"
              >
                <div
                  v-if="icon"
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10"
                >
                  <FontAwesomeIcon :icon="['fal', icon]" size="xl" />
                </div>
                <div
                  :class="[
                    !translucent ? '' : ' justify-center',
                    'flex min-h-0 w-full flex-1 flex-col gap-3'
                  ]"
                >
                  <DialogTitle
                    v-if="title && title.length"
                    as="h3"
                    class="shrink-0 text-2xl font-bold text-gray-900 capitalize-first"
                  >
                    {{ title }}
                  </DialogTitle>
                  <div
                    :class="[
                      !translucent ? 'flex w-full justify-center' : '',
                      'flex min-h-0 flex-1'
                    ]"
                  >
                    <slot name="body" />
                  </div>
                </div>
              </div>
              <div
                v-if="$slots.footer"
                class="mt-5 shrink-0 sm:mt-4 sm:flex sm:flex-row-reverse"
              >
                <div class="flex flex-row gap-x-3">
                  <slot name="footer" />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseCenteredModal',
  components: {
    FontAwesomeIcon,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    },
    translucent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:isModalOpen', 'close'],
  computed: {
    localeIsModalOpen: {
      get() {
        return this.isModalOpen
      },
      set(value) {
        this.$emit('update:isModalOpen', value)
      }
    },
    sizeClass() {
      if (this.large) {
        return 'w-2/6 md:w-10/12 '
      }
      switch (this.size) {
        case 'extra-large':
          return 'w-2/6 md:w-10/12'
        case 'large':
          return 'w-2/6 md:w-8/12'
        case 'medium':
          return 'w-2/6 md:w-1/2'
        default:
          return 'sm:w-full sm:max-w-lg'
      }
    }
  },
  methods: {
    close() {
      this.localeIsModalOpen = false
      this.$emit('close')
    }
  }
}
</script>
