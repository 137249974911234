import ActivityTable from './components/BaseActivityTable/ActivityTable.vue'
import ActivityTableRow from './components/BaseActivityTable/ActivityTableRow.vue'
import BaseBankShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseBankShowEditSelectSimple.vue'
import BaseBooleanRadioGroup from './components/BaseRadioGroup/BaseBooleanRadioGroup.vue'
import BaseButton from './components/BaseButton/BaseButton.vue'
import BaseThemeButton from './components/BaseButton/BaseThemeButton.vue'
import BaseButtonInput from './components/BaseInput/BaseButtonInput.vue'
import BaseCard from './components/BaseCard/BaseCard.vue'
import BaseCardSection from './components/BaseCard/BaseCardSection.vue'
import BaseCheckBox from './components/BaseCheckBox/BaseCheckBox.vue'
import BaseCompanyShowEditSelectMultiple from './components/BaseShowEditSelect/BaseShowEditFormSelectMultiple/BaseCompanyShowEditSelectMultiple.vue'
import BaseCompanyShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseCompanyShowEditSelectSimple.vue'
import BaseCountryShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseCountryShowEditSelectSimple.vue'
import BaseCustomerShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseCustomerShowEditSelectSimple.vue'
import BaseDateInput from './components/BaseInput/BaseDateInput.vue'
import BaseDocShowEditSelectMultiple from './components/BaseShowEditSelect/BaseShowEditFormSelectMultiple/BaseDocShowEditSelectMultiple.vue'
import BaseEditLabel from './components/BaseLabel/BaseEditLabel.vue'
import BaseEventTypeShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseEventTypeShowEditSelectSimple.vue'
import BaseInput from './components/BaseInput/BaseInput.vue'
import BaseLabel from './components/BaseLabel/BaseLabel.vue'
import BaseLanguageShowEditSelectMultiple from './components/BaseShowEditSelect/BaseShowEditFormSelectMultiple/BaseLanguageShowEditSelectMultiple.vue'
import BaseLanguageShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseLanguageShowEditSelectSimple.vue'
import BaseMaskedInput from './components/BaseInput/BaseMaskedInput.vue'
import BasePageFull from './components/BasePage/BasePageFull.vue'
import BasePhoneInput from './components/BaseInput/BasePhoneInput.vue'
import BaseRadioGroup from './components/BaseRadioGroup/BaseRadioGroup.vue'
import BaseSearchInput from './components/BaseInput/BaseSearchInput.vue'
import BaseSelectMultiple from './components/BaseSelect/BaseSelectFormMultiple/BaseSelectMultiple.vue'
import BaseSelectSimple from './components/BaseSelect/BaseSelectFormSimple/BaseSelectSimple.vue'
import BaseSelectSimpleAjax from './components/BaseSelect/BaseSelectFormSimple/BaseSelectSimpleAjax.vue'
import BaseShowEditDateInput from './components/BaseShowEditInput/BaseShowEditDateInput.vue'
import BaseShowEditInput from './components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditPhoneInput from './components/BaseShowEditInput/BaseShowEditPhoneInput.vue'
import BaseShowEditRadioGroup from './components/BaseShowEditRadioGroup/BaseShowEditRadioGroup.vue'
import BaseShowEditSelectAjax from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditSelectAjax.vue'
import BaseShowEditSelectWikiAjax from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditSelectWikiAjax.vue'
import BaseShowEditSelectMultiple from './components/BaseShowEditSelect/BaseShowEditFormSelectMultiple/BaseShowEditSelectMultiple.vue'
import BaseShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditSelectSimple.vue'
import BaseShowEditSelectSimpleColored from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditSelectSimpleColored.vue'
import BaseShowEditTextArea from './components/BaseShowEditInput/BaseShowEditTextArea.vue'
import BaseShowEditTimeInput from './components/BaseShowEditInput/BaseShowEditTimeInput.vue'
import BaseShowLabel from './components/BaseLabel/BaseShowLabel.vue'
import BaseShowPhoneLabel from './components/BaseLabel/BaseShowPhoneLabel.vue'
import BaseSimpleCard from './components/BaseCard/BaseSimpleCard.vue'
import BaseSimpleCheckboxGroup from './components/BaseCheckBox/BaseSimpleCheckboxGroup.vue'
import BaseSimpleDatePicker from './components/BaseDatepicker/BaseSimpleDatePicker.vue'
import BaseSimpleRadioGroup from './components/BaseRadioGroup/BaseSimpleRadioGroup.vue'
import BaseSimpleShowEditCheckboxGroup from './components/BaseShowEditCheckBox/BaseSimpleShowEditCheckboxGroup.vue'
import BaseSimpleTab from './components/BaseTabs/BaseSimpleTab.vue'
import BaseSwitch from './components/BaseSwitch/BaseSwitch.vue'
import BaseTable from './components/BaseTable/BaseTable.vue'
import BaseTextArea from './components/BaseInput/BaseTextArea.vue'
import BaseThComponent from './components/BaseTable/BaseThComponent.vue'
import BaseTimeInput from './components/BaseInput/BaseTimeInput.vue'
import BaseTrueFalseShowEditRadioGroup from './components/BaseShowEditRadioGroup/BaseTrueFalseShowEditRadioGroup.vue'
import BaseUserShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseUserShowEditSelectSimple.vue'
import CheckboxWithInput from './components/BaseShowEditCheckBox/components/CheckboxWithInput.vue'
import ColumnVisibilityDropDownComponent from './components/BaseTable/ColumnVisibilityDropDownComponent.vue'
import ContentTableCell from './components/BaseTable/ContentTableCell.vue'
import IFramePdf from './components/BaseIFramePDF/IFramePdf.vue'
import LabelWitchCheckBoxChecked from './components/BaseShowEditCheckBox/components/LabelWitchCheckBoxChecked.vue'
import SettingsPageFull from './components/BasePage/SettingsPageFull.vue'
import ToastUndoComponent from './components/BaseToast/ToastUndoComponent.vue'
import SelectCompaniesMultiple from './components/BaseSelect/BaseSelectFormMultiple/SelectCompaniesMultiple.vue'
import SelectCompanySimple from './components/BaseSelect/BaseSelectFormSimple/SelectCompanySimple.vue'
import SelectCustomer from './components/BaseSelect/BaseSelectFormSimple/SelectCustomer.vue'
import SelectLanguage from './components/BaseSelect/BaseSelectFormSimple/SelectLanguage.vue'
import SelectUserSimple from './components/BaseSelect/BaseSelectFormSimple/SelectUserSimple.vue'
import BaseWysiwyg from './components/Wysiwyg/BaseWysiwyg.vue'
import DynamicFormInput from './components/DynamicForm/DynamicFormInput.vue'
import DropdownColoredStatus from './components/DropdownFiles/DropdownColoredStatus.vue'
import DropdownStatus from './components/DropdownFiles/DropdownStatus.vue'
import DropdownTaskStatusSquare from './components/DropdownFiles/DropdownTaskStatusSquare.vue'
import DropdownWorkStatus from './components/DropdownFiles/DropdownWorkStatus.vue'
// import BaseFileDropComponent from './components/FileDrop/BaseFileDropComponent.vue'
import BaseInputDynamicForm from './components/DynamicForm/components/BaseInputDynamicForm.vue'
import BaseContentTable from './components/BaseTable/BaseContentTable.vue'
import BaseShowEditInputButton from './components/BaseShowEditInput/BaseShowEditInputButton.vue'
import BaseShowEditTreeInput from './components/BaseShowEditInput/BaseShowEditTreeInput.vue'
import BaseSimpleSearchInput from './components/BaseInput/BaseSimpleSearchInput.vue'
import BaseSimpleShowEditCheckbox from './components/BaseShowEditCheckBox/BaseSimpleShowEditCheckbox.vue'
import BaseShowEditCheckBox from './components/BaseShowEditCheckBox/BaseShowEditCheckBox.vue'
import BaseShowEditIsoDateTimeInput from './components/BaseShowEditInput/BaseShowEditIsoDateTimeInput.vue'
import BaseShowEditBooleanRadioGroup from './components/BaseRadioGroup/BaseShowEditBooleanRadioGroup.vue'
import BaseShowEditUndefinedRadioGroup from './components/BaseRadioGroup/BaseShowEditUndefinedRadioGroup.vue'
import BaseShowEditIsoDateToTimeInput from './components/BaseShowEditInput/BaseShowEditIsoDateToTimeInput.vue'
import BaseShowEditIsoDateToTimeInputPrependAndAppend from './components/BaseShowEditInput/BaseShowEditIsoDateToTimeInputPrependAndAppend.vue'
import BaseShowEditIsoDateAndTimeRangeInput from './components/BaseShowEditInput/BaseShowEditIsoDateAndTimeRangeInput.vue'
import BaseShowEditOptionGroupedSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectMultiple/BaseShowEditOptionGroupedSelectSimple.vue'
import BaseShowEditTaggableSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditTaggableSelectSimple.vue'
import BaseShowEditRadio from './components/BaseShowEditRadioGroup/BaseShowEditRadio.vue'
import TranslationComponent from './components/translation/TranslationComponent.vue'
import BaseShowEditPriceInput from './components/BaseShowEditInput/BaseShowEditPriceInput.vue'
import BaseShowEditPricingInput from './components/BaseShowEditInput/BaseShowEditPricingInput.vue'
import BaseShowEditContactInput from './components/BaseShowEditInput/BaseShowEditContactInput.vue'
import BaseCenteredModal from './components/modal/BaseCenteredModal.vue'
import BaseSlideOverModal from './components/modal/BaseSlideOverModal.vue'
import BaseModalTabs from './components/modal/components/BaseModalTabs.vue'
import BaseShowEditGallery from './components/images/gallery/BaseShowEditGallery.vue'
import BaseDisclosure from './components/disclosure/BaseDisclosure.vue'
import BaseDropDownMenu from './components/dropdown/BaseDropDownMenu.vue'
import BaseDropDownInput from './components/dropdown/BaseDropDownInput.vue'
import BaseShowEditDynamicRadioSelect from './components/DynamicForm/BaseShowEditDynamicRadioSelect.vue'
import wizardCard from './components/wizard/WizardCard.vue'
import wizardOptionCard from './components/wizard/WizardOptionCard.vue'
import BaseWideSlideOverModal from './components/modal/components/BaseWideSlideOverModal.vue'
import BaseShowEditSelectMultipleTaggableAjax from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditSelectMultipleTaggableAjax.vue'
import BaseDynamicFormInput from './components/DynamicForm/BaseDynamicFormInput.vue'
import BaseShowEditFileInput from './components/FileInput/BaseShowEditFileInput.vue'
import BaseStepsInput from './components/steps/baseStepsinput/BaseStepsInput.vue'
import PersonInput from './components/person/PersonInput.vue'
import SupplierOrderButton from './components/SupplierOrder/SupplierOrderButton.vue'
import SupplierInput from './components/supplier/SupplierInput.vue'
import ContactInput from './components/contact/ContactInput.vue'
import StatusInput from './components/status/StatusInput.vue'
import BaseShowEditCheckBoxList from './components/BaseShowEditCheckBox/BaseShowEditCheckBoxList.vue'
import BaseFormattedFuneralHomeShowEditSelectSimple from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseFormattedFuneralHomeShowEditSelectSimple.vue'
import ChatReactionList from './components/chat/ChatReactionList.vue'
import ReactionOptionMenu from './components/chat/ReactionOptionMenu.vue'
import BaseSimpleTabError from './components/BaseTabs/components/BaseSimpleTabError.vue'
import BaseShowEditInvoiceStatusInput from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditInvoiceStatusInput.vue'
import BaseShowEditDossierStatusInput from './components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditDossierStatusInput.vue'
import BaseDisabledButton from './components/BaseButton/BaseDisabledButton.vue'
import BaseDossierPageLayout from './components/layout/BaseDossierPageLayout.vue'
import BaseWikiCityPageLayout from './components/layout/BaseWikiCityPageLayout.vue'
import BaseMapComponent from './components/map/BaseMapComponent.vue'

import HelloWorld from './components/HelloWorld.vue'
import BaseShowEditIsoDateInput from './components/BaseShowEditInput/BaseShowEditIsoDateInput.vue'

export default (app) => {
  app.component('BaseMapComponent', BaseMapComponent)
  app.component('BaseWikiCityPageLayout', BaseWikiCityPageLayout)
  app.component('BaseDossierPageLayout', BaseDossierPageLayout)
  app.component('BaseDisabledButton', BaseDisabledButton)
  app.component(
    'BaseShowEditInvoiceStatusInput',
    BaseShowEditInvoiceStatusInput
  )
  app.component(
    'BaseShowEditDossierStatusInput',
    BaseShowEditDossierStatusInput
  )
  app.component('BaseSimpleTabError', BaseSimpleTabError)
  app.component('ReactionOptionMenu', ReactionOptionMenu)
  app.component('ChatReactionList', ChatReactionList)
  app.component(
    'BaseFormattedFuneralHomeShowEditSelectSimple',
    BaseFormattedFuneralHomeShowEditSelectSimple
  )
  app.component('BaseShowEditCheckBoxList', BaseShowEditCheckBoxList)
  app.component('StatusInput', StatusInput)
  app.component('ContactInput', ContactInput)
  app.component('SupplierInput', SupplierInput)
  app.component('SupplierOrderButton', SupplierOrderButton)
  app.component('PersonInput', PersonInput)
  app.component('BaseStepsInput', BaseStepsInput)
  app.component('BaseShowEditFileInput', BaseShowEditFileInput)
  app.component('BaseDynamicFormInput', BaseDynamicFormInput)
  app.component(
    'BaseShowEditSelectMultipleTaggableAjax',
    BaseShowEditSelectMultipleTaggableAjax
  )
  app.component('BaseWideSlideOverModal', BaseWideSlideOverModal)
  app.component('WizardOptionCard', wizardOptionCard)
  app.component('WizardCard', wizardCard)
  app.component(
    'BaseShowEditDynamicRadioSelect',
    BaseShowEditDynamicRadioSelect
  )
  app.component('BaseDropDownInput', BaseDropDownInput)
  app.component('BaseDropDownMenu', BaseDropDownMenu)
  app.component('BaseDisclosure', BaseDisclosure)
  app.component('BaseShowEditGallery', BaseShowEditGallery)
  app.component('BaseModalTabs', BaseModalTabs)
  app.component('BaseSlideOverModal', BaseSlideOverModal)
  app.component('BaseCenteredModal', BaseCenteredModal)
  app.component('BaseShowEditContactInput', BaseShowEditContactInput)
  app.component('BaseShowEditPricingInput', BaseShowEditPricingInput)
  app.component('BaseShowEditPriceInput', BaseShowEditPriceInput)
  app.component('TranslationComponent', TranslationComponent)
  app.component('ActivityTable', ActivityTable)
  app.component('BaseInputDynamicForm', BaseInputDynamicForm)
  app.component('BaseShowEditTreeInput', BaseShowEditTreeInput)
  app.component('BaseShowEditInputButton', BaseShowEditInputButton)
  // app.component('BaseFileDropComponent', BaseFileDropComponent)
  app.component('DropdownStatus', DropdownStatus)
  app.component('BaseContentTable', BaseContentTable)
  app.component('DropdownColoredStatus', DropdownColoredStatus)
  app.component('DropdownWorkStatus', DropdownWorkStatus)
  app.component('DropdownTaskStatusSquare', DropdownTaskStatusSquare)
  app.component('DynamicFormInput', DynamicFormInput)
  app.component('SelectCustomer', SelectCustomer)
  app.component('SelectUserSimple', SelectUserSimple)
  app.component('BaseWysiwyg', BaseWysiwyg)
  app.component('SelectLanguage', SelectLanguage)
  app.component('SelectCompaniesMultiple', SelectCompaniesMultiple)
  app.component('SelectCompanySimple', SelectCompanySimple)
  app.component('ActivityTableRow', ActivityTableRow)
  app.component('BaseBankShowEditSelectSimple', BaseBankShowEditSelectSimple)
  app.component('BaseBooleanRadioGroup', BaseBooleanRadioGroup)
  app.component('BaseButton', BaseButton)
  app.component('BaseThemeButton', BaseThemeButton)
  app.component('BaseButtonInput', BaseButtonInput)
  app.component('BaseCard', BaseCard)
  app.component('BaseCardSection', BaseCardSection)
  app.component('BaseCheckBox', BaseCheckBox)
  app.component('BaseShowEditCheckBox', BaseShowEditCheckBox)
  app.component(
    'BaseCompanyShowEditSelectMultiple',
    BaseCompanyShowEditSelectMultiple
  )
  app.component(
    'BaseCompanyShowEditSelectSimple',
    BaseCompanyShowEditSelectSimple
  )
  app.component(
    'BaseCountryShowEditSelectSimple',
    BaseCountryShowEditSelectSimple
  )
  app.component(
    'BaseCustomerShowEditSelectSimple',
    BaseCustomerShowEditSelectSimple
  ),
    app.component(
      'BaseShowEditIsoDateToTimeInputPrependAndAppend',
      BaseShowEditIsoDateToTimeInputPrependAndAppend
    )
  app.component('BaseDateInput', BaseDateInput)
  app.component('BaseDocShowEditSelectMultiple', BaseDocShowEditSelectMultiple)
  app.component('BaseEditLabel', BaseEditLabel)
  app.component(
    'BaseEventTypeShowEditSelectSimple',
    BaseEventTypeShowEditSelectSimple
  )
  app.component('BaseInput', BaseInput)
  app.component('BaseLabel', BaseLabel)
  app.component(
    'BaseLanguageShowEditSelectMultiple',
    BaseLanguageShowEditSelectMultiple
  )
  app.component(
    'BaseLanguageShowEditSelectSimple',
    BaseLanguageShowEditSelectSimple
  )
  app.component('BaseMaskedInput', BaseMaskedInput)
  app.component('BasePageFull', BasePageFull)
  app.component('BasePhoneInput', BasePhoneInput)
  app.component('BaseRadioGroup', BaseRadioGroup)
  app.component('BaseSearchInput', BaseSearchInput)
  app.component('BaseSelectMultiple', BaseSelectMultiple)
  app.component('BaseSelectSimple', BaseSelectSimple)
  app.component('BaseSelectSimpleAjax', BaseSelectSimpleAjax)
  app.component('BaseShowEditDateInput', BaseShowEditDateInput)
  app.component('BaseShowEditInput', BaseShowEditInput)
  app.component('BaseShowEditPhoneInput', BaseShowEditPhoneInput)
  app.component('BaseShowEditRadioGroup', BaseShowEditRadioGroup)
  app.component('BaseShowEditSelectAjax', BaseShowEditSelectAjax)
  app.component('BaseShowEditSelectWikiAjax', BaseShowEditSelectWikiAjax)
  app.component('BaseShowEditSelectMultiple', BaseShowEditSelectMultiple)
  app.component('BaseShowEditSelectSimple', BaseShowEditSelectSimple) // BaseShowEditSelectSimple)
  app.component(
    'BaseShowEditSelectSimpleColored',
    BaseShowEditSelectSimpleColored
  ) // BaseShowEditSelectSimple)
  app.component('BaseShowEditTextArea', BaseShowEditTextArea)
  app.component('BaseShowEditTimeInput', BaseShowEditTimeInput)
  app.component('BaseShowLabel', BaseShowLabel)
  app.component('BaseShowPhoneLabel', BaseShowPhoneLabel)
  app.component('BaseSimpleCard', BaseSimpleCard)
  app.component('BaseSimpleCheckboxGroup', BaseSimpleCheckboxGroup)
  app.component('BaseSimpleDatePicker', BaseSimpleDatePicker)
  app.component('BaseSimpleRadioGroup', BaseSimpleRadioGroup)
  app.component(
    'BaseSimpleShowEditCheckboxGroup',
    BaseSimpleShowEditCheckboxGroup
  )
  app.component('BaseSimpleTab', BaseSimpleTab)
  app.component('BaseSwitch', BaseSwitch)
  app.component('BaseTable', BaseTable)
  app.component('BaseTextArea', BaseTextArea)
  app.component('BaseThComponent', BaseThComponent)
  app.component('BaseTimeInput', BaseTimeInput)
  app.component(
    'BaseTrueFalseShowEditRadioGroup',
    BaseTrueFalseShowEditRadioGroup
  )
  app.component('BaseUserShowEditSelectSimple', BaseUserShowEditSelectSimple)
  app.component('CheckboxWithInput', CheckboxWithInput)
  app.component(
    'ColumnVisibilityDropDownComponent',
    ColumnVisibilityDropDownComponent
  )
  app.component('ContentTableCell', ContentTableCell)
  app.component('IFramePdf', IFramePdf)
  app.component('LabelWitchCheckBoxChecked', LabelWitchCheckBoxChecked)
  app.component('SettingsPageFull', SettingsPageFull)
  app.component('ToastUndoComponent', ToastUndoComponent)
  app.component('BaseSimpleSearchInput', BaseSimpleSearchInput)
  app.component('BaseShowEditIsoDateInput', BaseShowEditIsoDateInput)
  app.component('BaseSimpleShowEditCheckbox', BaseSimpleShowEditCheckbox)
  app.component('BaseShowEditIsoDateTimeInput', BaseShowEditIsoDateTimeInput)
  app.component('BaseShowEditBooleanRadioGroup', BaseShowEditBooleanRadioGroup)
  app.component(
    'BaseShowEditUndefinedRadioGroup',
    BaseShowEditUndefinedRadioGroup
  )
  app.component(
    'BaseShowEditIsoDateToTimeInput',
    BaseShowEditIsoDateToTimeInput
  )
  app.component(
    'BaseShowEditIsoDateAndTimeRangeInput',
    BaseShowEditIsoDateAndTimeRangeInput
  )
  app.component(
    'BaseShowEditOptionGroupedSelectSimple',
    BaseShowEditOptionGroupedSelectSimple
  )
  app.component(
    'BaseShowEditTaggableSelectSimple',
    BaseShowEditTaggableSelectSimple
  )
  app.component('BaseShowEditRadio', BaseShowEditRadio)

  HelloWorld
}
