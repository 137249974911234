<template >
  <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
    <li v-for="(image, index) in gallery" :key="index" class="relative">
      <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
        <img :src="image.original_url" :alt="image.name"
             class="object-cover pointer-events-none group-hover:opacity-75"
        />
        <button type="button" class="absolute inset-0 focus:outline-none" @click="handleImageDisplay(image)">
          <span class="sr-only">View details for {{ image.name }}</span >
        </button >
      </div >
      <div class="flex flex-row justify-between">
        <div >
          <p
            class="mt-2 block text-sm font-medium text-gray-900 w-48 truncate pointer-events-none hover:text-clip"
          >
            {{ image.name }}
          </p >
        </div >
        <div v-if="editionMode" class="z-100">
          <DotMenu
            :entry="image"
            :options="options"
          />
        </div >
      </div >
    </li >
  </ul >

  <ImageModal
    :open="isImageModalOpen"
    :src="displayedImageSrc"
    :alt="displayedImageAlt"
    @close="isImageModalOpen = false"
  />
</template >

<script >
import DotMenu from "@c/DotMenu";
import ImageModal from "@ui/modal/image/ImageModal";

export default  {
  name: 'BaseShowEditGallery',
  components: { ImageModal, DotMenu },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    gallery: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      isImageModalOpen: false,
      displayedImageSrc: null,
      displayedImageAlt: null,
    }
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('button.see'),
          icon: 'eye',
          bgColor: 'bg-gradient-to-b from-gray-100 to-gray-200',
          fgColor: 'text-gray-700',
          clickFunction: (image) => this.handleImageDisplay(image)
        },
        {
          label: this.$t('button.delete'),
          icon: 'trash',
          bgColor: 'bg-gradient-to-b from-red-600 to-red-700',
          fgColor: 'text-white',
          clickFunction: (image) => this.handleImageDeletion(image)
        },
      ];
    }
  },
  methods: {
    handleImageDisplay(image) {
      console.log('handleImageDisplay', image)
      this.displayedImageSrc = image.original_url
      this.displayedImageAlt = image.name
      this.isImageModalOpen = true
    },
    handleImageDeletion(image) {
      console.log('handleImageDeletion', image)
    }
  },
}
</script >
