const WikiCityCemeteryInformation = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/cemetery/form/tabs/information/WikiCityCemeteryInformation.vue'
  )
const WikiCityCemeteryBurial = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/cemetery/form/tabs/burial/WikiCityCemeteryBurial.vue'
  )
const WikiCityCemeteryDrone = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/cemetery/form/tabs/drone/WikiCityCemeteryDrone.vue'
  )
const WikiCityCemeteryFormTabsComponent = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/cemetery/form/WikiCityCemeteryFormTabsComponent.vue'
  )
const WikiCityCemeteryIndexPage = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/cemetery/index/WikiCityCemeteryIndexPage.vue'
  )
const WikiPlaceItemsTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceItemsTab.vue'
  )
const WikiPlaceTranslationTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceTranslationTab.vue'
  )
const WikiPlaceContactTab = () =>
  import(
    /* webpackChunkName: "group-wiki" */ '@/views/wiki/communes/form/tabs/components/WikiPlaceContactTab.vue'
  )

export const wikiCityCemeteryRoute = [
  {
    path: 'cemetery',
    children: [
      {
        path: '',
        name: 'wiki_city_cemetery_index_page_route',
        component: WikiCityCemeteryIndexPage,
        props: true,
        meta: {
          pre_page: 'wiki_edit_city_municipal_administration_page_route',
          next_page: 'wiki_edit_city_ceremony_page_route'
        }
      },
      {
        path: ':cemetery_id',
        name: 'wiki_city_edit_cemetery_page_route',
        component: WikiCityCemeteryFormTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_cemetery_information_page_route',
            component: WikiCityCemeteryInformation,
            props: true
          },
          {
            path: 'burial',
            name: 'wiki_city_edit_cemetery_burial_page_route',
            component: WikiCityCemeteryBurial,
            props: true
          },
          {
            path: 'translation',
            name: 'wiki_city_edit_cemetery_translation_page_route',
            component: WikiPlaceTranslationTab,
            props: true
          },
          {
            path: 'drone',
            name: 'wiki_city_edit_cemetery_drone_page_route',
            component: WikiCityCemeteryDrone,
            props: true
          },
          {
            path: 'contact',
            name: 'wiki_city_edit_cemetery_contact_page_route',
            component: WikiPlaceContactTab,
            props: true
          },
          {
            path: 'items',
            name: 'wiki_city_edit_cemetery_items_page_route',
            component: WikiPlaceItemsTab,
            props: true
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_cemetery_page_route',
        component: WikiCityCemeteryFormTabsComponent,
        children: [
          {
            path: '',
            name: 'wiki_city_create_cemetery_information_page_route',
            component: WikiCityCemeteryInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_create_cemetery_translation_page_route',
            component: WikiPlaceTranslationTab
          },
          {
            path: 'burial',
            name: 'wiki_city_create_cemetery_burial_page_route',
            component: WikiCityCemeteryBurial
          },
          {
            path: 'drone',
            name: 'wiki_city_create_cemetery_drone_page_route',
            component: WikiCityCemeteryDrone
          },
          {
            path: 'contact',
            name: 'wiki_city_create_cemetery_contact_page_route',
            component: WikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_create_cemetery_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
