const WorkInProgressComponent = () =>
  import(
    /* webpackChunkName: "group-partner" */ '@/views/wip/WorkInProgressComponent'
  )

export const partnersRootPages = {
  path: 'partners',
  name: 'partners_page_route',
  component: WorkInProgressComponent
}

export const partnersSubPages = [
  {
    path: 'partners/enaos',
    name: 'enaos_page_route',
    component: WorkInProgressComponent
  },
  {
    path: 'partners/inMemory',
    name: 'inMemory_page_route',
    component: WorkInProgressComponent
  },
  {
    path: 'partners/fragment',
    name: 'fragment_page_route',
    component: WorkInProgressComponent
  },
  {
    path: 'partners/funebra',
    name: 'funebra_page_route',
    component: WorkInProgressComponent
  }
]
