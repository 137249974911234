export default {
  // setModelNotificationList(state, modelNotificationList){
  //   state.modelNotificationList = modelNotificationList
  // },
  // setModelNotificationSelected(state, modelNotificationSelected) {
  //   state.modelNotificationSelected = modelNotificationSelected.map(a => a.id)
  // },
  // setHasChanged(state, status) {
  //   state.hasChanged = status
  // },
  setMetadata(state, metadata) {
    state.metadata = metadata
  }
  // setSingleModelNotificationSelected(state, modelNotificationSelected) {
  //   if(state.modelNotificationSelected.includes(modelNotificationSelected)) {
  //     state.modelNotificationSelected = state.modelNotificationSelected.filter(id => id !== modelNotificationSelected)
  //   } else {
  //     state.modelNotificationSelected.push(modelNotificationSelected)
  //   }
  // },
  // setModelNotification(state, modelNotification){
  //
  //   if(modelNotification && !modelNotification.attachments) {
  //     modelNotification.attachments = []
  //   }
  //   state.modelNotification = modelNotification
  // },
  // addPredefinedTextMessageToModelNotification(state, text) {
  //   state.modelNotification.message = text
  // },
  // setResetEmail(state, value) {
  //   state.resetEmail = value
  // },
  // addModelNotificationAttachment(state, attachment) {
  //   console.log(state.modelNotification.attachments)
  //   if (!state.modelNotification.attachments) {
  //     state.modelNotification.attachments = []
  //   }
  //   if (
  //     !state.modelNotification.attachments.find(
  //       (att) => att.name === attachment.name
  //     )
  //   ) {
  //     state.modelNotification.attachments.push(attachment)
  //   }
  // },
  // removeAttachmentByIndex(state, index) {
  //   if (state.modelNotification.attachments[index] !== undefined) {
  //     state.modelNotification.attachments.splice(index, 1)
  //   }
  // },
  // removeDefaultAttachmentByIndex(state, index) {
  //   if (state.defaultAttachments[index] !== undefined) {
  //     state.defaultAttachments.splice(index, 1)
  //     state.modelNotification.attachable.splice(index, 1)
  //   }
  // }
}
