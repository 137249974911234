<template>
  <div>
    <div v-if="line" class="relative mt-5 border-t border-slate-300 px-3 py-2">
      <h4
        v-if="title"
        class="absolute -top-3 left-5 -mt-px inline-block bg-white px-1 text-base font-medium text-gray-800 capitalize-first"
      >
        {{ title }}
      </h4>
    </div>
    <div :class="sectionClass">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCardSubSection',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    line: {
      type: Boolean,
      required: false,
      default: true
    },
    sectionClass: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
