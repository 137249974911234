<template>
  <div :class="cGroupClass">
    <label :for="name" :class="cLabelClass" class="capitalize-first">
      {{ label }}&nbsp;<span v-if="isRequired" class="text-red-600">*</span>
    </label>
    <input
      :id="name"
      v-model="newValue"
      v-mask="mask"
      :name="name"
      :type="type"
      :class="cInputClass"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="isRequired"
      class="border-red-400"
    />

    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'BaseMaskedInput',
  directives: { mask },
  props: {
    name: { type: String, required: true },
    value: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        const admittedTypes = [
          'text',
          'email',
          'url',
          'tel',
          'search',
          'password',
          'textarea'
        ]
        return admittedTypes.indexOf(value) !== -1
      }
    },
    placeholder: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    mask: { type: String, required: false, default: '' },
    token: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    inputClass: { type: String, required: false, default: '' },
    errors: { type: Object, required: false }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    isDisabled() {
      return this.disabled
    },
    isRequired() {
      return this.required
    },
    cGroupClass() {
      return this.groupClass === ''
        ? 'relative rounded-md border border-slate-300 px-3 py-2 shadow-sm '
        : this.groupClass
    },
    cLabelClass() {
      return this.labelClass === ''
        ? 'absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first'
        : this.labelClass
    },
    cInputClass() {
      return this.inputClass === ''
        ? 'block w-full border-0 p-0  sm:text-sm'
        : this.inputClass
    }
  },
  watch: {
    newValue(newValue) {
      this.$emit('change', {
        value: newValue,
        attribute: this.name
      })
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
