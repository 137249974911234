<template>
  <div class="min-w-0 flex-1">
    <SettingsPageFull>
      <!--region header-->
      <template #title>
        <div class="flex space-x-3">
          <span class="text-3xl font-bold capitalize-first">{{
            $t('navigation.content_supplier_title')
          }}</span>
        </div>
      </template>

      <template #cta>
        <BaseButton
          v-if="$can('manage_content')"
          color="primary"
          :title="$t('button.add_new_m', { var: $t('attributes.supplier') })"
          icon="plus"
          @click="$router.push({ name: 'settings_create_supplier_route' })"
        />
      </template>
      <!--endregion-->

      <!--region body-->
      <template #body>
        <ToastUndoComponent
          :text="$t('toasts.deleted_m', { var: $t('attributes.supplier') })"
        />
        <BaseTableCardsComponent
          v-if="filteredEntries && metadata"
          :entries="filteredEntries"
          :table-metadata="metadata"
          :with-pagination="true"
          base-card-title="name"
          :storage-name="'dt-supplier-index'"
          :select-entry="
            (supplier) =>
              $router.push({
                name: 'settings_edit_supplier_route',
                params: { id: supplier.id }
              })
          "
          :actions="cActions"
          :available-filter="metadata.filters"
          @update:filters="handleUpdateFilters"
        >
          <template #table-cell="{ index, data: supplier, column }">
            <td
              v-if="index === 0"
              class="top-0 z-10 border-b border-gray-200 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              <div class="flex flex-row items-center gap-2">
                <div>
                  <router-link
                    :to="{
                      name: 'settings_edit_supplier_route',
                      params: { id: supplier.id }
                    }"
                    class="font-bold"
                  >
                    <div class="capitalize-first">{{ supplier.name }}</div>
                  </router-link>
                </div>
              </div>
            </td>
          </template>
          <template #card-details="{ entry: supplier }">
            <div class="flex-gap-1 px-5 pb-5 text-center lg:text-left">
              <div
                v-if="supplier.email"
                class="flex cursor-default items-center justify-center text-gray-600 lg:justify-start"
              >
                <MailIcon class="mr-2 h-3 w-3" />
                {{ supplier.email }}
              </div>
              <div
                v-if="supplier.phone_field"
                class="flex cursor-default cursor-default items-center justify-center text-gray-600 lg:justify-start"
              >
                <PhoneIcon class="mr-2 h-3 w-3" />
                <BaseShowPhoneLabel
                  :country-value="supplier.phone_country"
                  :model-value="supplier.phone_field"
                />
              </div>
              <div
                v-if="supplier.full_address"
                class="flex cursor-default items-center justify-center text-gray-600 lg:justify-start"
              >
                <font-awesome-icon
                  :icon="['fal', 'location-arrow']"
                  size="xs"
                  class="mr-2"
                />
                {{ supplier.full_address }}
              </div>
            </div>
          </template>
        </BaseTableCardsComponent>
      </template>
      <!--endregion-->
    </SettingsPageFull>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import cash from 'cash-dom/dist/cash'

import SettingsPageFull from '../../../../../components/SettingsPageFull'
import BaseTableCardsComponent from '@c/BaseTableCardsComponent'
import BasePaginationMixin from '@u/mixins/Pagination/BasePaginationMixin'
import BaseTableCardsFilterMixin from '@u/mixins/Filter/BaseTableCardsFilterMixin'

export default {
  name: 'TheSupplierIndexPage',
  components: {
    BaseTableCardsComponent,
    SettingsPageFull
  },
  mixins: [BasePaginationMixin, BaseTableCardsFilterMixin],
  data() {
    return {
      filterText: ''
    }
  },
  computed: {
    ...mapGetters({
      entries: 'supplier/getSupplierList',
      metadata: 'supplier/getMetadata'
    }),
    cActions() {
      return [
        {
          label: this.$t('button.edit'),
          icon: 'edit',
          bgColor: 'bg-gradient-to-b from-gray-100 to-gray-200',
          fgColor: 'text-gray-700',
          clickFunction: (supplier) =>
            this.redirectToEditableModelPage(supplier.id)
        },
        {
          label: this.$t('button.delete'),
          icon: 'trash',
          bgColor: 'bg-gradient-to-b from-red-600 to-red-700',
          fgColor: 'text-white',
          clickFunction: (supplier) => this.deleteSupplier(supplier.id)
        }
      ]
    }
  },
  mounted() {
    this.processFetchSupplierListAction()
    this.processSetEditionModeMutation(false)
  },
  methods: {
    ...mapActions({
      processFetchSupplierListAction: 'supplier/fetchSupplierList',
      processDeleteSupplierByIdAction: 'supplier/deleteSupplierById',
      processUndoDeleteSupplierByIdAction: 'supplier/undoDeleteSupplierById'
    }),
    ...mapMutations({
      processSetEditionModeMutation: 'supplier/setEditionMode'
    }),
    redirectToForm() {
      this.$router.push({ name: 'settings_edit_supplier_route' })
    },
    redirectToModelPage(id) {
      this.$router.push({
        name: 'settings_edit_supplier_route',
        params: { id: id }
      })
    },
    redirectToEditableModelPage(id) {
      this.processSetEditionModeMutation(true)
      this.$router.push({
        name: 'settings_edit_supplier_route',
        params: { id: id }
      })
    },
    deleteSupplier(id) {
      this.processDeleteSupplierByIdAction({
        id: id,
        successCallback: () => {
          const toast = cash('#notification-with-actions-content').clone()
          toast.find('#undo_btn').on('click', () => {
            this.undoDeleteSupplier(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass('hidden')[0]
          })
        }
      })
    },
    undoDeleteSupplier(id) {
      this.processUndoDeleteSupplierByIdAction({
        id: id,
        successCallback: () => {
          this.$h.toastify(
            this.$t('toasts.reseted_m', {
              var: this.$t('attributes.supplier')
            }),
            { className: 'toastify-content success' }
          )
        }
      })
    }
  }
}
</script>
