export default {
  getItemCategoryList(state) {
    return state.itemCategoryList
  },
  getItemCategory(state) {
    return state.itemCategory
  },
  getMetadata(state) {
    return state.metadata
  },
  getEditionMode(state) {
    return state.editionMode
  }
}
