const TheMessageTextForm = () =>
  import('@/views/settings/management/messageText/form/TheMessageTextForm.vue')
const TheMessageTextIndexPage = () =>
  import(
    '@/views/settings/management/messageText/index/TheMessageTextIndexPage.vue'
  )

export const settingsMessageTextRoutes = [
  {
    path: 'message-text',
    children: [
      {
        path: '',
        name: 'settings_message_texts_route',
        component: TheMessageTextIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_message_text_info_route',
        props: true,
        component: TheMessageTextForm,
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_message_text_route',
        props: true,
        component: TheMessageTextForm,
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      }
    ]
  }
]
