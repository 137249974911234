import cityActions from './city/actions'
import placeActions from './place/actions'
import addressActions from './address/actions'
import itemActions from './items/actions'
import contactActions from './contact/actions'
export default {
  ...cityActions,
  ...placeActions,
  ...addressActions,
  ...itemActions,
  ...contactActions
}
