import apiClient from '@u/apiClient'

export default {
  // region fetch
  fetchNoteIndex({ commit }) {
    apiClient
      .get('note')
      .then(async (response) => {
        await commit('setNotesIndex', response.data.notes)
        await commit('setNoteMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNoteIndex', reason)
      })
  },
  async fetchNewNote({ commit }) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .get('model/new?model=note')
      .then(async (response) => {
        await commit('setNote', response.data.note)
        await commit('setNoteMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await commit('fetchNewNote', reason)
      })
  },
  async fetchNoteById({ commit }, payload) {
    await apiClient
      .get(`note/${payload.id}`)
      .then(async (response) => {
        commit('setNote', response.data.note)
        commit('setNoteMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNoteById', reason)
      })
  },
  // endregion

  // region store
  async storeNote({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .post('note', payload.note)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setNotesIndex', response.data.notes)
          if (payload.successCallback) {
            payload.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        console.error('storeNote', reason)
      })
  },
  // endregion

  // region update
  async updateNote({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .put(`note/${payload.note.id}`, payload.note)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setNotesIndex', response.data.notes)
          if (payload.successCallback) {
            payload.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        console.error('updateNote', reason)
      })
  }
  // endregion
}
