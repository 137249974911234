<template>
  <div
    class="relative rounded-md border border-slate-300 bg-gray-50 px-3 py-2 shadow-sm"
    :class="[label ? 'mt-2' : '']"
  >
    <label
      v-if="label"
      class="absolute -top-2 left-2 z-10 -mt-px inline-block rounded-md bg-white/75 px-1 text-xs font-medium text-gray-900 capitalize-first"
    >
      {{ label }}
    </label>
    <div class="flex w-full flex-row justify-end border-0 p-0 sm:text-sm">
      {{ displayedValue }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseShowPriceLabel',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    },
    currency: {
      type: String,
      required: false,
      default: 'EUR'
    },
    priceType: {
      type: String,
      required: true,
      validator: (value) =>
        ['excluding_vat', 'vat', 'tax_included'].includes(value)
    }
  },
  computed: {
    displayedValue() {
      return parseFloat(this.value).toLocaleString('fr-BE', {
        style: 'currency',
        currency: this.currency
      })
    }
  }
}
</script>
