import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      usersList: [],
      user: {},
      userActivities: [],
      metadata: { columns: [] },
      metadataActivities: [],
      errorImport: [],
      editionMode: false
    }
  },
  getters,
  actions,
  mutations
}
