/* eslint-disable no-unused-vars */
import apiClient from '@u/apiClient'

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

const base_errors = {}

export default {
    // region fetch
    async fetchVariablesByModel({commit}, payload) {
        await apiClient
            .post("/get-variable", payload)
            .then(async (response) => {
                await commit('setVariables', response.data.variables)
            })
            .catch(async (reason) => {
                console.error('fetchVariablesByModel', reason)
            })
    },
    // endregion
    //region export items
    async fetchVariablesDataExport({commit}, payload) {
        await apiClient
            .post('/get-variable-export')
            .then(async (response) => {
                window.open(response.data.url)
                processSuccessCallback(payload)
            })
            .catch((reason) => {
                console.error('fetchVariablesDataExport', reason)
                commit('auth/setErrors', reason.response, {root: true})
            })
    },
    //endregion

}
