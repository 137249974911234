import Pusher from 'pusher-js'
import { helper } from '@u/helper'
import axios from 'axios'
import apiClient from '@u/apiClient'

const ApiPort = helper.getDevPort()
let pusher = null
let _app = null

let baseURL = ''
if (process.env.NODE_ENV === 'development') {
  baseURL = `http://${window.location.hostname}:${ApiPort}`
}

let setupPusher = (app_key, options, tenantId) => {
  return new Promise((resolve, reject) => {
    pusher = new Pusher(app_key, {
      useTLS: true,
      channelAuthorization: {
        customHandler: (params, callback) => {
          var formData = new FormData()
          formData.append('socket_id', params.socketId)
          formData.append('channel_name', params.channelName)
          apiClient
            .post('broadcasting/auth', formData)
            .then((response) => {
              callback(false, response.data)
            })
            .catch((reason) => {
              callback(true, reason.message)
            })
        }
      },
      // authEndpoint: `${baseURL}/api/broadcasting/auth`,
      // auth: {
      //   headers: {
      //     Accept: 'application/json',
      //     Authorization: `Bearer ${localStorage.getItem('token')}`
      //   }
      // },
      ...options
    })
    _app.config.globalProperties.$pusher = pusher
    resolve()
  })
}

export default {
  install(app) {
    _app = app
  },
  setupPusher
}
