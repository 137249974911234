<template>
  <div class="flex flex-col gap-x-2">
    <div class="my-2 flex items-center justify-between gap-x-3">
      <BaseButton
        v-if="modelValue"
        :title="$t('button.reset')"
        class="mt-0.5"
        icon="trash"
        color="warning"
        @click="modelValue = null"
      />
      <BaseSimpleSearchInput v-model:modelValue="filterText" model-value="" />
    </div>
    <div class="my-3 flex justify-end">
      <BaseButton
        title="tous les contacts"
        :icon="!funeralHomeFilter ? 'eye' : 'eye-slash'"
        class="mt-0.5"
        @click="toggleAllContacts"
      />
    </div>
    <template v-if="filteredContact.length < 1">
      <template v-if="!isWikiContact">
        <div class="mx-auto mt-12 text-center text-slate-300">
          {{
            $t('global.there_is_no_yet', {
              settings: roleId ? roleName?.toLowerCase() : $t('global.contact')
            })
          }}
          <span v-if="funeralHomeId">
            <span>{{ $t('global.for_funeral_home') + ' ' }}</span>
            <span class="font-bold">{{
              metadata?.models?.funeralHomes?.filter(
                (fh) => fh.id === funeralHomeId
              )[0]?.name
            }}</span>
          </span>
        </div>
      </template>
      <template v-else>
        <div class="mx-auto mt-12 text-center text-slate-300">
          {{ $t('validation.there_is_no_wiki_contact_for_your_search') }}
        </div>
      </template>
    </template>
    <template v-else>
      <div class="grid grid-cols-1 gap-4 py-4 md:grid-cols-2">
        <template v-for="contact in filteredContact" :key="contact.id">
          <SelectContactCard
            :contact="contact"
            :icon="icon"
            :is-wiki-contact="isWikiContact"
            :selected="contact.id === modelValue"
            @edit-contact="handleEditContact"
            @click="selectContact(contact.id)"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectContactCard from '@ui/modal/contact/components/SelectContactCard.vue'

export default {
  name: 'SelectContactList',
  components: { SelectContactCard },
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    roleId: {
      type: [String, Number],
      required: false,
      default: null
    },
    funeralHomeId: {
      type: [String, Number],
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user'
    },
    roleName: {
      type: String,
      required: false,
      default: null
    },
    isWikiContact: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'selectContactContact', 'editContact'],
  data() {
    return {
      filterText: '',
      funeralHomeFilter: this.funeralHomeId
    }
  },
  computed: {
    ...mapGetters({
      contactList: 'contact/getContactList',
      roleContactList: 'contact/getRoleContactList',
      metadata: 'metadata/getInitialMetadata',
      wikiContactList: 'wiki/getWikiContactList',
      dossier: 'dossier/getDossier'
    }),
    searchList() {
      let search_list = this.roleId ? this.roleContactList : this.contactList
      return search_list ?? []
    },
    internalList() {
      if (this.isWikiContact) {
        return this.wikiContactList ?? []
      } else {
        if (this.funeralHomeFilter === null) return this.searchList

        if (this.searchList.length > 0)
          return this.searchList.filter((contact) => {
            return Object.keys(contact.funeral_homes_id)?.includes(
              this.funeralHomeId
            )
          })

        return this.searchList
      }
    },
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    filteredContact() {
      if (this.searchList.length === 0) {
        return []
      }

      if (this.filterText.length < 1) {
        return this.internalList
      }

      let searchTerm = this.filterText.toLowerCase().trim()

      return this.searchList.filter((contact) => {
        if (this.isWikiContact) {
          contact?.full_name?.toLowerCase()?.includes(searchTerm)
        } else {
          return (
            contact?.company_name?.toLowerCase()?.includes(searchTerm) ||
            contact?.last_name?.toLowerCase()?.includes(searchTerm) ||
            contact?.first_name?.toLowerCase()?.includes(searchTerm)
          )
        }
      })
    }
  },
  watch: {
    modelValue(newValue, _oldValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  mounted() {
    if (!this.dossier?.contacts) this.contactType = 'all'
  },
  methods: {
    handleEditContact(contact_id) {
      this.$emit('editContact', contact_id)
    },
    toggleAllContacts() {
      if (this.funeralHomeFilter === null) {
        this.funeralHomeFilter = this.funeralHomeId
      } else {
        this.funeralHomeFilter = null
      }
    },
    selectContact(contact_id) {
      this.localModelValue = contact_id
    }
  }
}
</script>
