import apiClient from '@u/apiClient'

export default {
  //region fetch

  async fetchAddressesByDossier({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`dossier/addresses/${payload.dossier_id}`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  }
  //endregion
}
