export default {
  setRoleMetadata(state, roleMetadata) {
    state.roleMetadata = roleMetadata
  },
  setRoleList(state, roleList) {
    state.roleList = roleList
  },
  setRole(state, role) {
    state.role = role
  }
}
