import apiClient from '@u/apiClient'

export default {
  // region fetch
  fetchElectronicSignature({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post('/electronic-signature', payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchCountElectronicSignature({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('/electronic-signature/count')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchElectronicSignatureByStatus({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('/electronic-signature', {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchElectronicSignatureById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`/electronic-signature/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion
  // region store
  // endregion
  // region update
  // endregion
  //region delete
  deleteMediaFromEnvelope({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(
          `/electronic-signature/${payload.electronic_signature_id}/media/${payload.media_id}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  restoreMediaFromEnvelope({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(
          `/electronic-signature/${payload.electronic_signature_id}/media/${payload.media_id}/undo-delete`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion
  addPdfToEnvelope({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post('/electronic-signature/add-to-envelope', payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  sendToYouSign({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(
          `/electronic-signature/${payload.electronic_signature_id}/send-to-yousign`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  syncToYouSign({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(
          `/electronic-signature/${payload.electronic_signature_id}/sync-to-yousign`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
