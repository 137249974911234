<template>
  <RouterLink
    v-if="!disabled"
    :to="to"
    :active-class="'bg-primary-500 hover:bg-primary-500 text-white hover:text-white dark:text-blue-900 font-medium'"
    class="group/nav-link group flex cursor-default flex-col rounded-md p-1.5 pl-2.5 hover:bg-primary-400 hover:text-white dark:text-white"
  >
    <div class="flex flex-1 flex-row space-x-2">
      <div class="flex-shrink-0">
        <FontAwesomeIcon
          :icon="Array.isArray(icon) ? icon : ['fal', icon]"
          fixed-width
        />
      </div>
      <div class="flex flex-1 cursor-pointer flex-row gap-2">
        <span class="truncate capitalize">
          {{ label }}
        </span>
      </div>
      <div
        v-if="newAccountingDocumentRouteName"
        class="flex aspect-square shrink-0 cursor-pointer items-center rounded bg-white px-0.5 text-primary opacity-0 group-hover/nav-link:opacity-100"
        @click.stop.prevent="
          () => $router.push({ name: newAccountingDocumentRouteName })
        "
      >
        <FontAwesomeIcon :icon="['fal', 'plus']" fixed-width />
      </div>
    </div>
  </RouterLink>

  <div
    v-else
    class="flex items-center gap-x-1 p-1.5 text-gray-500 dark:text-white"
  >
    <font-awesome-icon
      :icon="Array.isArray(icon) ? icon : ['fal', icon]"
      size="lg"
      fixed-width
    />&nbsp;
    <p class="cursor-pointer capitalize-first">{{ label }}</p>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'InvoicingNavLink',
  components: { FontAwesomeIcon },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: Object,
      required: true
    },
    icon: {
      type: [Array, String],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    newAccountingDocumentRouteName: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
