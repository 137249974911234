<template>
  <button
    class="flex h-10 flex-row items-center justify-center gap-3 rounded-full bg-blue-100 px-3"
    @click="processSetCommandPaletteOpened(true)"
  >
    <FontAwesomeIcon :icon="['fal', 'search']" class="dark:text-gray-300" />
    <span class="pr-10 text-gray-500">{{ $t('global.search') }}</span>
  </button>

  <TransitionRoot :show="commandPaletteOpened" as="template" appear>
    <Dialog
      as="div"
      class="relative z-100"
      @close="processSetCommandPaletteOpened(false)"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-blue-100 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-100 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-3xl transform divide-y divide-gray-200 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox v-slot="{ activeOption }">
              <div class="relative">
                <FontAwesomeIcon
                  :icon="['fal', 'magnifying-glass']"
                  class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-slate-300"
                  aria-hidden="true"
                />
                <input
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-slate-300 focus:border-0 focus:outline-none sm:text-sm"
                  placeholder="Search..."
                  v-model="searchValue"
                  id="search-input"
                />
                <FontAwesomeIcon
                  v-if="loading"
                  :icon="['fal', 'spinner-third']"
                  class="pointer-events-none absolute right-4 top-3.5 h-5 w-5 animate-spin text-slate-300"
                  aria-hidden="true"
                  spin
                />
              </div>

              <ComboboxOptions
                v-if="searchResult.length"
                class="flex divide-x divide-gray-200"
                as="div"
                static
                hold
              >
                <div
                  :class="[
                    'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                    activeOption && 'sm:h-96'
                  ]"
                >
                  <div
                    hold
                    class="-mx-2 flex flex-col gap-2 text-sm text-gray-700"
                  >
                    <template v-for="result in searchResult">
                      <div>
                        <h2 class="py-1 font-bold capitalize-first">
                          {{ $t('attributes.' + result.label) }}
                        </h2>
                        <ComboboxOption
                          v-for="option in result.results"
                          :key="option.id"
                          :value="option"
                          as="template"
                          v-slot="{ active }"
                        >
                          <div
                            :class="[
                              'group flex cursor-pointer select-none flex-row items-center rounded-md p-2 ',
                              active && 'bg-gray-100 text-gray-900'
                            ]"
                            @click="clickResult(activeOption)"
                          >
                            <span class="flex-1 truncate capitalize-first">
                              {{ option.title }}
                              <small
                                v-if="
                                  result.type === 'App\\Models\\WikiCity' &&
                                  option.subtitle &&
                                  option.subtitle.length
                                "
                                class="ml-2 text-slate-600"
                              >
                                {{ option.subtitle }}
                              </small>
                            </span>
                            <span
                              v-if="option.distance_in_kilometers"
                              class="shrink-0 rounded bg-primary px-1 text-xs text-contrast-primary"
                            >
                              {{ option.distance_in_kilometers }} km
                            </span>
                          </div>
                        </ComboboxOption>
                      </div>
                    </template>
                  </div>
                </div>

                <div
                  v-if="activeOption"
                  class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-200 overflow-y-auto sm:flex"
                >
                  <div class="flex flex-col gap-3 py-4">
                    <div class="flex flex-shrink-0 flex-row px-4">
                      <div class="flex flex-1 flex-col">
                        <h2
                          class="cursor-pointer text-xl font-semibold capitalize text-gray-900"
                          @click="clickResult(activeOption)"
                        >
                          {{ activeOption.title }}
                        </h2>
                        <h3
                          v-if="activeOption.subtitle"
                          class="text text-lg font-semibold text-gray-400"
                        >
                          {{ activeOption.subtitle }}
                        </h3>
                      </div>
                      <div v-if="activeOption.thumbnail" class="flex-shrink-0">
                        {{ activeOption.thumbnail }}
                      </div>
                    </div>
                    <div class="flex flex-col gap-1.5">
                      <template v-for="info in activeOption.extra">
                        <div
                          v-if="info.type === 'text' && info.value"
                          class="flex flex-row px-4"
                        >
                          <div class="flex flex-1 flex-col">
                            <span class="text-xs text-gray-700"
                              >{{ $t(info.label) }}
                            </span>
                            <span
                              class="-mt-0.5 font-medium"
                              v-html="info.value"
                            />
                          </div>
                          <div
                            v-if="info.links"
                            class="flex flex-shrink-0 flex-row items-center"
                          >
                            <template v-for="link in info.links">
                              <div
                                v-if="link.type === 'email'"
                                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-primary-500 text-white"
                                @click="sendEmail(link)"
                              >
                                <FontAwesomeIcon
                                  :icon="['fal', 'envelope']"
                                ></FontAwesomeIcon>
                              </div>
                              <a
                                v-if="link.type === 'external-link'"
                                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-primary-500 text-white"
                                :href="link.url"
                                target="_blank"
                              >
                                <FontAwesomeIcon
                                  :icon="['fal', link.icon ?? 'external-link']"
                                />
                              </a>
                            </template>
                          </div>
                        </div>
                        <div
                          v-else-if="info.type === 'image' && info.src"
                          class="flex flex-col px-4 py-1"
                        >
                          <img
                            :src="info.src"
                            alt=""
                            class="rounded-lg border p-1"
                          />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </ComboboxOptions>

              <div
                v-if="searchValue !== '' && searchResult.length === 0"
                class="px-6 py-14 text-center text-sm sm:px-14"
              >
                <p class="mt-2 text-gray-500">
                  {{ $t('global.no_result_found') }}
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import BaseButton from '@c/BaseButtonEXTRA.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from '@u/debounce'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'CommandPalette',
  components: {
    FontAwesomeIcon,
    BaseButton,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot
  },
  computed: {
    ...mapGetters({
      searchResult: 'globalSearch/getSearchResult',
      commandPaletteOpened: 'globalSearch/getCommandPaletteOpened'
    }),
    searchValue: {
      get() {
        return this.$store.state.globalSearch.searchValue
      },
      set(value) {
        this.$store.commit('globalSearch/setSearchValue', value)
      }
    }
  },
  data() {
    return {
      longitude: null,
      latitude: null,
      loading: false
    }
  },
  watch: {
    searchValue(...args) {
      this.debouncedSearch(...args)
    },
    commandPaletteOpened(newValue, oldValue) {
      if (newValue === true && newValue !== oldValue) {
        setTimeout(() => document.getElementById('search-input').select(), 250)
      }
    }
  },
  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log(position)
          this.latitude = position.coords.latitude
          this.longitude = position.coords.longitude
        },
        (error) => {
          // console.log(error)
        },
        { timeout: 10000 }
      )
    } else {
      // console.log('navigator.geolocation unavailable')
    }
  },
  created() {
    this.debouncedSearch = debounce((newValue, oldValue) => {
      if (newValue && newValue.length >= 3) {
        this.loading = true
        this.processGetGlobalSearchResultAction({
          terms: newValue,
          latitude: this.latitude,
          longitude: this.longitude
        }).finally((_) => (this.loading = false))
      } else if (!newValue || newValue.length === 0) {
        this.processResetSearchResult()
      }
    }, 750)
  },
  methods: {
    ...mapActions({
      processGetGlobalSearchResultAction: 'globalSearch/getGlobalSearchResult'
    }),
    ...mapMutations({
      processSetCommandPaletteOpened: 'globalSearch/setCommandPaletteOpened',
      processResetSearchResult: 'globalSearch/resetSearchResult',
      processSetTo: 'email/setTo',
      processSetIsModalOpen: 'email/setIsModalOpen',
      processResetEmail: 'email/resetEmail'
    }),
    clickResult(result) {
      this.$router.push(result.router)
      this.processSetCommandPaletteOpened(false)
    },
    sendEmail(email) {
      this.processResetEmail()
      this.processSetTo(email.email)
      this.processSetIsModalOpen(true)
      this.processSetCommandPaletteOpened(false)
    }
  }
}
</script>
