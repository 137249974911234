import apiClient from '@u/apiClient'
import Promise from 'lodash-es/_Promise'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  getDossierAttachment: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/dossier/${payload.dossier_id}/attachment`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  deleteDossierPicture: ({ commit }, payload) => {
    return apiClient
      .delete(
        `/dossier/${payload.dossier_id}/picture/${payload.collection_name}`
      )
      .catch(async (reason) => {
        await console.error(reason)
      })
  },
  updateDossierPictureByCollectionName: ({ commit }, payload) => {
    return apiClient
      .post(
        `/dossier/${payload.dossier_id}/picture/${payload.collection_name}`,
        payload.form_data
      )
      .catch(async (reason) => {
        await console.error(reason)
      })
  }
}
