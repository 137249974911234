<template>
  <div class="">
    <label :for="name" class="form-label">{{ label }}</label>
    <div class="relative w-full">
      <div class="w-22 absolute inset-y-0 left-0">
        <label class="sr-only">Country</label>
        <select
          v-model="countryValue"
          :disabled="isDisabled"
          :name="name + '_country'"
          class="block w-full appearance-none rounded-md border border-slate-300 placeholder-gray-500 focus:border-blue-300 focus:outline-none focus:ring-1 focus:ring-blue-300 disabled:cursor-not-allowed disabled:bg-gray-100 dark:border-transparent dark:bg-slate-800 disabled:dark:border-transparent disabled:dark:bg-slate-800"
        >
          <option disabled selected>{{ countryPlaceholder }}</option>
          <option
            v-for="(model, key) in countries"
            :key="key"
            :value="model.id"
            :disabled="!$can('manage_content')"
          >
            {{ model.id }}
          </option>
        </select>
      </div>
      <input
        :id="name"
        v-model="numberValue"
        type="text"
        :name="name + '_field'"
        class="block w-full appearance-none rounded-md border border-slate-300 px-6 py-2 pl-24 placeholder-gray-500 focus:border-blue-300 focus:outline-none focus:ring-1 focus:ring-blue-300 disabled:cursor-not-allowed disabled:bg-gray-100 dark:border-transparent dark:bg-slate-800 disabled:dark:border-transparent disabled:dark:bg-slate-800"
        :disabled="isDisabled"
        :placeholder="numberPlaceholder"
      />
    </div>
    <div v-if="errors" class="form-help text-red-600">
      <div v-for="(error, key) in errors" :key="key">
        {{ $t(error[0], { attribute: $t('attributes.' + key) }) }}
      </div>
    </div>
  </div>
</template>

<script>
// import _ from "core-js/internals/internal-state";

export default {
  name: 'PhoneInput',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    model: {
      type: Object,
      required: false
    },
    countryPlaceholder: {
      type: String,
      required: false
    },
    numberPlaceholder: {
      type: String,
      required: false
    },
    errors: {
      type: Object,
      required: false
    },
    countries: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      numberValue: null,
      phoneErrors: null,
      countryValue: null,
      metadata: {}
    }
  },
  computed: {
    isDisabled() {
      return this.disabled
    }
  },
  watch: {
    model(newValue) {
      this.numberValue = newValue[this.name + '_field']
      this.countryValue = newValue[this.name + '_country']
    },
    numberValue(newValue) {
      this.$emit('change', {
        value: newValue,
        attribute: `${this.name}_field`
      })
    },
    countryValue(newValue) {
      this.$emit('change', {
        value: newValue,
        attribute: `${this.name}_country`
      })
    }
    // errors(newValue){
    //   this.phoneErrors =  _.pick(newValue,[`${this.name}_country`,`${this.name}_field`])
    // },
  },
  mounted() {
    this.numberValue = this.model[this.name + '_field']
    this.countryValue = this.model[this.name + '_country']
  }
}
</script>
