<template>
  <div
    :class="[
      disabled ? 'text-gray-500' : 'text-gray-600',
      'flex w-0 flex-1 cursor-pointer items-center'
    ]"
  >
    <FontAwesomeIcon
      :icon="['fal', 'paperclip']"
      class="flex-shrink-0 text-sky-600"
      aria-hidden="true"
    />
    <button
      class="ml-2 w-0 flex-1 select-none truncate text-left text-sky-600 underline"
      @click="ShowPreviewModal"
    >
      {{ title }}
    </button>
  </div>
  <div v-if="!disabled" class="ml-4 flex-shrink-0">
    <FontAwesomeIcon
      :icon="['fal', 'trash']"
      size="sm"
      class="flex-shrink-0 cursor-pointer text-red-700"
      aria-hidden="true"
      @click="$emit('delete-attachment')"
    />
  </div>
  <ModalViewMedia
    v-if="attachment"
    :media="attachment"
    :open="toggleViewModal"
    @close="ShowPreviewModal"
  />
</template>

<script>
import ModalViewMedia from '@c/Media/ModalViewMedia.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'EmailAttachment',
  components: { FontAwesomeIcon, ModalViewMedia },
  props: {
    attachmentIndex: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String,
      required: true
    },
    attachment: {
      type: Object,
      required: false,
      default: null
    },
    url: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['delete-attachment'],
  data() {
    return {
      toggleViewModal: false
    }
  },
  methods: {
    ShowPreviewModal() {
      this.toggleViewModal = !this.toggleViewModal
    }
  }
}
</script>
