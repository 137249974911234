<template>
  <div>
    <!--#region edition mode -->
    <template v-if="editionMode">
      <div
        class="relative rounded-md border border-slate-300 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
        :class="[label ? 'mt-2' : '']"
      >
        <!--#region label -->
        <div class="flex">
          <label
            v-if="label"
            :for="name"
            class="absolute -top-2 left-2 z-10 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
            >{{ label }}&nbsp;
            <span
              v-if="displayTimeDifference && timeDifference"
              class="capitalize-first"
            >
              ( {{ timeDifference }} )
            </span>
            <span v-if="label && required" class="text-red-600">*</span>
          </label>
        </div>
        <!--#endregion -->
        <!--#region inputs -->
        <div class="flex -space-x-px divide-x">
          <!--#region date -->
          <div class="w-5/6 min-w-0">
            <!--#region Input -->
            <template v-if="!isDatePicker">
              <input
                :id="name"
                v-model="internalDate"
                v-mask="dateMask"
                type="text"
                :name="name"
                class="relative block w-full rounded-none rounded-bl-md rounded-tl-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-300 focus:ring-blue-300 sm:text-sm"
                :placeholder="datePlaceholder || $t('global.date')"
                @input="updateInput"
              />
            </template>
            <!--#endregion -->
            <!--#region datePicker -->
            <template v-else>
              <DatePicker
                v-model:date="dateValue"
                class="relative z-50 block w-full rounded-none rounded-bl-md rounded-tl-md border-0 border-gray-300 bg-transparent focus:z-10 focus:border-0 focus:ring-0 sm:text-sm"
                :view="view"
                :events="events"
              />

              <!--                :errors="errors?.concernedPerson?.birth_date"-->
              <!--              <datepicker-->
              <!--                v-model="datePickerValue"-->
              <!--                :month-heading-format="monthFormat"-->
              <!--                :weekday-format="weekFormat"-->
              <!--                :input-format="datePickerFormat"-->
              <!--                :week-starts-on="startingDayOfTheWeek"-->
              <!--                :clearable="clearButtonVisible"-->
              <!--                :typeable="canTypeDateManually"-->
              <!--                :starting-view="viewType"-->
              <!--                :style="style"-->
              <!--                :readonly="false"-->
              <!--                :locale="locale"-->
              <!--                class="relative z-50 block w-full rounded-none rounded-bl-md rounded-tl-md border-0 border-gray-300 bg-transparent focus:z-10 focus:border-0 focus:ring-0 sm:text-sm"-->
              <!--                @update:modelValue="handleDateSelectedInDatePicker"-->
              <!--              />-->
            </template>
            <!--#endregion -->
          </div>
          <!--#endregion -->

          <!--#region time -->
          <div v-if="showTimeInput" class="relative min-w-0">
            <label
              class="absolute -top-2 left-2 z-10 -mt-px inline-block hidden bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
            >
              {{ timePlaceholder || $t('global.time') }}
            </label>
            <input
              v-if="!isTimePicker"
              :id="`time-input-${name}`"
              ref="timeInput"
              v-model="internalTime"
              v-mask="timeMask"
              type="text"
              name="card-cvc"
              class="relative block w-full rounded-none rounded-br-md rounded-tr-md border-0 bg-transparent focus:z-10 focus:border-0 focus:ring-0 sm:text-sm"
              @input="updateInput"
              @click="selectAllText"
            />
            <datepicker
              v-else
              v-model="timePickerValue"
              :month-heading-format="monthFormat"
              :weekday-format="weekFormat"
              :input-format="timePickerFormat"
              :week-starts-on="startingDayOfTheWeek"
              :clearable="clearButtonVisible"
              :typeable="canTypeDateManually"
              :starting-view="'time'"
              :minimum-view="'time'"
              :style="style"
              :readonly="false"
              :locale="locale"
              class="relative block w-full rounded-none rounded-br-md rounded-tr-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-300 focus:ring-blue-300 sm:text-sm"
              @update:modelValue="handleTimeSelectedInTimePicker"
            />
          </div>
          <!--#endregion -->
        </div>
        <!--#endregion -->
      </div>
      <!--#region errors -->
      <template v-if="internalErrors">
        <div
          v-for="(error, index) in internalErrors"
          :key="index"
          class="form-help text-red-600"
        >
          {{ $t(error) }}
        </div>
      </template>
      <ErrorContainer :error-key="name" :errors="errors" />
      <!--#endregion -->
    </template>
    <!--#endregion -->
    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="displayedValueWhenNotInEditMode"
        :additional-information="displayTimeDifference ? timeDifference : null"
      />
    </template>
  </div>
</template>

<script>
import DatePicker from '@c/addf-package/components/BaseShowEditDatePicker/Component/DatePicker.vue'

import { fr } from 'date-fns/locale'
import moment from 'moment'
import BaseShowEditDatePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditIsoDateTimeInput',
  components: {
    ErrorContainer,
    BaseShowEditDatePicker,
    DatePicker
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    dateTo: {
      type: String,
      required: false,
      default: null
    },
    undefinedTimeValue: {
      type: Boolean,
      required: false,
      default: null
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    dateMask: {
      type: String,
      required: false,
      default: '##/##/####'
    },
    timeFormat: {
      type: String,
      required: false,
      default: 'HH:mm'
    },
    timeMask: {
      type: String,
      required: false,
      default: '##:##'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    datePlaceholder: {
      type: String,
      required: false,
      default: ''
    },
    timePlaceholder: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    displayTimeDifference: {
      type: Boolean,
      require: false,
      default: false
    },
    customReferenceDate: {
      type: String,
      required: false,
      default: null
    },
    customReferenceDateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    isDatePicker: {
      type: Boolean,
      required: false,
      default: true
    },
    monthFormat: {
      type: String,
      required: false,
      default: 'LLLL yyyy'
    },
    weekFormat: {
      type: String,
      required: false,
      default: 'EE'
    },
    startingDayOfTheWeek: {
      type: Number,
      required: false,
      default: 1
    },
    canTypeDateManually: {
      type: Boolean,
      required: false,
      default: false
    },
    clearButtonVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    viewType: {
      type: String,
      required: false,
      default: 'day',
      validator: function (value) {
        const admittedTypes = ['day', 'month', 'year', 'time']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    datePickerFormat: {
      type: String,
      required: false,
      default: 'dd/MM/yyyy'
    },
    isTimePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    showTimeInput: {
      type: Boolean,
      required: false,
      default: true
    },
    timePickerFormat: {
      type: String,
      required: false,
      default: 'hh:mm'
    }
  },
  emits: ['update:modelValue', 'update:undefinedTimeValue', 'change'],
  data() {
    return {
      internalDate: null,
      internalTime: null,
      internalUndefinedTime: null,
      internalErrors: [],
      locale: fr,
      style: {
        '--vdp-bg-color': '#ffffff',
        '--vdp-text-color': '#424242',
        '--vdp-box-shadow':
          '0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)',
        '--vdp-border-radius': '8px',
        '--vdp-heading-size': '2.5em',
        '--vdp-heading-weight': 'bold',
        '--vdp-heading-hover-color': '#eeeeee',
        '--vdp-arrow-color': 'currentColor',
        '--vdp-elem-color': 'currentColor',
        '--vdp-disabled-color': '#d5d9e0',
        '--vdp-hover-color': '#ffffff',
        '--vdp-hover-bg-color': '#4386C8',
        '--vdp-selected-color': '#ffffff',
        '--vdp-selected-bg-color': '#4386C8',
        '--vdp-elem-font-size': '0.8em',
        '--vdp-elem-border-radius': '3px',
        '--vdp-divider-color': '#d5d9e0'
      }
    }
  },
  computed: {
    timePickerValue() {
      return new Date(moment(this.internalTime, this.timeFormat))
    },
    datePickerValue() {
      return new Date(moment(this.internalDate, this.dateFormat))
    },
    isAValidDate() {
      const subValidation = moment(
        this.joinedInternalValue,
        this.joinedFormat
      ).format(this.joinedFormat)

      this.internalErrors = []

      if (subValidation === this.joinedInternalValue) {
        return moment(this.joinedInternalValue, this.joinedFormat).isValid()
      }

      if (
        !this.internalDateIsAValidDate &&
        this.internalDate !== null &&
        this.internalErrors.indexOf('validation.register_valid_date') === -1
      ) {
        this.internalErrors.push('validation.register_valid_date')
      }

      if (
        !this.internalTimeIsAValidTime &&
        this.internalErrors.indexOf('validation.register_valid_time') === -1
      ) {
        if (!this.internalUndefinedTime) {
          this.internalErrors.push('validation.register_valid_time')
        }
      }
      return false
    },
    internalDateIsAValidDate() {
      return (
        moment(this.internalDate, this.dateFormat).format(this.dateFormat) ===
        this.internalDate
      )
    },
    internalTimeIsAValidTime() {
      return (
        moment(this.internalTime, this.timeFormat).format(this.timeFormat) ===
        this.internalTime
      )
    },
    joinedFormat() {
      return [this.dateFormat, this.timeFormat].join(' - ')
    },
    joinedInternalValue() {
      let temp = null
      if (!this.internalTime || this.internalTime === '') {
        temp = this.timeMask.replaceAll('#', '0')
        this.$emit('update:undefinedTimeValue', 1)
      } else {
        this.$emit('update:undefinedTimeValue', 0)
        temp = this.internalTime
      }
      return [this.internalDate, temp].join(' - ')
    },
    displayedValueWhenNotInEditMode() {
      if (this.noDateProvided) {
        return null
      }
      if (this.internalUndefinedTime) {
        return this.internalDate
      }

      return this.joinedInternalValue
    },
    timeDifference() {
      if (!this.customReferenceDate) {
        if (this.isAValidDate) {
          if (this.internalUndefinedTime) {
            return moment(this.internalDate, this.dateFormat)
              .lang('fr')
              .from(moment().startOf('day'))
          } else {
            return moment(this.joinedInternalValue, this.joinedFormat)
              .lang('fr')
              .from(moment())
          }
        }
        return null
      }
      if (this.isAValidDate) {
        if (this.internalUndefinedTime) {
          return moment(this.internalDate, this.dateFormat)
            .lang('fr')
            .from(
              moment(this.customReferenceDate, this.customReferenceDateFormat)
            )
        } else {
          return moment(this.joinedInternalValue, this.joinedFormat)
            .lang('fr')
            .from(
              moment(this.customReferenceDate, this.customReferenceDateFormat)
            )
        }
      }
      return null
    },

    noDateProvided() {
      return (
        (!this.internalDate || this.internalDate === '') &&
        (!this.internalTime || this.internalTime === '')
      )
    }
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        // calcul du nouveau date_to en appliquant l'ancien offset
        if (
          oldValue !== undefined && // test si ce n'est pas le watch au mount
          newValue !== oldValue && // si va leur n'a pas changé, ne rien faire
          this.dateTo !== null // test si le dateTo a bien été envoyé dans le v-model
        ) {
          // récupération de la différence de temps entre le dateFrom original et le date to
          let offset = moment(this.dateTo).diff(moment(oldValue), 'seconds')
          if (offset !== 0) {
            this.$emit(
              'update:dateTo',
              // on applique l'offset de temps sur le nouveau dateFrom pour avoir le nouveau dateTo
              moment(newValue).add(offset, 'seconds').format('YYYY-MM-DD HH:mm')
            )
          }
        }
        if (newValue) {
          this.internalDate = moment(newValue).format(this.dateFormat)
          this.internalTime = this.internalUndefinedTime
            ? null
            : moment(newValue).format(this.timeFormat)
        } else {
          this.internalDate = null
          this.internalTime = null
        }
      },
      immediate: true,
      deep: true
    },
    undefinedTimeValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.internalUndefinedTime = Boolean(newValue)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    selectAllText() {
      this.$refs.timeInput.select()
    },
    handleTimeSelectedInTimePicker(timePickerTime) {
      this.internalTime = moment(timePickerTime).format(this.timeFormat)
      this.updateInput()
    },
    handleDateSelectedInDatePicker(datePickerDate) {
      this.internalDate = moment(datePickerDate).format(this.dateFormat)
      this.updateInput()
    },
    updateInput() {
      if (this.isAValidDate) {
        this.$emit(
          'update:modelValue',
          moment(this.joinedInternalValue, this.joinedFormat).format()
        )
        this.$emit(
          'change',
          moment(this.joinedInternalValue, this.joinedFormat).format()
        )
      }
    }
  }
}
</script>
<style>
.v3dp__popout {
  z-index: 50 !important;
}
</style>
