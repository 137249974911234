export default {
  getLocationIndex(state) {
    return state.locationList
  },
  getSelectedLocation(state) {
    return state.location
  },
  getLocationMetadata(state) {
    return state.locationMetadata
  },
  getLocationActivity(state) {
    return state.locationActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
  getEditionMode(state) {
    return state.editionMode
  }
}
