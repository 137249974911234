<template>
  <div :class="cGroupClass">
    <template v-if="editionMode">
      <div>
        <BaseEditLabel :label="label" :required="required" />
        <div class="mt-1 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              :id="id"
              :type="type"
              :min="min"
              :max="max"
              :value="modelValue"
              :placeholder="placeholder"
              class="form-control rounded-none rounded-l-md border-slate-300 focus:border-blue-300 focus:ring-1 focus:ring-blue-300"
              @input="updateInput"
            />
          </div>
          <button
            type="button"
            class="relative -ml-px inline-flex content-center items-center space-x-2 rounded-r-md px-4 py-2 text-sm font-medium hover:bg-slate-300 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            :class="buttonClasses"
            @click="buttonFunction"
          >
            <FontAwesomeIcon
              v-if="buttonIcon"
              class="item-center flex h-full w-3 cursor-pointer content-center dark:bg-slate-800"
              :icon="['fal', buttonIcon]"
              size="2x"
            />
            <span v-if="buttonLabel">{{ buttonLabel }}</span>
          </button>
        </div>
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>

    <template v-else>
      <BaseShowLabel :label="label" :model-value="modelValue" />
    </template>
  </div>
</template>

<script>
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'

export default {
  name: 'BaseShowEditInputButton',
  components: { BaseShowLabel, BaseEditLabel, FontAwesomeIcon, ErrorContainer },
  props: {
    name: {
      type: String,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    parseType: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        const admittedTypes = ['text', 'int', 'float']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    buttonLabel: { type: String, required: false, default: null },
    buttonColor: {
      type: String,
      required: false,
      default: 'primary',
      validator: function (value) {
        const possibleTypes = [
          '',
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'dark',
          'info'
        ]
        return possibleTypes.indexOf(value) !== -1
      }
    },
    isOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    isRounded: {
      type: Boolean,
      required: false,
      default: false
    },
    isSoft: {
      type: Boolean,
      required: false,
      default: false
    },
    isElevated: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function (value) {
        const possibleSizes = ['', 'small', 'medium', 'large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    buttonIcon: { type: String, required: false, default: null },
    buttonFunction: {
      type: Object,
      required: false
    }
  },
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? ' w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === '' ? 'form-control' : this.inputClass
    },
    buttonTextColorClass() {
      return this.textColor ? `text-${this.textColor}` : ''
    },
    buttonCursorClass() {
      return `cursor-${this.cursor}`
    },
    buttonRoundedClass() {
      return this.isRounded ? 'rounded-full' : ''
    },
    buttonTypeClass() {
      if (this.isOutline) {
        return `btn-outline-${this.buttonColor}`
      }
      if (this.isSoft) {
        return `btn-${this.buttonColor}-soft`
      }
      return `btn-${this.buttonColor}`
    },
    buttonSizeClass() {
      switch (this.size) {
        case 'small':
          return 'btn-sm'
        case 'large':
          return 'btn-lg'
        case 'medium':
        default:
          return ''
      }
    },
    buttonElevationClass() {
      return this.isElevated ? 'shadow-md' : ''
    },
    buttonClasses() {
      return [
        this.buttonTypeClass,
        this.buttonRoundedClass,
        this.buttonElevationClass,
        this.buttonSizeClass,
        this.scopedClasses,
        this.buttonCursorClass,
        this.buttonTextColorClass
      ].join(' ')
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
