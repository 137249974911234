const TheInvoicingCategorySettingPage = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/invoicing/invoicingCategory/index/TheInvoicingCategoryIndexPage.vue'
  )
const TheInvoicingCategoryFormTabComponent = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/invoicing/invoicingCategory/form/TheInvoicingCategoryFormTabComponent.vue'
  )
const TheInvoicingCategoryInformationPage = () =>
  import(
    /* webpackChunkName: "group-settings" */ '@/views/settings/invoicing/invoicingCategory/form/tabs/TheInvoicingCategoryInformationPage.vue'
  )

export const settingsInvoicingCategoryRoutes = [
  {
    path: 'invoicing-category',
    children: [
      {
        path: '',
        name: 'settings_invoicing_categories_route',
        component: TheInvoicingCategorySettingPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_invoicing_category_profile_route',
        props: true,
        component: TheInvoicingCategoryFormTabComponent,
        children: [
          {
            path: '',
            component: TheInvoicingCategoryInformationPage,
            name: 'settings_edit_invoicing_category_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: false },
            { title: 'content_item_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_invoicing_categories_route',
        component: TheInvoicingCategoryFormTabComponent,
        children: [
          {
            path: '',
            component: TheInvoicingCategoryInformationPage,
            name: 'settings_create_invoicing_category_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_item_new_title', active: true }
          ]
        }
      }
    ]
  }
]
