<template>
  <div v-if="editionMode" :class="groupClass">
    <div
      class="base-multiselect-simple relative mt-1.5 rounded-md border border-slate-300 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
    >
      <!--      <BaseEditLabel :label="label" :required="required"/>-->
      <label
        :for="name"
        class="absolute -top-2 left-2 z-10 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
        >{{ label }}&nbsp;
        <span v-if="required" class="text-red-600">*</span>
      </label>
      <multiselect
        :options="orderItem"
        :placeholder="cPlaceholder"
        :model-value="cDefautlValue"
        :searchable="searchable"
        :label="attributeLabel"
        :track-by="trackBy"
        :disabled="disabled"
        :required="required"
        :select-label="$t('button.select')"
        :deselect-label="$t('button.unselect')"
        :selected-label="$t('button.selected')"
        :show-pointer="showPointer"
        :hide-selected="hideSelected"
        @update:modelValue="updateValueAction"
      >
        <template #noOptions
          ><span>{{ $t('global.is_empty_list') }}</span></template
        >
      </multiselect>
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>

  <div v-else>
    <BaseShowLabel
      :label="label"
      :model-value="cDefautlValue ? cDefautlValue[attributeLabel] : null"
    />
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import BaseShowLabel from '../../BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditSelectSimple',
  components: {
    ErrorContainer,
    multiselect,
    BaseShowLabel
  },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: '',
      require: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    selectClass: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    showPointer: {
      type: Boolean,
      required: false,
      default: true
    },
    hideSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    canTrackByBeNull: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    cDefautlValue() {
      if (
        this.options &&
        this.options.find((option) => option[this.trackBy] === this.modelValue)
      ) {
        return this.options.find(
          (option) => option[this.trackBy] === this.modelValue
        )
      } else {
        return null
      }
    },
    cPlaceholder() {
      if (this.placeholder) return this.placeholder
      return this.$t('button.select_an_option')
    },
    orderItem(){
      return this.options.sort((a, b) => a.name.localeCompare(b.name))

    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue) {
        this.$emit('update:modelValue', newValue[this.trackBy])
        this.$emit('change', newValue[this.trackBy])
      } else {
        if (this.canTrackByBeNull) {
          this.$emit('update:modelValue', null)
          this.$emit('change', null)
        }
      }
    }
  }
}
</script>
