export default {
  setTaskDossierList(state, taskDossierList) {
    state.taskDossierList = taskDossierList
  },
  setTaskDossier(state, taskDossier) {
    state.taskDossier = taskDossier
  },
  addUserResponsibleToTaskDossier(state, payload) {
    state.taskDossier.users[0]['manager'] = payload.user
  },
  addUserExecutorToTaskDossier(state, payload) {
    state.taskDossier.users[0]['executors'].push(payload.user)
    state.taskDossier.users[0]['executors'] = [
      ...new Set(state.taskDossier.users[0]['executors'])
    ]
  },
  addExecutorToTaskDossier(state, payload) {
    state.taskDossier.users.executors.push(payload.executor)
    state.taskDossier.users.executors = [
      ...new Set(state.taskDossier.users.executors)
    ]
  },
  deleteUserExecutorToTaskDossier(state, payload) {
    let index = Object.keys(state.taskDossier.users[0]['executors']).find(
      (key) => state.taskDossier.users[0]['executors'][key] === payload.user
    )
    state.taskDossier.users[0]['executors'].splice(index, 1)
  },
  setTaskDossierUserFuneralHomeId(state, funeral_home_id) {
    state.taskDossier.users.funeral_home_id = funeral_home_id
  },
  setTaskDossierManager(state, manager) {
    state.taskDossier.users.manager = manager
  },
  setTaskDossierComments(state, comments) {
    state.taskDossier.comments = comments
  }
}
