<template>
  <div
    class="relative rounded-md border border-gray-300 py-2 pl-3 shadow-sm"
    :class="label ? 'mt-2' : ''"
  >
    <label
      class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
    >
      {{ label }}
    </label>
    <select
      v-model="selectedValue"
      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
    >
      <option
        v-for="option in options"
        :key="option[trackBy]"
        :value="option[trackBy]"
        v-text="option.name"
      />
    </select>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'SelectSimpleComponent',
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const selectedValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value)
        emit('change', value)
      }
    })
    return { selectedValue }
  }
}
</script>

<style scoped></style>
