<template>
  <div class="flex flex-col">
    <label v-if="label" class="form-label capitalize-first"
      >{{ label }}&nbsp;<span v-if="required" class="text-red-600"
        >*</span
      ></label
    >
    <multiselect
      v-model="value"
      :options="orderItems"
      multiple
      :placeholder="placeholder"
      :searchable="searchable"
      :label="attributeLabel"
      :track-by="trackBy"
      :disabled="disabled"
      :required="required"
    />
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseSelectMultiple',
  components: {
    ErrorContainer,
    multiselect
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    errors: {
      type: Object,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      value: this.defaultValue
    }
  },
  computed: {
    orderItems(){
      return this.options.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    value(newValue) {
      console.log(
        'newValue +++ ',
        newValue.map((el) => el.id)
      )
      this.$emit(`setSelected`, {
        value: newValue,
        attribute: this.name145808
      })
      this.$emit(
        `change`,
        newValue.map((el) => el.id)
      )
      this.$emit(
        'update:modelValue',
        newValue.map((el) => el.id)
      )
    }
  }
}
</script>
