export default {
  // #region setters
  getPartnerData(state, payload) {
    return state.partnerData
  },
  getPartnerSharingErrors(state, payload) {
    return state.partnerSharingErrors
  },
  getPartnerSharing(state, payload) {
    return state.partnerSharing
  }
  // #endregion
}
