<template>
  <div>
    <!-- les classes de taille doivent être dynamiques -->
    <div class="box lg:mt-5">
      <!-- header attention faut que je vérifie quand je lui passe une icone aussi  -->
      <header
        v-if="$slots.header || (headerTitle && headerTitle.length)"
        class="flex items-center border-b border-gray-200 p-5 dark:border-slate-600"
      >
        <slot name="header">
          <h2 class="mr-auto text-base font-medium">
            {{ headerTitle }}
          </h2>
        </slot>
      </header>

      <!-- settings -->
      <div class="p-5">
        <slot name="default"></slot>
      </div>

      <!-- actions -->
      <footer class="mt-5 flex justify-end">
        <menu class="text-right">
          <slot name="actions"> </slot>
        </menu>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    headerTitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped></style>
