import getters from '@/store/modules/automation/getters'
import mutations from '@/store/modules/automation/mutations'
import actions from '@/store/modules/automation/actions'

export default {
  namespaced: true,
  state() {
    return {
      automationMetadata: [],
      automationList: [],
      automation: {
        results: [{}],
        conditions: []
      }
    }
  },
  getters,
  actions,
  mutations
}
