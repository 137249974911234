<template>
  <RouterLink
    :to="{
      name: 'mail_box_page_group_id',
      params: { groupId: thread.group_id }
    }"
    class="flex flex-row gap-1 p-1.5 pt-1 text-xs"
    :active-class="'bg-gray-200 rounded'"
  >
    <div class="shrink-0 cursor-default pt-0.5">
      <span class="text-yellow-300">
        <FontAwesomeIcon :icon="['fas', 'star']" size="xs" />
      </span>
    </div>
    <div class="flex min-w-0 flex-1 flex-col">
      <div class="flex shrink-0 items-center">
        <div class="min-w-0 flex-1 truncate text-sm font-bold">
          {{ toText }}
        </div>
        <div class="shrink-0 text-gray-500">{{ sendDateHour }}</div>
      </div>
      <p
        class="line-clamp-3 h-12"
        v-html="thread.message.replace(/(<([^>]+)>)/gi, '')"
      ></p>
    </div>
  </RouterLink>
</template>
<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'ThreadPreviewComponent',
  components: { FontAwesomeIcon },
  props: {
    thread: {
      type: Object,
      required: true
    }
  },
  computed: {
    sendDateHour() {
      return this.$h.momentLocalised(this.thread.send_date, 'LL')
    },
    toText() {
      if (this.thread.direction === 'incoming') {
        return this.thread.from.name
      }
      return Array.isArray(this.thread.to) && this.thread.to.length
        ? this.thread.to.map((item) => item.name).join(', ')
        : this.$t('global.undefined')
    }
  }
})
</script>
