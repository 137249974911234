const DashboardTypes = Object.freeze({
  DASHBOARD: 'dashboard',
  AGENDA: 'agenda',
  PLANNING: 'planning',
  INVOICE: 'invoice',
  PURCHASE_ORDER: 'purchase_order',
  QUOTE: 'quote',
  CREDIT_NOTE: 'credit_note',
  ANALYTICS: 'analytics'
})

export default DashboardTypes
