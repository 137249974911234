<template >
  <span v-html="cellValue"></span >
</template >
<script >
export default {
  name: 'ContentTableCell',
  props: {
    column: {},
    model: {},
  },
  computed: {
    cellValue() {
      if (this.column.type === 'date' && this.model[this.column.field]) {
        return this.model[this.column.field]['label']
      }
      return this.model[this.column.field]
    }
  },


}
</script >
