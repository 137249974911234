export default {
  setNotificationSettingsList(state, notificationList) {
    state.notificationList = notificationList
  },
  setNotification(state, notification) {
    state.notification = notification
  },
  setNotificationMetadata(state, metadata) {
    state.metadata = metadata
  },
  alterNotificationSettings(state, payload) {
    state.notificationList[payload.attribute] = payload.value
  }
}
