<template >
  <input
    :id="`${name}-${indexKey}`"
    class='form-check-input'
    type='checkbox'
    :name='name'
    :value="trackBy ===null ? key : element[trackBy]"
    :checked="modelValue"
    @change="updateInput"
  >
  <br >
  <label class='form-check-label capitalize-first' :for="`${name}-${indexKey}`">
    {{ translatedLabel }}
  </label >
</template >
<script >
export default {
  name: 'CheckboxWithInput',
  props: {
    attributeLabel: {},
    indexKey: {},
    modelValue: {},
    name: {},
    trackBy: {},
    translatable: {},
    translationExtention: {},
    updateInput: {},
    element: {}
  },
  computed: {
    translatedLabel() {
      if (this.translatable) {
        return this.$t(`${ this.translationExtention }.${ this.key }`)
      }
      else {
        return this.element[this.attributeLabel]
      }
    }
  },
}
</script >
