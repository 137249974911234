<template>
  <div
    class="relative rounded-md border border-slate-300 shadow-sm"
    :class="{
      'mt-2': !!label,
      'cursor-no-drop': disabled
    }"
  >
    <label
      v-if="label"
      for="name"
      class="absolute -top-2 left-2 z-10 -mt-px inline-block rounded-md bg-white/75 px-1 text-xs font-medium text-gray-900 capitalize-first"
    >{{ label }}
      <span v-if="required" class="capitalize text-red-500">*</span>
    </label>
    <Combobox v-model="localValueObject" :disabled="disabled">
      <Float
        adaptive-width
        :offset="2"
        :auto-placement="{
          autoAlignment: true,
          allowedPlacements: ['bottom', 'top'],
        }"
      >
        <div class="flex w-full flex-row overflow-hidden">
          <ComboboxInput
            autocomplete="nope"
            autofill="nope"
            class="w-full rounded-md border-0 px-3 py-2 text-sm leading-5 focus:border-0 focus:ring-0"
            :class="{
              'bg-white': !disabled,
              'bg-gray-100 cursor-no-drop': disabled
            }"
            :display-value="(val) => val?.[attributeLabel] ?? ''"
            @change="query = $event.target.value"
          />
          <div class="absolute inset-y-0 right-3 flex items-center gap-2">
            <div v-if="requesting" class="flex items-center">
              <FontAwesomeIcon
                :icon="['fal', 'spinner-third']"
                class="animate-spin"
              />
            </div>
            <ComboboxButton class="flex items-center">
              <FontAwesomeIcon :icon="['fal', 'chevron-down']" />
            </ComboboxButton>
            <button
              v-if="
                !required && (localValue || localValueObject?.[attributeLabel])
              "
              type="button"
              tabindex="-1"
              class="flex-shrink-0 text-red-600"
              @click.prevent="clear"
            >
              <FontAwesomeIcon :icon="['far', 'times']"></FontAwesomeIcon>
            </button>
          </div>
        </div>
        <ComboboxOptions
          class="z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <template v-if="options === null">
            <div class="relative cursor-default select-none px-4 py-2">
              {{ $t('global.taper_qq') }}
            </div>
          </template>
          <template v-else-if="options.length === 0">
            <div class="relative cursor-default select-none px-4 py-2">
              {{ $t('global.no_result_found') }}
            </div>
          </template>
          <ComboboxOption
            v-for="option in options"
            v-else
            :key="option.id"
            v-slot="{ selected, active, disabled }"
            as="template"
            :value="option"
          >
            <li
              class="relative cursor-default select-none py-2 pl-8 pr-2"
              :class="{
                'bg-gray-200 text-gray-700': disabled,
                'bg-primary-500 text-contrast-primary': selected,
                'bg-primary-300 text-white': active,
                'text-gray-900': !active && !selected
              }"
            >
              <slot
                name="option"
                :option="option"
                :active="active"
                :selected="selected"
                :disabled="disabled"
              >
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ option[attributeLabel] }}
                </span>
              </slot>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center bg-primary-500 pl-3 text-contrast-primary"
              >
                <FontAwesomeIcon :icon="['fas', 'check']" />
              </span>
            </li>
          </ComboboxOption>
          <ComboboxOption
            v-if="allowTags && queryValue"
            v-slot="{ active }"
            :value="queryValue"
          >
            <li
              class="relative cursor-default select-none py-2 pl-8 pr-4"
              :class="{
                'bg-primary-300 text-white': active,
                'text-gray-900': !active
              }"
            >
              <span class="block truncate font-normal italic">{{
                  queryValue[attributeLabel]
                }}</span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </Float>
    </Combobox>
  </div>
  <ErrorContainer :error-key="name" :errors="errors" />
</template>

<script>
import { defineComponent } from 'vue'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import apiClient from '@u/apiClient'
import { debounce } from '@u/debounce'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { Float } from '@headlessui-float/vue'

export default defineComponent({
  name: 'SelectAjax',
  components: {
    Float,
    ErrorContainer,
    ComboboxButton,
    FontAwesomeIcon,
    ComboboxOption,
    ComboboxOptions,
    ComboboxInput,
    Combobox
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    valueObject: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    ajaxExtraParams: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    allowTags: {
      type: Boolean,
      required: false,
      default: false
    },
    regexTag: {
      type: String,
      required: false,
      default: '.*'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:value', 'update:valueObject', 'change', 'clear'],
  data() {
    return {
      requesting: false,
      query: '',
      options: null
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      }
    },
    localValueObject: {
      get() {
        return this.valueObject
      },
      set(value) {
        this.localValue = value[this.trackBy]
        this.$emit('update:valueObject', value)
        this.$emit('change', value)
      }
    },
    queryValue() {
      if (this.allowTags && !!this.query && this.query.length) {
        let re = new RegExp(this.regexTag, 'g')
        if (this.query.match(re)) {
          return { [this.trackBy]: null, [this.attributeLabel]: this.query }
        }
      }
      return null
    }
  },
  watch: {
    query(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.debouncedSearch(newValue)
      }
    }
  },
  created() {
    this.debouncedSearch = debounce((terms) => {
      if (terms && terms.length >= 2) {
        this.search(terms)
      }
    }, 1000)
  },
  methods: {
    remove(index) {
      this.localValues.splice(index, 1)
    },
    clear() {
      this.$emit('update:value', null)
      this.$emit('update:valueObject', null)
      this.$emit('clear')
    },
    search(terms) {
      this.requesting = true
      apiClient
        .get(this.url, {
          params: {
            terms: terms,
            ...this.ajaxExtraParams
          }
        })
        .then((response) => {
          this.options = response.data
          this.requesting = false
        })
    }
  }
})
</script>
