<template>
  <div class="my-6 grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2">
    <BaseShowEditInput
      v-model="contact.company_name"
      :label="$t('attributes.company_name')"
      name="company_name"
      :edition-mode="editionMode"
      :errors="errors.company_name"
      :required="true"
    />
    <BaseShowEditInput
      v-model="contact.tva"
      :label="$t('attributes.TVA')"
      name="tva"
      :edition-mode="editionMode"
      :errors="errors.tva"
      :required="true"
    />
  </div>
</template>

<script>
export default {
  name: 'ContactCardCompanyComponent',
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    }
  }
}
</script>
