<template>
  <div class="flex flex-col">
    <h3 class="text-base font-bold capitalize-first">
      {{ $t('global.button.dashboard.agenda.time_slot') }}
    </h3>
    <div class="flex flex-row flex-wrap gap-2">
      <button
        v-for="predefined_slot in predefined_slots"
        :key="predefined_slot.label"
        type="button"
        class="flex items-center gap-2 rounded-md border px-2 py-1"
        :class="[
          predefined_slot.slotTime.min === slotTime.min &&
          predefined_slot.slotTime.max === slotTime.max
            ? 'bg-blue-400 text-white'
            : 'bg-white'
        ]"
        @click="() => handlePredefinedSlot(predefined_slot)"
      >
        <span class="capitalize-first">{{ predefined_slot.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeSelector',
  props: {
    slotTime: {
      type: Object,
      required: true
    }
  },
  emits: ['update:slotTime'],
  data() {
    return {
      predefined_slots: [
        {
          label: this.$t('global.button.dashboard.agenda.business_hour'),
          gate: true,
          slotTime: {
            min: '09:00:00',
            max: '18:00:00'
          }
        },
        {
          label: this.$t('global.button.dashboard.agenda.extended_hour'),
          gate: true,
          slotTime: {
            min: '06:00:00',
            max: '20:00:00'
          }
        },
        {
          label: this.$t('global.button.dashboard.agenda.whole_day'),
          gate: true,
          slotTime: {
            min: '00:00:00',
            max: '24:00:00'
          }
        }
      ]
    }
  },
  computed: {
    localSlotTime: {
      get() {
        return this.slotTime
      },
      set(value) {
        this.$emit('update:slotTime', value)
      }
    }
  },
  methods: {
    handlePredefinedSlot(predefined_slot) {
      this.localSlotTime = {
        min: predefined_slot.slotTime.min,
        max: predefined_slot.slotTime.max
      }
    }
  }
}
</script>

<style scoped></style>
