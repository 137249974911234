<template>
  <div class="flex h-full max-h-full flex-col">
    <div
      :class="[inAScrollablePage ? 'sticky top-14' : '', 'flex-shrink-0']"
      class="px-4 pt-2"
    >
      <BaseSimpleTab v-if="tabs" :tabs="tabs" color-when-selected="primary"/>
    </div>
    <div class="flex h-full max-h-full flex-col overflow-scroll bg-gray-50">
      <div
        v-if="pageHeader"
        class="sticky inset-x-0 top-0 z-50 flex shrink-0 flex-col justify-center gap-3 border-b border-gray-300 bg-white bg-opacity-50 p-3 px-4 text-center backdrop-blur backdrop-filter md:flex-row md:justify-between"
      >
        <div class="flex flex-row justify-center gap-x-3 md:min-w-0 md:flex-1">
          <div class="flex min-w-0 flex-1 space-x-3">
            <FontAwesomeIcon
              v-if="pageTitleIcon"
              :icon="['fal', pageTitleIcon]"
              size="2x"
            />
            <span
              v-if="pageTitle"
              class="truncate text-3xl font-bold capitalize-first"
            >{{ pageTitle }}</span
            >
            <slot name="header"/>
          </div>
        </div>
        <div class="flex justify-center md:shrink-0">
          <div class="flex gap-x-2">
            <slot name="buttons"/>
            <BaseButton
              v-if="editButtonCondition"
              theme-color="bg-secondary text-contrast-secondary"
              :icon="editionMode ? 'times' : 'pen'"
              :title="editionMode ? $t('button.cancel') : $t('button.edit')"
              :disabled="formSubmitting"
              :display-loader="formSubmitting"
              @click="switchEditModeFunction"
            />
            <BaseButton
              theme-color="bg-primary text-contrast-primary"
              icon="long-arrow-left"
              :title="$t('button.back')"
              :disabled="formSubmitting"
              :display-loader="formSubmitting"
              @click="redirectBackButtonFunction"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-1">
        <div class="relative flex-1 @container/dossier-main">
          <RouterView v-slot="{ Component }">
            <transition
              mode="out-in"
              enter-active-class="duration-200 ease-in-out"
              enter-from-class="transform opacity-0 translate-x-2"
              enter-to-class="opacity-100"
              leave-active-class="duration-200 ease-in-out"
              leave-from-class="opacity-100"
              leave-to-class="transform opacity-0 -translate-x-2"
            >
              <component :is="Component"/>
            </transition>
          </RouterView>
        </div>
      </div>
      <div
        v-if="withFooter"
        class="sticky bottom-0 z-50 flex flex-col justify-end gap-3 border-t border-gray-300 bg-white bg-opacity-50 p-3 px-5 backdrop-blur backdrop-filter md:flex-row"
        :class="[mainButtonDisplayCondition ? 'gap-x-3' : '']"
      >
        <BaseButton
          v-if="thereIsAPreviousTab"
          theme-color="bg-secondary text-contrast-secondary"
          color="secondary"
          icon="chevron-left"
          :title="$t('button.previous')"
          :disabled="formSubmitting"
          :display-loader="formSubmitting"
          @click="redirectToPreviousTab"
        />
        <BaseButton
          v-if="thereIsATabAfter"
          theme-color="bg-secondary text-contrast-secondary"
          icon="chevron-right"
          :title="$t('button.next')"
          :disabled="formSubmitting"
          :display-loader="formSubmitting"
          @click="redirectToNextTab"
        />
        <BaseButton
          v-if="mainButtonDisplayCondition"
          theme-color="bg-primary text-contrast-primary"
          :icon="mainButtonIcon"
          :title="mainButtonTitle"
          :disabled="formSubmitting"
          :display-loader="formSubmitting"
          @click="mainButtonFunction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSimpleTab from '@c/addf-package/components/BaseTabs/BaseSimpleTab.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  name: 'TabContentFormComponent',
  components: {FontAwesomeIcon, BaseButton, BaseSimpleTab},
  props: {
    tabs: {
      type: Array,
      required: true
    },
    mainButtonFunction: {
      type: Function,
      required: true
    },
    mainButtonTitle: {
      type: String,
      required: true
    },
    mainButtonIcon: {
      type: String,
      required: false,
      default: null
    },
    mainButtonDisplayCondition: {
      type: Boolean,
      required: true
    },
    pageTitleIcon: {
      type: String,
      required: false,
      default: null
    },
    pageTitle: {
      type: String,
      required: false,
      default: null
    },
    switchEditModeFunction: {
      type: Function,
      required: false,
      default: null
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    editButtonCondition: {
      type: Boolean,
      required: false,
      default: false
    },
    pageHeader: {
      type: Boolean,
      required: true
    },
    redirectBackButtonFunction: {
      type: Function,
      required: false,
      default: () => {
        this.$router.go(-1)
      }
    },
    withFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    inAScrollablePage: {
      type: Boolean,
      required: false,
      default: false
    },
    formSubmitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    filteredTabs() {
      return this.tabs?.filter((tab) => tab.gate === true)
    },
    currentTabIndex() {
      return this.filteredTabs?.indexOf(
        this.filteredTabs.find((tab) => tab.routeName === this.$route.name)
      )
    },
    thereIsAPreviousTab() {
      return this.currentTabIndex > 0
    },
    thereIsATabAfter() {
      return this.currentTabIndex < this.filteredTabs?.length - 1
    }
  },
  methods: {
    redirectToPreviousTab() {
      this.$router.push({
        name: this.filteredTabs[this.currentTabIndex - 1]?.routeName
      })
    },
    redirectToNextTab() {
      this.$router.push({
        name: this.filteredTabs[this.currentTabIndex + 1]?.routeName
      })
    }
  }
}
</script>
