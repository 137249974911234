<template>
  <div class="flex max-h-screen h-5/6 px-36">
    <div class="w-full h-full flex flex-col gap-y-8">

      <div
        v-if="$can('manage_wiki_city')"
        class="flex justify-end"
      >
        <BaseButton
          :title="$t('button.add_wiki_city')"
          icon="plus"
          @click="$router.push({name: 'create_wiki_city_information_page_route'})"
        />
      </div>

      <div class="w-full max-h-full grid grid-cols-2 gap-x-16">

        <WikiClosestCitiesList/>

        <WikiFavoriteCitiesList/>

      </div>

    </div>

  </div>

</template>

<script>

import WikiClosestCitiesList from "@/views/wiki/communes/index/components/WikiClosestCitiesList.vue";
import WikiFavoriteCitiesList from "@/views/wiki/communes/index/components/WikiFavoriteCitiesList.vue";

export default {
  name: "TheWikiCityIndexPage",
  components: {WikiFavoriteCitiesList, WikiClosestCitiesList},
}
</script>
