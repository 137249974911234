const TheChatPage = () =>
  import(/* webpackChunkName: "group-chat" */ '@/views/chat/TheChatPage')
const TheChatDialog = () =>
  import(
    /* webpackChunkName: "group-chat" */ '@/views/chat/components/dialog/TheChatDialog'
  )
const TheChatEmptyPage = () =>
  import(
    /* webpackChunkName: "group-chat" */ '@/views/chat/components/TheChatEmptyPage'
  )

export const chatRootPages = [
  {
    path: 'chat',
    name: 'chat_page_route',
    to: { name: 'chat_page_default_route' },
    children: [
      {
        path: '',
        name: 'chat_page_default_route',
        component: TheChatEmptyPage
      },
      {
        path: ':id',
        name: 'chat_page_dialog_route',
        props: true,
        component: TheChatDialog
      }
    ],
    component: TheChatPage
  }
]
