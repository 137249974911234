import CheckGetters from './check/getters'
import PersonGetters from './person/getters'
import ExportGetters from './export/getters'

export default {
  ...CheckGetters,
  ...PersonGetters,
  ...ExportGetters,

  getFilters(state) {
    return state.filters
  },
  getInvoiceIndex(state) {
    return state.invoiceIndex
  },
  getInvoice(state) {
    return state.invoice
  },
  getInvoiceMetadata(state) {
    return state.invoiceMetadata
  },

  getInvoiceItemsMetadata(state) {
    return state.invoiceItemsMetadata
  },
  getInvoiceItem(state) {
    return state.invoiceItem
  },
  getInvoiceItems(state) {
    return state.invoiceItems
  },
  getPayment(state) {
    return state.payment
  },
  getPaymentMetadata(state) {
    return state.paymentMetadata
  },
  getInvoiceActivity(state) {
    return state.invoiceActivity
  },
  getMetadataActivities(state) {
    return state.metadataActivities
  },
  getSendInvoiceData(state) {
    return state.sendInvoiceData
  },
  getOnlineMembers(state) {
    return state.online_members
  }
}
