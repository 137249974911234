const TheAutomationIndexPage = () =>
  import('@/views/settings/management/automations/index/TheAutomationIndexPage')

const TheAutomationFormPage = () =>
  import(
    '@/views/settings/management/automations/form/TheAutomationFormPage.vue'
  )
const TheOldAutomationFormPage = () =>
  import(
    '@/views/settings/management/automations/form2/TheOldAutomationFormPage.vue'
  )

export const settingsAutomationRoutes = [
  {
    path: 'automations',
    children: [
      {
        path: '',
        name: 'settings_list_automations_route',
        component: TheAutomationIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_automations_route',
        component: TheAutomationFormPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_new_title', active: true }
          ]
        }
      },
      {
        path: ':automationId',
        children: [
          {
            path: '',
            component: TheAutomationFormPage,
            name: 'settings_edit_automations_route',
            props: true
          },
          {
            path: 'old',
            component: TheOldAutomationFormPage,
            name: 'old_settings_edit_automations_route',
            props: true
          }
        ]
      }
    ]
  }
]
