import TheWikiCityIndexPage from '@/views/wiki/communes/index/TheWikiCityIndexPage.vue'
import TheWikiCityFormLayout from '@/views/wiki/communes/form/TheWikiCityFormLayout.vue'

import {wikiCityMunicipalAdministrationRoutes} from './municipalAdministration/index'
import {wikiCityCemeteryRoute} from './cemetery/index'
import {wikiCityCeremonyRoutes} from './ceremony/index'
import {wikiCityHospitalRoutes} from './hospital/index'
import {wikiCityRestHomeRoutes} from './restHome/index'
import {wikiCityFeesAndTaxesRoutes} from './feesAndTaxes/index'
import {wikiCityCrematoriumRoutes} from './crematorium/index'
import {wikiCityFunerariumRoutes} from './funerarium/index'
import {wikiDocumentRoutes} from './document/index'
import {wikiCityContactRoutes} from './contacts/index'
import WikiCityFormTabComponent from '@/views/wiki/communes/form/tabs/city/form/WikiCityFormTabComponent.vue'
import WikiCityInformation from '@/views/wiki/communes/form/tabs/city/form/tabs/information/WikiCityInformation.vue'
import WikiCityCities from '@/views/wiki/communes/form/tabs/city/form/tabs/cities/WikiCityCities.vue'

export const wikiCityRoutes = [
  {
    path: ':city_id',
    name: 'wiki_edit_city_page_route',
    props: true,
    component: TheWikiCityFormLayout,
    children: [
      {
        path: '',
        props: true,
        redirect: {
          name: 'wiki_city_municipal_administration_index_page_route'
        }
      },
      ...wikiCityMunicipalAdministrationRoutes,
      ...wikiCityCemeteryRoute,
      ...wikiCityCeremonyRoutes,
      ...wikiCityHospitalRoutes,
      ...wikiCityRestHomeRoutes,
      ...wikiCityFeesAndTaxesRoutes,
      ...wikiCityCrematoriumRoutes,
      ...wikiCityFunerariumRoutes,
      ...wikiDocumentRoutes,
      ...wikiCityContactRoutes
    ]
  },
  {
    path: 'new',
    name: 'create_wiki_city_page_route',
    component: WikiCityFormTabComponent,
    children: [
      {
        path: 'information',
        name: 'create_wiki_city_information_page_route',
        component: WikiCityInformation
      },
      {
        path: 'cities',
        name: 'create_wiki_city_cities_page_route',
        component: WikiCityCities
      }
    ],
    meta: {
      type: 'create'
    }
  },
  {
    path: ':city_id',
    name: 'edit_wiki_city_page_route',
    props: true,
    component: WikiCityFormTabComponent,
    children: [
      {
        path: 'information',
        name: 'edit_wiki_city_information_page_route',
        component: WikiCityInformation
      },
      {
        path: 'cities',
        name: 'edit_wiki_city_cities_page_route',
        component: WikiCityCities
      }
    ],
    meta: {
      type: 'edit'
    }
  }
]
