<template>
  <SelectAjax
    v-model:value-object="localDossier"
    :url="'dossier-for-select'"
    attribute-label="title"
    :label="$t('global.dossier')"
    :disabled="disabled"
  />
</template>

<script>
import { defineComponent } from 'vue'
import SelectAjax from '@c/SelectAjaxComponent/component/SelectAjax.vue'

export default defineComponent({
  name: 'SelectDossier',
  components: { SelectAjax },
  props: {
    dossier: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:dossier', 'unselect', 'select'],
  computed: {
    localDossier: {
      get() {
        return this.dossier
      },
      set(value) {
        this.$emit('update:dossier', value)
        if (value) {
          this.$emit('select', true)
        } else {
          this.$emit('unselect', true)
        }
      }
    }
  }
})
</script>
