<template>
  <div v-if="editionMode" class="relative">
    <label
      :for="name"
      class="absolute -top-2 left-2 z-60 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
      >{{ label ? label : $t('attributes.funeral_homes') }}&nbsp;
      <span v-if="label && required" class="text-red-600">*</span>
    </label>
    <multiselect
      v-if="metadata?.funeralHomes"
      v-model="cDefaultValue"
      :options="orderFuneralHomes"
      :placeholder="placeholder"
      :searchable="searchable"
      :label="attributeLabel"
      :track-by="trackBy"
      :required="required"
      :select-label="$t('button.select')"
      :deselect-label="$t('button.unselect')"
      :selected-label="$t('button.selected')"
      @update:model-value="updateValueAction"
    />
    <template v-if="errors?.funeral_home_id">
      <div
        v-for="(error, index) in errors?.funeral_home_id"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </div>
  <div v-else class="flex flex-col">
    <BaseShowLabel
      :label="label ? label : $t('attributes.funeral_homes')"
      :required="required"
      :model-value="cDefaultValue?.name"
    />
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  name: 'BaseFuneralHomeShowEditSelectSimple',
  components: { multiselect },
  props: {
    name: {
      type: String,
      required: false,
      default: 'funeral_homes'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    metadata: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    selectClass: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    cDefaultValue() {
      return (
        this.metadata?.funeralHomes?.find(
          (option) => option.id === this.modelValue
        ) ?? null
      )
    },
    orderFuneralHomes(){
      return this.metadata?.funeralHomes.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue) {
        this.$emit('update:modelValue', newValue[this.trackBy])
      } else {
        this.$emit('update:modelValue', null)
      }
    }
  }
}
</script>
