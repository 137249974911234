import apiClient from '@u/apiClient'

const baseErrors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  deleteContact({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`contact/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  undoDeleteContact: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`contact/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // region fetch

  async fetchContactList({ commit }) {
    await apiClient
      .get(`contact`)
      .then(async (response) => {
        await commit('setContactList', response.data.contacts)
      })
      .catch(async (reason) => {
        await console.error('fetchContactList', reason)
      })
  },

  async fetchRoleContactList({ commit }, payload) {
    await apiClient
      .get(`contact?role=${payload.id}`)
      .then(async (response) => {
        commit('setRoleContactList', response.data.contacts)
        commit('setContactMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchContactList', reason)
      })
  },
  async fetchContactById({ commit }, payload) {
    commit('setContact', {})
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get(`contact/${payload.contact_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setContact', response.data.contact)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchContactById', reason)
      })
  },
  async fetchNewContact({ commit }, predefinedRole) {
    commit('setContact', {})
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .get(`model/new?model=contact`)
      .then(async (response) => {
        if (predefinedRole) {
          let temp = response.data.contact
          temp.role = predefinedRole
          await commit('setContact', temp)
        } else {
          await commit('setContact', response.data.contact)
        }
      })
      .catch(async (reason) => {
        await console.error('fetchNewContact', reason)
      })
  },
  // endregion

  // region store
  async storeNewContact({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .post('contact', payload.contact)
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
          commit('setContactList', response.data.contacts)
          commit('setContactMetadata', response.data.metadata)
        }
      })
      .catch((reason) => {
        console.error('error storeNewAddress', reason)
      })
  },
  // endregion

  // region update
  async updateContact({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    await apiClient
      .put(`contact/${payload.id}`, payload.contact)
      .then((response) => {
        if (response.status === 201) {
          commit('setContactList', response.data.contacts)
          commit('setContactMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('updateContact', reason)
      })
  }
  // endregion
}
